import httpClient from "./httpClient";
import axios from "axios";
import QueryString from "query-string";

const RETURNS = "/users/me/retake";
const UPLOAD = RETURNS + "/upload";
const PHASE_2 = "/retake/phase2/v2";
const EXTERIOR_COLORS = "/retake/exteriorColors";
const INTERIOR_COLORS = "/retake/interiors";

function getAllReturns() {
  return httpClient.get(RETURNS);
}

function getReturnDetails(id) {
  return httpClient.get(RETURNS + "/" + id);
}

function addPhoto(file) {
  axios.post(UPLOAD, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

const buildQueryString = () => {
  const utms = JSON.parse(sessionStorage.getItem("utms")) || {};
  let queryString = "?";
  queryString += QueryString.stringify(utms);

  return queryString;
};

function updateReturn(details) {
  const queryString = buildQueryString();

  return new Promise((resolve) => {
    resolve(httpClient.post(PHASE_2 + queryString, details));
  });
}

function deleteReturn(id) {
  return httpClient.delete(RETURNS + "/" + id);
}

function getExteriorColors({ companyID }) {
  return httpClient.get(EXTERIOR_COLORS, {
    params: { companyID },
  });
}

function getInteriorColors({ companyID }) {
  return httpClient.get(INTERIOR_COLORS, {
    params: { companyID },
  });
}

export {
  getAllReturns,
  addPhoto,
  getReturnDetails,
  deleteReturn,
  updateReturn,
  getExteriorColors,
  getInteriorColors,
};
