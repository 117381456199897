import httpClient from "./httpClient";
import QueryString from "query-string";

const VEHICLE_BY_PLATE = "/retake/plate/v2/";
const GET_VALUATION = "/retake/phase1/v2";
const SAVE_RETAKE = "/users/retake/";
const UPDATE_TO_SELL = "/retake/{id}/sell";
const GET_ALL_BRANDS = "/brands";
const GET_ALL_MODELS = "/brands/{id}/models/v2?year=";
const GET_ALL_VERSIONS = "/brands/{id}/models/v2/{modelId}/version?year=";

const buildQueryString = () => {
  const utms = JSON.parse(sessionStorage.getItem("utms")) || {};
  let queryString = "?";
  queryString += QueryString.stringify(utms);

  return queryString;
};

function getVehicleByPlate(plate) {
  return httpClient.get(VEHICLE_BY_PLATE + plate);
}

function getBrands() {
  return httpClient.get(GET_ALL_BRANDS);
}

function getModels(brand, year) {
  return httpClient.get(GET_ALL_MODELS.replace("{id}", brand) + year);
}

function getVersions(brand, modelId, year) {
  return httpClient.get(
    GET_ALL_VERSIONS.replace("{id}", brand).replace("{modelId}", modelId) + year
  );
}

function getValuation(valuationDetails) {
  const queryString = buildQueryString();
  return httpClient.post(GET_VALUATION + queryString, valuationDetails);
}

function saveValuation(retakeId) {
  return httpClient.post(SAVE_RETAKE, retakeId);
}

export function updateToSell(retakeId, sellInStore) {
  return httpClient.put(UPDATE_TO_SELL.replace("{id}", retakeId), sellInStore);
}

export {
  getVehicleByPlate,
  getBrands,
  getModels,
  getVersions,
  getValuation,
  saveValuation,
};
