<template>
  <div
    :class="['container--wrapper', customClass, classes]"
    :style="customStyles"
  >
    <div class="title--content">
      <div class="tags">
        <StatusTag
          class="status-tag"
          :type="
            $t(
              `pages.personalPage.reservations.savedReservation.labels.missingActions.type`
            )
          "
          :label="
            $t(
              `pages.personalPage.reservations.savedReservation.labels.missingActions.description`
            )
          "
          v-if="showActionsMissingTag"
        />
      </div>
      <Label
        class="price-model"
        color="primary"
        bold="bold"
        :size="titleSize"
        :customClass="size === 'medium' ? 'title--content-title' : ''"
      >
        {{ productTitle }}
      </Label>
      <Label
        class="price-model-description"
        color="tertiary"
        :size="smallSize"
        :customClass="size === 'medium' ? 'title--content-subtitle' : ''"
      >
        {{ productDescription }}
      </Label>
    </div>
    <div class="finance--container">
      <Label
        class="finance-model-price"
        color="secondary"
        bold="bold"
        :size="smallSize"
        align="right"
        :customClass="size === 'medium' ? 'finance--container-title' : ''"
      >
        {{ priceLabel }}
      </Label>
      <div class="monthtaeg">
        <div class="finance--wrapper" v-if="!absolutePrice && hasFinancing">
          <div class="tootltip-container">
            <Tooltip
              v-if="!noTooltip"
              :tooltip="tooltipDescription"
              :position="tooltipPosition"
              size="large"
            >
              <template v-slot:content>
                <Icon type="dark" size="18" icon="info" class="icon--margin" />
              </template>
            </Tooltip>
          </div>
          <Label
            color="secondary"
            class="finance-model-modality modality--margin"
            :size="smallSize"
            align="right"
            :customClass="
              size === 'medium' ? 'finance--container-subtitle' : ''
            "
          >
            {{ modality + $t("pages.detailsPage.modality") }}
          </Label>
        </div>
        <Label
          v-if="!absolutePrice && hasFinancing"
          class="finance-model-taeg taeg--margin"
          color="secondary"
          bold="normal"
          :size="smallSize"
          align="right"
          :customClass="
            size === 'medium'
              ? 'finance--container-subtitle'
              : 'finance-model-taeg'
          "
        >
          {{ financeTaeg }}
        </Label>
      </div>
      <div class="financing-details" v-if="hasFinancing">
        <div>
          <p class="financing-details-block">
            <span class="financing-details-light">{{
              `${this.$t("vehicle.monthly")}: `
            }}</span
            ><span class="financing-details-dark">{{ monthlyFinancing }}</span>
          </p>
        </div>
        <div>
          <p class="financing-details-block">
            <span class="financing-details-light">{{
              `${this.$t("vehicle.entry")}: `
            }}</span
            ><span class="financing-details-dark">{{ entryFinancing }}</span>
          </p>
        </div>
        <div>
          <p class="financing-details-block">
            <span class="financing-details-light">{{
              `${this.$t("vehicle.financing")}: `
            }}</span
            ><span class="financing-details-dark">{{
              totalValueFinancing
            }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Label } from "./../../atoms";
import { Icon, StatusTag, Tooltip } from "@sc/ds-ui-component-library";
import { getPriceFormatted } from "../../../utils/currency";

export default {
  name: "PriceModelDetails",
  components: { Label, Tooltip, Icon, StatusTag },
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      },
    },
    customClass: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "small",
      validator: (value) => {
        return ["small", "medium"].indexOf(value) !== -1;
      },
    },
    noTooltip: {
      type: Boolean,
      default: false,
    },
    absolutePrice: {
      type: Boolean,
      default: false,
    },
    productTitle: {
      type: String,
      default: "",
    },
    productDescription: {
      type: String,
      default: "",
    },
    priceLabel: {
      type: String,
      default: "",
    },
    financeTaeg: {
      type: String,
      default: "",
    },
    modality: {
      type: String,
      default: "",
    },
    iconName: {
      type: String,
      default: "",
    },
    tooltipDescription: {
      type: String,
      default: "",
    },
    tooltipPosition: {
      type: String,
      default: "top",
    },
    showActionsMissingTag: {
      type: Boolean,
      default: true,
    },
    vehicle: {
      type: Object,
    },
  },
  computed: {
    hasFinancing() {
      if (
        this.vehicle &&
        this.vehicle.tooltipDescription &&
        this.vehicle.tooltipDescription.depositValue &&
        this.vehicle.tooltipDescription.months &&
        this.vehicle.tooltipDescription.capitalFinance &&
        this.financeTaeg &&
        this.modality
      )
        return true;
      return false;
    },
    entryFinancing() {
      if (this.hasFinancing)
        return `${getPriceFormatted(
          this.vehicle.tooltipDescription.depositValue
        )}`;
      return "";
    },
    monthlyFinancing() {
      if (this.hasFinancing)
        return `${this.vehicle.tooltipDescription.months} meses`;
      return "";
    },
    totalValueFinancing() {
      if (this.hasFinancing)
        return `${getPriceFormatted(
          this.vehicle.tooltipDescription.capitalFinance
        )}`;
      return "";
    },
    classes() {
      return {
        ...this.customClass,
      };
    },
    titleSize() {
      return this.size === "medium"
        ? "medium"
        : this.size === "small"
        ? "small"
        : "";
    },

    smallSize() {
      return this.size === "medium"
        ? "small"
        : this.size === "small"
        ? "x-small"
        : "";
    },
    customStyles() {
      return {
        ...this.styles,
      };
    },
  },
};
</script>

<style lang="css" scoped src="./PriceModelDetails.css"></style>
