<template>
  <div class="mechanical-general-condition">
    <div class="mechanical-general-condition--title">
      <Label color="primary" size="medium">
        {{
          $t(
            "pages.personalPage.returnDetails.mechanicalAndGeneralConditions.title"
          )
        }}
      </Label>
    </div>
    <div class="mechanical-general-condition--subtitle">
      <TextLabel
        customClass="subtitle"
        :label="
          $t(
            'pages.personalPage.returnDetails.mechanicalAndGeneralConditions.subtitle'
          )
        "
      >
      </TextLabel>
    </div>
    <div class="mechanical-general-condition--content">
      <div class="mechanical-general-condition--content-left">
        <div class="mechanical-general-condition--content-left-title">
          <Label customClass="dark-label" color="tertiary" size="small">
            {{
              $t(
                "pages.personalPage.returnDetails.mechanicalAndGeneralConditions.mechanicalState"
              )
            }}
          </Label>
        </div>
        <div class="mechanical-general-condition--content-left-group">
          <div
            class="radio-list"
            v-for="(item, index) in mechanicalStateList"
            :key="index"
          >
            <RadioGroup
              :disabled="getReturn.submitted"
              :title="item.title"
              :subtitle="item.subtitle"
              :options="item.radioGroupList"
              @onInput="onChangeMechanicalState(index, $event)"
            />
            <Label
              v-if="emptyMechanicalValues(index)"
              class="validate-message--label"
              color="tertiary"
              bold="thin"
              size="small"
            >
              {{
                $t(
                  "pages.personalPage.returnDetails.mechanicalAndGeneralConditions.warningLabel"
                )
              }}
            </Label>
            <div
              v-if="index < mechanicalStateList.length - 1"
              class="divider"
            />
          </div>
        </div>
      </div>
      <div class="mechanical-general-condition--content-right">
        <div class="mechanical-general-condition--content-right-title">
          <Label customClass="dark-label" color="tertiary" size="small">
            {{
              $t(
                "pages.personalPage.returnDetails.mechanicalAndGeneralConditions.generalState"
              )
            }}
          </Label>
        </div>
        <div class="mechanical-general-condition--content-right-group">
          <div
            class="radio-list"
            v-for="(item, index) in generalStatusList"
            :key="index"
          >
            <RadioGroup
              :disabled="getReturn.submitted"
              :title="item.title"
              :subtitle="item.subtitle"
              :options="item.radioGroupList"
              @onInput="onChangeGeneralState(index, $event)"
            />
            <Label
              v-if="emptyGeneralStatus(index)"
              class="validate-message--label"
              color="tertiary"
              bold="thin"
              size="small"
            >
              {{
                $t(
                  "pages.personalPage.returnDetails.mechanicalAndGeneralConditions.warningLabel"
                )
              }}
            </Label>
            <div v-if="index < generalStatusList.length - 1" class="divider" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Label } from "../../atoms";
import TextLabel from "../../refactor/atoms/TextLabel/TextLabel.vue";
import { RadioGroup } from "@sc/ds-ui-component-library";
export default {
  name: "MechanicalAndGeneralCondition",
  components: {
    Label,
    TextLabel,
    RadioGroup,
  },
  data() {
    return {
      mechanicalAndGeneralStatus: {
        mechanicalStateList: this.mechanicalStateList,
        generalStatusList: this.generalStatusList,
      },
    };
  },
  props: {
    mechanicalStateList: {
      type: Array[Object],
    },
    generalStatusList: {
      type: Array[Object],
    },
    hasSubmitted: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onChangeMechanicalState(rad, event) {
      this.mechanicalAndGeneralStatus.mechanicalStateList[rad].radioGroupList =
        event;

      this.mechanicalAndGeneralStatus.mechanicalStateList[rad].radioGroupValue =
        event.find((e) => e.checked)?.radioValue;

      this.$store.commit(
        "setMechanicalState",
        this.mechanicalAndGeneralStatus.mechanicalStateList
      );
    },
    onChangeGeneralState(rad, event) {
      this.mechanicalAndGeneralStatus.generalStatusList[rad].radioGroupList =
        event;

      this.mechanicalAndGeneralStatus.generalStatusList[rad].radioGroupValue =
        event.find((e) => e.checked)?.radioValue;

      this.$store.commit(
        "setGeneralStatus",
        this.mechanicalAndGeneralStatus.generalStatusList
      );
    },
    emptyMechanicalValues(index) {
      return (
        !this.getMechanicalState[index].radioGroupList.some(
          (elem) => !!elem.checked
        ) && this.hasSubmitted
      );
    },
    emptyGeneralStatus(index) {
      return (
        !this.getGeneralStatus[index].radioGroupList.some(
          (elem) => !!elem.checked
        ) && this.hasSubmitted
      );
    },
  },
  computed: {
    ...mapGetters(["getReturn", "getMechanicalState", "getGeneralStatus"]),
  },
};
</script>

<style lang="css" scoped src="./MechanicalAndGeneralCondition.css"></style>
