<template>
  <Section id="section-5" class="section-bg-white">
    <Heading
      class="header"
      :label="$t('pages.homepage.ourdifference.title')"
      tag="h2"
    />
    <DifferencesSwiper :slides="differences" />
  </Section>
</template>

<script>
import {
  Section,
  DifferencesSwiper,
  Heading,
} from "@sc/ds-ui-component-library";

export default {
  name: "CpOurDifference",
  components: {
    Section,
    DifferencesSwiper,
    Heading,
  },
  computed: {
    differences() {
      return [
        {
          image:
            this.$t("resources.images.homepage.differencesSwiperCard1") ||
            require("@/assets/images/homepage/Test-Drive.svg"),
          title: this.$t("pages.homepage.ourdifference.0.title"),
          description: this.$t("pages.homepage.ourdifference.0.description"),
        },
        {
          image:
            this.$t("resources.images.homepage.differencesSwiperCard2") ||
            require("@/assets/images/homepage/Garantia.svg"),
          title: this.$t("pages.homepage.ourdifference.1.title"),
          description: this.$t("pages.homepage.ourdifference.1.description"),
        },
        {
          image:
            this.$t("resources.images.homepage.differencesSwiperCard3") ||
            require("@/assets/images/homepage/Satisfacao_Reembolso.svg"),
          title: this.$t("pages.homepage.ourdifference.2.title"),
          description: this.$t("pages.homepage.ourdifference.2.description"),
        },
        {
          image:
            this.$t("resources.images.homepage.differencesSwiperCard4") ||
            require("@/assets/images/homepage/Check-Up.svg"),
          title: this.$t("pages.homepage.ourdifference.3.title"),
          description: this.$t("pages.homepage.ourdifference.3.description"),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
#section-5 {
  padding-top: 0;
  --section-header-margin: 64px auto;
  --diff-swiper-slide-max-width: 436px;
  --diff-card-max-width: 100%;
  --card-shadow: 0px 1px 3px -1px rgba(0, 0, 0, 0.3),
    0px 2px 5px -1px rgba(50, 50, 93, 0.25);
  --card-shadow-hover: 0px 1px 3px -1px rgba(0, 0, 0, 0.3),
    0px 2px 5px -1px rgba(50, 50, 93, 0.25);

  .header {
    --heading-color: var(--color-1);
    margin: var(--section-header-margin);
    text-align: center;
  }

  ::v-deep .h2 {
    .title {
      color: var(--color-1);
      text-align: center;
    }
  }
}

.cards {
  display: flex;
  gap: 16px;
  --card-border-radius: 4px;
  --card-header-shadow: none;

  .card-content-inline {
    flex-direction: column;
    --card-content-align: center;
    text-align: center;
    --text-label-font-size: 16px;
    gap: 16px;
    padding: 40px 48px;
    --title-color: var(--theme-color);
  }
  .separator {
    height: 1px;
    width: 356px;
    background-color: var(--color-4);
    margin: auto;
  }
}

@media (max-width: 1440px) {
}
@media (max-width: 1366px) {
}
@media (max-width: 1280px) {
}
@media (max-width: 1024px) {
}
@media (max-width: 768px) {
  .cards {
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  .header {
    --heading-large-font-size: var(--subtitle-1-font-size);
    --heading-large-line-height: var(--subtitle-1-line-height);
  }
}
@media (max-width: 375px) {
}
</style>
