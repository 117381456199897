<template>
  <Section id="section-mini">
    <div class="mini">
      <WidgetMini
        businessunitId="618d0f0432485112032d0938"
        href="https://pt.trustpilot.com/review/carplus.pt"
      />
    </div>
  </Section>
</template>

<script>
import { Section, WidgetMini } from "@sc/ds-ui-component-library";

export default {
  name: "TrustpilotMini",
  components: {
    Section,
    WidgetMini,
  },
};
</script>

<style lang="scss" scoped>
#section-mini {
  --section-bg-color: var(--color-7);

  .mini {
    &:after {
      content: "";
      height: 1px;
      width: 35%;
      background-color: var(--color-4);
      position: absolute;
      margin-top: -9px;
    }
    &:before {
      content: "";
      height: 1px;
      width: 35%;
      background-color: var(--color-4);
      position: absolute;
      margin-top: 11px;
      right: 64px;
    }
  }
}

@media (min-width: 2000px) {
  #section-mini {
    .mini {
      &:after {
        width: 35%;
        left: 7%;
      }
      &:before {
        width: 35%;
        right: 7%;
      }
    }
  }
}

@media (min-width: 2300px) {
  #section-mini {
    .mini {
      &:after {
        width: 30%;
        left: 13%;
      }
      &:before {
        width: 30%;
        right: 13%;
      }
    }
  }
}

@media (min-width: 2800px) {
  #section-mini {
    .mini {
      &:after {
        width: 25%;
        left: 20%;
      }
      &:before {
        width: 25%;
        right: 20%;
      }
    }
  }
}

@media (min-width: 3300px) {
  #section-mini {
    .mini {
      &:after {
        width: 20%;
        left: 25%;
      }
      &:before {
        width: 20%;
        right: 25%;
      }
    }
  }
}

@media (max-width: 1200px) {
  #section-mini {
    .mini {
      &:after {
        width: 30%;
      }
      &:before {
        width: 30%;
      }
    }
  }
}

@media (max-width: 1000px) {
  #section-mini {
    .mini {
      &:after {
        left: 20px;
      }
      &:before {
        right: 20px;
      }
    }
  }
}

@media (max-width: 800px) {
  #section-mini {
    .mini {
      &:after {
        width: 25%;
      }
      &:before {
        width: 25%;
      }
    }
  }
}

@media (max-width: 400px) {
  #section-mini {
    .mini {
      &:after {
        width: 15%;
      }
      &:before {
        width: 15%;
      }
    }
  }
}
</style>
