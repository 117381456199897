<template>
  <section :id="id" :class="['section', customClass]">
    <slot />
  </section>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    customClass: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.section {
  margin: var(--section-margin);
  padding: var(--section-padding);
  box-sizing: var(--section-box-sizing);
  max-width: var(--section-max-width);
  background-color: var(--section-bg-color);
  overflow: var(--section-overflow);
  transition: padding 0.25s ease-in-out;
}
</style>
