<template>
  <div class="financing-result">
    <div v-if="error === false">
      <div class="financing-result--header">
        <div v-if="withFinancing" class="financing-result--header-left">
          <div>
            <Label customClass="header-title">
              {{ getFormattedMonthlyPriceSimple }}
            </Label>
          </div>
          <div>
            <Label customClass="header-subtitle">{{
              $t("pages.detailsPage.financing.financingResult.month")
            }}</Label>
          </div>
        </div>
        <div v-if="!withFinancing" class="financing-result--header-center">
          <div>
            <Label customClass="header-title">{{
              getFormattedTotalPrice
            }}</Label>
          </div>
          <div>
            <Label customClass="header-subtitle">{{
              $t("pages.detailsPage.financing.financingResult.amountToPay")
            }}</Label>
          </div>
        </div>
        <div v-if="withFinancing" class="financing-result--header-right">
          <div>
            <Label customClass="header-title"> {{ getTaeg }}% </Label>
          </div>
          <div>
            <Label customClass="header-subtitle">TAEG</Label>
          </div>
        </div>
      </div>
      <div class="financing-result--content">
        <div v-if="withFinancing" class="withFinancing">
          <div class="content-row-title">
            <Label
              color="tertiary"
              size="small"
              customClass="content-row-label"
              >{{
                $t("pages.detailsPage.financing.financingResult.simulationData")
              }}</Label
            >
          </div>
          <div class="content-row">
            <div>
              <Label
                color="tertiary"
                size="small"
                customClass="content-row-label  content-row-label-left"
                >{{
                  $t("pages.detailsPage.financing.financingResult.monthlyValue")
                }}</Label
              >
            </div>

            <div class="label-icon">
              <div class="month-price">
                <Label
                  color="tertiary"
                  size="small"
                  customClass="content-row-label content-row-label-right"
                >
                  {{ getFormattedMonthlyPriceSimple }}
                </Label>
                <div class="tootltip-container">
                  <Tooltip :tooltip="tooltip" size="large" position="left">
                    <template v-slot:content>
                      <Icon icon="info" :size="18" />
                    </template>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
          <div class="content-row">
            <Label
              color="tertiary"
              size="small"
              customClass="content-row-label content-row-label-left"
              >{{
                $t("pages.detailsPage.financing.financingResult.numberOfMonths")
              }}</Label
            >
            <Label
              color="tertiary"
              size="small"
              customClass="content-row-label content-row-label-right"
              >{{ getVehicleNumberMonths }}</Label
            >
          </div>
          <div class="content-row">
            <Label
              color="tertiary"
              size="small"
              customClass="content-row-label content-row-label-left"
              >TAEG</Label
            >
            <Label
              color="tertiary"
              size="small"
              customClass="content-row-label content-row-label-right"
              >{{ getTaeg }}%
            </Label>
          </div>
          <div class="content-row">
            <Label
              color="tertiary"
              size="small"
              customClass="content-row-label content-row-label-left"
              >{{
                $t("pages.detailsPage.financing.financingResult.financedAmunt")
              }}</Label
            >
            <Label
              color="tertiary"
              size="small"
              customClass="content-row-label content-row-label-right"
              >{{ getFormattedCapitalToFinance }}</Label
            >
          </div>
          <div class="content-row">
            <Label
              color="tertiary"
              size="small"
              customClass="content-row-label content-row-label-left"
              >{{
                $t("pages.detailsPage.financing.financingResult.initialEntry")
              }}</Label
            >
            <Label
              color="tertiary"
              size="small"
              customClass="content-row-label content-row-label-right"
              >{{ getVehicleInitialDepositFormatted }}</Label
            >
          </div>
          <div class="content-row" v-if="getVehicleResidualValue">
            <Label
              color="tertiary"
              size="small"
              customClass="content-row-label content-row-label-left"
              >{{
                $t("pages.detailsPage.financing.financingResult.residualValue")
              }}</Label
            >
            <Label
              color="tertiary"
              size="small"
              customClass="content-row-label content-row-label-right"
              >{{ getVehicleResidualValue }}€</Label
            >
          </div>
          <div v-if="!isGuest" class="content-row-footer">
            <Label
              color="tertiary"
              size="small"
              customClass="content-row-label"
              >{{
                $t("pages.detailsPage.financing.financingResult.retakeValue")
              }}</Label
            >
            <Label
              v-if="!haveSimulation"
              @click.native="addResume"
              color="secondary"
              size="small"
              class="add-retake"
            >
              {{ $t("pages.detailsPage.financing.financingResult.addRetake") }}
            </Label>
            <Label
              v-else
              color="tertiary"
              size="small"
              customClass="content-row-label"
              >{{ resumePrice }}€</Label
            >
          </div>
        </div>
        <div v-if="!withFinancing" class="withoutFinancing">
          <div class="content-row-title">
            <Label
              color="tertiary"
              size="small"
              customClass="content-row-label"
              >{{
                $t("pages.detailsPage.financing.financingResult.paymentData")
              }}</Label
            >
          </div>
          <div v-if="!isGuest" class="content-row-footer">
            <Label
              color="tertiary"
              size="small"
              customClass="content-row-label"
              >{{
                $t("pages.detailsPage.financing.financingResult.retake")
              }}</Label
            >
            <Label
              v-if="!haveSimulation"
              @click.native="addResume"
              color="secondary"
              size="small"
              class="add-retake"
            >
              {{ $t("pages.detailsPage.financing.financingResult.addRetake") }}
            </Label>
            <Label
              v-else
              color="tertiary"
              size="small"
              customClass="content-row-label"
              >{{ Number(resumePrice).toFixed(2) }}€</Label
            >
          </div>
        </div>
      </div>
      <div class="financing-result--footer">
        <Button
          :disabled="isButtonDisabled"
          :icon="isButtonDisabled ? 'lock' : ''"
          iconPosition="left"
          :label="btnLabel"
          uppercase
          type="secondary"
          :fill="!isButtonDisabled"
          :border="false"
          @onClick="clickInterest"
        />
        <div class="financing-result--footer-download" v-if="withFinance">
          <div class="financing-result-download--wrapper">
            <Button
              filename="download-icon.svg"
              iconPosition="left"
              type="secondary"
              :border="false"
              customClass="download-fins download-fins-url"
              :label="
                $t(
                  'pages.detailsPage.financing.financingResult.downloadFins.title'
                )
              "
              @onClick="getFinFile"
            />
          </div>
          <Popup
            id="dealers-list-popup"
            v-show="openDealersPopup"
            :closeButton="true"
            :closeOnClickOutside="true"
            @close="openDealersPopup = false"
            customClass="z-30"
            :title="
              $t(
                'pages.detailsPage.financing.financingResult.downloadFins.dealersPopup.title'
              )
            "
          >
            <template slot="body" class="dealers-list-popup--wrapper">
              <div class="main-section">
                <div class="grid sm:grid-cols-2 mb-8">
                  <Label color="primary" size="small">{{
                    $t(
                      "pages.detailsPage.financing.financingResult.downloadFins.dealersPopup.content.title.1"
                    )
                  }}</Label>
                  <Label color="primary" size="small">{{
                    $t(
                      "pages.detailsPage.financing.financingResult.downloadFins.dealersPopup.content.title.2"
                    )
                  }}</Label>
                </div>
                <div class="section-wrapper">
                  <div
                    class="grid sm:grid-cols-2 mb-5"
                    v-for="(dealer, index) in getDealers"
                    :key="index"
                  >
                    <Label
                      class="dealer-name--label"
                      color="primary"
                      size="small"
                    >
                      {{ dealer.designation }} ({{ dealer.address }}-{{
                        dealer.district
                      }})</Label
                    >
                    <Label
                      class="dealer-type--label"
                      color="tertiary"
                      size="small"
                    >
                      {{
                        $t(
                          "pages.detailsPage.financing.financingResult.downloadFins.dealersPopup.content.dealerTypeLabel"
                        )
                      }}</Label
                    >
                  </div>
                </div>
              </div>
            </template>

            <template slot="footer"
              ><div class="footer-btn">
                <div />
                <div />
                <div />
                <Button
                  uppercase
                  customClass="exit-btn"
                  :label="
                    $t(
                      'pages.detailsPage.financing.financingResult.downloadFins.dealersPopup.close'
                    )
                  "
                  type="primary"
                  @onClick="openDealersPopup = false"
                />
              </div>
            </template>
          </Popup>
        </div>
      </div>
    </div>
    <div v-if="error === true">
      <div class="financing-result--header">
        <div v-if="withFinancing" class="financing-result--header-center">
          <div>
            <Label customClass="header-title">{{
              $t("pages.detailsPage.financing.financingResult.noResults")
            }}</Label>
          </div>
          <div>
            <Label customClass="header-subtitle">{{
              $t("pages.detailsPage.financing.financingResult.adjustSimulation")
            }}</Label>
          </div>
        </div>
      </div>
      <div class="financing-result--content">
        <div v-if="withFinancing" class="withFinancing">
          <div class="content-row-title mt-5">
            <Label
              color="tertiary"
              size="small"
              customClass="content-row-label"
              >{{
                $t(
                  "pages.detailsPage.financing.financingResult.adjustInitialEntry"
                )
              }}</Label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Label, Button, Popup } from "../../atoms";
import { mapGetters } from "vuex";
import {
  getPriceFormatted,
  getPriceFormattedNoDecimal,
  formatNonCurrency,
} from "../../../utils/currency";
import { Icon, Tooltip } from "@sc/ds-ui-component-library";
import { RESERVE_TYPE } from "../../../enums/reserveType.js";
import { PAGES } from "../../../enums/pages";

export default {
  name: "FinancingResult",
  components: {
    Label,
    Button,
    Popup,
    Icon,
    Tooltip,
  },
  props: {
    deposit: {
      type: Number,
    },
    resumePrice: {
      type: Number,
      default: 0,
    },
    haveSimulation: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: "Valor Mensal",
    },
    error: {
      type: Boolean,
    },
    reserveStatus: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      openDealersPopup: false,
    };
  },
  methods: {
    addResume() {
      this.getReturns.length > 0
        ? this.$emit("onAddResume", !this.haveSimulation)
        : this.$router.push({ name: PAGES.RETAKE });
    },
    clickInterest() {
      this.$emit("interestFootprint");
      this.$store.commit("PanelModule/toggleReserve");
    },
    getFinFile(event) {
      this.$emit("getFinFile", event);
    },
  },
  computed: {
    ...mapGetters([
      "isGuest",
      "getVehicleResidualValue",
      "getDealers",
      "getReturns",
    ]),
    ...mapGetters({
      getVehicle: "VehicleModule/getVehicle",
      withFinance: "VehicleModule/withFinance",
      getOnEditing: "PanelModule/getOnEditing",
    }),
    withFinancing() {
      return this.withFinance;
    },
    getFinance() {
      return this.getVehicle?.carDetailFinance;
    },
    getFormattedMonthlyPriceSimple() {
      return getPriceFormatted(this.getFinance.monthlyPayment);
    },
    getFormattedTotalPrice() {
      return getPriceFormattedNoDecimal(this.getFinance.pvp);
    },
    getTaeg() {
      return formatNonCurrency(this.getFinance.taeg, 1, 1, true);
    },
    getVehicleNumberMonths() {
      return this.getFinance.months;
    },
    getFormattedCapitalToFinance() {
      return getPriceFormatted(this.getFinance.capitalFinance);
    },
    getVehicleInitialDepositFormatted() {
      return getPriceFormatted(this.deposit);
    },
    isButtonDisabled() {
      return (
        this.reserveStatus === RESERVE_TYPE.RESERVED ||
        this.reserveStatus === RESERVE_TYPE.BOUGHT
      );
    },
    btnLabel() {
      return this.reserveStatus === RESERVE_TYPE.RESERVED
        ? this.$t("labels.reserveTypeBtnLabel.1")
        : this.reserveStatus === RESERVE_TYPE.BOUGHT
        ? this.$t("labels.reserveTypeBtnLabel.2")
        : this.getOnEditing
        ? this.$t("pages.detailsPage.financing.financingResult.continueReserve")
        : this.$t(
            "pages.detailsPage.financing.financingResult.buttonInterested"
          );
    },
  },
};
</script>

<style lang="scss" scoped>
.financing-result {
  background-color: rgba(255, 255, 255, 1);
  padding-bottom: 2rem;
}

.financing-result--header {
  height: 120px;
  background-color: rgba(2, 34, 111, 1);
  display: flex;
  width: 100%;
  align-items: center;
}

.header-title {
  color: rgba(255, 255, 255, 1);
  font-size: 32px;
}

.header-subtitle {
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
}

.financing-result--header-center {
  text-align: center;
  width: 100%;
}

.financing-result--header-left {
  text-align: center;

  width: 50%;
}

.financing-result--header-right {
  text-align: center;

  width: 50%;
}

.content-row-title .label {
  font-size: 16px;
  font-weight: 500;
  font-family: Mont;
}

.content-row .label {
  width: max-content;
  font-family: Mont;
  font-weight: 400;
}

.financing-result--content {
  padding: 1.5rem;
  margin-top: 0.5rem;
}

.content-row {
  margin-top: 0.75rem;
  display: flex;
  justify-content: space-between;
}

.content-row-footer {
  padding-top: 0.75rem;
  padding-bottom: 1rem;
  margin-top: 1.25rem;
  border-top: 1px solid #e5ecf0;
  border-bottom: 1px solid #e5ecf0;
  justify-content: space-between;
  display: flex;
}

.content-row-footer .label {
  font-family: Mont;
  font-weight: 400;
}

.content-row-footer .add-retake {
  font-weight: 500;
  font-family: Mont;
  cursor: pointer;
}

.content-row-footer .label,
.link {
  width: max-content;
  font-size: 16px;
}

.financing-result--footer {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.financing-result--footer .button {
  height: 64px;
  font-size: 18px;
  line-height: 72px;
}

::v-deep .label {
  font-family: Mont;
  font-weight: 500;
}

::v-deep .tooltip--text {
  width: 24rem;
}

.label-icon {
  display: flex;
  align-items: center;
}

.label-icon .label {
  line-height: 16px;
}

.icon--margin {
  margin-left: 4px;
}

.financing-result--footer-download {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
}

.financing-result--footer-download ::v-deep .label {
  font-size: 14px;
  line-height: 19px;
}

.financing-result--footer-download
  .financing-result-download--wrapper
  ::v-deep
  .button {
  font-family: Mont;
  justify-content: flex-start;
  font-weight: 500;
  padding: 0px;
  text-decoration: underline;
  font-size: 14px;
  line-height: 19px;
  width: fit-content;
  height: fit-content;
}

.financing-result--footer-download
  .financing-result-download--wrapper
  ::v-deep
  .button:hover,
.financing-result--footer-download
  .financing-result-download--wrapper
  ::v-deep
  .button:focus {
  color: rgba(0, 170, 210, 1);
  text-decoration: underline;
  background-color: inherit;
}

.download-fins ::v-deep img {
  margin-right: 0.125rem;
  width: 15px;
  height: 15px;
}

.download-fins-description {
  margin-top: 1rem;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: var(--color-2);
}

.footer-btn {
  width: 100%;
  padding-bottom: 2rem;
  display: flex;
  justify-content: flex-end;
}

#dealers-list-popup ::v-deep .popup-header-title label {
  font-size: 18px;
  line-height: 16px;
}

.dealer-type--label,
.dealer-name--label {
  font-weight: 400;
}

#dealers-list-popup ::v-deep .popup {
  height: 100%;
}

@media only screen and (min-width: 640px) {
  #dealers-list-popup ::v-deep .popup {
    max-width: 55%;
    max-height: 70%;
  }
}

@media (min-width: 640px) {
  .footer-btn ::v-deep .button {
    width: 25%;
  }

  .section-wrapper {
    height: 18rem;
  }
}

@media (min-width: 768px) {
  .vehicle-reserved {
    position: relative;
    z-index: 0;
  }
}

.footer-btn ::v-deep .button {
  width: 40%;
}

.section-wrapper {
  overflow-y: scroll;
  height: 24rem;
}

.vehicle-reserved {
  height: 70px;
  bottom: 0px;
  right: 0px;
  position: fixed;
  z-index: 50;
  background-color: #e5ecf0;
  border-color: #aab1c7;
  font-weight: 600;
  color: rgba(91, 141, 244, 1);
  pointer-events: none;
}

.content-row-title {
  .label {
    font-weight: 600;
    font-size: 20px;
    line-height: 18px;
    color: var(--color-1);
  }
}

.content-row-footer {
  .content-row-label {
    font-weight: 700;
    font-size: 18px;
    line-height: 16px;
    color: var(--color-1);
  }
  .add-retake {
    font-size: 18px;
    line-height: 16px;
    text-decoration-line: underline;
    color: var(--theme-color-tertiary);
  }
}

.content-row-label-left {
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  color: var(--color-2);
}

.content-row-label-right {
  font-weight: 500;
  font-size: 20px;
  line-height: 16px;
  color: var(--color-1);
}

.month-price {
  display: flex;
  gap: 8px;
  align-items: center;
}
.tootltip-container {
  height: 18px;
}

::v-deep .tooltip .tooltip--text {
  z-index: 100;
}

@media (max-width: 1440px) {
  .content-row-label-left {
    font-size: 16px;
  }
  .content-row-label-right {
    font-size: 18px;
  }
  .content-row-title .label {
    font-size: 18px;
  }

  .content-row-footer .content-row-label {
    font-size: 16px;
  }

  .content-row-footer .add-retake {
    font-size: 16px;
  }
}
</style>
