<template>
  <Page>
    <template v-slot:content>
      <div class="personal-page--container">
        <div class="personal-page--container-left">
          <div class="personal-page--container-left-name">
            <Avatar
              class="avatar"
              :username="username"
              :size="138"
              backgroundColor="white"
              color="#D3D9DE"
            ></Avatar>
            <Label customClass="welcome" class="avatar-name" size="medium">{{
              $t("pages.personalPage.welcome", {
                user: firstName,
              })
            }}</Label>
          </div>
          <div class="separator" />
          <div class="personal-page--container-left-nav">
            <LinkList
              class="personal-nav"
              :list="personalList"
              bold="normal"
              display="vertical"
              size="large"
              :uppercase="true"
            />
          </div>
        </div>

        <div class="personal-page--container-right">
          <router-view></router-view>
        </div>
      </div>
    </template>
  </Page>
</template>

<script>
import { LinkList } from "../../molecules";
import { Page } from "../../templates";
import { Label } from "../../atoms";
import Avatar from "vue-avatar";
import { mapGetters } from "vuex";
export default {
  components: {
    LinkList,
    Page,
    Avatar,
    Label,
  },
  methods: {
    OpenRegisterForm() {
      this.isRegisterFormOpen = true;
    },
  },
  computed: {
    ...mapGetters(["getUserInfo"]),
    firstName() {
      return this.getUserInfo?.name?.split(" ")[0];
    },
    username() {
      return this.getUserInfo?.name || "";
    },
    personalList() {
      return [
        {
          label: this.$t("pages.personalPage.personalList[0]"),
          url: "/pessoal/reserva",
        },
        {
          label: this.$t("pages.personalPage.personalList[1]"),
          url: "/pessoal/retoma",
        },
        {
          label: this.$t("pages.personalPage.personalList[2]"),
          url: "/pessoal/pesquisas-guardadas",
        },
        {
          label: this.$t("pages.personalPage.personalList[3]"),
          url: "/pessoal/notificacao",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.personal-page--container {
  width: 100%;
  padding: 40px 64px;

  & > * {
    margin: 0 20px;
  }

  & > *:first-child {
    margin: 0 20px 0 0;
  }

  & > *:last-child {
    margin: 0 0 0 20px;
  }
}

.personal-page--container-left {
  width: 100%;
  margin-bottom: 1rem;
  /* width: 25%; */
  background-color: var(--color-6);
  color: white;
  padding: 40px;
}

.personal-page--container-right {
  width: 100%;
  /* width: 75%; */
  padding: 16px 0px;
}

.personal-page--container-left-name {
  text-align: center;
  margin-bottom: 2.5rem;
}

.welcome {
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-family: Mont;
  font-weight: 500;
}

.avatar {
  margin: auto;
  margin-bottom: 1.5rem;
}

.personal-nav.link-list {
  text-align: center;
}

.personal-nav ::v-deep .link {
  color: white;
  font-size: 20px;
  margin-bottom: 40px;
  font-family: Mont;
  font-weight: 500;
}

.logout {
  color: white;
  font-size: 20px;
  cursor: pointer;
  font-family: Mont;
  font-weight: 500;
}

.welcome {
  color: var(--theme-color);
}

.personal-page--container-left-nav {
  text-align: center;

  ::v-deep {
    .link {
      color: var(--color-1);
      font-weight: 600;
      font-size: 21px;
      line-height: 21px;
    }

    .logout {
      color: var(--color-1);
    }
  }
}

::v-deep .personal-nav .item .router-link-active a {
  color: rgba(15, 83, 251, 1);
}

::v-deep .personal-nav .item a:hover,
.logout:hover {
  color: rgba(15, 83, 251, 1);
}

.avatar-name {
  font-weight: 600;
  font-size: 24px;
  line-height: 21px;
  color: var(--theme-color);
}

.page {
  background-color: var(--color-7);
}

::v-deep .price-drop-card .car-img {
  background-size: cover !important;
}
@media (min-width: 768px) {
  .personal-page--container {
    display: flex;
  }

  .personal-page--container-left {
    width: 25%;
    margin-bottom: 0px;
    height: 2.5rem;
  }

  .personal-page--container-right {
    width: 75%;
  }

  .personal-page--container-left-name {
    margin-bottom: 5rem;
  }
}
@media (min-width: 1025px) {
  .personal-page--container-left {
    height: auto;
  }
}
@media (max-width: 1680px) {
  .personal-page--container {
    padding: 52px;

    & > * {
      margin: 0 26px;
    }

    & > *:first-child {
      margin: 0 26px 0 0;
    }

    & > *:last-child {
      margin: 0 0 0 26px;
    }
  }

  .personal-page--container-left {
    padding: 52px;
  }

  .personal-page--container-right {
    padding: 8px 0px;
  }

  .personal-page--container-left-name {
    margin-bottom: 52px;
  }

  .avatar-name {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
  }

  .personal-page--container-left-nav {
    ::v-deep .item a {
      font-size: 16px;
      line-height: 16px;
    }
    ::v-deep .link {
      margin-bottom: 28px;
    }
  }

  ::v-deep .reserved-area-titles {
    font-size: 18px !important;
    line-height: 18px !important;
  }

  ::v-deep .saved-card {
    --subtitle-1-font-size: 18px;
    --subtitle-1-line-height: 18px;
    padding: 8px;
  }

  ::v-deep .saved-card--header-footer {
    margin-top: 8px;
    padding-top: 8px;
    --saved-card-footer-margin-top: 8px;
    --saved-card-footer-padding-top: 8px;
  }

  ::v-deep .price-model-description {
    --text-2-font-size: 14px;
    --text-2-line-height: 21px;
  }

  ::v-deep .finance-model-price {
    --title-4-font-size: 23px;
  }

  ::v-deep .finance-model-modality {
    font-size: 16px;
  }

  ::v-deep .finance-model-taeg {
    font-size: 16px;
  }

  ::v-deep .saved-return--header-right-close-prices {
    .old-value {
      font-size: 16px;
    }

    .price {
      font-size: 23px;
    }
  }

  ::v-deep .saved-return--header-left .label--small {
    font-size: 14px;
    line-height: 21px;
  }

  ::v-deep .card-footer-label .label--small {
    font-size: 14px;
    line-height: 14px;
  }

  ::v-deep .saved-return--footer-left .label--small {
    font-size: 14px;
    line-height: 14px;
  }

  ::v-deep {
    .no-results--title .label--medium {
      font-size: 18px;
    }

    .no-results--description .label--small {
      font-size: 14px;
    }
  }

  ::v-deep .notification-card {
    padding: 8px !important;
  }

  ::v-deep .price-drop-card--content {
    --subtitle-large-font-size: 18px;
    --subtitle-large-line-height: 18px;
    --text-label-normal-font-size: 14px;
    --text-label-normal-line-height: 21px;
  }

  ::v-deep .saved-search {
    --saved-card-padding: 8px;

    .title {
      --text-label-font-size: 18px;
      --text-label-line-height: 18px;
    }

    .date-label {
      .text-label {
        --text-label-font-size: 14px;
        --text-label-line-height: 21px;
      }
    }
    .saved-card--header-footer {
      .saved-card--header-footer-left {
        .filter-tag-container {
          .tag-container {
            .text-label {
              --text-label-font-size: 14px;
              --text-label-line-height: 14px;
            }
          }
        }
      }
    }
  }

  ::v-deep .notification-card--left {
    .subtitle {
      --subtitle-font-size: 18px;
    }
  }

  ::v-deep .page-title {
    font-size: 18px !important;
  }

  ::v-deep .saved-card--header-content {
    .header--container {
      justify-content: space-between;

      .car-img {
        width: 200px;
        height: 105px;
      }

      .container--wrapper {
        margin-bottom: 0 !important;
      }
    }
  }

  ::v-deep .saved-card--header-footer-left {
    --saved-search-tag-height: none;
  }

  ::v-deep .saved-card--header-footer-button {
    .btn {
      --button-padding: 8px 28px;
      --saved-card-footer-button-padding: 8px 28px;
      --button-height: none;
      --button-font-size: 14px;
    }
  }
}
@media (max-width: 1366px) {
  .personal-page--container {
    padding: 40px;

    & > * {
      margin: 0 20px;
    }

    & > *:first-child {
      margin: 0 20px 0 0;
    }

    & > *:last-child {
      margin: 0 0 0 20px;
    }
  }

  .personal-page--container-left {
    padding: 40px;
  }

  .personal-page--container-left-name {
    margin-bottom: 40px;
  }
}
@media (max-width: 1280px) {
  .personal-page--container {
    padding: 28px;

    & > * {
      margin: 0 14px;
    }

    & > *:first-child {
      margin: 0 14px 0 0;
    }

    & > *:last-child {
      margin: 0 0 0 14px;
    }
  }

  .personal-page--container-left {
    padding: 28px;
  }
}
@media (max-width: 1024px) {
  .personal-page--container {
    display: flex;
    flex-direction: column;
    padding: 0px 16px;

    & > * {
      margin: 14px 0;
    }

    & > *:first-child {
      margin: 0 0 14px 0;
    }

    & > *:last-child {
      margin: 14px 0 0 0;
    }

    .personal-page--container-left {
      height: auto;
      width: 100%;
    }

    .personal-page--container-right {
      height: auto;
      width: 100%;
      padding: 16px 0px;
    }
  }
}
@media (max-width: 375px) {
  ::v-deep .tooltip {
    --tooltip-position-default: none;
    --tooltip-margin-default: none;
  }
  ::v-deep .tooltip--text {
    --tooltip-width-large: 200px !important;
  }
  ::v-deep .saved-card--header-content {
    .header--container {
      flex-direction: column;

      .car-img {
        width: 100%;
        height: 155px;
        margin-bottom: 4px;
        margin-right: 0;
      }

      .container--wrapper {
        flex-direction: column;
      }
    }
  }

  ::v-deep .reservation {
    .header--container {
      flex-direction: column;

      .car-img {
        width: 100%;
        height: 155px;
        margin-bottom: 4px;
        margin-right: 0;
      }

      .container--wrapper {
        flex-direction: column;

        .finance--container {
          .monthtaeg {
            .finance-model-modality {
              font-size: 12px;
              line-height: 14px;
            }
            .finance-model-taeg {
              font-size: 12px;
              line-height: 14px;
            }
          }
          .financing-details {
            .financing-details-light {
              font-size: 12px;
              line-height: 14px;
            }
            .financing-details-dark {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
