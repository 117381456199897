<template>
  <div class="no-results">
    <Section id="no-results">
      <SectionHeader
        customClass="section-header--center"
        :title="$t('pages.searchpage.emptyResults.title')"
        :subtitle="$t('pages.searchpage.emptyResults.description')"
      >
        <Button
          customClass="no-results--button"
          :label="$t('pages.searchpage.emptyResults.btnLabel')"
          @onClick="handleCarAlert"
        />
      </SectionHeader>
    </Section>
    <Section id="similar" class="similar--section">
      <CarRow
        :vehicles="getInterest"
        :text="$t('pages.searchpage.emptyResults.similarVehicles')"
      />
    </Section>
  </div>
</template>

<script>
import Section from "../../refactor/atoms/Section/Section.vue";
import SectionHeader from "../../refactor/molecules/SectionHeader/SectionHeader.vue";
import { Button } from "@sc/ds-ui-component-library";
import CarRow from "../../refactor/CarRow.vue";
import { mapMutations } from "vuex";
import { mapGetters } from "vuex";
export default {
  name: "SearchNoResults",
  components: {
    Section,
    SectionHeader,
    Button,
    CarRow,
  },
  methods: {
    ...mapMutations({
      toggleCarAlert: "PanelModule/toggleCarAlert",
    }),
    handleCarAlert() {
      this.toggleCarAlert();
    },
  },
  computed: {
    ...mapGetters({
      getInterest: "SearchModule/getInterest",
    }),
  },
  mounted() {
    if (this.getInterest.length === 0) {
      this.$store.dispatch("SearchModule/fetchInterest");
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .section-header {
  --section-header-width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    margin: 8px 0;
  }

  & > *:first-child {
    margin: 0 0 8px 0;
  }

  & > *:last-child {
    margin: 8px 0 0 0;
  }
}
</style>
