<template>
  <BaseButton
    :customClass="['btn', customClass]"
    :type="type"
    :size="size"
    :disabled="disabled"
    :label="label"
    @onClick="handleClick"
  >
    <Icon
      v-if="withIcon"
      :customIcon="`btn--icon ${iconCustomClass} ${iconClasses}`"
      :icon="computedIcon"
    />
  </BaseButton>
</template>

<script>
import BaseButton from "../../atoms/BaseButton/BaseButton.vue";
import Icon from "../../atoms/Icon/Icon.vue";

export default {
  name: "Button",
  components: { BaseButton, Icon },
  props: {
    customClass: {
      type: String,
      default: "",
    },
    iconCustomClass: {
      type: String,
      default: "",
    },
    withIcon: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "default",
      validator: (size) => {
        return ["default", "large", "ghost"].includes(size);
      },
    },
    type: {
      type: String,
      default: "primary",
      validator: (type) => {
        return ["primary", "secondary", "tertiary"].includes(type);
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "chevron-right",
    },
    iconPosition: {
      type: String,
      default: "right",
      validator: (position) => ["right", "left"].includes(position),
    },
  },
  computed: {
    computedIcon() {
      // Different than default
      if (this.icon !== "chevron-right") return this.icon;
      if (this.size === "ghost") return "arrow-right";
      return this.iconPosition === "right" ? this.icon : "chevron-left";
    },
    iconClasses() {
      if (this.size === "ghost") return "ghost--icon";

      switch (this.type) {
        case "secondary":
          return "secondary--icon";
        case "tertiary":
          return "tertiary--icon";
        default:
          return "primary--icon";
      }
    },
    classes() {
      let str = this.size === "ghost" ? "short" : "";
      return str + (this.iconPosition === "right" ? "" : "reserved");
    },
  },
  methods: {
    handleClick(e) {
      this.$emit("onClick", e);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  display: flex;
  align-items: center;
  gap: var(--button-gap);
}

.reserved {
  flex-direction: row-reverse;
}

.primary--icon {
  fill: var(--button-icon-primary-color);
}
.secondary--icon {
  fill: var(--button-icon-secondary-color);
}
.tertiary--icon {
  fill: var(--button-icon-tertiary-color);

  &:hover,
  &:active {
    fill: var(--button-icon-tertiary-color-hover);
  }
}

.btn:hover .tertiary--icon {
  fill: var(--button-icon-tertiary-color-hover);
}

.btn:hover .ghost--icon {
  fill: var(--button-icon-ghost-color-hover);
}

.ghost--icon {
  fill: var(--button-icon-ghost-color);

  &:hover {
    fill: var(--button-icon-ghost-color-hover);
  }
}

.short {
  gap: var(--button-gap-ghost);
}
</style>
