<template>
  <a
    :href="href"
    :class="['baselink', classes, customClass, disabled ? 'disabled' : '']"
    @click="clickHandler"
  >
    {{ label }}
    <slot />
  </a>
</template>

<script>
export default {
  name: "BaseLink",
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      },
    },
    customClass: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "md",
      validator: (value) => {
        return ["xs", "sm", "md", "lg", "xl"].includes(value);
      },
    },
    label: {
      type: String,
      default: "",
    },
    href: {
      type: String,
      default: "#",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clickHandler(event) {
      this.$emit("onClick", event);
    },
  },
  computed: {
    classes() {
      switch (this.size) {
        case "xs":
          return "size--xs";
        case "sm":
          return "size--sm";
        case "lg":
          return "size--lg";
        case "xl":
          return "size--xl";
        default:
          return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.baselink {
  /* Default */
  color: var(--baselink-color);
  font-size: var(--baselink-md-font-size);
  font-weight: var(--baselink-font-weight);
  line-height: var(--baselink-md-line-height);

  &:hover,
  &:active {
    font-weight: var(--baselink-hover-font-weight);
  }
}

.disabled {
  pointer-events: none;
  color: var(--baselink-disabled-color);
  font-weight: var(--baselink-weight);
}

.size--xs {
  font-size: var(--baselink-xs-font-size);
  line-height: var(--baselink-xs-line-height);
}
.size--sm {
  font-size: var(--baselink-sm-font-size);
  line-height: var(--baselink-sm-line-height);
}
.size--lg {
  font-size: var(--baselink-lg-font-size);
  line-height: var(--baselink-lg-line-height);
}
.size--xl {
  font-size: var(--baselink-xl-font-size);
  line-height: var(--baselink-xk-line-height);
}
</style>
