import { getVehicle, getVehicleSimilars } from "../../api/product";
import vehicleViewer from "./utils/vehicleViewer";
import router from "@/router";
import { PAGES } from "../../enums/pages";
import {
  getPriceFormatted,
  formatNonCurrency,
  getPriceFormattedNoDecimal,
} from "../../utils/currency";
import vehicle from "../mock";

const quarters = {
  1: "31/03/",
  2: "30/06/",
  3: "30/09/",
  4: "31/12/",
};

const quarterEndDt = () => {
  var today = new Date();
  var quarter = Math.floor((today.getMonth() + 3) / 3);
  return `${quarters[quarter]}${today.getFullYear()}`;
};

const state = {
  ...vehicleViewer.state,
  vehicle: {},
  tooltip: "",
  withFinance: true,
  defaultDeposit: 0,
};

const getters = {
  ...vehicleViewer.getters,
  getExternalImages: (state) =>
    state.vehicle?.carDetailImage?.staticImages?.externalImages.map(
      (img) => img.url
    ) ||
    vehicle.vehicle.carDetailImage?.staticImages?.externalImages.map(
      (img) => img.url
    ),
  // TODO: remove mock
  getMainImage: (state) =>
    state.vehicle?.carDetailCard?.imageUrl ||
    "https://assets.maxterauto.com/vo/imagenes/h_img_100_45457_381955_1634831164.jpg",
  getSimilar: (state) => state.vehicle?.carCardSimilar,
  getFinancing: (state) => {
    const { monthlyPayment, months, taeg, capitalFinance, depositValue } =
      state.vehicle?.carDetailFinance;
    return {
      monthlyRate: monthlyPayment,
      months,
      taeg: formatNonCurrency(taeg, 1, 1, true),
      capitalToFinance: capitalFinance,
      depositValue,
    };
  },
  getVehicle: (state) => state.vehicle,
  getVehicleInitialExpenses: (state) =>
    state.vehicle?.tooltipDescription?.initialExpenses,
  getCard: (state) => {
    const {
      brand,
      imageUrl,
      seats,
      transmission,
      financeId,
      commercialDescription,
      power,
      fuel,
      kilometers,
      model,
      vin,
      year,
      previousPvp,
    } = state.vehicle?.carDetailCard;
    const {
      monthlyPayment,
      taeg,
      pvp,
      depositValue,
      capitalFinance,
      tan,
      mtic,
      months,
    } = state.vehicle?.carDetailFinance;
    return {
      url: imageUrl,
      tags: [fuel, transmission, year, `${kilometers}km`],
      financeId,
      vin,
      img: imageUrl,
      monthlyRate: `${getPriceFormatted(monthlyPayment)}/mês`,
      previousPvp: previousPvp ? getPriceFormatted(previousPvp) : "",
      pvp: getPriceFormattedNoDecimal(pvp),
      taeg: `TAEG: ${formatNonCurrency(taeg, 1, 1, true)}%`,
      price: {
        monthly: `${getPriceFormatted(monthlyPayment)}/mês`,
        taeg,
        now: pvp,
        previousPvp: previousPvp,
        depositValue: getPriceFormatted(depositValue),
        capitalFinance: getPriceFormatted(capitalFinance),
        tan,
        mtic,
        months,
      },
      brand,
      model,
      commercialDescription,
      kilometers,
      fuel,
      power,
      fid: financeId,
      transmission,
      seats,
      tooltip: state.tooltip,
      withFinance: state.withFinance,
    };
  },
  getQuarterEndDate: () => quarterEndDt(),
  getViews: (state) => state.vehicle?.viewers,
  getLikes: (state) => state.vehicle?.likes,
  getAOS: (state) => state.vehicle?.externalPlayer?.autoOnShow,
  getTooltip: (state) => state.tooltip,
  withFinance: (state) => state.withFinance,
  getDefaultDeposit: (state) => state.defaultDeposit,
};

const mutations = {
  ...vehicleViewer.mutations,
  setVehicle(state, val) {
    state.vehicle = val;
  },
  setVehicleFinance(
    state,
    {
      taeg,
      mtic,
      pvp,
      previousPvp,
      initialValue,
      fee,
      capitalToFinance,
      nrPayments,
      initialExpenses,
      lastFee,
      dtEndOfCampaign,
    }
  ) {
    state.vehicle = {
      ...state.vehicle,
      carDetailFinance: {
        ...state.vehicle.carDetailFinance,
        taeg: formatNonCurrency(taeg, 1, 1, true),
        mtic,
        pvp,
        previousPvp,
        depositValue: initialValue,
        monthlyPayment: fee,
        capitalFinance: capitalToFinance,
        months: nrPayments,
      },
      carDetailCard: {
        ...state.vehicle.carDetailCard,
        taeg,
        tooltipDescription: {
          ...state.vehicle.carDetailCard.tooltipDescription,
          taeg: formatNonCurrency(taeg, 1, 1, true),
          depositValue: initialValue,
          monthPrice: fee,
          months: nrPayments,
          capitalFinance: capitalToFinance,
          mtic,
          initialExpenses,
          lastFee,
          dtEndOfCampaign,
        },
      },
    };
  },
  setTooltip(state, v) {
    state.tooltip = v;
  },
  toggleWithFinance(state, val) {
    state.withFinance = val;
  },
  setEmptyVehicleValues(state) {
    if (state.vehicle.carDetailCard) {
      Object.keys(state.vehicle.carDetailFinance).forEach(
        (v) => (state.vehicle.carDetailFinance[v] = "--")
      );
      state.vehicle.carDetailCard.brand = "--";
      state.vehicle.carDetailCard.commercialDescription = "--";
      state.vehicle.carDetailCard.model = "--";
    }
  },
  setDefaultDeposit(state, val) {
    state.defaultDeposit = val;
  },
};

const actions = {
  ...vehicleViewer.actions,
  async fetchVehicle({ commit }, { vehicle, similar, reuse, vin, fid }) {
    commit("spinnerModule/setLoading", true, { root: true }); // -> 'someMutation'
    try {
      const res = reuse && vehicle ? vehicle : await getVehicle(vin, fid);
      res.data.data.carDetailCard.financeId = fid;
      res.data.data.carDetailCard.pricePvp = res.data.data.carDetailCard
        .pricePvp
        ? res.data.data.carDetailCard.pricePvp
        : 0;
      res.data.data.carDetailCard.previousPvp =
        res.data.data.carDetailCard.prices?.filter(
          (p) => p.priceType === "TotalPrice"
        ) &&
        res.data.data.carDetailCard.prices?.filter(
          (p) => p.priceType === "TotalPrice"
        ).previousValue
          ? res.data.data.carDetailCard.prices?.filter(
              (p) => p.priceType === "TotalPrice"
            ).previousValue
          : 0;
      const resSimilars = reuse ? similar : await getVehicleSimilars(vin);
      commit("setVehicle", {
        ...res?.data?.data,
        carCardSimilar: resSimilars.data.data,
      });
      commit("setTooltip", res?.data?.data.carDetailCard?.legalInfo);
      commit(
        "setDefaultDeposit",
        res?.data?.data?.carDetailFinance?.depositValue
      );
    } catch (err) {
      console.error(err);
      router.push({
        name: PAGES.ERROR,
      });
    } finally {
      commit("spinnerModule/setLoading", false, { root: true }); // -> 'someMutation'
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
