<template>
  <div class="vehicle-info--container">
    <div class="actions--row">
      <div class="actions--left">
        <div @click="captionClicks(actions[0].label)">
          <CaptionIcon :icon="actions[0].icon" :label="`${actions[0].label}`" />
        </div>
        <div @click="captionClicks(actions[1].label)">
          <CaptionIcon :icon="favoriteImg" :label="`${actions[1].label}`" />
        </div>
        <div @click="captionClicks(actions[2].label)">
          <CaptionIcon :icon="actions[2].icon" :label="`${actions[2].label}`" />
        </div>
      </div>
      <div class="user-interaction">
        <VehicleViewers />
        <XLikes :likes="likes" />
      </div>
    </div>
    <div class="vehicle-price">
      <div class="vehicle-price--left">
        <Title :label="vehicle.title" />
        <Subtitle :label="vehicle.designation" />
      </div>
      <div class="vehicle-price--right">
        <TextLabel
          v-if="vehicle.previousPvp"
          class="previousPrice"
          :label="`antes ${getPriceFormattedNoDecimal(vehicle.previousPvp)}`"
        />
        <Subtitle
          class="new-price"
          :label="getPriceFormattedNoDecimal(vehicle.price)"
        />
        <Subtitle
          v-if="hasIva"
          class="iva"
          :label="$t('pages.detailsPage.details.iva')"
        />
        <TextLabel
          v-if="vehicle.withFinancing"
          customClass="expenses--margin"
          :label="
            $t('vehicleinfo.withFinancingLabel', {
              contractExpenses: getPriceFormattedNoDecimal(
                financialInfo.contractExpenses
              ),
            })
          "
        />
        <div class="details--row" v-if="vehicle.withFinancing">
          <div class="month-price">
            <div class="tootltip-container">
              <Tooltip :tooltip="vehicle.tooltip" size="large" position="left">
                <template v-slot:content>
                  <Icon icon="info" :size="18" />
                </template>
              </Tooltip>
            </div>
            <div class="month-taeg-wrapper">
              <Subtitle
                class="month-taeg--subtitle"
                weight="regular"
                :label="`ou ${getPriceFormatted(vehicle.monthlyRate)} ${$t(
                  'pages.detailsPage.modality'
                )}*`"
              />
              <Subtitle class="month-taeg--separator" :label="`|`" />
              <Subtitle
                class="month-taeg--subtitle"
                weight="regular"
                :label="`TAEG: ${formatNonCurrency(vehicle.taeg, 1, 1, true)}%`"
              />
            </div>
          </div>
        </div>
        <div class="details-financing" v-if="vehicle.withFinancing">
          <div class="details-financing-row">
            <Subtitle
              class="label-details-financing"
              :label="`${$t('pages.detailsPage.priceDetails.monthly')}: `"
            />
            <Subtitle
              class="price-details-financing"
              :label="`${vehicle.months} meses`"
            />
          </div>
          <div class="details-financing-row">
            <Subtitle
              class="label-details-financing"
              :label="`${$t('pages.detailsPage.priceDetails.entry')}: `"
            />
            <Subtitle
              class="price-details-financing"
              :label="`${getPriceFormatted(vehicle.deposit)}`"
            />
          </div>
          <div class="details-financing-row">
            <Subtitle
              class="label-details-financing"
              :label="`${$t('pages.detailsPage.priceDetails.financing')}:`"
            />
            <Subtitle
              class="price-details-financing"
              :label="` ${getPriceFormatted(vehicle.financedAmount)}`"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="caption-icon--list"
      :style="vehicle.withFinancing ? 'margin-top: 44px' : 'margin-top: 96px'"
    >
      <CaptionIcon
        :key="`${icon.name}-${idx}`"
        v-for="(icon, idx) in icons"
        :label="getCaptionIconLabel(icon.name)"
        :icon="icon.name"
        vertical
        :iconWidth="icon.width"
        :iconHeight="icon.height"
        :iconColor="'#333'"
      />
    </div>
    <div class="redirection--row">
      <CaptionIcon
        :key="`${r.name}-${idx}`"
        v-for="(r, idx) in redirects"
        reverse
        icon="arrow-down"
        @click.native="scrollTo(r.elementId)"
        :label="`${$t(`vehicleinfo.redirect.${r.name}`)}`"
      />
    </div>
    <Button
      :disabled="isButtonDisabled"
      :withIcon="isButtonDisabled"
      icon="lock"
      iconPosition="left"
      :label="btnLabel"
      @onClick="toggleReserve"
    />
    <div class="link">
      <BaseLink
        @onClick="handlePriceDrop"
        customClass="link"
        :label="$t('vehicleinfo.priceDropButton')"
      />
    </div>
  </div>
</template>

<script>
import XLikes from "../../refactor/XLikes.vue";
import VehicleViewers from "../../refactor/VehicleViewers.vue";
import {
  getPriceFormatted,
  getPriceFormattedNoDecimal,
  formatNonCurrency,
} from "../../../utils/currency";

import { RESERVE_TYPE } from "../../../enums/reserveType.js";

import {
  CaptionIcon,
  Title,
  Subtitle,
  Icon,
  TextLabel,
  Button,
  BaseLink,
  Tooltip,
} from "@sc/ds-ui-component-library";
import { mapGetters, mapMutations } from "vuex";
import { Status } from "../../../store/modules/panelModule";

import { CompareMixin, FavoritesMixin } from "../../../mixins";

export default {
  name: "VehicleInfo",
  components: {
    CaptionIcon,
    XLikes,
    VehicleViewers,
    Title,
    Subtitle,
    Icon,
    TextLabel,
    Button,
    BaseLink,
    Tooltip,
  },
  mixins: [CompareMixin, FavoritesMixin],
  props: {
    vehicle: {
      type: Object,
    },
  },
  data() {
    return {
      getPriceFormatted,
      actions: [
        {
          icon: "share",
          label: this.$t("vehicleinfo.actions.share"),
        },
        {
          icon: "heart",
          label: this.$t("vehicleinfo.actions.favorite"),
        },
        {
          icon: "compare",
          label: this.$t("vehicleinfo.actions.compare"),
        },
      ],
      icons: [
        { name: "fuel", height: 36, width: 36 },
        { name: "year", height: 36, width: 28 },
        { name: "kilometers", height: 36, width: 33 },
        { name: "power", height: 36, width: 54 },
        { name: "transmission", height: 36, width: 28 },
        { name: "seats", height: 36, width: 28 },
      ],
      endIconNames: {
        fuel: this.$t("pages.detailsPage.captionIcons.fuel"),
        year: this.$t("pages.detailsPage.captionIcons.year"),
        kilometers: this.$t("pages.detailsPage.captionIcons.km"),
        power: this.$t("pages.detailsPage.captionIcons.power"),
        transmission: this.$t("pages.detailsPage.captionIcons.transmission"),
        seats: this.$t("pages.detailsPage.captionIcons.seats"),
      },
      redirects: [
        { name: "details", elementId: "details" },
        { name: "financing", elementId: "financing" },
        { name: "quality", elementId: "quality" },
      ],
    };
  },
  computed: {
    ...mapGetters(["getSelectedVehicle"]),
    ...mapGetters({
      likes: "VehicleModule/getLikes",
      inFavoritesList: "FavoritesModule/exists",
      vehicleInUse: "VehicleModule/getCard",
      personal: "UserModule/getPersonal",
      financialInfo: "SearchModule/dropdown/getFinanceDropdown",
    }),
    hasIva() {
      return this.getSelectedVehicle.carDetailFinance.deductibleIva;
    },
    favoriteImg() {
      return !this.inFavoritesList(this.getSelectedVehicle)
        ? "heart"
        : "heart-filled";
    },
    isButtonDisabled() {
      return (
        this.vehicle?.reserveType === RESERVE_TYPE.RESERVED ||
        this.vehicle?.reserveType === RESERVE_TYPE.BOUGHT
      );
    },
    btnLabel() {
      return this.vehicle?.reserveType === RESERVE_TYPE.RESERVED
        ? this.$t("labels.reserveTypeBtnLabel.1")
        : this.vehicle?.reserveType === RESERVE_TYPE.BOUGHT
        ? this.$t("labels.reserveTypeBtnLabel.2")
        : this.$t(
            "pages.detailsPage.financing.financingResult.buttonInterested"
          );
    },
  },
  methods: {
    getPriceFormattedNoDecimal,
    formatNonCurrency,
    ...mapMutations({
      setContactPanel: "setContactPanel",
    }),
    handlePriceDrop() {
      this.$store.commit("PanelModule/setDetailPageStatus", Status.NONE);
      this.setContactPanel({
        display: true,
        vehicle: this.vehicleInUse,
        personal: this.personal,
        type: "PRICE-DROP",
        optionalButton: "",
        optionalButtonType: "",
        optionalButtonDisabled: false,
      });
    },
    toggleReserve() {
      this.$store.commit("PanelModule/toggleReserve");
      this.$emit("interestFootprint");
    },
    captionClicks(action) {
      if (action === this.$t("vehicleinfo.actions.share")) {
        this.$emit("openShareModal");
      } else if (action === this.$t("vehicleinfo.actions.favorite")) {
        this.$emit("openFavoriteSideBar", this.vehicle);
      } else if (action === this.$t("vehicleinfo.actions.compare")) {
        this.$emit("openCompareSideBar", this.vehicle);
      }
    },
    scrollTo(elementId) {
      const el = document.getElementById(elementId);
      el.scrollIntoView();
    },
    isZeroOrEmpty(value) {
      return value === "" || value === "0" || value === 0;
    },
    getCaptionIconLabel(iconName) {
      const value = this.isZeroOrEmpty(this.vehicle.icons[iconName])
        ? "--"
        : this.vehicle.icons[iconName];
      const tab = " ";
      const endIconName = this.isZeroOrEmpty(this.vehicle.icons[iconName])
        ? ""
        : this.endIconNames[iconName];
      return value + tab + endIconName;
    },
  },
};
</script>

<style lang="scss" scoped>
.actions--row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > * {
    margin: 0 10px;
  }

  & > *:first-child {
    margin: 0 10px 0 0;
  }

  & > *:last-child {
    margin: 0 0 0 10px;
  }
}

.vehicle-info--container {
  ::v-deep Button {
    margin-top: 40px;
    width: 100%;
    --button-justify-content: center;
  }
}

.details-financing {
  display: flex;
  flex-direction: column;
  width: max-content;
}
.details-financing-row {
  display: flex;
  justify-content: flex-end;
  padding: 6px 0px;
}

.label-details-financing {
  --subtitle-font-size: 18px;
  --subtitle-line-height: 20px;
  --subtitle-weight: 500;
  --subtitle-color: #808080;
  margin-right: 4px;
}
.price-details-financing {
  --subtitle-font-size: 20px;
  --subtitle-line-height: 20px;
  --subtitle-weight: 600;
}

.month-taeg--separator {
  --subtitle-font-size: 20px;
  --subtitle-line-height: 20px;
  --subtitle-weight: 500;
  --subtitle-color: #808080;
}

.month-taeg--subtitle {
  width: max-content;
  --subtitle-font-size: 20px;
  --subtitle-line-height: 20px;
  --subtitle-weight-regular: 600;
}

.month-taeg-wrapper {
  display: flex;

  & > * {
    margin: 0 2px;
  }

  & > *:first-child {
    margin: 0 2px 0 0;
  }

  & > *:last-child {
    margin: 0 0 0 2px;
  }
}

@media (max-width: 768px) {
  .vehicle-info--container {
    ::v-deep Button {
      z-index: 50;
      height: 70px;
      bottom: 0;
      right: 0;
      position: fixed;
    }
  }
}

.actions--left {
  display: flex;

  & > * {
    margin: 0 13px;
  }

  & > *:first-child {
    margin: 0 13px 0 0;
  }

  & > *:last-child {
    margin: 0 0 0 13px;
  }
}

.vehicle-price {
  margin-top: 66px;

  display: flex;
  justify-content: space-between;
}

@media (min-width: 1201px) and (max-width: 1400px) {
  .vehicle-price {
    flex-direction: column;
    .vehicle-price--right {
      align-items: start;
    }
  }
  .redirection--row {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 1900px) {
  .vehicle-price {
    margin-top: 20px;
  }
}

.redirection--row {
  margin-top: 40px;

  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 19px;
  padding-bottom: 19px;
  border-top: 1px solid var(--color-4);
  border-bottom: 1px solid var(--color-4);

  --text-label-color: var(--color-2);
  fill: var(--color-2);
}

::v-deep .link {
  margin-top: 40px;
  --baselink-color: var(--theme-color-tertiary);
  text-decoration: underline;
}

@media (max-width: 500px) {
  .vehicle-price {
    flex-direction: column;
    .vehicle-price--right {
      align-items: start;
    }
  }
  .redirection--row {
    flex-direction: column;
    align-items: center;
    border: none;
  }
  .redirection--row ::v-deep .caption-icon {
    width: 100%;
    justify-content: space-between;
    --text-label-font-size: 14px;
    --text-label-line-height: 14px;
    --text-label-weight: 600;
    border-top: 1px solid var(--color-4);
    border-bottom: 1px solid var(--color-4);
    padding-top: 20px;
    padding-bottom: 20px;
  }

  ::v-deep .link {
    margin-top: 24px;
    --baselink-color: var(--theme-color-tertiary);
    --baselink-md-font-size: 14px;
    --baselink-font-weight: 600;
  }
}

.vehicle-price--left {
  --title-color: var(--theme-color);
  --subtitle-color: var(--theme-color);
}

.vehicle-price--right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  & > * {
    margin: 4px 0;
  }

  & > *:first-child {
    margin: 0 0 4px 0;
  }

  & > *:last-child {
    margin: 4px 0 0 0;
  }

  .new-price {
    --subtitle-color: var(--theme-color-secondary);
    --subtitle-font-size: 56px;
    --subtitle-line-height: 56px;
    --subtitle-weight: 700;
    margin: 0;
  }

  .iva {
    --subtitle-color: var(--theme-color-secondary);
    --subtitle-font-size: 20px;
    --subtitle-line-height: 20px;
    --subtitle-weight: 600;
    margin: 0 0 10px 0;
  }

  --text-label-font-size: 12px;
  --text-label-line-height: 12px;
  --text-label-color: var(--color-2);
}

.user-interaction {
  display: flex;

  & > * {
    margin: 0 10px;
  }

  & > *:first-child {
    margin: 0 10px 0 0;
  }

  & > *:last-child {
    margin: 0 0 0 10px;
  }
}

@media (min-width: 1201px) and (max-width: 1900px) {
  .actions--row {
    flex-direction: column;
    align-items: start;
  }
}

.details--row {
  display: flex;
  align-items: center;
  margin-top: 20px;

  & > * {
    margin: 0 4px;
  }

  & > *:first-child {
    margin: 0 4px 0 0;
  }

  & > *:last-child {
    margin: 0 0 0 4px;
  }
}

.caption-icon--list {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .actions--row {
    flex-direction: column;
    align-items: start;

    & > * {
      margin: 10px 0;
    }

    & > *:first-child {
      margin: 0 0 10px 0;
    }

    & > *:last-child {
      margin: 10px 0 0 0;
    }
  }
  .actions--left {
    display: flex;

    & > * {
      margin: 0 5px;
    }

    & > *:first-child {
      margin: 0 5px 0 0;
    }

    & > *:last-child {
      margin: 0 0 0 5px;
    }
  }
  .user-interaction {
    justify-content: space-between;
  }
  .vehicle-price--left ::v-deep h1 {
    --title-weight-bold: 700;
    font-size: 28px;
    line-height: 36px;
  }
  .vehicle-price--left ::v-deep h2 {
    font-style: normal;
    --subtitle-weight-bold: 500;
    font-size: 16px;
    line-height: 21px;
  }
  .vehicle-price--right ::v-deep h1 {
    margin-top: 32px;
    font-style: normal;
    --subtitle-weight-bold: 500;
    font-size: 32px;
    line-height: 32px;
  }
  .details--row {
    margin-top: 0px;
  }
  .details--row ::v-deep h2 {
    font-style: normal;
    --subtitle-weight-regular: 600;
    font-size: 16px;
    line-height: 21px;
  }
  .details-financing-row {
    justify-content: start;
  }
  .vehicle-price--right p:last-child {
    margin-top: 10px;
  }
  .caption-icon--list {
    margin-top: 45px;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}

@media (max-width: 375px) {
  .user-interaction,
  .actions--left {
    --text-label-font-size: 10px;
  }
}

.details--row {
  ::v-deep {
    --subtitle-weight-regular: 600;
    --subtitle-font-size: 21px;
    --subtitle-line-height: 21px;
    --subtitle-color: var(--color-1);
  }
}

.vehicle-price--right {
  --text-label-weight: 500;
  --text-label-font-size: 12px;
  --text-label-line-height: 12px;
  --text-label-color: var(--color-2);

  .expenses--margin {
    --text-label-weight: 500;
    --text-label-font-size: 14px;
    --text-label-line-height: 14px;
    --text-label-color: var(--color-2);
  }
}

::v-deep {
  .caption-icon--list {
    .caption-icon {
      cursor: default;
      .text-label {
        --text-label-font-weight: 700;
        --text-label-font-size: 16px;
        --text-label-line-height: 14px;
        --text-label-color: var(--color-1);
        margin-top: 17px;
      }
    }
  }

  .actions--row {
    .actions--left {
      --text-label-weight: 600;
      --text-label-font-size: 14px;
      --text-label-line-height: 14px;
      --text-label-color: var(--color-1);
    }

    .user-interaction {
      .caption-icon {
        --text-label-weight: 700;
        --text-label-font-size: 14px;
        --text-label-line-height: 14px;
        --text-label-color: var(--color-2);

        .text-label {
          .viewers-description {
            font-weight: 600;
            font-size: 14px;
            line-height: 14px;
            --text-label-color: var(--color-2);
          }
        }
      }
    }
  }
}

.month-price {
  display: flex;
  align-items: center;

  & > * {
    margin: 0 4px;
  }

  & > *:first-child {
    margin: 0 4px 0 0;
  }

  & > *:last-child {
    margin: 0 0 0 4px;
  }
}
.tootltip-container {
  height: 18px;
}

::v-deep .tooltip .tooltip--text {
  z-index: 100;
}

.previousPrice {
  --text-label-font-size: 24px;
  --text-label-line-height: 24px;
  text-decoration-line: line-through;
  --text-label-weight: 400;
  --text-label-color: #333333;
}

.vehicle-price--right {
  max-width: 66%;
  text-align: right;
}
@media (max-width: 1440px) {
  .vehicle-price--right {
    max-width: 65%;
    text-align: right;
  }

  .label-details-financing {
    --subtitle-font-size: 16px;
    --subtitle-line-height: 18px;
  }
  .price-details-financing {
    --subtitle-font-size: 18px;
    --subtitle-line-height: 18px;
  }

  .month-taeg--separator {
    --subtitle-font-size: 18px;
    --subtitle-line-height: 18px;
  }

  .month-taeg--subtitle {
    --subtitle-font-size: 18px;
  }

  .actions--left {
    display: flex;

    & > * {
      margin: 0 4px;
    }

    & > *:first-child {
      margin: 0 4px 0 0;
    }

    & > *:last-child {
      margin: 0 0 0 4px;
    }
  }

  .user-interaction {
    display: flex;

    & > * {
      margin: 0 4px;
    }

    & > *:first-child {
      margin: 0 4px 0 0;
    }

    & > *:last-child {
      margin: 0 0 0 4px;
    }
  }
}
@media (max-width: 1366px) {
}
@media (max-width: 1280px) {
}
@media (max-width: 1024px) {
  .vehicle-price--right {
    max-width: 100%;
    text-align: right;
  }
}
@media (max-width: 768px) {
  .tooltip {
    --tooltip-margin-default: -255px;
    --tooltip-width-large: 250px;
    --tooltip-position-default: 0;
  }
}
@media (max-width: 375px) {
  .tooltip {
    --tooltip-margin-default: -205px;
    --tooltip-width-large: 200px;
    --tooltip-position-default: 0;
  }
}
</style>
