<template>
  <div class="estimated-value">
    <Subtitle
      :label="$t('pages.personalPage.returnDetails.estimatedValue.title') + ':'"
    />
    <TextLabel class="title" :label="priceLabel" />
  </div>
</template>

<script>
import Subtitle from "../../refactor/atoms/Subtitle/Subtitle.vue";
import { getPriceFormattedNoDecimal } from "../../../utils/currency.js";
import { TextLabel } from "@sc/ds-ui-component-library";

export default {
  name: "EstimatedValue",
  components: {
    TextLabel,
    Subtitle,
  },
  props: {
    price: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    priceLabel() {
      if (this.price === 0) return this.$t("emptyRetakeValuation");
      return getPriceFormattedNoDecimal(this.price);
    },
  },
};
</script>

<style lang="scss" scoped>
.estimated-value {
  display: flex;
  align-items: center;

  --subtitle-font-size: 18px;
  --subtitle-line-height: 18px;
  --subtitle-weight: 600;
  --subtitle-color: var(--theme-color);

  --text-label-font-size: 30px;
  --text-label-line-height: 31px;
  --text-label-color: var(--theme-color-secondary);
  --text-label-weight: 700;
}
</style>
