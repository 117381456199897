<template>
  <p :class="['text-label', customClass, size, weight]" v-html="label"></p>
</template>

<script>
export default {
  name: "TextLabel",
  props: {
    customClass: {
      type: String,
    },
    label: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      validator: (size) => {
        return ["small", "normal"].includes(size);
      },
    },
    weight: {
      type: String,
      validator: (weight) => {
        return ["regular", "semibold", "bold"].includes(weight);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.text-label {
  font-size: var(--text-label-font-size);
  line-height: var(--text-label-line-height);
  font-weight: var(--text-label-weight);
  color: var(--text-label-color);

  &.small {
    font-size: var(--text-label-small-font-size);
    line-height: var(--text-label-small-line-height);
  }

  &.regular {
    font-weight: var(--font-weight-regular);
  }

  &.semibold {
    font-weight: var(--font-weight-semibold);
  }

  &.bold {
    font-weight: var(--font-weight-bold);
  }
}
</style>
