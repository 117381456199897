<template>
  <div :class="['section--header', classes]">
    <Label
      class="section--header-title"
      :size="titleSize"
      :color="labelColor"
      bold="bold"
    >
      <div v-html="title"></div>
    </Label>
    <Label
      v-if="subtitle"
      class="section--header-subtitle"
      :size="subtitleSize"
      color="tertiary"
    >
      <div v-html="subtitle"></div>
    </Label>
  </div>
</template>

<script>
import { Label } from "../../atoms";

export default {
  components: {
    Label,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: "",
      required: false,
    },
    type: {
      type: String,
      default: "primary",
      validator: (type) => {
        return ["primary", "secondary"].includes(type);
      },
    },
    titleSize: {
      type: String,
      default: "large",
      required: false,
      validator(value) {
        return ["small", "medium", "large"].includes(value);
      },
    },
    subtitleSize: {
      type: String,
      default: "small",
      required: false,
      validator(value) {
        return ["small", "medium", "large"].includes(value);
      },
    },
    customClass: {
      type: String,
      required: false,
      default: "",
    },
    customStyle: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    classes() {
      return {
        ...this.customClass,
      };
    },
    styles() {
      return {
        ...this.customStyle,
      };
    },
    labelColor() {
      return this.type === "secondary" ? "secondary" : "primary";
    },
  },
};
</script>
<style lang="css" scoped src="./SectionHeader.css"></style>
