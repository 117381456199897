<template>
  <div class="warranty-card">
    <div class="warranty-card--content">
      <div class="warranty-card--content-dual">
        <div class="first-dual">
          <div class="first-dual-content">
            <Label class="first-label color-1" size="small">{{ info }}</Label>
            <div
              class="warranty-card--content-list"
              v-if="informationList.length"
            >
              <div
                class="warranty-card--content-list-item"
                v-for="(item, index) in informationList"
                :key="index"
              >
                <Label class="intermediate-label color-1" size="small">{{
                  item
                }}</Label>
              </div>
            </div>
          </div>
          <div class="v-divisor"></div>
        </div>
        <div class="second-dual">
          <div class="second-dual-content">
            <img
              class="img-size"
              :src="
                $t('resources.images.searchpage.warrantyCard') ||
                require('./../../../assets/images/search-page/Garantia.svg')
              "
              :alt="$t('pages.detailsPage.details.warrantyCard.altImg')"
            />
            <Label class="year-label" size="small">Anos de Garantia</Label>
          </div>
        </div>
      </div>
      <Label class="last-label color-2" size="small">{{ info }}</Label>
    </div>
  </div>
</template>

<script>
import { Label } from "../../atoms";
export default {
  components: {
    Label,
  },
  props: {
    info: {
      type: String,
      default: "",
    },
    infoList: {
      type: Array[String],
      default: [],
    },
  },
  data() {
    return {
      informationList: [],
    };
  },
  mounted() {
    this.informationList = this.infoList.filter((info) => info.trim());
  },
};
</script>

<style lang="scss" scoped>
.warranty-card {
  padding: 48px;
  background-color: var(--color-6);
  max-width: 1038px;
}

.warranty-card--content {
  display: flex;
  flex-direction: column;

  & > * {
    margin: 24px 0;
  }

  & > *:first-child {
    margin: 0 0 24px 0;
  }

  & > *:last-child {
    margin: 24px 0 0 0;
  }
}

.warranty-card--content-dual {
  display: flex;
  flex-direction: row;
  & > * {
    margin: 0 40px;
  }

  & > *:first-child {
    margin: 0 40px 0 0;
  }

  & > *:last-child {
    margin: 0 0 0 40px;
  }
}

.first-dual {
  display: flex;
  flex-direction: row;
  & > * {
    margin: 0 24px;
  }

  & > *:first-child {
    margin: 0 24px 0 0;
  }

  & > *:last-child {
    margin: 0 0 0 24px;
  }
}

.second-dual {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.second-dual-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  max-width: 93px;

  & > * {
    margin: 12px 0;
  }

  & > *:first-child {
    margin: 0 0 12px 0;
  }

  & > *:last-child {
    margin: 12px 0 0 0;
  }
}

.first-dual-content {
  display: flex;
  flex-direction: column;
  max-width: 689px;

  & > * {
    margin: 20px 0;
  }

  & > *:first-child {
    margin: 0 0 20px 0;
  }

  & > *:last-child {
    margin: 20px 0 0 0;
  }
}

.warranty-card--content-list {
  display: flex;
  flex-direction: column;

  & > * {
    margin: 12px 0;
  }

  & > *:first-child {
    margin: 0 0 12px 0;
  }

  & > *:last-child {
    margin: 12px 0 0 0;
  }
}

.v-divisor {
  border-left: 1px solid grey;
  height: auto;
}

.img-size {
  /* width: 75px;
  height: 110px; */
}

.first-label {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
}

.intermediate-label {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
}

.year-label {
  color: var(--theme-color);
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
}

.last-label {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
}

.color-1 {
  color: var(--color-1);
}

.color-2 {
  color: var(--color-2);
}

@media (max-width: 768px) {
  .warranty-card {
    padding: 16px;
  }

  .warranty-card--content-dual {
    display: flex;
    flex-direction: column-reverse;

    & > * {
      margin: 40px 0;
    }

    & > *:first-child {
      margin: 40px 0 0 0;
    }

    & > *:last-child {
      margin: 0 0 0 0;
    }
  }

  .first-dual {
    display: flex;
    flex-direction: column;
  }

  .v-divisor {
    display: none;
  }
}
</style>
