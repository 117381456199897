import {
  getPriceFormatted,
  getPriceFormattedNoDecimal,
} from "../../utils/currency";
import {
  getVehicleByPlate,
  getBrands,
  getModels,
  getVersions,
  getValuation,
  saveValuation,
  updateToSell,
} from "../../api/retake";
import { getReturnDetails } from "../../api/returns.js";
import router from "./../../router";
import i18n from "@/i18n.js";

const processMonth = (month) => {
  if (typeof month == "string") return month;
  return month < 10 ? "0" + month : month;
};

/*
  Helper Functions
*/
// const setDefaults = (commit) => {
//   commit("setBcaId", "");
//   commit("setModelsResponse", []);
//   commit("setModel", "");
//   commit("setModels", []);
//   commit("setVersions", []);
//   commit("setKilometersRetake", "");
//   commit("setPlateDate", "");
//   commit("setIsPrefill", false);
// };

const initialState = {
  successfullPlateCall: false,
  prefill: false,
  plate: "",
  dealer: "",
  brandObj: {},
  brandId: "",
  brand: "",
  brands: [],
  model: "",
  models: [],
  version: "",
  versions: [],
  kilometers: "",
  plateDate: "",
  bcaId: "",
  fuel: null,
  vin: null,
  transmission: null,
  valuationRequest: {
    plate: "",
    brand: "",
    model: "",
    version: "",
    kilometers: "",
    plateDate: "",
    bcaId: "",
  },
  status: "",
  id: "",
  month: 0,
  year: 0,
  tradeInId: "",
  valuation: 0.0,
  firstLoad: true,
};

/* 
  Store
*/
const state = {
  ...initialState,
};

const getters = {
  getTradein: (state) => {
    if (!state.valuation) return null;
    return {
      dealer: state.dealer,
      plate: state.plate,
      brand: state.brand,
      value: getPriceFormatted(state.valuation),
      pvp: getPriceFormatted(state.valuation),
    };
  },
  getDealer: (state) => state.dealer,
  getYear: (state) => state.year,
  getMonth: (state) => state.month,
  getPlate: (state) => state.plate,
  getBrand: (state) => state.brand,
  getBrands: (state) => state.brands,
  getModell: (state) => state.model,
  getModels: (state) => state.models,
  getVersion: (state) => state.version,
  getVersions: (state) => state.versions,
  getKilometersRetake: (state) => state.kilometers,
  getPlateDate: (state) => state.plateDate,
  getBcaId: (state) => state.bcaId,
  getValuationRequest: (state) => state.valuationRequest,
  getValuation: (state) =>
    state.valuation && state.valuation !== 0
      ? getPriceFormattedNoDecimal(Number(state.valuation))
      : router.app.$t("emptyRetakeValuation"),
  getTradeInId: (state) => state.tradeInId,
  isPrefill: (state) => state.prefill,
  getRetakeValue: (state) => state.valuation,
  getRetakeObj: (state) => {
    const { model, valuation, plate } = state;
    return {
      model,
      value: valuation,
      registration: plate,
      expire: 2,
    };
  },
  getSuccessfullPlateCall: (state) => state.successfullPlateCall,
  isFirstLoad: (state) => state.firstLoad,
};

const mutations = {
  setMonth(state, val) {
    state.month = val;
  },
  setYear(state, val) {
    state.year = val;
  },
  setSuccessfullPlateCall(state, val) {
    state.successfullPlateCall = val;
  },
  setAllVersions(state, versions) {
    state.allVersions = versions;
  },
  setPrefillBrand(state, { brand, brandId }) {
    state.brand = { key: brandId, value: brand, text: brand, selected: true };
    state.brands = [state.brand];
  },
  setFormContent(state) {
    state.prefill = true;
  },
  setPlate(state, plate) {
    state.plate = plate.toUpperCase();
  },
  setDealer(state, dealer) {
    state.dealer = dealer;
  },
  setBrand(state, brand) {
    state.brand = brand;
    state.brands = state.brands.length
      ? state.brands.map((el) => {
          return {
            key: el.key,
            value: el.value,
            text: el.text,
            selected: brand.value === el.value,
          };
        })
      : [];
  },
  setBrandId(state, brandId) {
    state.brandId = brandId;
  },
  setBrandById(state, id) {
    state.brand = state.brands.find((b) => b.value == id);
  },
  setBrands(state, brands) {
    state.brands = [
      ...brands.map((el) => ({
        key: el.id,
        value: el.description,
        text: el.description,
      })),
    ];
  },
  setModel(state, model) {
    state.model = model;
    state.models = state.models.length
      ? state.models.map((el) => {
          return {
            key: el.key,
            value: el.value,
            text: el.text,
            selected: model.value === el.value,
          };
        })
      : [];
  },
  setModels(state, models) {
    state.models =
      models?.length > 0
        ? models.map((el) => ({
            value: el.model,
            key: el.model,
            text: el.model,
            selected: models.length === 1,
          }))
        : [];
  },
  setVersion(state, version) {
    state.version = version;
    state.versions = state.versions.length
      ? state.versions.map((el) => {
          return {
            key: el.key,
            value: el.value,
            text: el.text,
            selected: version === el.value,
            fuel: el.fuel,
            transmission: el.transmission,
          };
        })
      : [];
  },
  setVersions(state, versions) {
    state.versions =
      versions?.length > 0
        ? versions.map((el) => ({
            value: el.version,
            key: el.versionId,
            text: el.version,
            selected: versions.length === 1,
            fuel: el.fuel,
            transmission: el.transmission,
          }))
        : [];
    if (versions.length === 1) {
      state.bcaId = versions[0].versionId;
      state.version = versions[0].version;
      state.fuel = versions[0].fuel;
      state.transmission = versions[0].transmission;
    }
  },
  setPlateDate(state, plateDate) {
    state.plateDate = plateDate;
  },
  setKilometersRetake(state, kilometers) {
    state.kilometers = kilometers;
  },
  setBcaId(state, bcaId) {
    state.bcaId = bcaId;
  },
  setValuation(state, valuationResponse) {
    state.valuation = valuationResponse.valuation;
    state.tradeInId = valuationResponse.tradeInId;
  },
  setValuationRequest(state, valuationRequest) {
    state.valuationRequest = valuationRequest;
  },
  setIsPrefill(state, prefill) {
    state.prefill = prefill;
  },
  setRetake(state, retake) {
    state.plate = retake.licensePlate;
    state.brand = retake.brand;
    state.model = retake.model;
    state.version = retake.version;
    state.kilometers = retake.kilometers;
    state.bcaId = retake.bcaId;
    state.valuation =
      retake.status === 1 ? retake.actualValuation : retake.initialValuation;
    state.status = retake.status;
    state.id = retake.id;
    state.plateDate = `${retake.year}-${processMonth(retake.month)}-01`;
  },
  setTransmission(state, transmission) {
    state.transmission = transmission;
  },
  setFuel(state, fuel) {
    state.fuel = fuel;
  },
  setVin(state, vin) {
    state.vin = vin;
  },
  setInitialState(state) {
    for (var [key, value] of Object.entries(state)) {
      value;
      state[key] = initialState[key];
    }
  },
  toggleFirstLoad(state) {
    state.firstLoad = !state.firstLoad;
  },
};

const actions = {
  async updateToSell({ state }, sellInStore) {
    try {
      await updateToSell(state.tradeInId, sellInStore);
    } catch (err) {
      console.error(err);
    }
  },
  async storeVehicleByPlate({ commit, state }) {
    try {
      commit("spinnerModule/setLoading", true, { root: true }); // -> 'someMutation'
      //setDefaults(commit);
      const plate = state.plate;
      const res = await getVehicleByPlate(state.plate);

      if (
        res?.data?.data?.brand !== null ||
        res?.data?.data?.model?.model !== null
      ) {
        const [year, month] = res.data.data.registrationDate
          .split("-")
          .slice(0, 2);
        commit("setYear", year);
        commit("setMonth", month);
        commit("setSuccessfullPlateCall", true);
        commit("setPrefillBrand", {
          brand: res.data.data.brand,
          brandId: res.data.data.brandId,
        });

        const model = res.data.data.models[0];
        commit("setModels", res.data.data.models);

        if (res.data.data.models.length === 1) {
          commit("setModel", {
            key: model.model,
            value: model.model,
            text: model.model,
            selected: true,
          });
          commit("setVersions", model.versions);
        }
        commit("setAllVersions", res.data.data.models);
        commit("setFormContent");
        commit("setVin", res.data.data.vin);
      } else {
        commit("setInitialState");
        commit("setPlate", plate);
        const resBrands = await getBrands();
        commit("setBrands", resBrands.data.data);
      }
    } catch (err) {
      const resBrands = await getBrands();
      //setDefaults(commit);
      commit("setBrands", resBrands.data.data);
      commit(
        "setAlert",
        {
          message: i18n.t("toast.valuation.error.generic"),
          type: "error",
          display: true,
          global: true,
        },
        { root: true }
      );
    } finally {
      commit("spinnerModule/setLoading", false, { root: true }); // -> 'someMutation'
    }
  },
  async storeBrands({ commit }) {
    try {
      const res = await getBrands();
      //setDefaults(commit);
      commit("setBrands", res.data.data);
    } catch (err) {
      console.error("Could not fetch brands from backend");
    }
  },
  async storeModels({ commit, state }) {
    commit("spinnerModule/setLoading", true, { root: true }); // -> 'someMutation'
    try {
      const res = await getModels(state.brand.key, state.year);
      commit("setModels", res.data.data);
    } catch (err) {
      console.error("Could not fetch models from backend");
    } finally {
      commit("spinnerModule/setLoading", false, { root: true }); // -> 'someMutation'
    }
  },

  async storeVersions({ commit, state }) {
    commit("spinnerModule/setLoading", true, { root: true }); // -> 'someMutation'
    try {
      let versions = await state.allVersions.filter(
        (v) => v.model === state.model.value
      )[0].versions;
      if (!versions) {
        const res = await getVersions(
          state.brand.key,
          state.model.key,
          state.year
        );
        versions = res.data.data;
      }
      commit("setVersions", versions);
    } catch (err) {
      console.error("Could not fetch versions from backend");
    } finally {
      commit("spinnerModule/setLoading", false, { root: true }); // -> 'someMutation'
    }
  },
  storeValuation({ state }, userInfo) {
    return new Promise((resolve, reject) => {
      getValuation({
        dealerId: state.dealer,
        plate: state.plate,
        brand: state.brand.value,
        brandId: state.brand.key,
        model: state.model.key,
        version: state.version,
        kilometers: state.kilometers,
        plateDate: `${state.year}-${processMonth(state.month)}-01`,
        versionId: state.bcaId,
        firstName: userInfo.name.split(" ")[0],
        lastName: userInfo.name.split(" ")[1],
        emailAddress: userInfo.email,
        phoneNumber: userInfo.phoneNumber,
        fuel: state.fuel,
        transmission: state.transmission,
        vin: state.vin,
        tradeInType: userInfo.tradeInType,
        assignToUser: true,
      })
        .then((response) => {
          if (!response.data) {
            reject(response.response.data);
          }
          resolve(response.data.data);
        })
        .catch((error) => {
          console.error("Could not fetch valuation from backend");
          reject(error);
        });
    });
  },
  async assignValuationToUser({ state }) {
    try {
      await saveValuation({ tradeInId: state.tradeInId });
    } catch (err) {
      console.error("Could not assign valuation to user");
    }
  },
  async storeTradein({ commit }, id) {
    try {
      const response = await getReturnDetails(id);
      commit("setRetake", response.data.data);
    } catch (e) {
      console.error(e);
    }
  },

  sellVehicle(_, payload) {
    console.log("sellVehicle", payload);
  },
  cleanTradein({ commit }) {
    commit("setInitialState");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
