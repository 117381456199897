import { autocomplete } from "@/api/autocomplete";

const state = {
  results: [],
  // errors
  errors: [],
  // for loading spinner
  loadingAutocomplete: false,
};

const getters = {
  getResults: (state) => state.results,
  getAutoCompleteLoadingState: (state) => state.loadingAutocomplete,
};

const actions = {
  // Fetch all results from the api
  async storeResults({ commit }, { word, source }) {
    commit("setAutoCompleteLoading", true);
    try {
      const response = await autocomplete(word, source);
      commit("setResults", response.data.data);
      commit("setAutoCompleteLoading", false);
    } catch (e) {
      commit("setErrors", e);
    }
  },
};

// serves to define state value
const mutations = {
  setResults(state, results) {
    state.results = results;
  },
  setErrors(state, errors) {
    state.errors = errors;
  },
  setAutoCompleteLoading(state, loading) {
    state.loadingAutocomplete = loading;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
