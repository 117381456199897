<template>
  <div class="upload">
    <div class="upload-header">
      <div class="info">
        <div class="labels">
          <TextLabel customClass="title" :label="title" />
          <TextLabel customClass="subtitle" :label="`(${subtitle})`" />
        </div>
        <TextLabel
          customClass="filename"
          v-if="!!filename || currentFilename"
          :label="`Ficheiro adicionado - ${filename || currentFilename}`"
        />
      </div>
      <input
        type="file"
        :id="`file-${pov}`"
        ref="file"
        accept=".jpg, .jpeg, .png, .webp"
        @change="compress"
      />
    </div>
    <div class="upload-button">
      <Button
        v-if="!getReturn.submitted"
        :disabled="disabled"
        label="Upload"
        :fill="false"
        type="tertiary"
        :border="true"
        uppercase
        rounded
        @onClick="submitFile"
      ></Button>
      <Button
        v-if="
          getReturn.phaseTwoDTOV3 &&
          getReturn.phaseTwoDTOV3.files &&
          getReturn.phaseTwoDTOV3.files.find((image) => image.name === pov)
        "
        :disabled="false"
        label="Download"
        :fill="false"
        type="tertiary"
        :border="true"
        uppercase
        rounded
        @onClick="downloadFile"
      ></Button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Button from "../../refactor/molecules/Button/Button.vue";
import TextLabel from "../../refactor/atoms/TextLabel/TextLabel.vue";

// MAX 3MB
const MAX_ALLOWED = 3145728;

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
      if (encoded.length % 4 > 0) {
        encoded += "=".repeat(4 - (encoded.length % 4));
      }
      resolve(encoded);
    };
    reader.onerror = (error) => reject(error);
  });

export default {
  name: "UploadFile",
  components: {
    Button,
    TextLabel,
  },
  data() {
    return {
      browseFile: false,
      file: "",
      filename: "",
    };
  },
  props: {
    title: {
      type: String,
      default: "Title",
    },
    subtitle: {
      type: String,
      default: "Subtitle",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    pov: {
      type: String,
    },
  },
  methods: {
    async handleFileUpload(rawFile) {
      this.file = await toBase64(rawFile);
      this.filename = rawFile.name;
      this.$emit("onUpload", { content: this.file, name: this.filename });

      // /this.submitFile();
    },
    async compress(e) {
      const file = e.target.files[0];
      const blob = file.slice(0, 4);
      const buffer = await blob.arrayBuffer();
      const uint = new Uint8Array(buffer);
      let bytes = [];
      uint.forEach((byte) => {
        bytes.push(byte.toString(16));
      });
      const hex = bytes.join("").toUpperCase();

      const mimeType = this.checkMimeType(hex);

      if (
        !(
          (mimeType === "image/jpeg" ||
            mimeType === "image/png" ||
            mimeType === "image/webp") &&
          (file.type === "image/jpeg" ||
            file.type === "image/jpg" ||
            file.type === "image/png" ||
            file.type === "image/webp")
        )
      ) {
        this.$refs.file.value = null;
        this.$toast.open({
          message: this.$t("toast.uploadFile.invalidFileTypeError"),
          dismissible: true,
          type: "error",
        });
        return;
      }
      if (file.size < MAX_ALLOWED) {
        const fileName = file.name;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;
          img.onload = () => {
            const elem = document.createElement("canvas");
            elem.width = img.width * 0.9;
            elem.height = img.height * 0.9;
            const ctx = elem.getContext("2d");
            ctx.drawImage(img, 0, 0, elem.width, elem.height);

            if (
              file.type === "image/jpg" ||
              file.type === "image/jpeg" ||
              file.type === "image/webp"
            ) {
              ctx.canvas.toBlob(
                (blob) => {
                  const file = new File(
                    [blob],
                    fileName.replace(".jpeg", ".jpg").replace(".webp", ".jpg"),
                    {
                      type: "image/jpg",
                      lastModified: Date.now(),
                    }
                  );
                  this.handleFileUpload(file);
                },
                "image/jpg",
                0.5
              );
            } else if (file.type === "image/png") {
              ctx.canvas.toBlob(
                (blob) => {
                  const file = new File([blob], fileName, {
                    type: "image/png",
                    lastModified: Date.now(),
                  });
                  this.handleFileUpload(file);
                },
                "image/png",
                0.5
              );
            }
          };
          img.onerror = () => {
            this.$refs.file.value = null;
            this.$toast.open({
              message: this.$t("toast.uploadFile.invalidFile"),
              dismissible: true,
              type: "error",
            });
            return;
          };
        };
        reader.onerror = () => {
          this.$refs.file.value = null;
          this.$toast.open({
            message: this.$t("toast.uploadFile.invalidFile"),
            dismissible: true,
            type: "error",
          });
          return;
        };
      } else {
        this.$refs.file.value = null;
        this.$toast.open({
          message: this.$t("toast.uploadFile.maximumFileSizeError"),
          dismissible: true,
          type: "error",
        });
        return;
      }
    },
    submitFile() {
      document.getElementById(`file-${this.pov}`).click();
    },
    downloadFile() {
      this.$emit("onDownload", { content: this.file, name: this.filename });
    },
    checkMimeType(signature) {
      switch (signature) {
        case "89504E47":
          return "image/png";
        case "47494638":
          return "image/gif";
        case "25504446":
          return "application/pdf";
        case "FFD8FFE8":
        case "FFD8FFE3":
        case "FFD8FFE2":
        case "FFD8FFE1":
        case "FFD8FFE0":
        case "FFD8FFDB":
        case "FFD8FFEE":
          return "image/jpeg";
        case "52494646":
          return "image/webp";
        case "504B0304":
          return "application/zip";
        default:
          return "Unknown filetype";
      }
    },
  },
  computed: {
    ...mapGetters(["getReturn", "getReturnImages"]),
    currentFilename() {
      return (
        this.getReturnImages?.find((image) => image.name === this.pov)?.file
          ?.name || ""
      );
    },
  },
};
</script>

<style lang="css" scoped src="./UploadFile.css"></style>
