<template>
  <!-- TODO: toggle when loading tags -->
  <div class="tags--row" v-if="getSelectedTags.length > 0">
    <FilterTag
      v-for="(tag, idx) in getSelectedTags"
      :key="idx"
      rounded
      :label="tag.value"
      closable
      labelWeight="semibold"
      @onClick="handleClose(tag)"
      iconColor="#00226f"
      :iconSize="14"
    />
    <div class="links-content">
      <Link underline :label="$t('searchform.save')" @onClick="saveSearch" />
      <Link underline :label="$t('searchform.clean')" @onClick="cleanAll" />
    </div>
  </div>
</template>

<script>
import { FilterTag, Link } from "@sc/ds-ui-component-library";
import { mapGetters } from "vuex";

export default {
  name: "SearchFormTags",
  components: {
    FilterTag,
    Link,
  },
  computed: {
    ...mapGetters({
      getSelectedTags: "SearchModule/filter/getTags",
    }),
  },
  methods: {
    handleClose(tag) {
      this.$emit("closeTag", tag);
    },
    saveSearch() {
      this.$emit("onSaveSearch");
    },
    cleanAll() {
      this.$emit("onClean");
    },
  },
};
</script>

<style scoped lang="scss">
.tags--row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & > * {
    margin: 8px 16px 8px 0;
  }
}
.links-content {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

@media (max-width: 768px) {
  .tags--row ::v-deep .filter-tag-container {
    --tag-width: fit-content;
    height: fit-content;
  }
  .tags--row ::v-deep .filter-tag-container .tag-container {
    min-height: 26px;
    height: unset;
  }
  _::-webkit-full-page-media,
  .tags--row ::v-deep .filter-tag-container {
    margin: 6px 0px;
  }
}
</style>
