import httpClient from "./httpClient";
const AUTOCOMPLETE =
  process.env.VUE_APP_DIGITAL_STORE_BASE_URL + "/vehicle/autocomplete";

function autocomplete(word, source) {
  try {
    return httpClient.get(`${AUTOCOMPLETE}?q=${word}&withBrand=true`, {
      cancelToken: source.token,
    });
  } catch (err) {
    return err;
  }
}

export { autocomplete };
