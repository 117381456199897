import { inBusinessDaysAhead } from "./date";

function prepareZipCode(zipcode) {
  return zipcode.split("-");
}

export function prepareLeadPayload({
  vehicle,
  receipt,
  extraReceipt,
  user,
  dealer,
  deliveryDealer,
  withFinance,
  withTradein,
  retake,
  tradeInId,
  withDelivery,
}) {
  const { vin, financeId, tooltipDescription } = vehicle.carDetailCard;
  const {
    taeg,
    pvp,
    tan,
    months,
    monthlyPayment,
    depositValue,
    capitalFinance,
    mtic,
  } = vehicle.carDetailFinance;

  const deliveryZipCode = prepareZipCode(
    extraReceipt.zipCode || receipt.zipCode
  );
  const { id, creditName } = vehicle.creditTypes[0];
  const billingZipCode = prepareZipCode(receipt.zipCode);
  return {
    vin,
    fid: financeId,
    leadPayload: {
      name: user.name,
      dealerId: deliveryDealer,
      phoneNumber: user.phone,
      emailAddress: user.email,
      infoCheck: true,
      vin,
      delivery: {
        deliveryType: withDelivery,
        address: extraReceipt.address || receipt.address,
        zipCode: deliveryZipCode[0],
        zipCodeExtension: deliveryZipCode[1],
        city: extraReceipt.city || receipt.city,
      },
      billing: {
        vatNumber: receipt.nif,
        address: receipt.address,
        zipCode: billingZipCode[0],
        zipCodeExtension: billingZipCode[1],
        city: receipt.city,
      },
      noFinance: !withFinance,
      finance: !withFinance
        ? null
        : {
            nbrPayments: months,
            taeg,
            pvp,
            productName: creditName,
            fee: monthlyPayment,
            initialValue: depositValue,
            initialExpenses: 500, // TODO: load value from env or store
            tan,
          },
      currentUrl: window.location.href,
      tradeInId: tradeInId,
    },
    reservationPayload: {
      reserveDate: new Date(),
      endReserveDate: inBusinessDaysAhead(10),
      pricePvp: pvp,
      finance: {
        monthlyValue: monthlyPayment,
        depositValue,
        pricePvp: pvp,
        nbrMonths: months,
        taeg,
        financedValue: capitalFinance,
        creditType: id,
        capitalFinance,
        tan,
        mtic,
        lastFee: Number(tooltipDescription.lastFee),
        dtEndOfCampaign: tooltipDescription.dtEndOfCampaign,
      },
      reserveType: withDelivery,
      deliveryDealer: deliveryDealer,
      totalPrice: pvp,
      receipt,
      dealershipId: dealer || "1231",
      ...(withTradein && { retakeId: retake.id }),
    },
    delivery: withDelivery,
  };
}
