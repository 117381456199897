<template>
  <CaptionIcon
    :iconColor="'#808080'"
    icon="like"
    :label="$tc('likes', likes, { count: likes })"
  />
</template>

<script>
import CaptionIcon from "./molecules/CaptionIcon/CaptionIcon.vue";

export default {
  name: "XLikes",
  components: { CaptionIcon },
  props: {
    likes: {
      type: Number,
    },
  },
};
</script>
