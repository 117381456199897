import httpClient from "./httpClient";

const TRANSLATIONS = "/translations";

function getTranslation(locale) {
  return httpClient.get(TRANSLATIONS, {
    params: {
      companyId: process.env.VUE_APP_DIGITAL_STORE_COMPANY_ID,
      locale: locale,
    },
  });
}

export default getTranslation;
