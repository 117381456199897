<template>
  <div class="wrapper">
    <div class="container" :class="[disabled ? 'disabled' : '', validated]">
      <BaseInput
        :name="name"
        customClass="input--input"
        @onFocus="showLabel = true"
        @onBlur="showLabel = false"
        @onInput="handleInput"
        :value.sync="currentValue"
      />
      <TextLabel
        :customClass="`input--label ${currentValue ? 'focused' : ''}`"
        :label="label"
      />
      <Icon customIcon="input--icon" :icon="icon" />
    </div>
    <Indicator
      v-if="showLabel && message"
      :type="resultType"
      :label="message"
      :icon="resultIcon"
    />
  </div>
</template>

<script>
import BaseInput from "../../atoms/BaseInput/BaseInput.vue";
import Icon from "../../atoms/Icon/Icon.vue";
import TextLabel from "../../atoms/TextLabel/TextLabel.vue";
import Indicator from "../Indicator/Indicator.vue";

export default {
  name: "Input",
  components: {
    BaseInput,
    Icon,
    TextLabel,
    Indicator,
  },
  props: {
    name: {
      type: String,
    },
    value: {
      type: [String, Number, Date],
      default: "",
    },
    label: {
      type: String,
      default: "TextField",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    validator: {
      type: Function,
      default: () => {},
    },
    messageIcon: {
      type: Boolean,
      default: false,
    },
    inputType: {
      type: String,
      default: "text",
      validator: (t) => ["text", "date", "password"].includes(t),
    },
  },
  data() {
    return {
      showLabel: false,
      validated: "",
      message: "",
      resultType: "",
    };
  },
  methods: {
    matcher(entry, suffix) {
      if (!entry) return "";
      const [type] = entry.split("-");
      return `${type}-${suffix}`;
    },
    handleInput(e) {
      console.log("Triggered ", e);
      this.currentValue = e.target.value;
      this.$emit("onInput", e.target.value);
      const out = this.validator(e.target.value);
      if (out) {
        const [res, msg] = out;
        this.message = msg;
        this.resultType = res;
        if (res) {
          this.validated = `${res}-label`;
        }
        return res === "success";
      }
    },
  },
  computed: {
    resultIcon() {
      if (this.resultType === "error") {
        return "exclamation-mark";
      }
      return this.resultType;
    },
    currentValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("onChange", value);
      },
    },
  },
};
</script>

<style scoped>
.wrapper {
  --indicator-margin-top: 5.5px;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.container {
  --baseinput-width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  background-color: #f5f7f9;
}

.input--label {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
}

.input--input {
  height: 56px;
  padding-left: 16px;
  padding-top: 28px;
  padding-bottom: 12px;
  outline: none;
  color: #02226f;
}

.input--label {
  position: absolute;
  pointer-events: none;
  left: 16px;
  top: 20px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  color: #757989;
}

.input--input:focus ~ .input--label,
.focused {
  top: 10px;
  font-size: 14px;
  line-height: 14px;
}

.container:hover,
.container:focus-within {
  box-shadow: inset 0px -2px 0px #02226f;
}

.input--icon {
  margin-right: 16px;
}

.error-label,
.error-label:hover,
.error-label:focus-within {
  box-shadow: inset 0px -2px 0px #e63312;
}

.warning-label,
.warning-label:hover,
.warning-label:focus-within {
  box-shadow: inset 0px -2px 0px #ffa300;
}
.info-label,
.info-label:hover,
.info-label:focus-within {
  box-shadow: inset 0px -2px 0px #00aad2;
}
.success-label,
.success-label:hover,
.success-label:focus-within {
  box-shadow: inset 0px -2px 0px #43a047;
}
</style>
