<template>
  <div class="specifications-container">
    <div class="vehicle-equipments-wrapper">
      <Label
        class="pt-8 label-text-color-1 vehicle-equipments-wrapper-title"
        color="primary"
        size="medium"
        bold="bold"
      >
        {{ $t("pages.detailsPage.details.equipments") }}
      </Label>
      <CollapseContainer
        class="padding-top-40"
        collapsedHeight="280px"
        :expandButtonText="
          isOpenCollapseContainer
            ? $t('pages.detailsPage.details.collapse.showLess')
            : $t('pages.detailsPage.details.collapse.showAll')
        "
        expandButtonColor="secondary"
        @onOpen="onOpenCollapseContainer"
      >
        <template v-for="(equipment, index) in equipments">
          <div :key="`${index}-equipment-4`">
            <Label class="pt-8" color="primary" size="medium" bold="semibold">{{
              $t(`pages.detailsPage.equipments.${equipment.categoryName}`)
            }}</Label>
            <div class="flex-container padding-top-40">
              <div class="flex-child">
                <template v-for="(equipment, index) in equipment.equipments">
                  <div :key="`${index}-equipment-5`" v-if="index % 2 === 0">
                    {{ equipment }}
                    <hr :key="equipment" class="divisor" />
                  </div>
                </template>
              </div>
              <div class="flex-child">
                <template v-for="(equipment, index) in equipment.equipments"
                  ><div :key="`${index}-equipment-5`" v-if="index % 2 !== 0">
                    {{ equipment }}
                    <hr :key="equipment" class="divisor" />
                  </div>
                </template>
              </div>
            </div>
          </div>
        </template>
      </CollapseContainer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Label } from "../../atoms";
import { CollapseContainer } from "../../molecules";

export default {
  name: "SpecificationsContainer",
  components: {
    Label,
    CollapseContainer,
  },
  props: {
    isOpenCollapseContainer: {
      type: Boolean,
    },
    featuresList: {
      type: Array,
    },
  },
  methods: {
    onOpenCollapseContainer(event) {
      this.$emit("onOpenCollapseContainer", event);
    },
  },
  computed: {
    ...mapGetters({
      getVehicle: "VehicleModule/getVehicle",
    }),
    equipments() {
      if (!this.getVehicle) return {};
      return this.getVehicle?.carDetailEquipmentByCategory
        ?.equipmentsByCategory;
    },
  },
};
</script>

<style lang="css" scoped>
.flex-container {
  display: flex;
}
.vehicle-equipments-wrapper {
  text-align: left;
}

.vehicle-equipments-wrapper .label {
  font-size: 18px;
  line-height: 36px;
}

@media (max-width: 767px) {
  .label-text-color-1 {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
  }
  ::v-deep .vehicle-specifications {
    padding: 24px 0px;
  }
  .flex-container {
    flex-direction: column;
  }
  .vehicle-equipments-wrapper {
    width: 100%;
  }
  ::v-deep .specification .block--content .specification--title {
    width: fit-content;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
  }
  ::v-deep .specification .block--content .specification--label {
    font-weight: 500;
    font-size: 14px;
    text-align: end;
    line-height: 14px;
    float: right;
  }

  ::v-deep .specification .block--content {
    display: inline;
  }
  .flex-child {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    word-break: break-word;
  }
  .vehicle-equipments-wrapper .label {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
  }
  .padding-top-40 {
    padding-top: 24px;
    margin-bottom: 24px;
  }
  .flex-child hr {
    margin: 16px 0px;
  }
  .specifications-container {
    width: 100%;
  }
  ::v-deep .collapse-container--link-wrapper .button {
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
  }
}

@media (max-width: 363px) {
  ::v-deep .specification .block--content {
    display: flex;
  }
}
</style>
