<template>
  <div class="buy-with-financing">
    <div class="buy-with-financing--title">
      <Label size="small">{{ title }}</Label>
    </div>
    <form>
      <div class="buy-with-financing--radio">
        <div class="buy-with-financing--radio-option1">
          <RadioButtonLabel
            :title="yesTitle"
            radioId="yes"
            radioSize="small"
            radioColor="secondary"
            radioName="radio"
            radioCircular
            radioValue="yes"
            titleColor="primary"
            titleFor="yes"
            subtitleColor="tertiary"
            :radioChecked="true"
            @onChange="onChange"
          />
        </div>
        <div class="buy-with-financing--radio-option2">
          <RadioButtonLabel
            :title="noTitle"
            radioId="no"
            radioSize="small"
            radioColor="secondary"
            radioName="radio"
            radioCircular
            radioValue="no"
            titleColor="primary"
            titleFor="no"
            subtitleColor="tertiary"
            @onChange="onChange"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { Label } from "../../atoms";
import { RadioButtonLabel } from "../../molecules";
export default {
  name: "BuyWithFinancing",
  components: {
    Label,
    RadioButtonLabel,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    yesTitle: {
      type: String,
      default: "",
    },
    noTitle: {
      type: String,
      default: "",
    },
  },
  methods: {
    onChange(event) {
      this.$emit("onChange", event === "yes");
    },
  },
};
</script>

<style lang="scss" scoped>
.buy-with-financing {
  background-color: rgba(255, 255, 255, 1);
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.buy-with-financing--title {
  margin-bottom: 1rem;
}

.buy-with-financing--radio {
  align-items: center;
}

.buy-with-financing--radio-option1 {
  margin-bottom: 0.75rem;
}

.buy-with-financing--radio-option1 .label {
  font-size: 21px;
  vertical-align: middle;
}

.buy-with-financing--radio-option2 .label {
  font-size: 21px;
  vertical-align: middle;
}

input[type="radio"] {
  vertical-align: middle;
}

::v-deep .buy-with-financing--title {
  .label {
    font-weight: 700;
    font-size: 21px;
    line-height: 27px;
    color: var(--theme-color);
  }
}

::v-deep .buy-with-financing--radio {
  .label {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: var(--color-1);
  }
}

@media (min-width: 768px) {
  .buy-with-financing--radio {
    display: flex;
  }
  .buy-with-financing--radio-option1 {
    margin-bottom: 0px;
  }
  .buy-with-financing--radio-option2 {
    margin-left: 1.5rem /* 24px */;
  }
}
</style>
