<template>
  <SavedCard
    @onClick="onSearch"
    @onDelete="onDelete"
    labelFooterButton="Ver detalhe"
  >
    <template v-slot:header>
      <div class="saved-return--header">
        <div class="saved-return--header-left-container">
          <div class="saved-return--header-left">
            <Label size="small" color="primary"
              ><span>{{
                $t("pages.personalPage.returns.savedReturn.registrationPlate")
              }}</span>
              {{ formatePlate }}</Label
            >
            <Label size="small" color="primary"
              ><span>{{
                $t("pages.personalPage.returns.savedReturn.brand")
              }}</span>
              {{ vehicle.brand }}</Label
            >
            <Label size="small" color="primary"
              ><span>{{
                $t("pages.personalPage.returns.savedReturn.model")
              }}</span>
              {{ vehicle.model }}</Label
            >
          </div>
          <StatusTag
            class="status-tag"
            v-if="vehicle.status !== retakeStatusEnum.Q2_REFUSED"
            :type="
              $t(
                `pages.personalPage.returns.savedReturn.returnState[${vehicle.status}].type`
              )
            "
            :label="
              $t(
                `pages.personalPage.returns.savedReturn.returnState[${vehicle.status}].description`
              )
            "
          />
          <StatusTag
            class="status-tag"
            type="warning"
            :label="
              $t('pages.personalPage.returns.savedReturn.expirationLabel', {
                expiration: expirationDate,
              })
            "
          />
        </div>
        <div class="saved-return--header-right">
          <div class="saved-return--header-right-close">
            <div class="saved-return--header-right-close-value">
              <Label class="mb-2 vehicle-value" size="small" color="primary">{{
                $t("pages.personalPage.returns.savedReturn.value")
              }}</Label>
              <div class="saved-return--header-right-close-prices">
                <Label
                  v-if="vehicle.actualValuation !== vehicle.initialValuation"
                  class="old-value"
                  size="medium"
                  color="tertiary"
                  bold="bold"
                  :lineThrough="true"
                  >{{ initialPriceLabel }}</Label
                >
                <Label
                  class="price"
                  style="text-align: right"
                  size="medium"
                  color="secondary"
                  bold="bold"
                  >{{ priceLabel }}</Label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="saved-return--footer-left">
        <Label size="small" color="primary"
          ><span>{{
            $t("pages.personalPage.returns.savedReturn.registrationDate")
          }}</span>
          {{ vehicle.month }} / {{ vehicle.year }}</Label
        >
        <Label v-if="vehicle.fuel" size="small" color="primary"
          ><span>{{ $t("pages.personalPage.returns.savedReturn.fuel") }}</span>
          {{ vehicle.fuel }}</Label
        >
        <Label v-if="vehicle.transmission" size="small" color="primary"
          ><span>{{
            $t("pages.personalPage.returns.savedReturn.transmission")
          }}</span>
          {{ vehicle.transmission }}</Label
        >
        <Label size="small" color="primary"
          ><span>{{ $t("pages.personalPage.returns.savedReturn.km") }}</span>
          {{ formateKilometers }}</Label
        >
      </div>
    </template>
  </SavedCard>
</template>

<script>
import { SavedCard } from "../../organisms";
import { Label } from "../../atoms";
import {
  getPriceFormatted,
  getPriceFormattedNoDecimal,
  formatNonDecimalNumber,
} from "../../../utils/currency.js";
import { StatusTag } from "@sc/ds-ui-component-library";
import { RETAKESTATUS } from "../../../enums/retakeStatus.js";

export default {
  name: "SavedReturn",
  components: {
    SavedCard,
    Label,
    StatusTag,
  },
  props: {
    vehicle: {
      type: Object,
    },
  },
  data() {
    return {
      retakeStatusEnum: RETAKESTATUS,
    };
  },
  methods: {
    onSearch() {
      this.$emit("onSearch");
    },
    onDelete() {
      this.$emit("onDelete");
    },
  },
  computed: {
    initialPriceLabel() {
      return getPriceFormatted(this.vehicle?.initialValuation, {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    priceLabel() {
      if (this.vehicle?.status === 1 && this.vehicle?.actualValuation === 0)
        return this.$t("emptyRetakeValuation");
      if (this.vehicle?.status === 2 && this.vehicle?.initialValuation === 0)
        return this.$t("emptyRetakeValuation");
      if (this.vehicle?.status === 5) return this.$t("emptyRetakeValuation");
      if (this.vehicle?.status === 6) return this.$t("emptyRetakeValuation");
      return getPriceFormattedNoDecimal(
        this.vehicle?.actualValuation !== this.vehicle?.initialValuation
          ? this.vehicle?.actualValuation
          : this.vehicle?.initialValuation
      );
    },
    formatePlate() {
      return (
        this.vehicle.licensePlate.substring(0, 2) +
        "-" +
        this.vehicle.licensePlate.substring(2, 4) +
        "-" +
        this.vehicle.licensePlate.substring(4, 6)
      );
    },
    expirationDateFlag() {
      return (
        this.vehicle.status !== this.retakeStatusEnum.EXPIRED &&
        this.vehicle.status !== this.retakeStatusEnum.Q2_REFUSED &&
        this.vehicle.status !== this.retakeStatusEnum.FINALIZED
      );
    },
    expirationDate() {
      var createdDate = new Date(this.vehicle.createTime);
      const daysToExpire = 30;
      createdDate.setDate(createdDate.getDate() + daysToExpire);
      var expirationDate = new Date(createdDate);
      const expirationDateStr =
        expirationDate.getDate() +
        "/" +
        (expirationDate.getMonth() + 1) +
        "/" +
        expirationDate.getFullYear();

      return expirationDateStr;
    },
    formateKilometers() {
      return formatNonDecimalNumber(this.vehicle.kilometers);
    },
  },
};
</script>

<style lang="scss" scoped>
.saved-return--header-left-container {
  display: flex;

  & > * {
    margin: 0 10px;
  }

  & > *:first-child {
    margin: 0 10px 0 0;
  }

  & > *:last-child {
    margin: 0 0 0 10px;
  }
}

.status-tag {
  --tag-normal-height: 32px;
  --tag-border: 1px solid var(--text-label-color);
  --font-weight-bold: 500;
}

.saved-return {
  border: 2px solid #e4ecf0;
  padding: 1.5rem;
}

.saved-return--header {
  justify-content: space-between;
  width: 100%;
}

.saved-return--header-left {
  display: grid;
  margin-bottom: 1.5rem;
}

.saved-return--header-right-close-value {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.saved-return--header-right-close-prices {
  display: block;
  display: grid;
}

.saved-return--header-right-close-button .button {
  width: 32px;
  height: 32px;
  padding: 0;
  background-color: #f5f7f9;
}

.saved-return--footer {
  display: flex;
  margin-bottom: 0.75rem;
  border-top: 2px solid #e4ecf0;
  padding-top: 2.5rem;

  margin-top: 2.5rem;
  justify-content: space-between;
  align-items: center;
}

.saved-return--footer-left .label {
  margin-right: 1.5rem;
  padding-bottom: 1rem;
  vertical-align: middle;
  font-family: Mont;
  font-weight: 500;
  display: inline-block;
}

.saved-return--footer-right {
}

.saved-return--footer-right .button {
  margin-top: 1rem;
  height: 50px;
  width: 247px;
}

.vehicle-value {
  font-weight: 500;
  font-family: Mont;
  text-align: left;
}

::v-deep .label span {
  color: #757989;
  font-family: Mont;
  font-weight: 400;
}

.saved-return--header-left .label {
  font-size: 18px;
  margin-bottom: 0.5rem;
  font-family: Mont;
  font-weight: 500;
}

.saved-return--header-right-close-value .label {
  font-size: 16px;
  vertical-align: middle;
}

.saved-return--header-right-close-value .price {
  font-size: 32px;
  font-family: Mont;
  font-weight: 700;
  text-align: right;
}
.saved-return--header-right-close-value .old-value {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  text-align: right;
  text-decoration-line: line-through;
  color: var(--theme-color);
}

@media (min-width: 768px) {
  .saved-return--header {
    display: flex;
  }

  .saved-return--header-left {
    margin-bottom: 0px;
  }

  .saved-return--header-right {
    float: right;
  }

  .saved-return--header-right-close {
    display: flex;
  }

  .saved-return--header-right-close-value {
    display: grid;
    margin-right: 1.5rem;
  }

  .saved-return--footer {
    display: flex;
  }

  .saved-return--footer-left .label {
    padding-bottom: 0px;
    display: inline;
  }

  .saved-return--footer-right .button {
    margin-top: 0px;
    width: max-content;
  }

  .vehicle-value {
    text-align: right;
  }

  .saved-return--header-right-close-value .price {
    text-align: right;
  }
}

@media (max-width: 768px) {
  .saved-return--header-left-container {
    flex-direction: column;

    & > * {
      margin: 5px 0;
    }

    & > *:first-child {
      margin: 0 0 5px 0;
    }

    & > *:last-child {
      margin: 5px 0 0 0;
    }

    margin-bottom: 20px;
  }
}
</style>
