const RESERVE_STATUS = {
  START: 1,
  TEST_DRIVE: 2,
  IN_PROGRESS: 3,
  DONE: 4,
  CONTACT: 5,
  CONTACT_VIDEO: 6,
};

export { RESERVE_STATUS };
