<template>
  <div class="swiper-slide">
    <slot />
  </div>
</template>

<script>
export default {
  name: "SwiperSlide",
};
</script>

<style lang="css" scoped src="./SwiperSlide.css"></style>
