import httpClient from "./httpClient";

const DROPDOWNS = "/vehicle/dropdowns";

function getDropdowns() {
  return httpClient.post(DROPDOWNS, {
    headers: { Authorization: "" },
  });
}

export { getDropdowns };
