var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SavedCard',{attrs:{"labelFooterButton":"Ver detalhe"},on:{"onClick":_vm.onSearch,"onDelete":_vm.onDelete},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"saved-return--header"},[_c('div',{staticClass:"saved-return--header-left-container"},[_c('div',{staticClass:"saved-return--header-left"},[_c('Label',{attrs:{"size":"small","color":"primary"}},[_c('span',[_vm._v(_vm._s(_vm.$t("pages.personalPage.returns.savedReturn.registrationPlate")))]),_vm._v(" "+_vm._s(_vm.formatePlate))]),_c('Label',{attrs:{"size":"small","color":"primary"}},[_c('span',[_vm._v(_vm._s(_vm.$t("pages.personalPage.returns.savedReturn.brand")))]),_vm._v(" "+_vm._s(_vm.vehicle.brand))]),_c('Label',{attrs:{"size":"small","color":"primary"}},[_c('span',[_vm._v(_vm._s(_vm.$t("pages.personalPage.returns.savedReturn.model")))]),_vm._v(" "+_vm._s(_vm.vehicle.model))])],1),(_vm.vehicle.status !== _vm.retakeStatusEnum.Q2_REFUSED)?_c('StatusTag',{staticClass:"status-tag",attrs:{"type":_vm.$t(
              `pages.personalPage.returns.savedReturn.returnState[${_vm.vehicle.status}].type`
            ),"label":_vm.$t(
              `pages.personalPage.returns.savedReturn.returnState[${_vm.vehicle.status}].description`
            )}}):_vm._e(),_c('StatusTag',{staticClass:"status-tag",attrs:{"type":"warning","label":_vm.$t('pages.personalPage.returns.savedReturn.expirationLabel', {
              expiration: _vm.expirationDate,
            })}})],1),_c('div',{staticClass:"saved-return--header-right"},[_c('div',{staticClass:"saved-return--header-right-close"},[_c('div',{staticClass:"saved-return--header-right-close-value"},[_c('Label',{staticClass:"mb-2 vehicle-value",attrs:{"size":"small","color":"primary"}},[_vm._v(_vm._s(_vm.$t("pages.personalPage.returns.savedReturn.value")))]),_c('div',{staticClass:"saved-return--header-right-close-prices"},[(_vm.vehicle.actualValuation !== _vm.vehicle.initialValuation)?_c('Label',{staticClass:"old-value",attrs:{"size":"medium","color":"tertiary","bold":"bold","lineThrough":true}},[_vm._v(_vm._s(_vm.initialPriceLabel))]):_vm._e(),_c('Label',{staticClass:"price",staticStyle:{"text-align":"right"},attrs:{"size":"medium","color":"secondary","bold":"bold"}},[_vm._v(_vm._s(_vm.priceLabel))])],1)],1)])])])]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"saved-return--footer-left"},[_c('Label',{attrs:{"size":"small","color":"primary"}},[_c('span',[_vm._v(_vm._s(_vm.$t("pages.personalPage.returns.savedReturn.registrationDate")))]),_vm._v(" "+_vm._s(_vm.vehicle.month)+" / "+_vm._s(_vm.vehicle.year))]),(_vm.vehicle.fuel)?_c('Label',{attrs:{"size":"small","color":"primary"}},[_c('span',[_vm._v(_vm._s(_vm.$t("pages.personalPage.returns.savedReturn.fuel")))]),_vm._v(" "+_vm._s(_vm.vehicle.fuel))]):_vm._e(),(_vm.vehicle.transmission)?_c('Label',{attrs:{"size":"small","color":"primary"}},[_c('span',[_vm._v(_vm._s(_vm.$t("pages.personalPage.returns.savedReturn.transmission")))]),_vm._v(" "+_vm._s(_vm.vehicle.transmission))]):_vm._e(),_c('Label',{attrs:{"size":"small","color":"primary"}},[_c('span',[_vm._v(_vm._s(_vm.$t("pages.personalPage.returns.savedReturn.km")))]),_vm._v(" "+_vm._s(_vm.formateKilometers))])],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }