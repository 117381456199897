import {
  getUserPriceDropNotifications,
  activatePriceDropNotification,
  deletePriceDropNotification,
  getUserCarAlertNotifications,
  activateCarAlertNotification,
  deleteCarAlertNotification,
} from "../../api/notifications";

const state = {
  priceDropNotifications: {},
  carAlertNotifications: {},
};

const getters = {
  getPriceDropNotifications: (state) => state.priceDropNotifications,
  getCarAlertNotifications: (state) => state.carAlertNotifications,
};

const mutations = {
  setPriceDropNotifications(state, priceDropNotifications) {
    state.priceDropNotifications = priceDropNotifications;
  },
  setCarAlertNotifications(state, carAlertNotifications) {
    state.carAlertNotifications = carAlertNotifications;
  },
};

const actions = {
  async fetchPriceDropNotifications({ commit }) {
    var res = {};
    try {
      res = await getUserPriceDropNotifications();
      res = res?.data?.data;
    } catch (err) {
      console.error(err);
    } finally {
      commit("setPriceDropNotifications", res);
    }
  },
  async activatePriceDropNotification({ commit, dispatch }, vin) {
    commit("spinnerModule/setLoading", true, { root: true });
    try {
      await activatePriceDropNotification(vin);
    } catch (err) {
      console.error(err);
    } finally {
      dispatch("fetchPriceDropNotifications");
      commit("spinnerModule/setLoading", false, { root: true });
    }
  },
  async deletePriceDropNotification({ commit, dispatch }, vin) {
    commit("spinnerModule/setLoading", true, { root: true });
    try {
      await deletePriceDropNotification(vin);
    } catch (err) {
      console.error(err);
    } finally {
      dispatch("fetchPriceDropNotifications");
      commit("spinnerModule/setLoading", false, { root: true });
    }
  },
  async fetchCarAlertNotifications({ commit }) {
    var res = {};
    try {
      res = await getUserCarAlertNotifications();
      res = res?.data?.data;
    } catch (err) {
      console.error(err);
    } finally {
      commit("setCarAlertNotifications", res);
    }
  },
  async activateCarAlertNotification({ commit, dispatch }, id) {
    commit("spinnerModule/setLoading", true, { root: true });
    try {
      await activateCarAlertNotification(id);
    } catch (err) {
      console.error(err);
    } finally {
      dispatch("fetchCarAlertNotifications");
      commit("spinnerModule/setLoading", false, { root: true });
    }
  },
  async deleteCarAlertNotification({ commit, dispatch }, id) {
    commit("spinnerModule/setLoading", true, { root: true });
    try {
      await deleteCarAlertNotification(id);
    } catch (err) {
      console.error(err);
    } finally {
      dispatch("fetchCarAlertNotifications");
      commit("spinnerModule/setLoading", false, { root: true });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
