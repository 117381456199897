<template>
  <Panel :opened="getIsOpenReserve" @clickOutside="resetPanel">
    <PanelHeader
      :title="panelTitle"
      @onClosePanel="resetPanel"
      closeButton
      :divider="status === Status.NONE"
    />
    <PanelContent
      :class="status === Status.NONE ? 'lead--intro' : 'lead--reserve'"
    >
      <LeadIntro
        v-if="status === Status.NONE"
        :financingLabels="financingLabels"
        :vehicle="vehicle"
        :faqs="faqs"
        :financing="withFinance"
        @onReserve="handleReserve"
        @onTestDrive="handleTestDrive"
        @onContact="handleContact"
        @onActiveFootprint="(e) => faqsFootprint(e)"
        :labels="$t('panels.leadIntro')"
      />
      <LeadReserve
        class="reserve-container"
        ref="reserve"
        v-else-if="status === Status.RESERVE"
        :vehicle="vehicle"
        :financingLabels="financingLabels"
        :financing="financing"
        :tradein="getIncludeRetake ? getReturns[0] : undefined"
        :tradeInValuationFallback="$t('emptyRetakeValuation')"
        :general="general"
        :currentStep="getReserveStep"
        :getWithFinancing="withFinance"
        :includeFinancing="includeFinancing"
        :includeTradein="getIncludeRetake"
        :deliveryType="deliveryType"
        :personal="personal"
        :receipt="receipt"
        :extraReceipt="extraReceipt"
        :includeExtraReceipt="includeExtraReceipt"
        :dealers="dealers"
        :cardOwner="cardOwner"
        :pk="pk"
        :totalPayment="totalPayment"
        :totalPaymentTitle="totalPaymentTitle"
        @startLoadingPayment="setLoading(true)"
        @stopLoadingPayment="setLoading(false)"
        @onCardOwner="onCardOwner"
        @paymentDetailsUpdated="handlePaymentDetailsUpdate"
        @onSuccessfulPayment="onSuccessfulPayment"
        @onFailedPayment="onFailedPayment"
        @onInput="handleInput"
        @onDeliveryDealerChange="handleDeliveryDealerChange"
        @toggleExtraReceipt="handleToggleExtraReceipt"
        @onDealerChange="handleDealerChange"
        @onIncludeFinancing="onIncludeFinancing"
        @onIncludeTradeinInput="onIncludeTradein"
        @onDelivery="onDelivery"
        @onValuateTradein="onValuateTradein"
        @onEditFinancing="onEditFinancing"
        @onLogin="onLogin"
        @onRegister="onRegister"
        @onTradein="onTradein"
        @onFinancing="onFinancing"
        @onEditTradein="onEditTradein"
        :labels="$t('panels.reserve')"
        :loginLabels="$t('panels.leadLogin')"
        :customLabels="customLabels"
      />
    </PanelContent>
    <PanelFooter v-if="status === Status.RESERVE && getReserveStep <= 7">
      <Button
        :disabled="validStep"
        @onClick="handleInc"
        :label="$t('panels.reserve.button.next')"
        type="secondary"
      />
      <Button
        v-if="getReserveStep > 1"
        @onClick="handleDec"
        :label="$t('panels.reserve.button.previous')"
        type="tertiary"
      />
      <Button
        v-else
        @onClick="handleCloseBack"
        :label="$t('panels.reserve.button.previous')"
        type="tertiary"
      />
    </PanelFooter>
  </Panel>
</template>

<script>
import {
  Panel,
  PanelHeader,
  LeadIntro,
  PanelContent,
  LeadReserve,
  PanelFooter,
  Button,
} from "@sc/ds-ui-component-library";
import { mapGetters, mapMutations } from "vuex";
import formDemultiplexer from "../../utils/formDemultiplexer";
import { getPriceFormattedNoDecimal } from "../../utils/currency";

import { FOOTPRINT_TYPES, registerEvent } from "../../api/footprint";
import { PAGES } from "../../enums/pages";

import {
  pushNewEvent,
  GOOGLE_TAG_MANAGER_EVENT_TYPES,
  reserveStepEvent,
  purchaseEvent,
} from "../../utils/googleTagManager";

const Status = {
  NONE: 0,
  RESERVE: 1,
  TEST_DRIVE: 2,
  CONTACT: 3,
  PRICE_DROP: 4,
};

export default {
  name: "ReservePanel",
  components: {
    Panel,
    PanelHeader,
    LeadIntro,
    PanelContent,
    PanelFooter,
    Button,
    LeadReserve,
  },
  props: {
    financingLabels: {
      type: Object,
    },
  },
  data() {
    return {
      Status,
      pk: process.env.VUE_APP_STRIPE_PK,
      validPaymentDetails: false,
      contactLabels: {
        date: this.$t("panels.contact.dayLabel"),
        time: this.$t("panels.contact.hourLabel"),
      },
      faqs: this.$t("pages.detailsPage.faqs.interestFaqsList"),
      reservePanelLabel: "ReservePanel",
      dealerName: "",
    };
  },
  methods: {
    ...mapMutations({
      toggleReserve: "PanelModule/toggleReserve",
      setStatus: "PanelModule/setDetailPageStatus",
      setOnEditing: "PanelModule/setOnEditing",
      setReserveStep: "PanelModule/setReserveStep",
      setAlert: "setAlert",
      setContactPanel: "setContactPanel",
      setDetailPageComesFromReserve:
        "PanelModule/setDetailPageComesFromReserve",
      setLoading: "spinnerModule/setLoading",
    }),

    sendEvent(pg, o) {
      let deliveryType = "";

      switch (this.deliveryType) {
        case 0:
          deliveryType = "Domicílio";
          break;
        case 1:
          deliveryType = this.dealerName;
          break;
        case 2:
          deliveryType = "Personalizada ao Domicílio";
          break;
        default:
          deliveryType = "";
          break;
      }
      const vehicle = this.getVehicle.carDetailCard;
      let fields = {
        retake_added: this.getIncludeRetake,
        simulated_financing: this.includeFinancing,
        delivery_details: deliveryType,
      };

      if (pg < 2) {
        fields.retake_added = "";
      }
      if (pg < 3) {
        fields.simulated_financing = "";
      }
      if (pg < 4) {
        fields.delivery_details = "";
      }

      switch (pg) {
        case 2:
          reserveStepEvent(vehicle, 2, fields, true);
          break;
        case 3:
          reserveStepEvent(vehicle, 3, fields, true);
          break;
        case 4:
          reserveStepEvent(vehicle, 4, fields, false);
          localStorage.setItem("reserveFields", JSON.stringify(fields));
          break;
        case 5:
          fields = this.localStorageFieldsCheck(fields);
          reserveStepEvent(vehicle, 5, fields, true);
          break;
        case 6:
          fields = this.localStorageFieldsCheck(fields);
          reserveStepEvent(vehicle, 6, fields, true);
          break;
        case 7:
          fields = this.localStorageFieldsCheck(fields);
          reserveStepEvent(vehicle, 7, fields, true);
          break;
        default:
          this.removeLocalStorageFields();
          if (o !== 7) reserveStepEvent(vehicle, 1, fields, true);
          break;
      }
    },
    localStorageFieldsCheck(fields) {
      const localStorageFields = localStorage.getItem("reserveFields");
      if (localStorageFields) {
        return JSON.parse(localStorageFields);
      }
      return fields;
    },
    removeLocalStorageFields() {
      const localStorageFields = localStorage.getItem("reserveFields");
      if (localStorageFields) {
        localStorage.removeItem("reserveFields");
      }
    },
    onValuateTradein() {
      this.setDetailPageComesFromReserve({
        check: true,
        vehicle: this.getVehicle.carDetailCard,
      });
      this.$router.push({
        name: PAGES.RETAKE,
      });
    },
    onEditFinancing() {
      this.toggleReserve();
      this.setStatus(Status.RESERVE);
      this.setOnEditing(true);
    },
    onLogin() {
      registerEvent(FOOTPRINT_TYPES.RESERVE_STEP_4, {
        vin: this.vehicle?.vin,
        eventType: "login clicked",
      });
      document.querySelectorAll("[data-idgo-login]")[0].click();
    },
    onRegister() {
      registerEvent(FOOTPRINT_TYPES.RESERVE_STEP_4, {
        vin: this.vehicle?.vin,
        eventType: "register clicked",
      });
      document.querySelectorAll("[data-idgo-registration]")[0].click();
    },
    onTradein() {
      this.$router.push({
        name: PAGES.PERSONAL_RETAKE,
      });
    },
    onFinancing() {
      this.$router.push({
        name: PAGES.RESERVATIONS,
      });
    },
    onEditTradein() {
      const id = this.getReturns[0].id;
      this.$router.push({ name: "returnsDetails", params: { id: id } });
    },
    onDelivery(e) {
      this.$store.commit("ReserveModule/setDelivery", e);
    },
    resetPanel() {
      this.toggleReserve();
      this.setStatus(Status.NONE);
      this.setOnEditing(false);
      this.closePanelFootprint();
      this.$store.commit("ReserveModule/resetReserve");
    },
    closePanelFootprint() {
      const reserveDetails = {
        ...this.getVehicle?.carDetailCard,
        ...this.getVehicle?.carDetailFinance,
      };

      const footprintEvent = {
        from: this.footprintCloseLabel,
        reserveDetails: reserveDetails,
      };

      registerEvent(FOOTPRINT_TYPES.CLOSE_RESERVE_PANEL, footprintEvent);
    },
    handleInput(e) {
      const action = formDemultiplexer(e);
      this.$store.commit(action, e[1]);
    },
    onIncludeTradein(e) {
      this.$store.commit("ReserveModule/setTradein", e);
      this.$store.commit("setIncludeRetake", e);
    },
    onIncludeFinancing(e) {
      this.$store.commit("setWithFinancing", e);
      this.$store.commit("ReserveModule/setFinancing", e);
    },
    handleReserve() {
      this.setReserveStep(1);
      const footprintEvent = {
        vin: this.vehicle?.vin,
      };
      registerEvent(FOOTPRINT_TYPES.START_RESERVE, footprintEvent);

      this.setStatus(Status.RESERVE);
    },
    handleTestDrive() {
      this.setStatus(Status.NONE);
      this.setContactPanel({
        display: true,
        vehicle: this.vehicle,
        personal: this.personal,
        type: "TEST-DRIVE",
        optionalButton: "Anterior",
        optionalButtonType: "tertiary",
        optionalButtonDisabled: false,
      });
    },
    handleContact() {
      this.setStatus(Status.NONE);
      this.setContactPanel({
        display: true,
        vehicle: this.vehicle,
        personal: this.personal,
        type: "CONTACT-VEHICLE",
        optionalButton: "Anterior",
        optionalButtonType: "tertiary",
        optionalButtonDisabled: false,
      });
    },
    reserveStepFootprint(step, paymentResult) {
      const deliveryPayload = {
        0: { label: "HomeDeliveryCost", value: this.general.homeDeliveryCost },
        1: { label: "DealerDelivery", value: this.dealerName },
        2: { label: "CustomDeliveryCost", value: this.general.deliveryValue },
      };
      switch (step) {
        case 1:
          registerEvent(FOOTPRINT_TYPES.RESERVE_STEP_1, {
            vin: this.vehicle?.vin,
            includeRetake: this.getIncludeRetake,
            vehicleCard: { ...this.vehicle },
            retakeInfo: this.getIncludeRetake ? { ...this.getReturns[0] } : {},
          });
          break;
        case 2:
          registerEvent(FOOTPRINT_TYPES.RESERVE_STEP_2, {
            vin: this.vehicle?.vin,
            includeFinancing: this.includeFinancing,
            vehicleCard: { ...this.vehicle },
            financeInfo: this.includeFinancing ? { ...this.financing } : {},
          });
          break;
        case 3:
          registerEvent(FOOTPRINT_TYPES.RESERVE_STEP_3, {
            vin: this.vehicle?.vin,
            deliveryInfo: { ...deliveryPayload[this.deliveryType] },
          });
          break;
        case 4:
          registerEvent(FOOTPRINT_TYPES.RESERVE_STEP_4, {
            vin: this.vehicle?.vin,
            userInfo: { ...this.personal },
          });
          break;
        case 5:
          registerEvent(FOOTPRINT_TYPES.RESERVE_STEP_5, {
            vin: this.vehicle?.vin,
            userInfo: { ...this.personal },
            receiptInfo: { ...this.receipt },
            extraReceiptInfo: this.includeExtraReceipt
              ? { ...this.extraReceipt }
              : {},
          });
          break;
        case 6:
          registerEvent(FOOTPRINT_TYPES.RESERVE_STEP_6, {
            vin: this.vehicle?.vin,
            includeFinancing: this.includeFinancing,
            vehicleCard: {
              ...this.vehicle,
              contractExpenses: this.general.contractExpensesValue,
            },
            retakeInfo: this.getIncludeRetake ? { ...this.getReturns[0] } : {},
            financeInfo: this.includeFinancing ? { ...this.financing } : {},
          });
          break;
        case 7:
          registerEvent(FOOTPRINT_TYPES.RESERVE_STEP_7, {
            vin: this.vehicle?.vin,
            paymentResult: paymentResult,
          });
          break;
        default:
      }
    },
    handleInc() {
      if (this.getReserveStep === 5) {
        if (this.validateForm) {
          this.$refs.reserve.validateReceiptForm();
          return;
        }
      }

      if (this.getReserveStep === 6) {
        this.submitLead();
      }

      if (this.getReserveStep === 7) {
        if (!this.cardOwner || !this.validPaymentDetails) {
          this.$refs.reserve.validatePaymentForm();
          return;
        }

        this.submitPayment();
        return;
      }

      if (this.getReserveStep > 7) {
        pushNewEvent(
          GOOGLE_TAG_MANAGER_EVENT_TYPES.PRODUCT_REMOVE_FROM_CART,
          PAGES.DETAILS,
          this.getSelectedVehicle
        );
      }
      this.reserveStepFootprint(this.getReserveStep);

      this.setReserveStep(this.getReserveStep + 1);
      if (this.getReserveStep === 4 && !this.isGuest) {
        this.setReserveStep(this.getReserveStep + 1);
      }
    },
    handleDec() {
      this.setReserveStep(this.getReserveStep - 1);
      if (this.getReserveStep === 4 && !this.isGuest) {
        this.setReserveStep(this.getReserveStep - 1);
      }
    },
    handleCloseBack() {
      this.setStatus(Status.NONE);
    },
    submitPayment() {
      this.$refs.reserve.triggerPayment(this.clientId);
    },
    submitLead() {
      this.$store.dispatch("ReserveModule/submitLead");
    },
    onCardOwner(e) {
      this.$store.commit("ReserveModule/setCardOwner", e[0]);
    },
    handleDeliveryDealerChange(dealer) {
      this.dealerName = dealer.value;
      this.$store.commit("ReserveModule/setDeliveryDealer", dealer.key);
    },
    handleToggleExtraReceipt(e) {
      this.$store.commit("ReserveModule/setincludeExtraReceipt", e);
    },
    handleDealerChange(dealer) {
      this.$store.commit("ReserveModule/setDealer", dealer.key);
    },
    onSuccessfulPayment() {
      const paymentResult = "Pagamento efetuado com sucesso.";
      this.reserveStepFootprint(this.getReserveStep, paymentResult);
      this.setReserveStep(this.getReserveStep + 1);
      this.$toast.open({
        message: paymentResult,
        dismissible: true,
        type: "info",
      });
      const payload = {
        value: "500",
        id: this.clientId,
      };
      purchaseEvent(this.getVehicle?.carDetailCard, payload);
    },
    onFailedPayment() {
      const paymentResult = "Pagamento falhou.";
      this.reserveStepFootprint(this.getReserveStep, paymentResult);
      this.$toast.open({
        message: paymentResult,
        dismissible: true,
        type: "error",
      });
    },
    handlePaymentDetailsUpdate() {
      setTimeout(() => {
        this.validPaymentDetails =
          document.getElementById("card-number") !== null &&
          document.getElementById("card-expiry") !== null &&
          document.getElementById("card-cvc") !== null &&
          document
            .getElementById("card-number")
            .classList.contains("StripeElement--complete") &&
          document
            .getElementById("card-expiry")
            .classList.contains("StripeElement--complete") &&
          document
            .getElementById("card-cvc")
            .classList.contains("StripeElement--complete");
      }, 100);
    },
    getExpirationDays(date) {
      date = new Date(date);
      const daysToExpire = 30;
      date.setDate(date.getDate() + daysToExpire);

      const expirationDate = new Date(date);
      const currentDate = new Date();
      const oneDay = 24 * 60 * 60 * 1000;
      const diffDays = Math.round(
        Math.abs((expirationDate - currentDate) / oneDay)
      );

      return diffDays;
    },
    faqsFootprint(e) {
      const footprintEvent = {
        from: this.reservePanelLabel,
        title: e?.title,
      };

      registerEvent(FOOTPRINT_TYPES.FAQS, footprintEvent);
    },
  },
  watch: {
    getReserveStep(n, o) {
      this.sendEvent(n, o);
    },
    status(n) {
      if (n === 1) this.sendEvent(1);
    },
  },
  computed: {
    ...mapGetters(["getReturns"]),
    ...mapGetters({
      getReserveStep: "PanelModule/getReserveStep",
      getDetailPageComesFromReserve:
        "PanelModule/getDetailPageComesFromReserve",
      getAlert: "getAlert",
      getContactPanel: "getContactPanel",
      isGuest: "isGuest",
      clientId: "ReserveModule/getClientId",
      cardOwner: "ReserveModule/getCardOwner",
      vehicle: "VehicleModule/getCard",
      financing: "VehicleModule/getFinancing",
      includeFinancing: "ReserveModule/withFinancing",
      includeTradein: "ReserveModule/withTradein",
      isSetFinancing: "ReserveModule/isSetFinancing",
      deliveryType: "ReserveModule/withDelivery",
      isOpenPanel: "PanelModule/isOpenReserve",
      personal: "UserModule/getPersonal",
      validUser: "UserModule/getValidUser",
      validForm: "ReserveModule/validForm",
      dealer: "ReserveModule/getDealer",
      deliveryDealer: "ReserveModule/getDeliveryDealer",
      receipt: "ReserveModule/getReceipt",
      extraReceipt: "ReserveModule/getExtraReceipt",
      includeExtraReceipt: "ReserveModule/withExtraReceipt",
      status: "PanelModule/getDetailPageStatus",
      dealers: "StoreModule/getDealersDropdowns",
      withFinance: "VehicleModule/withFinance",
      getVehicle: "VehicleModule/getVehicle",
      getIncludeRetake: "getIncludeRetake",
      financialInfo: "SearchModule/dropdown/getFinanceDropdown",
    }),
    totalPayment() {
      let value = "";
      value =
        this.deliveryType === 2
          ? getPriceFormattedNoDecimal(
              this.vehicle.price.now +
                this.general.contractExpensesValue +
                this.general.deliveryValue
            )
          : this.deliveryType === 0
          ? getPriceFormattedNoDecimal(
              this.vehicle.price.now +
                this.general.contractExpensesValue +
                this.general.homeDeliveryCost
            )
          : getPriceFormattedNoDecimal(
              this.vehicle.price.now + this.general.contractExpensesValue
            );
      return value;
    },
    getIsOpenReserve() {
      this.isOpenPanel
        ? document.body.classList.add("no-scroll")
        : document.body.classList.remove("no-scroll");
      return this.isOpenPanel;
    },
    totalPaymentTitle() {
      return this.$t("panels.reserve.totalValueTitle");
    },
    validateForm() {
      return !this.validUser || !this.validForm;
    },
    validStep() {
      // Each Step has different logic
      if (this.getReserveStep === 1) {
        return !(
          !this.includeTradein ||
          (this.includeTradein && this.getReturns && this.getReturns[0])
        );
      }
      if (this.getReserveStep === 2) {
        return !(
          !this.includeFinancing ||
          (this.includeFinancing && this.financing)
        );
      }
      if (this.getReserveStep === 3) {
        if (this.deliveryType === 1) {
          return !(
            typeof this.deliveryType === "number" && this.deliveryDealer
          );
        } else {
          return !(typeof this.deliveryType === "number");
        }
      }
      if (this.getReserveStep === 4) {
        return this.isGuest;
      }
      if (this.getReserveStep === 5) {
        return this.isGuest;
      }

      if (this.getReserveStep === 7) {
        return this.isGuest;
      }

      return false;
    },
    general() {
      return {
        pvp: this.vehicle?.pvp,
        baseValue: this.financialInfo.reserveConfirmationValue,
        base: getPriceFormattedNoDecimal(
          this.financialInfo.reserveConfirmationValue
        ),
        contractExpensesValue: this.financialInfo.contractExpenses,
        contractExpenses: getPriceFormattedNoDecimal(
          this.financialInfo.contractExpenses
        ),
        homeDeliveryCost: this.financialInfo.homeDeliveryCost,
        homeDelivery: getPriceFormattedNoDecimal(
          this.financialInfo.homeDeliveryCost
        ),
        deliveryValue: this.financialInfo.customDeliveryCost,
        delivery: getPriceFormattedNoDecimal(
          this.financialInfo.customDeliveryCost
        ),
      };
    },
    panelTitle() {
      if (this.status === Status.NONE) {
        return this.withFinance
          ? this.$t("panels.reserve.title.reserve")
          : this.$t("panels.reserve.title.buy");
      }
      switch (this.getReserveStep) {
        case 1:
          return this.$t("panels.reserve.title.tradeinStep");
        case 2:
          return this.$t("panels.reserve.title.financingStep");
        case 3:
          return this.$t("panels.reserve.title.deliveryStep");
        case 4:
          return this.$t("panels.reserve.title.loginStep");
        case 5:
          return this.$t("panels.reserve.title.receiptStep");
        case 6:
          return this.$t("panels.reserve.title.detailsStep");
        case 7:
          return this.$t("panels.reserve.title.paymentStep");
        default:
          return "";
      }
    },
    customLabels() {
      return {
        deliveryLabel: this.$t("panels.reserve.customDeliveryLabel", {
          price: this.general.deliveryValue,
        }),
        homeDeliveryLabel: this.$t("panels.reserve.delivery.homeLabel", {
          homeDeliveryCost: this.general.homeDeliveryCost,
        }),
        expiringLabel: this.$t("panels.reserve.tradein.block.expiringLabel", {
          days: this.getReturns[0]?.updateTime
            ? this.getExpirationDays(this.getReturns[0]?.updateTime)
            : this.getExpirationDays(this.getReturns[0]?.createTime),
        }),
      };
    },
  },
  mounted() {
    this.$store.commit("ReserveModule/setDealer", "");
    this.setOnEditing(false);

    const loginAction = "?action=login";
    setTimeout(async () => {
      if (sessionStorage.getItem("previousUrl").includes(loginAction)) {
        if (this.getReserveStep === 4) {
          this.setReserveStep(5);
        }
      }
    }, 2500);
  },
};
</script>

<style scoped lang="scss">
::v-deep .panel--footer {
  .button {
    width: 50%;
    justify-content: center;
  }

  .panel--footer-inner {
    flex-direction: row-reverse;

    & > *:first-child {
      margin-left: 16px;
    }
  }
}
::v-deep .lead--intro {
  padding-bottom: 64px;
  --block-overflow: none;
}

::v-deep .lead--reserve {
  padding-bottom: var(--panel-footer-height);
  --block-overflow: none;
}

::v-deep .panel--content .panel--content-inner .accordion-block h1 {
  --title-color: #333333;
}

::v-deep
  .panel--content
  .panel--content-inner
  .button-block
  .button-row
  .button {
  padding-right: 15px;
  padding-left: 15px;
}

::v-deep
  .panel--content
  .panel--content-inner
  .accordion-block
  .accordion--item {
  .accordion--item-title p {
    font-size: 16px;
    line-height: 21px;
    color: #333333;
  }

  .accordion--item-content p {
    font-size: 14px;
    line-height: 18px;
    color: #808080;
  }
}

::v-deep .panel--content .lead-intro .accordion-block {
  position: relative;
  margin-right: 0px;
}

::v-deep .vertical-card {
  --block-overflow: hidden;
}

::v-deep .tootltip-container-lead-reserve-details {
  .tooltip {
    position: relative;
  }
}

@media (max-width: 1280px) {
}

//Mobile
@media (max-width: 768px) {
  ::v-deep .panel--content {
    .financing {
      min-width: unset;
    }
    .title-row h1 {
      word-break: break-word;
      width: 70%;
    }
    .row h1:first-child {
      word-break: break-word;
      width: 75%;
    }
    .missing-tradein {
      width: 100%;
    }
    .tradein {
      min-width: unset;
    }
    .lead-reserve-details .block:last-child {
      .caption-icon:not(:last-child),
      .caption-tooltip {
        width: 70%;
      }
    }
  }

  @media (max-width: 530px) {
    ::v-deep .lead-intro {
      .button-row {
        --lead-intro-button-row-flex-direction: column;
      }
    }
  }
}
</style>
