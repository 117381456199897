<template>
  <div
    :class="['tag--container', classes, customClass]"
    :style="customStyles"
    :data-tag="tagId"
  >
    <div class="tag--label-container">
      <span>{{ label }}</span>
    </div>
    <div class="tag--icon-container" @click="onClick">
      <font-awesome-icon class="tag--icon" icon="times"></font-awesome-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tag",
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      },
    },
    customClass: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "primary",
      validator: (type) => {
        return ["primary", "secondary"].indexOf(type) !== -1;
      },
    },
    label: {
      type: String,
      default: "",
    },
    uppercase: {
      type: Boolean,
      default: false,
    },
    fill: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    tagId: {
      type: String,
      default: Math.random().toString(36).substring(6),
    },
  },
  computed: {
    classes() {
      return {
        "tag--no--bg": this.type === "primary",
        "tag--bg--primary": this.fill && this.type === "primary",
        "tag--bg--secondary": this.fill && this.type === "secondary",
        "tag--uppercase": this.uppercase,
        "tag--round": this.rounded,
      };
    },
    customStyles() {
      return {
        ...this.styles,
      };
    },
  },
  methods: {
    onClick() {
      this.$emit("onClick", this.tagId);
    },
  },
};
</script>
<style lang="css" scoped src="./Tag.css"></style>
