<template>
  <div class="car-row--container">
    <span class="title">{{ text }}</span>
    <div class="vehicle-cards-inline">
      <template v-for="(v, idx) in vehicles">
        <CpVehicleCard :key="idx" class="vehicle-card-margin" :v="v" />
      </template>
    </div>
  </div>
</template>

<script>
import CpVehicleCard from "./CpVehicleCard.vue";

export default {
  name: "CarRow",
  components: {
    CpVehicleCard,
  },
  props: {
    text: {
      type: String,
      default: "Missing Title",
    },
    vehicles: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped>
.vehicle-card-margin {
  width: 436px;
  height: 453px;
}

.vehicle-cards-inline {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
}

.car-row--container {
  display: flex;
  flex-direction: column;
}
.card--block {
  width: 436px;
  border: 1px solid #eaeaea;
}

.row--container {
  display: flex;
  gap: 16px;
}

.title {
  color: #333;
  line-height: 36px;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 24px;
}

@media (max-width: 767px) {
  .vehicle-card-margin {
    height: min-content;
    margin-bottom: 16px;
  }
}
</style>
