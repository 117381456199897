<template>
  <div :class="['content-block', customClass]" :style="customStyles">
    <span v-html="content" v-if="html && content"></span>
    <div v-else-if="checkContent && !html">
      <Heading
        v-for="(element, index) in handleContent"
        :key="index"
        :label="element.innerHTML"
        :tag="checkTag(element)"
        :weight="checkWeight(element)"
      />
    </div>
    <div v-else>
      <Heading
        :label="$t('pages.searchpage.contentBlock.h1.0')"
        tag="h1"
        weight="bold"
      />
      <Heading
        :label="$t('pages.searchpage.contentBlock.h2.0')"
        tag="h2"
        weight="regular"
      />
      <Heading
        :label="$t('pages.searchpage.contentBlock.h3.0')"
        tag="h3"
        weight="regular"
      />
      <br />
      <Heading
        :label="$t('pages.searchpage.contentBlock.h2.1')"
        tag="h2"
        weight="regular"
      />
      <Heading
        :label="$t('pages.searchpage.contentBlock.h3.1')"
        tag="h3"
        weight="regular"
      />
    </div>
  </div>
</template>

<script>
import { Heading } from "@sc/ds-ui-component-library";
export default {
  name: "ContentBlock",
  components: {
    Heading,
  },
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      },
    },
    customClass: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    html: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    isEmpty(str) {
      return !str || str.length === 0;
    },
    checkTag(htmlElement) {
      return htmlElement.localName;
    },
    checkWeight(htmlElement) {
      return htmlElement.localName === "h1" ? "bold" : "regular";
    },
  },
  computed: {
    customStyles() {
      return {
        ...this.styles,
      };
    },
    headingContent() {
      return {
        h1: this.isEmpty(this.h1Content),
        h2: this.isEmpty(this.h2Content),
        h3: this.isEmpty(this.h3Content),
      };
    },
    checkContent() {
      const doc = new DOMParser().parseFromString(this.content, "text/html");
      const elementH1 = doc.querySelectorAll(["h1", "h2", "h3"]);
      return elementH1.length > 0;
    },
    handleContent() {
      const doc = new DOMParser().parseFromString(this.content, "text/html");
      const elements = doc.querySelectorAll(["h1", "h2", "h3"]);
      return elements;
    },
  },
};
</script>

<style lang="scss" scoped>
.content-block {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: Mont;
  margin-top: 2.25rem;
}

::v-deep .label {
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;
}

@media (max-width: 1920px) {
}

@media (max-width: 1440px) {
}
@media (max-width: 1366px) {
}
@media (max-width: 1280px) {
}
@media (max-width: 1024px) {
}

@media (max-width: 768px) {
}

.content-block ::v-deep ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.content-block ::v-deep ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.content-block ::v-deep h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.content-block ::v-deep h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.content-block ::v-deep h3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.content-block ::v-deep h4 {
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.content-block ::v-deep h5 {
  display: block;
  font-size: 0.83em;
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.content-block ::v-deep h6 {
  display: block;
  font-size: 0.67em;
  margin-block-start: 2.33em;
  margin-block-end: 2.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.content-block ::v-deep p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.content-block ::v-deep a:link {
  /* browser's internal value */
  text-decoration: underline;
  cursor: auto;
}
.content-block ::v-deep a:visited {
  /* browser's internal value */
  text-decoration: underline;
  cursor: auto;
}
.content-block ::v-deep a:link:active {
  /* browser's internal value */
}
.content-block ::v-deep a:visited:active {
  /* browser's internal value */
}
.content-block ::v-deep address {
  display: block;
  font-style: italic;
}
.content-block ::v-deep area {
  display: none;
}
.content-block ::v-deep article {
  display: block;
}
.content-block ::v-deep aside {
  display: block;
}
.content-block ::v-deep b {
  font-weight: bold;
}
.content-block ::v-deep bdo {
  unicode-bidi: bidi-override;
}
.content-block ::v-deep blockquote {
  display: block;
  margin: 1em 40px;
}
.content-block ::v-deep body {
  display: block;
  margin: 8px;
  body: focus;
  outline: none;
}
.content-block ::v-deep caption {
  display: table-caption;
  text-align: center;
}
.content-block ::v-deep cite {
  font-style: italic;
}
.content-block ::v-deep code {
  font-family: monospace;
}
.content-block ::v-deep col {
  display: table-column;
}
.content-block ::v-deep colgroup {
  display: table-column-group;
}
.content-block ::v-deep datalist {
  display: none;
}
.content-block ::v-deep dd {
  display: block;
  margin-left: 40px;
}
.content-block ::v-deep del {
  text-decoration: line-through;
}
.content-block ::v-deep details {
  display: block;
}
.content-block ::v-deep dfn {
  font-style: italic;
}
.content-block ::v-deep div {
  display: block;
}
.content-block ::v-deep dl {
  display: block;
  margin: 1em 0;
}
.content-block ::v-deep dt {
  display: block;
}
.content-block ::v-deep em {
  font-style: italic;
}
.content-block ::v-deep embed:focus {
  outline: none;
}
.content-block ::v-deep fieldset {
  display: block;
  margin-left: 2px;
  margin-right: 2px;
  padding: 0.35em 0.75em 0.625em;
  /* browser's internal value */
  border: 2px groove;
}
.content-block ::v-deep figcaption {
  display: block;
}
.content-block ::v-deep figure {
  display: block;
  margin: 1em 40px;
}
.content-block ::v-deep footer {
  display: block;
}
.content-block ::v-deep form {
  display: block;
  margin-top: 0em;
}
.content-block ::v-deep head {
  display: none;
}
.content-block ::v-deep header {
  display: block;
}
.content-block ::v-deep hr {
  display: block;
  margin: 0.5em auto;
  border-style: inset;
  border-width: 1px;
}
.content-block ::v-deep html {
  display: block;
}
.content-block ::v-deep html:focus {
  outline: none;
}
.content-block ::v-deep i {
  font-style: italic;
}
.content-block ::v-deep iframe:focus {
  outline: none;
}
.content-block ::v-deep iframe[seamless] {
  display: block;
}
.content-block ::v-deep img {
  display: inline-block;
}
.content-block ::v-deep ins {
  text-decoration: underline;
}
.content-block ::v-deep kbd {
  font-family: monospace;
}
.content-block ::v-deep label {
  cursor: default;
}
.content-block ::v-deep legend {
  display: block;
  padding-left: 2px;
  padding-right: 2px;
  border: none;
}
.content-block ::v-deep li {
  display: list-item;
}
.content-block ::v-deep link {
  display: none;
}
.content-block ::v-deep map {
  display: inline;
}
.content-block ::v-deep mark {
  background-color: yellow;
  color: black;
}
.content-block ::v-deep menu {
  display: block;
  list-style-type: disc;
  margin: 1em 0;
  padding-left: 40px;
}
.content-block ::v-deep nav {
  display: block;
}
.content-block ::v-deep object:focus {
  outline: none;
}
.content-block ::v-deep output {
  display: inline;
}
.content-block ::v-deep param {
  display: none;
}
.content-block ::v-deep pre {
  display: block;
  font-family: monospace;
  white-space: pre;
  margin: 1em 0;
}

.content-block ::v-deep q {
  display: inline;
}
.content-block ::v-deep q::before {
  content: open-quote;
}
.content-block ::v-deep q::after {
  content: close-quote;
}
.content-block ::v-deep rt {
  line-height: normal;
}
.content-block ::v-deep s {
  text-decoration: line-through;
}
.content-block ::v-deep samp {
  font-family: monospace;
}
.content-block ::v-deep script {
  display: none;
}
.content-block ::v-deep section {
  display: block;
}
.content-block ::v-deep small {
  font-size: smaller;
}
.content-block ::v-deep strike {
  text-decoration: line-through;
}
.content-block ::v-deep strong {
  font-weight: bold;
}
.content-block ::v-deep style {
  display: none;
}
.content-block ::v-deep sub {
  vertical-align: sub;
  font-size: smaller;
}
.content-block ::v-deep summary {
  display: block;
}
.content-block ::v-deep sup {
  vertical-align: super;
  font-size: smaller;
}
.content-block ::v-deep table {
  display: table;
  border-collapse: separate;
  border-spacing: 2px;
  border-color: gray;
}
.content-block ::v-deep tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}
.content-block ::v-deep td {
  display: table-cell;
  vertical-align: inherit;
}
.content-block ::v-deep tfoot {
  display: table-footer-group;
  vertical-align: middle;
  border-color: inherit;
}
.content-block ::v-deep th {
  display: table-cell;
  vertical-align: inherit;
  font-weight: bold;
  text-align: center;
}
.content-block ::v-deep thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}
.content-block ::v-deep title {
  display: none;
}
.content-block ::v-deep tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
.content-block ::v-deep u {
  text-decoration: underline;
}
.content-block ::v-deep var {
  font-style: italic;
}
</style>
