<template>
  <div class="filters--row">
    <MonthDropdown
      name="Mensalidade"
      ref="monthlydropdown"
      :rightRangeLabel="$t('searchform.dropdowns.monthPrice.rightRangeLabel')"
      :leftRangeLabel="$t('searchform.dropdowns.monthPrice.leftRangeLabel')"
      :monthlyMax="finance.monthlyMax"
      :monthlyMin="finance.monthlyMin"
      :monthlyStep="finance.monthlyStep"
      :monthlyValue="finance.monthlyValue"
      :depositMax="finance.depositMax"
      :depositMin="finance.depositMin"
      :depositStep="finance.depositStep"
      :depositValue="finance.depositValue"
      @onChangeValues="handleMonth"
      :activated="monthTagActivated"
      :key="'monthKey' + monthKey"
    />
    <PriceDropdown
      name="Preço"
      ref="pricingDropdown"
      :priceFrom="finance.priceFrom"
      :priceTo="finance.priceTo"
      @onChangeValues="handlePrice"
      :activated="priceTagActivated"
      :key="'priceKey' + priceKey"
    />
    <Dropdown
      multiple
      mode="check"
      :items="segmentItems"
      :name="$t('searchform.dropdowns.segment')"
      @onItemClick="(e) => onItemClick('segment', e)"
      ref="segment"
      :key="`segment-${getKey('segment')}`"
      @onSelectedItems="(e) => updateDropdown(e, 'segment')"
      :loading="!segmentItems"
      widthMaxContent
      :scroll="true"
    />
    <Dropdown
      multiple
      mode="nested"
      :items="brandItems"
      :name="$t('searchform.dropdowns.brands')"
      @onItemClick="handleBrands"
      ref="brands"
      :key="`brands-${getKey('brands')}`"
      @onSelectedItems="(e) => updateDropdown(e, 'brands')"
      :loading="!brandItems"
      widthMaxContent
      :scroll="true"
    />
    <YearDropdown
      class="years-dropdown"
      @onSelect="handleYears"
      :years="getDropdowns['year']"
      :yearInf="reuseYearInf"
      :yearSup="reuseYearSup"
      ref="yearsDropdown"
      :key="`years-${getKey('year')}${yearKey}`"
      name="Anos"
      :labels="$t('searchform.dropdowns.kmAndYearDropdown')"
    />
    <KmDropdown
      class="kms-dropdown"
      @onSelect="handleKms"
      :kms="getDropdowns['km']"
      :kmInf="reuseKmInf"
      :kmSup="reuseKmSup"
      ref="kmsDropdown"
      :key="`kms-${getKey('km')}${kmKey}`"
      name="Quilómetros (km)"
      :labels="$t('searchform.dropdowns.kmAndYearDropdown')"
    />
    <Dropdown
      multiple
      mode="check"
      :items="fuelItems"
      :name="$t('searchform.dropdowns.fuel')"
      @onItemClick="(e) => onItemClick('fuel', e)"
      ref="fuel"
      :key="`fuel-${getKey('fuel')}`"
      @onSelectedItems="(e) => updateDropdown(e, 'fuel')"
      :loading="!fuelItems"
      widthMaxContent
      :scroll="true"
    />

    <!-- <Button
      customClass="extend--button"
      :label="$t('searchform.extend')"
      withIcon
      @onClick="handleClick"
      :icon="iconShowExtended"
      type="tertiary"
    /> -->
  </div>
</template>

<script>
import {
  // Button,
  Dropdown,
  KmDropdown,
  YearDropdown,
  MonthDropdown,
  PriceDropdown,
} from "@sc/ds-ui-component-library";
import { mapGetters } from "vuex";
import { PAGES } from "../../../enums/pages";
import { getPriceFormatted } from "../../../utils/currency";
export default {
  name: "SearchFormInputs",
  components: {
    // Button,
    Dropdown,
    KmDropdown,
    YearDropdown,
    MonthDropdown,
    PriceDropdown,
  },
  data() {
    return {
      showExtended: false,
      currentSide: "left",
      retakeIcon: require("@/assets/images/car.jpg"),
      fuelKey: 0,
      fuelItems: [],
      brandKey: 0,
      brandItems: [],
      segmentKey: 0,
      segmentItems: [],
      monthKey: 0,
      priceKey: 0,
      yearKey: 0,
      reuseYearInf: undefined,
      reuseYearSup: undefined,
      kmKey: 0,
      reuseKmInf: undefined,
      reuseKmSup: undefined,
    };
  },
  watch: {
    getTags(newGetTags, oldGetTags) {
      if (
        oldGetTags.some((tag) => tag.type === "monthlyDeposit") &&
        !newGetTags.some((tag) => tag.type === "monthlyDeposit")
      )
        this.monthKey += 1;
      if (
        oldGetTags.some((tag) => tag.type === "price") &&
        !newGetTags.some((tag) => tag.type === "price")
      )
        this.priceKey += 1;
      if (
        oldGetTags.some((tag) => tag.type === "year") &&
        !newGetTags.some((tag) => tag.type === "year")
      ) {
        this.reuseYearInf = undefined;
        this.reuseYearSup = undefined;
        this.yearKey += 1;
      }
      if (
        oldGetTags.some((tag) => tag.type === "km") &&
        !newGetTags.some((tag) => tag.type === "km")
      ) {
        this.reuseKmInf = undefined;
        this.reuseKmSup = undefined;
        this.kmKey += 1;
      }
    },
  },
  methods: {
    updateDropdown(e, dropdown) {
      this.$store.commit("SearchModule/dropdown/setDropdownParent", {
        dropdown: dropdown,
        val: e,
      });
      if (dropdown === "fuel") {
        this.fuelItems = this.getDropdowns["fuel"];
        this.fuelKey += 1;
      }
      if (dropdown === "segment") {
        this.segmentItems = this.getDropdowns["segment"];
        this.segmentKey += 1;
      }
      if (dropdown === "brands") {
        this.brandItems = this.getDropdowns["brands"];
        this.brandKey += 1;
      }
    },
    onItemClick(dropdown, e) {
      this.$emit("onItemClick", { dropdown, value: e });
    },
    handleClick(e) {
      this.showExtended = !this.showExtended;
      this.$emit("toggleOpen", e);
    },
    handleBrands(e) {
      e.type = "brands";
      var isModel = false;
      e.children.forEach((model) => {
        var newTag = {
          type: "model",
          id: model.key,
          value: model.value,
          children: model.children || [],
          count: model.count || 0,
          parent: e || "",
        };
        var notExist =
          this.getTags.findIndex(
            (tag) => tag.id === model.key && tag.type === "model"
          ) === -1;
        if (model.selected) {
          isModel = true;
          if (notExist) {
            this.$store.commit("SearchModule/filter/addTag", newTag);
          }
        } else {
          if (!notExist) {
            this.$store.commit("SearchModule/filter/removeTag", newTag);
            isModel = !notExist;
          }
        }
      });
      if (!isModel) this.$emit("onItemClick", { dropdown: "brands", value: e });
      else {
        this.$store.dispatch("SearchModule/fetchRecent", {
          quickSearchItem: [],
          dynamic: false,
        });
      }
    },
    handleKms(e) {
      this.onItemClick("km", {
        key: { kmInf: `${e[1].kmInf}`, kmSup: `${e[1].kmSup}` },
        value: this.$t("searchform.tags.km", {
          value1: e[1].kmInf,
          value2: e[1].kmSup,
        }),
        selected: e[1].selected,
      });
    },
    handleYears(e) {
      this.onItemClick("year", {
        key: { yearInf: `${e[1].yearInf}`, yearSup: `${e[1].yearSup}` },
        value: this.$t("searchform.tags.year", {
          value1: e[1].yearInf,
          value2: e[1].yearSup,
        }),
        selected: e[1].selected,
      });
    },
    handleMonth(e) {
      this.$store.commit("setMonthlyValue", e.monthly);
      this.$store.commit("setDepositValue", e.deposit);
      this.onItemClick("monthlyDeposit", {
        key: {
          monthlyPayment: `${e.monthly}`,
          depositValue: `${e.deposit}`,
        },
        value: this.$t("searchform.tags.monthlyDeposit", {
          value1: getPriceFormatted(e.monthly),
          value2: getPriceFormatted(e.deposit),
        }),
      });
    },
    handlePrice(e) {
      this.onItemClick("price", {
        key: { priceInf: `${e.priceFrom}`, priceSup: `${e.priceTo}` },
        value: this.$t("searchform.tags.price", {
          value1: getPriceFormatted(e.priceFrom),
          value2: getPriceFormatted(e.priceTo),
        }),
      });
    },
    handleToggleRetake(event) {
      this.$store.commit("setIncludeRetake", event);
      this.$store.commit("setTradein", event);
    },
    goToRetake() {
      if (this.$route.name !== PAGES.RETAKE)
        this.$router.push({
          name: PAGES.RETAKE,
        });
    },
    getExpirationDate(date) {
      date = new Date(date);
      const daysToExpire = 30;
      date.setDate(date.getDate() + daysToExpire);

      const expirationDate = new Date(date);
      const currentDate = new Date();
      const oneDay = 24 * 60 * 60 * 1000;
      const diffDays = Math.round(
        Math.abs((expirationDate - currentDate) / oneDay)
      );

      return diffDays;
    },
  },
  computed: {
    calcIndex() {
      return (idx, dropdown) => `${idx}-${this.getDropdowns[dropdown]?.length}`;
    },
    monthTagActivated() {
      return !!this.getTags?.filter((tag) => tag.type === "monthlyDeposit")
        .length;
    },
    priceTagActivated() {
      return !!this.getTags?.filter((tag) => tag.type === "price").length;
    },
    ...mapGetters([
      "getReturns",
      "getMonthlyStep",
      "getIncludeRetake",
      "getReturnValue",
      "getLastDepositValue",
    ]),
    ...mapGetters({
      getDropdowns: "SearchModule/dropdown/getDropdowns",
      getKey: "SearchModule/dropdown/getKey",
      getTags: "SearchModule/filter/getTags",
      getFinanceDropdown: "SearchModule/dropdown/getFinanceDropdown",
    }),
    iconShowExtended() {
      return this.showExtended ? "chevron-up" : "chevron-down";
    },
    suggestionBox() {
      const hasRetakes = this.getReturns.length !== 0;
      const retake = hasRetakes ? this.getReturns[0] : undefined;
      var expirationDays;
      if (retake)
        expirationDays = retake.updateTime
          ? this.getExpirationDate(retake.updateTime)
          : this.getExpirationDate(retake.createTime);
      return [
        !hasRetakes
          ? {
              title: "Tem viatura para retoma?",
              description:
                "Avalie a sua viatura de retoma em apenas 2 minutos e receba um valor real, sem esperas.",
              button: "Avaliar Valor da Retoma",
            }
          : {
              retake: {
                title: "Oferta de retoma",
                description: this.getReturns[0]?.model,
                plate: `Matrícula: ${this.getReturns[0]?.licensePlate}`,
                extra:
                  expirationDays > 0
                    ? this.$t(
                        "pages.detailsPage.financing.rateCard.expireMessage",
                        {
                          days: expirationDays,
                        }
                      )
                    : this.$t(
                        "pages.detailsPage.financing.rateCard.expiredMessage"
                      ),
                value: this.getReturns[0]?.actualValuation,
              },
            },
      ];
    },
    finance() {
      let monthly = "";
      let deposit = "";
      let fromPrice = "";
      let toPrice = "";

      const that = this;
      if (this.getTags.length) {
        this.getTags.forEach((tag) => {
          if (tag.type === "monthlyDeposit") {
            monthly = tag.id?.monthlyPayment;
            deposit = tag.id?.depositValue;

            that.$refs?.monthlydropdown?.activateForceSelected();
          }
          if (tag.type === "price") {
            fromPrice = tag.id?.priceInf;
            toPrice = tag.id?.priceSup;

            that.$refs?.pricingDropdown?.activateForceSelected();
          }
        });
      }

      return {
        monthlyMax:
          Math.round(
            Number(this.getFinanceDropdown?.monthlyMax) /
              this.getFinanceDropdown?.monthlyStep
          ) * this.getFinanceDropdown?.monthlyStep || 2,
        monthlyMin:
          Math.floor(
            Number(this.getFinanceDropdown?.monthlyMin) /
              this.getFinanceDropdown?.monthlyStep
          ) * this.getFinanceDropdown?.monthlyStep || 0,
        monthlyStep: Number(this.getFinanceDropdown?.monthlyStep) || 1,
        monthlyValue:
          monthly ||
          Math.floor(
            Number(this.getFinanceDropdown?.monthlyMin) /
              this.getFinanceDropdown?.monthlyStep
          ) * this.getFinanceDropdown?.monthlyStep ||
          0,
        depositMax: Math.ceil(Number(this.getFinanceDropdown?.depositMax)) || 2,
        depositMin:
          Math.floor(Number(this.getFinanceDropdown?.depositMin)) || 0,
        depositStep: Number(this.getFinanceDropdown?.depositStep) || 1,
        depositValue:
          deposit ||
          Math.floor(Number(this.getFinanceDropdown?.depositMin)) ||
          1,
        priceFrom: fromPrice || Number(this.getFinanceDropdown?.priceMin) || 0,
        priceTo: toPrice || Number(this.getFinanceDropdown?.priceMax) || 1,
      };
    },
  },
  mounted() {
    this.$store.commit("setMonthlyValue", null);
    this.$store.commit("setDepositValue", null);
    //this.$store.dispatch("SearchModule/fetchAll");
    if (this.getTags.length) {
      this.getTags.forEach((tag) => {
        if (tag.type === "fuel") {
          let items = this.getDropdowns[tag.type];
          items = items.map((item) => {
            if (item.key === tag.id) {
              return {
                key: tag.id,
                value: tag.value,
                selected: true,
              };
            }
            return item;
          });
          this.updateDropdown(items, tag.type);
        } else if (tag.type === "segment") {
          let items = this.getDropdowns[tag.type];
          items = items.map((item) => {
            if (item.key === tag.id) {
              return {
                key: tag.id,
                value: tag.value,
                selected: true,
              };
            }
            return item;
          });
          this.updateDropdown(items, tag.type);
        } else if (tag.type === "brands") {
          let items = this.getDropdowns[tag.type];
          items = items.map((item) => {
            if (item.id === tag.id) {
              return {
                id: tag.id,
                value: tag.value,
                models: tag.children,
                count: tag.count,
                type: tag.type,
                selected: true,
              };
            }
            return item;
          });
          this.updateDropdown(items, tag.type);
        } else if (tag.type === "year") {
          this.reuseYearSup = tag.id?.yearSup;
          this.reuseYearInf = tag.id?.yearInf;
        } else if (tag.type === "km") {
          this.reuseKmSup = tag.id?.kmSup;
          this.reuseKmInf = tag.id?.kmInf;
        }
      });
    }

    this.fuelItems = this.getDropdowns["fuel"];
    this.fuelKey += 1;

    this.segmentItems = this.getDropdowns["segment"];
    this.segmentKey += 1;

    this.brandItems = this.getDropdowns["brands"];
    this.brandKey += 1;

    this.monthKey += 1;
    if (this.getTags.some((tag) => tag.type === "monthlyDeposit"))
      this.$refs.monthlydropdown.activateForceSelected();

    this.priceKey += 1;
    if (this.getTags.some((tag) => tag.type === "price"))
      this.$refs.pricingDropdown.activateForceSelected();

    if (this.getTags.some((tag) => tag.type === "year")) {
      this.$refs.yearsDropdown.activateForceSelected();
    }

    if (this.getTags.some((tag) => tag.type === "km")) {
      this.$refs.kmsDropdown.activateForceSelected();
    }
  },
};
</script>

<style lang="scss" scoped>
.filters--row {
  display: inline-grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
  z-index: 2;
}

.filters-checkboxes--row {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  gap: 16px;
}

.km-year ::v-deep .dropdown-menu {
  min-width: 100% !important;
}

::v-deep .complete {
  height: 56px;
  border: none;
}

::v-deep .megabig--price {
  align-items: center;
  padding: 20px;
  height: 56px;
  border: none;
  color: #333;
}

::v-deep .megabig--container {
  background-color: white;
}

::v-deep .dropdown--title-label {
  color: #333;
  line-height: 20px;
}

::v-deep .description {
  line-height: 16px;
  font-size: 12px;
  color: #848484;
}

::v-deep .subtitle {
  line-height: 14px;
  font-size: 14px;
  color: #333;
}

::v-deep .button {
  width: 203px;
  display: flex;
  justify-content: center !important; /* Missing variable */
  flex-shrink: 0;
}

::v-deep .button--primary {
  height: 40px;
}

::v-deep .button--primary div {
  font-size: 14px;
  line-height: 18px;
}

::v-deep .dropdown--wrapper {
  width: 100%;
  --dropdown-header-box-background-color: var(--color-7);
}

::v-deep .megabig--price {
  align-items: center;
  padding: 20px;
  height: 56px;
  border: none;
  color: #333;
}

::v-deep .megabig--container {
  background-color: white;
}

::v-deep .description {
  line-height: 16px;
  font-size: 12px;
  color: #848484;
}

::v-deep .subtitle {
  line-height: 14px;
  font-size: 14px;
  color: #333;
}

::v-deep .button--primary {
  height: 40px !important;
}

::v-deep .button--primary div {
  font-size: 14px;
  line-height: 18px;
}

.month-price {
  --toggle-small-before-width: 1rem;
}

@media (max-width: 1920px) {
  .years-dropdown ::v-deep .years,
  .kms-dropdown ::v-deep .kms {
    width: 500px;
  }
  .filters--row {
    display: inline-grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 16px;
    z-index: 2;
  }
  @media (max-width: 1790px) {
    .years-dropdown ::v-deep .years,
    .kms-dropdown ::v-deep .kms {
      width: 450px;
    }
  }
  @media (max-width: 1550px) {
    .years-dropdown ::v-deep .years,
    .kms-dropdown ::v-deep .kms {
      width: 400px;
    }
  }
}

@media (max-width: 1440px) {
  .years-dropdown ::v-deep .years,
  .kms-dropdown ::v-deep .kms {
    width: 380px;
  }
}
@media (max-width: 1366px) {
  .years-dropdown ::v-deep .years,
  .kms-dropdown ::v-deep .kms {
    width: 350px;
  }
  .kms-dropdown
    ::v-deep
    .kms
    .dropdown-header
    .dropdown-header-inner
    p.text-label {
    --dropdown-header-inner-label-white-space: wrap;
  }
}
@media (max-width: 1280px) {
  @media (max-width: 1279px) {
    .filters--row {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
  }
  @media (max-width: 1068px) {
    .years-dropdown ::v-deep .years,
    .kms-dropdown ::v-deep .kms {
      width: 100%;
    }
  }
}
@media (max-width: 1024px) {
}

@media (max-width: 768px) {
}
</style>
