<template>
  <div :class="['caption-icon', ...classes]">
    <Icon :color="iconColor" :size="iconSize" :icon="icon" />
    <TextLabel :weight="labelWeight" v-if="!href" :label="label" />
    <BaseLink :disabled="disableLink" v-if="href" :href="href" :label="label" />
    <slot />
  </div>
</template>

<script>
import Icon from "../../atoms/Icon/Icon.vue";
import TextLabel from "../../atoms/TextLabel/TextLabel.vue";
import BaseLink from "../../atoms/BaseLink/BaseLink.vue";

export default {
  name: "CaptionIcon",
  components: { Icon, TextLabel, BaseLink },
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      },
    },
    customClass: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    href: {
      type: String,
      default: "",
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    iconColor: {
      type: String,
    },
    iconSize: {
      type: Number,
    },
    labelWeight: {
      type: String,
    },
    disableLink: {
      type: Boolean,
    },
  },
  computed: {
    classes() {
      return [
        this.reverse ? "reverse" : "",
        this.vertical ? "vertical" : "horizontal",
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.caption-icon {
  display: var(--caption-icon-display);
  width: var(--caption-icon-width);
  align-items: var(--caption-icon-align-items);
  gap: var(--caption-icon-gap);
  flex-direction: var(--caption-icon-direction);

  &.vertical {
    --caption-icon-direction: column;
  }

  &.horizontal {
    &.reverse {
      --caption-icon-direction: row-reverse;
    }
  }

  &.vertical {
    &.reverse {
      --caption-icon-direction: column-reverse;
    }
  }
}
</style>
