// eslint-disable-next-line no-unused-vars
import {
  getAllReturns,
  updateReturn,
  getReturnDetails,
  deleteReturn,
  addPhoto,
  getExteriorColors,
  getInteriorColors,
} from "../../api/returns.js";
import { getPriceFormatted } from "../../utils/currency";
import { defaults } from "./utils/phase2Defaults.js";
import i18n from "../../i18n.js";

function changeDataBasedOnBackend(arr, base) {
  const parsedArr = createDuplicatedDataForCheckboxes(arr);
  return base?.map((it, idx) => ({
    ...it,
    ...parsedArr[idx],
  }));
}

function createDuplicatedDataForCheckboxes(arr) {
  return arr?.map(({ radioGroupName, radioGroupValue }) => ({
    radioGroupName,
    radioGroupValue,
    radioGroupList: [
      {
        label: "Bom",
        radioValue: "GOOD",
        radioId: "interiors-good",
        checked: "GOOD" === radioGroupValue,
      },
      {
        label: "Razoável",
        radioValue: "AVERAG",
        radioId: "interiors-reasonable",
        checked: "AVERAG" === radioGroupValue,
      },
      {
        label: "Mau",
        radioValue: "POOR",
        radioId: "interiors-bad",
        checked: "POOR" === radioGroupValue,
      },
    ],
  }));
}

const state = {
  returns: [],
  returnObj: {},
  // errors
  errorsReturns: [],
  // for loading spinner
  loadingReturn: false,
  features: {},
  additionInfo: {},
  ...defaults,
};

const getters = {
  getFeatures: (state) => state.features,
  getGeneralStatus: (state) => state.generalStatus,
  getMechanicalState: (state) => state.mechanicalState,
  getExternalColorList: (state) => {
    return state.exteriorColors;
  },
  getAirConditioningList: (state) => state.airConditioningList,
  getProvenance: (state) => state.provenance,
  getVehicleOperation: (state) => state.vehicleOperation,
  getAccessories: (state) => state.accessories,
  getEquipments: (state) => state.equipments,
  getInteriorList: (state) => {
    return state.interiorList;
  },
  getReturnKilometers: (state) => state.returnObj?.kilometers,
  getReturns: (state) => {
    return state.returns;
  },
  getReturn: (state) => {
    return state.returnObj;
  },
  getReturnId: (state) => state.returns[0]?.id,
  getLoadingReturns: (state) => state.loadingReturn,
  getReturnValue: (state) =>
    state.returns[0]?.status === 1
      ? state.returns[0]?.actualValuation
      : state.returns[0]?.initialValuation,
  getFormattedReturnValue: (state) =>
    state.returns[0]?.status === 1
      ? state.returns[0]?.actualValuation
      : state.returns[0]?.initialValuation
      ? getPriceFormatted(
          Number(
            state.returns[0]?.status === 1
              ? state.returns[0]?.actualValuation
              : state.returns[0]?.initialValuation
          ).toFixed(2),
          {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
        )
      : getPriceFormatted(0, {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
  getReturnLicensePlate: (state) => {
    return state.returns[0]?.licensePlate;
  },

  getReturnImages: (state) => state.returnObj?.phaseTwoDTOV3?.files,
  getAdditionInfo: (state) => state.additionInfo,
  getCarculatorId: (state) => state.returnObj.carculatorId,
};

const actions = {
  async fetchExteriorColors({ commit }, params) {
    try {
      let res = await getExteriorColors(params);
      res.data.data = res.data.data.map((color) => {
        return {
          text: color.label,
          value: color.value,
        };
      });
      res.data.data.unshift({
        text: i18n.t("pages.personalPage.returns.dropdowns.exteriorColor"),
        value: "-1",
        disabled: true,
        selected: true,
      });

      commit("setExteriorColors", res.data.data);
    } catch (err) {
      console.error(err);
    }
  },
  async fetchInteriorColors({ commit }, params) {
    try {
      let res = await getInteriorColors(params);
      res.data.data = res.data.data.map((color) => {
        return {
          text: color.label,
          value: color.value,
        };
      });
      res.data.data.unshift({
        text: i18n.t("pages.personalPage.returns.dropdowns.interiorColor"),
        value: "-1",
        disabled: true,
        selected: true,
      });
      commit("setInteriorColors", res.data.data);
    } catch (err) {
      console.error(err);
    }
  },

  async storeGetReturnDetails({ commit, dispatch }, id) {
    commit("setLoadingReturns", true);

    try {
      const response = await getReturnDetails(id);
      commit("setReturn", response.data.data);
      dispatch("processFeatures", response.data.data?.tradeInPayload?.features);
      commit(
        "processMechanicalStateList",
        response.data.data?.tradeInPayload?.mechanicalAndGeneralStatus
          ?.mechanicalStateList
      );
      commit(
        "processGeneralStatus",
        response.data.data?.tradeInPayload?.mechanicalAndGeneralStatus
          ?.generalStatusList
      );
    } catch (e) {
      commit("setErrorsReturns", e);
      commit("setLoadingReturns", false);
    }
    commit("setLoadingReturns", false);
  },

  async storeUpdateReturnDetails({ commit }, returnDetails) {
    try {
      commit("spinnerModule/setLoading", true, { root: true });
      //
      // !! - required
      //
      // - additionalInformation:
      //    - vehicleOperation (financing)    !!
      //    - accessories
      // - features
      //    - airConditiong
      //    - bcaid                           !!
      //    - cabintriminlay
      //    - equipments
      //    - evaluation (adjustedValuation)  !!
      //    - externalColor                   !!
      //    - internalColor                   !!
      //    - kilometers                      !!
      //    - plate                           !!
      //    - plateDate                       !!
      //    - tradeInId                       !!
      // - mechanicalAndGeneralStatus         !!
      //    - generalStatusList
      //    - mechanicalStateList
      // - submitted
      //
      // **Extra**
      // - files (format?)
      //
      var res = await updateReturn(returnDetails);
      // commit("setReturn", res.data.data);
      if (res.response?.status && res.response?.status == 500) {
        return Promise.reject();
      }
      return Promise.resolve(res);
    } catch (e) {
      return Promise.reject();
    } finally {
      commit("spinnerModule/setLoading", false, { root: true });
    }
  },

  async storeAddPhoto({ commit }, files) {
    commit("setLoadingReturns", true);
    try {
      const response = await addPhoto(files);

      if (response.status === 404 || response.status === 500) {
        commit("setErrorsReturns", response.data.message);
      } else {
        // commit("setAddPhoto", response.data.data);
        state.errorsReturns = [];
      }
    } catch (e) {
      // commit("setErrorsReturns", e);
    } finally {
      commit("setLoadingReturns", false);
    }
  },

  async storeGetAllReturns({ commit }) {
    commit("setLoadingReturns", true);
    try {
      const response = await getAllReturns();

      commit("setAllReturns", response.data.data);
    } catch (e) {
      commit("setErrorsReturns", e);
      commit("setLoadingReturns", false);
    }
    commit("setLoadingReturns", false);
  },

  async storeDeleteReturn({ commit, dispatch }, id) {
    commit("setLoadingReturns", true);

    try {
      await deleteReturn(id);
      dispatch("storeGetAllReturns");
    } catch (e) {
      commit("setErrorsReturns", e);
      commit("setLoadingReturns", false);
    }
    commit("setLoadingReturns", false);
  },

  processFeatures({ commit }, features) {
    commit("processProvenance", features?.provenance);
    commit("processFeatures", features);
  },
  processAddInfo({ commit }, additionalInformation) {
    commit("processVehicleOperation", additionalInformation?.vehicleOperation);
    commit("processAcessories", additionalInformation?.accessories);
    commit("setAdditionalInfo", additionalInformation);
  },
};

// serves to define state value
const mutations = {
  setInteriorColor(state, val) {
    state.returnObj.tradeInPayload.features.cabinTrimInlay = val;
  },
  setExteriorColor(state, val) {
    state.returnObj.tradeInPayload.features.externalColor = val;
  },
  setInteriorColors(state, val) {
    state.interiorList = val;
  },
  setExteriorColors(state, val) {
    state.exteriorColors = val;
  },
  setMechanicalState(state, list) {
    state.mechanicalState = list;
  },
  setGeneralStatus(state, list) {
    state.generalStatus = list;
  },
  processAirConditioningList(state, airConditiong) {
    state.returnObj.tradeInPayload.features.airConditiong = airConditiong;
    state.airConditiongList = state.airConditiongList.map((opt) =>
      opt.value === airConditiong ? { ...opt, selected: true } : opt
    );
  },
  processProvenance(state, provenance) {
    if (provenance) {
      state.provenance.radioGroupValue = provenance;
      state.provenance.radioGroupList = state.provenance.radioGroupList.map(
        (p) =>
          p.radioValue === provenance
            ? { ...p, checked: true }
            : { ...p, checked: false }
      );
    }
  },
  setProvenance(state, provenance) {
    state.provenance = provenance;
  },
  processGeneralStatus(state, list) {
    state.generalStatus = changeDataBasedOnBackend(list, state.generalStatus);
  },
  processMechanicalStateList(state, list) {
    state.mechanicalState = changeDataBasedOnBackend(
      list,
      state.mechanicalState
    );
  },
  processVehicleOperation(state, list) {
    state.vehicleOperation = state.vehicleOperation.map((opt, idx) => ({
      ...opt,
      checked: list[idx].checked,
    }));
  },
  processAcessories(state, list) {
    state.accessories = state.accessories.map((opt, idx) => ({
      ...opt,
      checked: list[idx].checked,
    }));
  },
  processEquipments(state, list) {
    state.equipments = state.equipments.map((opt, idx) => ({
      ...opt,
      checked: list[idx].checked,
    }));
    console.log("eq", state.equipments);
  },
  processFeatures(state, features) {
    state.features = features;
  },
  setEquipments(state, [equipment, value]) {
    const eqs = state.returnObj?.tradeInPayload?.features?.equipments;
    if (eqs) {
      state.returnObj.tradeInPayload.features.equipments = eqs.map((e) =>
        e.value !== equipment
          ? e
          : {
              ...e,
              checked: value,
            }
      );
    }
  },
  setAirConditioning(state, val) {
    state.returnObj.tradeInPayload.features.airConditioning = val;
  },
  setKilometers(state, val) {
    state.returnObj.tradeInPayload.features.kilometers = Number(val);
  },
  setReturnKilometers(state, val) {
    state.returnObj.kilometers = val;
  },
  setAllReturns(state, returns) {
    state.returns = returns;
  },
  setReturn(state, returnObj) {
    if (!returnObj.tradeInPayload) {
      returnObj.tradeInPayload = {
        mechanicalAndGeneralStatus: {
          generalStatusList: [],
          mechanicalStateList: [],
        },
        additionalInformation: { vehicleOperation: [] },
        features: {},
      };
    }
    state.returnObj = returnObj;
  },
  setReturnByIndex(state, index) {
    state.returnObj = state.returns[index];
  },
  setErrorsReturns(state, errors) {
    state.errorsReturns = errors;
  },
  setLoadingReturns(state, loading) {
    state.loadingReturn = loading;
  },
  setFeatures(state, features) {
    state.features = features;
  },
  setAdditionalInfo(state, additionInfo) {
    state.additionInfo = additionInfo;
  },
  formatPlateDate(state) {
    const dataFormatted =
      state.returnObj.year + "-" + state.returnObj.month + "-01";
    state.returnObj.plateDate = dataFormatted;
    state.returnObj.tradeInPayload.features.plateDate = dataFormatted;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
