<template>
  <div>
    <VehicleCard
      :scale="scale"
      :customClass="customClass"
      :vehicle="v"
      :hrefOptions="hrefOptions"
      :financeInformation="hasFinancing"
      :favorite="inFavoritesList({ vin: v.vin, financeId: v.financeId })"
      :tooltipDescription="v.legalInfo"
      :tooltipPosition="tooltipPosition"
      :tooltipSize="tooltipSize"
      :status="status"
      :formatted="formatted"
      :tags="tags"
      :labels="labelsForVehicleCard"
      @onClick="handleClick"
      @compare="handleCompare"
      @like="handleFavorite"
    >
    </VehicleCard>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { productClickEvent } from "../../utils/googleTagManager";

import { PAGES } from "../../enums/pages";
import {
  formatKilometers,
  formatNonCurrency,
  getPriceFormatted,
} from "../../utils/currency";

import { RESERVE_TYPE } from "../../enums/reserveType.js";

import { CompareMixin, FavoritesMixin } from "../../mixins";

import { VehicleCard } from "@sc/ds-ui-component-library";

export default {
  name: "CpVehicleCard",
  components: {
    VehicleCard,
  },
  mixins: [CompareMixin, FavoritesMixin],
  props: {
    v: {
      type: Object,
      default: () => {},
    },
    relativePos: {
      type: Number,
      default: 0,
    },
    customClass: {
      type: String,
    },
    tooltipPosition: {
      type: String,
    },
    tooltipSize: {
      type: String,
    },
    scale: {
      type: Number,
    },
  },

  computed: {
    ...mapGetters({
      inFavoritesList: "FavoritesModule/exists",
      filterState: "SearchModule/filter/getFilterState",
      isCompOpened: "CompareModule/isPanelOpened",
      isFavOpened: "FavoritesModule/isPanelOpened",
      getQuarterEndDate: "VehicleModule/getQuarterEndDate",
    }),
    href() {
      return `/veiculo/${this.v?.vin}`;
    },
    hrefOptions() {
      return {
        to: this.href,
        target: "_self",
      };
    },
    status() {
      return {
        reserved: this.isReserved,
        soon: this.isSoon,
        bought: this.isBought,
      };
    },
    formatted() {
      return {
        monthlyPrice: `${getPriceFormatted(this.v.monthlyPrice)}`,
        previousPrice: `${
          this.v.prices.find((p) => p.priceType === "TotalPrice") &&
          this.v.prices.find((p) => p.priceType === "TotalPrice").previousValue
            ? getPriceFormatted(
                this.v.prices.find((p) => p.priceType === "TotalPrice")
                  .previousValue,
                {},
                0,
                0
              )
            : ""
        }`,
        totalPrice: `${getPriceFormatted(
          this.v.prices.find((p) => p.priceType === "TotalPrice").actualValue,
          {},
          0,
          0
        )}`,
        depositValue: `${getPriceFormatted(
          this.v.tooltipDescription.depositValue
        )}`,
        capitalFinance: `${getPriceFormatted(
          this.v.tooltipDescription.capitalFinance
        )}`,
        kilometers: `${formatKilometers(this.v.kilometers)}`,
      };
    },
    isReserved() {
      return this.v?.reserveType === RESERVE_TYPE.RESERVED;
    },
    isBought() {
      return this.v?.reserveType === RESERVE_TYPE.BOUGHT;
    },
    isSoon() {
      return this.v?.status === "soon";
    },
    labelsForVehicleCard() {
      return {
        months: this.$t("labels.vehicle.months"),
        monthly: this.$t("labels.vehicle.monthly"),
        taeg: this.$t("labels.vehicle.taeg"),
        km: this.$t("labels.vehicle.km"),
        financeMonths: this.$t("labels.vehicle.financeMonths"),
        financeEntry: this.$t("labels.vehicle.financeEntry"),
        financeValue: this.$t("labels.vehicle.financeValue"),
        financeSeparator: this.$t("labels.vehicle.financeSeparator"),
        reserved: this.$t("labels.vehicle.reserved"),
        bought: this.$t("labels.vehicle.bought"),
        soon: this.$t("labels.vehicle.soon"),
        lowCost: this.$t("labels.vehicle.lowCost"),
      };
    },
    hasFinancing() {
      return (
        this.v &&
        this.v.tooltipDescription &&
        this.v.tooltipDescription.depositValue &&
        this.v.tooltipDescription.months &&
        this.v.tooltipDescription.capitalFinance &&
        this.v.tooltipDescription.monthPrice &&
        typeof this.v.taeg == "number"
      );
    },
    tags() {
      return [
        this.v.fuel,
        this.v.transmission,
        this.v.year || 0,
        formatKilometers(this.v.kilometers),
      ];
    },
    tooltip() {
      return this.$t("pages.detailsPage.tooltip", {
        pricePvp: getPriceFormatted(
          this.v.prices.find((p) => p.priceType === "TotalPrice").actualValue
        ),
        minDeposit: getPriceFormatted(this.v.tooltipDescription.depositValue),
        capitalToFinance: getPriceFormatted(
          this.v.tooltipDescription.capitalFinance
        ),
        tan: formatNonCurrency(this.v.tooltipDescription?.tan, 3, 3, false),
        taeg: formatNonCurrency(this.v.taeg, 1, 1),
        mtic: getPriceFormatted(this.v.tooltipDescription?.mtic),
        monthlyValue: getPriceFormatted(this.v.monthlyPrice),
        numMonths: this.v.tooltipDescription?.months,
        brand: this.v.brand,
        commercialDescription: this.v.commercialDescription,
        model: this.v.model,
        totalPrice: getPriceFormatted(
          this.v.prices.find((p) => p.priceType === "TotalPrice")
            ? this.v.prices.find((p) => p.priceType === "TotalPrice")
                .actualValue
            : 0
        ),
        consumption: this.v.tooltipDescription?.consumptionCombine,
        emissions: this.v.tooltipDescription?.emissions,
        initialExpenses: getPriceFormatted(
          this.v.tooltipDescription?.initialExpenses
        ),
        lastFee: getPriceFormatted(this.v.tooltipDescription?.lastFee) || "--",
        dtEndOfCampaign: this.v.tooltipDescription?.dtEndOfCampaign || "--",
        taxStamp: getPriceFormatted(this.v.taxStamp) || "--",
        quarterEndDt: this.getQuarterEndDate,
      });
    },
  },
  methods: {
    ...mapMutations({
      closeFavPanel: "FavoritesModule/closePanel",
      closeCompPanel: "CompareModule/closePanel",
    }),
    handleCompare(e) {
      e.stopPropagation();
      this.toggleCompared(this.v);
    },
    handleFavorite(e) {
      e.stopPropagation();
      this.toggleFavorite(this.v);
    },
    handleClick() {
      const search = { ...this.filterState, saveTags: true };

      productClickEvent(this.v, PAGES.SEARCH, this.relativePos);

      sessionStorage.setItem(
        "searchPath",
        JSON.stringify({
          path: this.$router.currentRoute.path,
          name: this.$route.name,
        })
      );
      this.$router.push({
        name: `DetailPage`,
        params: {
          id: this.v?.vin,
          fid: this.v?.financeId,
          search,
        },
      });

      if (this.isCompOpened || this.isFavOpened) {
        this.closeFavPanel();
        this.closeCompPanel();
      }
    },
    getAltImage(model) {
      return this.$t("pages.searchpage.SearchVehicleResultCard.altImage", {
        vehicleModel: model,
      });
    },
  },
};
</script>
