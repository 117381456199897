import httpClient from "./httpClient";

const VEHICLES = "/vehicle";
const SPECIFICATIONS = "/specifications";
const SIMILARS = "/similar";
const IMAGES = "/images";
const FULLVIEW = "/fullview";
const COMPARE = VEHICLES + "/compare";
const TOTAL_VEHICLES = VEHICLES + "/count";
const VIEWERS = "/viewers";

function getTotalVehicles() {
  return httpClient.get(TOTAL_VEHICLES);
}

function getAllVehicles(page) {
  return httpClient.get(VEHICLES + "?page=" + page);
}

function getVehicle(id, fid) {
  return httpClient.get(VEHICLES + "/" + id, { params: { fid } });
}

function getVehicleSpecifications(id) {
  return httpClient.get(VEHICLES + "/" + id + SPECIFICATIONS);
}

function getVehicleSimilars(id, reserved = 0) {
  let endpoint = VEHICLES + "/" + id + SIMILARS + "?reserved=" + reserved;
  return httpClient.get(endpoint);
}

function getVehicleImages(id) {
  return httpClient.get(VEHICLES + "/" + id + IMAGES);
}

function getVehicleImagesFullView(id) {
  return httpClient.get(VEHICLES + "/" + id + IMAGES + FULLVIEW);
}

function getVehiclesToCompare(vinsList) {
  const vins = "?vins=" + vinsList.join(",");

  return httpClient.get(COMPARE + vins);
}

function getVehicleViewers(id) {
  return httpClient.get(VEHICLES + "/" + id + VIEWERS);
}

export {
  getAllVehicles,
  getVehicle,
  getVehicleSpecifications,
  getVehicleSimilars,
  getVehicleImages,
  getVehicleImagesFullView,
  getVehiclesToCompare,
  getTotalVehicles,
  getVehicleViewers,
};
