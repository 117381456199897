var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Page',{attrs:{"id":"credit-intermediary"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('Section',[_c('SectionHeader',{attrs:{"title":_vm.$t(`pages.CreditIntermediary.title`)}}),_c('div',{staticClass:"credit-intermediary-container"},_vm._l((_vm.sections),function(section){return _c('div',{key:section,staticClass:"credit-intermediary-block"},[_c('Subtitle',{staticClass:"credit-intermediary-title",attrs:{"label":_vm.$t(`pages.CreditIntermediary.${Object.keys(section)[0]}.title`)}}),_vm._l((section),function(subsection){return _c('div',{key:subsection,staticClass:"credit-intermediary-block"},_vm._l((subsection),function(subsubsection){return _c('div',{key:subsubsection},[_c('Subtitle',{attrs:{"label":_vm.$t(
                    `pages.CreditIntermediary.${
                      Object.keys(section)[0]
                    }.subsections.${subsubsection}.title`
                  )}}),_c('TextLabel',{domProps:{"innerHTML":_vm._s(
                  _vm.$t(
                    `pages.CreditIntermediary.${
                      Object.keys(section)[0]
                    }.subsections.${subsubsection}.textLabel`
                  )
                )}})],1)}),0)})],2)}),0)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }