import { getPriceFormatted, formatNonCurrency } from "../../../utils/currency";
import {
  getVehicle,
  getVehicleSimilars /*, getVehicleSpecifications*/,
} from "@/api/product";
import i18n from "../../../i18n";

const state = {
  vehicle: {},
  financeId: 0,
  minDeposit: 0,
};

const getters = {
  getFormattedTotalPrice: (state) =>
    getPriceFormatted(
      state.vehicle?.prices.find((p) => p.priceType === "TotalPrice")
        ? state.vehicle?.prices.find((p) => p.priceType === "TotalPrice")
            .actualValue
        : 0
    ),
  getCreditTypeDescription: (state) => (creditType) =>
    state.vehicle?.creditTypes[creditType - 1].creditName,
  getCreditTypeDetails: (state) => (creditType) => {
    return state.vehicle?.creditTypes.find(
      (credit) => credit.id === creditType
    );
  },
  getVehicleTotalPrice: (state) =>
    state.vehicle?.prices.find((p) => p.priceType === "TotalPrice")
      ? state.vehicle?.prices.find((p) => p.priceType === "TotalPrice")
          .actualValue
      : 0,
  getVehicleTan: (state) => state.vehicle?.carDetailFinance?.tan,
  getVehicleFee: (state) => state.vehicle?.carDetailFinance?.fee,
  getVehicleFinanceId: (state) => state.financeId,
  getMinDeposit: (state) => state.minDeposit,
  getVin: (state) => state.vehicle?.vin,
  getSelectedVehicle: (state) => state.vehicle,
  getFavorite: (state) => state.vehicle?.favorite,
  getCarSimilarVehicles: (state) => state.vehicle?.carCardSimilar,
  getCarDetailEquipment: (state) => state.vehicle?.carDetailEquipment,
  getFinanceId: (state) => state.vehicle?.financeId,
  getCarDetailFaqs: (state) => {
    return state.vehicle?.carDetailFaqs?.faqs;
  },
  getCarSpecifications: (state) => {
    return state.vehicle?.carDetailSpecifications;
  },
  getCarSpecificationsMapped: (state) => {
    return state.vehicle?.carDetailSpecification?.specifications?.map((vd) => ({
      title: vd.title,
      specification: vd.description,
    }));
  },
  getCarFinance(state) {
    return state.vehicle?.carDetailFinance;
  },
  getModel: (state) => state.vehicle?.model,
  getRawTaeg: (state) => Number(state.vehicle?.taeg).toFixed(1),

  getTaeg: (state) => {
    let value = getPriceFormatted(state.vehicle?.taeg, {
      currency: "EUR",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).replace("€", "%");

    if (value && value !== "--")
      value = !value.includes(",") ? `${value},0` : value;

    return value;
  },

  getDescription: (state) => state.vehicle?.commercialDescription,
  getVehiclePrice: (state) => state.vehicle?.pricePvp,
  getFormattedPrice: (state) =>
    getPriceFormatted(state.vehicle?.pricePvp, {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),

  getPrice: (state) => state.vehicle?.pricePvp,

  getRawMonthlyPrice: (state) => state.vehicle?.monthlyPrice,

  getFormattedMonthlyPrice: (state) =>
    getPriceFormatted(Number(state.vehicle?.monthlyPrice), {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),

  getFormattedMonthlyPriceSimple: (state) =>
    getPriceFormatted(Number(state.vehicle?.monthlyPrice), {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),

  getImg: (state) =>
    state.vehicle?.imageUrl ||
    require("../../../assets/images/placeholder.jpg"),

  getFormattedTaeg: (state) => {
    let value = getPriceFormatted(state.vehicle?.taeg, {
      currency: "EUR",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).replace("€", "%");

    if (value && value !== "--")
      value = !value.includes(",") ? `${value},0` : value;

    return value;
  },

  getNbrPaymentsValue: (state) => state.vehicle?.carDetailFinance?.months,

  getFormattedInitialDeposit: (state) =>
    getPriceFormatted(
      Number(state.vehicle?.carDetailFinance?.depositValue).toFixed(2),
      {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
    ),

  getInitialDeposit: (state) => state.vehicle?.carDetailFinance?.depositValue,

  getRawCapitalToFinance: (state) =>
    state.vehicle?.carDetailFinance?.capitalFinance,

  getFormattedCapitalToFinance: (state) =>
    getPriceFormatted(
      Number(state.vehicle?.carDetailFinance?.capitalFinance).toFixed(2),
      {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
    ),

  getVehicleFaqs: (state) => state.vehicle?.carDetailFaqs?.faqs,

  getVehicleInitialDepositFormatted: (state) =>
    getPriceFormatted(state.vehicle?.carDetailFinance?.depositValue, {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),

  getVehicleInitialDeposit: (state) =>
    state.vehicle?.carDetailFinance?.depositValue,

  getVehicleNumberMonths: (state) => {
    return state.vehicle?.carDetailFinance?.months;
  },

  getVehicleResidualValue: (state) => {
    const res = Number(state.vehicle?.carDetailFinance?.residualValue)?.toFixed(
      2
    );
    return res === "NaN" || res == "0.00" ? null : res;
  },

  getVehicleResidualValueOD: (state) => (creditType, months) => {
    var res = state.vehicle?.creditTypes[creditType]?.residualValues.filter(
      (resVal) => resVal.months === months
    )[0];

    if (res) {
      res =
        res.valueType === "P"
          ? (res.value / res.months / 100) *
            state.vehicle?.carDetailCard.pricePvp
          : res.value;
    } else {
      res = "NaN";
    }

    return res === "NaN" || res == "0.00" ? null : res;
  },

  getVehicleRate: (state) => (creditType) => {
    return state.vehicle?.creditTypes[creditType]?.rate;
  },
  getVehicleMinRate: (state) => (creditType) => {
    return state.vehicle?.creditTypes[creditType]?.minRate;
  },

  // FIXME: Why is this here??!!
  // eslint-disable-next-line no-unused-vars
  getSessionRetake: (state) => JSON.parse(sessionStorage.getItem("retake")),

  getInfoIconTooltip: (state) => {
    return i18n.t("pages.detailsPage.tooltip", {
      pricePvp: getPriceFormatted(state.vehicle?.pricePvp),
      minDeposit: getPriceFormatted(state.minDeposit),
      capitalToFinance: getPriceFormatted(
        state.vehicle?.carDetailFinance?.capitalFinance
      ),
      tan: formatNonCurrency(state.vehicle?.tooltipDescription?.tan),
      taeg: formatNonCurrency(state.vehicle?.taeg, 1, 1, true),
      mtic: getPriceFormatted(state.vehicle?.tooltipDescription?.mtic),
      monthlyValue: getPriceFormatted(state.vehicle?.monthlyPrice),
      numMonths: state.vehicle?.carDetailFinance?.months,
      commercialDescription: state.vehicle?.commercialDescription,
    });
  },
};

const mutations = {
  setVehicle(state, vehicle) {
    state.vehicle = vehicle;
  },
  updateVehicleFinance(
    state,
    {
      pvp,
      taeg,
      fee,
      nrPayments,
      capitalToFinance,
      initialValue,
      residualValue,
    }
  ) {
    state.vehicle = {
      ...state.vehicle,
      pricePvp: pvp,
      taeg,
      monthlyPrice: fee,
      carDetailFinance: {
        ...state.vehicle.carDetailFinance,
        months: nrPayments,
        capitalFinance: capitalToFinance,
        depositValue: initialValue,
        residualValue,
      },
    };
  },

  setVehicleNumberMonths(state, val) {
    state.vehicle.carDetailFinance.nbr_payments_value = val;
  },
  setVehicleInitialDeposit(state, val) {
    state.vehicle.carDetailFinance.initial_deposit_value = val;
  },
  setVehicleFinanceId(state, id) {
    state.financeId = id;
  },
  setMinDeposit(state, val) {
    state.minDeposit = val;
  },
};

const actions = {
  async storeVehicle({ commit }, { vin, fid }) {
    let response = {};

    try {
      commit("VehicleModule/setEmptyVehicleValues");
      commit("spinnerModule/setLoading", true, { root: true });
      const res = await getVehicle(vin, fid);
      const resS = {}; // await getVehicleSpecifications(vin); TODO
      const resSimilars = await getVehicleSimilars(vin);
      const { carDetailCard } = res.data.data;
      carDetailCard.financeId = fid;
      commit("setVehicle", {
        carDetailSpecifications: resS, //resS.data.data, TODO
        ...carDetailCard,
        ...res.data.data,
        carCardSimilar: resSimilars.data.data,
      });

      response.vehicle = res;
      response.similar = resSimilars;
      commit("setMinDeposit", res?.data?.data?.carDetailFinance?.depositValue);
    } catch (err) {
      console.error("Could not fetch vehicle details from backend");
      commit("setVehicle", { vin, fid });
    } finally {
      commit("spinnerModule/setLoading", false, { root: true });
    }

    return response;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
