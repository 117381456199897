<template>
  <input
    :class="['input--text', customClass]"
    @focus="$emit('onFocus')"
    @blur="$emit('onBlur')"
    @input="(e) => $emit('onInput', e)"
    :disabled="isDisabled"
    :readonly="isReadonly"
    :required="isRequired"
    :maxlength="maxLength"
    :autocomplete="allowAutocomplete"
    :placeholder="placeholder"
    :name="name"
    :type="inputType"
    :value="value"
    :checked="checked"
    :id="id"
  />
</template>

<script>
export default {
  name: "BaseInput",
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
    },
    customClass: {
      type: String,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      required: false,
    },
    allowAutocomplete: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    value: {
      type: [Number, String],
    },
    inputType: {
      type: String,
      default: "text",
      validator: (inputType) => {
        return [
          "text",
          "email",
          "number",
          "password",
          "tel",
          "date",
          "checkbox",
          "radio",
        ].includes(inputType);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.input--text {
  font-size: var(--baseinput-font-size);
  line-height: var(--baseinput-line-height);
  font-weight: var(--baseinput-font-weight);
  background-color: var(--baseinput-bg-color);
  padding-left: var(--baseinput-padding-left);
  padding-top: var(--baseinput-padding-top);
  padding-bottom: var(--baseinput-padding-bottom);
  outline: var(--baseinput-outline);
  border: var(--baseinput-border);
  color: var(--baseinput-color);
  height: var(--baseinput-height);
  width: var(--baseinput-width);
}
</style>
