import { createPaymentIntent } from "../../api/reservations";
import { prepareLeadPayload } from "../../utils/mapper";

const initialState = () => ({
  cardOwner: "",
  clientId: "",
  includeFinancing: true,
  includeTradein: false,
  includeDelivery: DeliveryType.HOME,
  includeCustomDelivery: false,
  toggledFinancing: false,
  toggledTradein: false,
  toggledDelivery: false,
  receipt: {
    nif: "",
    address: "",
    zipCode: "",
    city: "",
  },
  includeExtraReceipt: false,
  extraReceipt: {
    address: "",
    zipCode: "",
    city: "",
  },
  dealer: "", // WARN: must be id
  deliveryDealer: "",
  validReceipt: {
    nif: false,
    address: false,
    zipCode: false,
    city: false,
  },
  validExtraReceipt: {
    address: false,
    zipCode: false,
    city: false,
  },
});

const getters = {
  withFinancing: (state) => state.includeFinancing,
  withTradein: (state) => state.includeTradein,
  withDelivery: (state) => state.includeDelivery,
  withExtraReceipt: (state) => state.includeExtraReceipt,
  isSetFinancing: (state) => state.toggledFinancing,
  isSetTradein: (state) => state.toggledTradein,
  isSetDelivery: (state) => state.toggledDelivery,
  validForm: (state) => {
    const validReceipt = Object.values(state.validReceipt).every(Boolean);
    const validExtraReceipt =
      !state.includeExtraReceipt ||
      Object.values(state.validExtraReceipt).every(Boolean);
    return validReceipt && validExtraReceipt;
  },
  getReceipt: (state) => state.receipt,
  getExtraReceipt: (state) => state.extraReceipt,
  getCardOwner: (state) => state.cardOwner,
  getClientId: (state) => state.clientId,
  getDealer: (state) => state.dealer,
  getDeliveryDealer: (state) => state.deliveryDealer,
  getReceiptOrExtra: (state) =>
    Object.values(state.validExtraReceipt).every(Boolean)
      ? { nif: state.receipt.nif, ...state.extraReceipt }
      : state.receipt,
  getDeliveryType: (state) => {
    if (!state.includeDelivery) return DeliveryType.DEALER;
    return state.includeCustomDelivery
      ? DeliveryType.CUSTOM
      : DeliveryType.HOME;
  },
};

const mutations = {
  // TODO: simplify flag lofic
  setFinancing(state, val) {
    state.toggledFinancing = true;
    state.includeFinancing = val;
  },
  setTradein(state, val) {
    state.toggledTradein = true;
    state.includeTradein = val;
  },
  setDelivery(state, val) {
    state.toggledDelivery = true;
    state.includeDelivery = val;
  },
  setCustomDelivery(state, val) {
    state.toggledDelivery = true;
    state.includeCustomDelivery = val;
  },

  setAddress(state, [val, valid]) {
    state.receipt.address = val;
    state.validReceipt.address = valid;
  },
  setNif(state, [val, valid]) {
    state.receipt.nif = val;
    state.validReceipt.nif = valid;
  },
  setCity(state, [val, valid]) {
    state.receipt.city = val;
    state.validReceipt.city = valid;
  },
  setZipCode(state, [val, valid]) {
    state.receipt.zipCode = val;
    state.validReceipt.zipCode = valid;
  },
  setExtraAddress(state, [val, valid]) {
    state.extraReceipt.address = val;
    state.validExtraReceipt.address = valid;
  },
  setExtraCity(state, [val, valid]) {
    state.extraReceipt.city = val;
    state.validExtraReceipt.city = valid;
  },
  setExtraZipCode(state, [val, valid]) {
    state.extraReceipt.zipCode = val;
    state.validExtraReceipt.zipCode = valid;
  },
  setDealer(state, val) {
    state.dealer = val;
  },
  setDeliveryDealer(state, val) {
    state.deliveryDealer = val;
  },
  setincludeExtraReceipt(state, val) {
    state.includeExtraReceipt = val;
  },
  setCardOwner(state, val) {
    state.cardOwner = val;
  },
  setClientId(state, val) {
    state.clientId = val;
  },
  resetReserve(state) {
    state.cardOwner = "";
    state.clientId = "";
    state.includeFinancing = true;
    state.includeTradein = false;
    state.includeDelivery = DeliveryType.HOME;
    state.includeCustomDelivery = false;
    state.toggledFinancing = false;
    state.toggledTradein = false;
    state.toggledDelivery = false;
    state.receipt = {
      nif: "",
      address: "",
      zipCode: "",
      city: "",
    };
    state.includeExtraReceipt = false;
    state.extraReceipt = {
      address: "",
      zipCode: "",
      city: "",
    };
    state.dealer = ""; // WARN: must be id
    state.deliveryDealer = "";
    state.validReceipt = {
      nif: false,
      address: false,
      zipCode: false,
      city: false,
    };
    state.validExtraReceipt = {
      address: false,
      zipCode: false,
      city: false,
    };
  },
};

const DeliveryType = {
  HOME: 0,
  DEALER: 1,
  CUSTOM: 2,
};

const actions = {
  async submitLead({ commit, rootGetters, getters }) {
    try {
      const receipt = getters.getReceipt;
      const extraReceipt = getters.getExtraReceipt;
      console.log(rootGetters["UserModule/getPersonal"]);
      const res = await createPaymentIntent(
        prepareLeadPayload({
          vehicle: rootGetters["VehicleModule/getVehicle"],
          receipt: { ...receipt, location: receipt.city },
          extraReceipt,
          user: rootGetters["UserModule/getPersonal"],
          dealer:
            getters.getDealer ||
            rootGetters["SearchModule/dropdown/getDefaultDealer"],
          deliveryDealer:
            getters.getDeliveryDealer ||
            rootGetters["SearchModule/dropdown/getDefaultDealer"],
          withFinance: getters.withFinancing,
          withTradein: getters.withTradein,
          retake: rootGetters.getReturns[0],
          withDelivery: getters.withDelivery,
          tradeInId: rootGetters["getIncludeRetake"]
            ? rootGetters.getReturns[0].id
            : null,
        })
      );
      commit("setClientId", res.data.data.client_secret);
    } catch (err) {
      console.error(err);
    }
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions,
};
