<template>
  <Section id="section-2" class="quicklinks-section">
    <Block id="ql1" :style="leftCampaignImage" class="quicklink">
      <TextLabel
        class="block-title"
        :label="$t('pages.homepage.quicklinks.search.title')"
      />
      <div class="dropdowns-inline">
        <div class="dropdowns-before-button">
          <InputField
            :label="
              $t('pages.homepage.sections.hero.SearchSegmentMonthly.monthly')
            "
            @onInput="emitMonthlyValue"
            :mode="'number'"
          />

          <Dropdown
            class="initial-entry-dropdown"
            id="initial-entry"
            :uppercase="false"
            :title="
              $t(
                'pages.homepage.sections.hero.SearchSegmentMonthly.initialEntryPlaceholder',
                { initialEntry: this.initialEntry }
              )
            "
            bgColor="white"
          >
            <div class="initial-entry">
              <RangeInput
                :label="
                  $t(
                    'pages.homepage.sections.hero.SearchSegmentMonthly.initialEntrySlide'
                  )
                "
                sliderType="secondary"
                :sliderValue="initialEntry"
                :sliderMax="12000"
                :sliderMin="500"
                :sliderInterval="500"
                @onChange="emitEntryValue"
              />
            </div>
          </Dropdown>
        </div>

        <Button
          :label="$t('searchform.search')"
          @onClick="handleClickSearch"
          type="secondary"
          class="monthlyEntryButton"
          :disabled="submitDisabled"
        ></Button>
      </div>
    </Block>

    <Block id="ql2" class="quicklink">
      <TextLabel
        class="block-title quicklink-front"
        :label="$t('pages.homepage.quicklinks.sell.title')"
      />
      <Button
        customClass="sell-btn"
        class="quicklink-front"
        :label="$t('pages.homepage.quicklinks.sell.button')"
        type="secondary"
        @onClick="handleClickRetake"
      ></Button>
      <img
        :src="
          $t('resources.images.homepage.quicklinkCenter') ||
          require('@/assets/images/stuff/quicklink2.webp')
        "
        class="quicklink-image"
        :alt="$t('pages.homepage.quicklinks.sell.alt')"
      />
    </Block>

    <Block
      id="ql3"
      class="quicklink"
      @click.native="redirectToCampaign"
      :style="campaignImage"
    >
      <div v-if="false" class="redirect" @click="redirectTo">
        <TextLabel
          :label="$t('pages.homepage.quicklinks.campain.label')"
        ></TextLabel>
        <Icon color="white" icon="chevron-right" />
      </div>
    </Block>
  </Section>
</template>

<script>
import {
  Section,
  Button,
  Block,
  InputField,
  TextLabel,
  Icon,
} from "@sc/ds-ui-component-library";
import { Dropdown, RangeInput } from "../molecules";
import { PAGES } from "../../enums/pages";

export default {
  name: "CpQuicklinks",
  components: {
    Section,
    Button,
    Block,
    InputField,
    TextLabel,
    Icon,
    Dropdown,
    RangeInput,
  },
  props: {
    paymentOptions: {
      type: Array,
    },
    submitDisabled: {
      type: Boolean,
    },
    isMobile: {
      type: Boolean,
    },
    isTablet: {
      type: Boolean,
    },
    isTabletPlus: {
      type: Boolean,
    },
  },
  data() {
    return {
      initialEntry: 500,
      maxInitialEntry: 12000,
      initialEntryChanged: false,
      finalPrice: 0,
    };
  },
  methods: {
    redirectToCampaign() {
      this.$emit("onClickCampaign");
    },
    redirectTo() {
      this.$router.push({
        name: PAGES.SEARCH,
      });
    },
    emitEntryValue(value) {
      this.initialEntry = value;
      this.initialEntryChanged = true;
      this.$emit("onEntryValue", this.initialEntry);
    },
    handleClickSearch() {
      this.$emit("onClickSearch");
    },
    handleClickRetake() {
      this.$emit("onClickRetake");
    },
    emitMonthlyValue(value) {
      this.$emit("onMonthlyValue", value[0]);
    },
  },
  computed: {
    campaignImage() {
      if (this.isMobile) {
        return {
          backgroundImage: `url(${
            this.$t("resources.images.homepage.quicklinkRightMobile") ||
            this.$t("pages.homepage.quicklinks.campain.image")
          })`,
        };
      }
      if (this.isTablet) {
        return {
          backgroundImage: `url(${
            this.$t("resources.images.homepage.quicklinkRightTablet") ||
            this.$t("pages.homepage.quicklinks.campain.image")
          })`,
        };
      }
      if (this.isTabletPlus) {
        return {
          backgroundImage: `url(${
            this.$t("resources.images.homepage.quicklinkRightTabletPlus") ||
            this.$t("pages.homepage.quicklinks.campain.image")
          })`,
        };
      }
      return {
        backgroundImage: `url(${
          this.$t("resources.images.homepage.quicklinkRight") ||
          this.$t("pages.homepage.quicklinks.campain.image")
        })`,
      };
    },
    leftCampaignImage() {
      return {
        backgroundImage: `url(${
          this.$t("resources.images.homepage.quicklinkLeft") ||
          require("../../assets/images/stuff/quicklink1.webp")
        })`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.quicklinks-section {
  --section-bg-color: var(--color-7);
  height: 325px;
  display: flex;
  --section-padding: 16px;
  --section-overflow: visible;
  --block-overflow: visible;

  #ql2 {
    margin: 0 17px;
  }

  .block-title {
    width: 100%;
    max-width: 295px;
    --text-label-color: #02226f;
  }
}

@media only screen and (max-width: 1845px) and (min-width: 1025px) {
  .quicklinks-section {
    height: 325px;
  }

  .quicklink {
    .sell-btn {
      padding: 19px 20px;
    }
  }

  .dropdowns-inline {
    flex-direction: column;
    width: 100%;
    ::v-deep {
      .btn {
        justify-content: center;
      }
    }
  }
}

::v-deep .block--content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  padding: 24px;
}

.quicklink {
  width: 100%;

  .dropdowns-inline {
    display: flex;

    .button {
      margin: 0 0 0 8px;
    }

    .initial-entry-dropdown ::v-deep {
      .dropdown--title-label {
        padding: 1px 0px;
      }
    }
  }

  .sell-btn {
    display: flex;
    justify-content: center;
    --button-padding: 19px 104px;
  }
}

#ql1 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#ql2 {
  position: relative;
}

.quicklink-front {
  z-index: 2;
}

.quicklink-image {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1;
  object-fit: cover;
}

#ql3 {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;

  ::v-deep {
    --block-padding: 0 0 16px 0;

    .block--content {
      padding: 0px;
    }
  }
}

.monthlyEntryButton {
  --button-padding: 24px 19px;
}

.redirect {
  display: flex;
  justify-content: space-between;
  background-color: var(--theme-color-secondary);
  --text-label-color: var(--color-7);
  padding: 24px 22px;
  width: 100%;
  cursor: pointer;
}

.dropdowns-before-button {
  display: flex;
  flex-direction: row;

  .wrapper {
    margin: 0 8px 0 0;
  }
}

@media (max-width: 1440px) {
  .quicklinks-section {
    height: 250px;
  }
  .quicklink {
    height: 215px;

    ::v-deep .dropdowns-inline {
      .button {
        margin: 8px 0 0 0;
      }
    }
  }
}
@media (max-width: 1366px) {
}
@media (max-width: 1280px) {
}
@media (max-width: 1024px) {
  .quicklinks-section {
    height: 1040px;

    #ql2 {
      margin: 17px 0;
    }
  }
  .quicklink {
    height: 325px;

    .dropdowns-inline {
      flex-direction: column;
      width: 100%;

      ::v-deep {
        .btn {
          justify-content: center;
        }
        .wrapper .container {
          max-width: 100%;
        }
      }
    }

    ::v-deep {
      .title {
        --title-small-font-weight: bold;
        --title-small-font-size: 18px;
        --title-small-line-height: 23px;
      }
    }
  }

  .quicklinks-section {
    flex-direction: column;
    height: 100%;
  }

  .quicklinks-section .title {
    width: 100%;
  }

  .quicklink:nth-child(2) {
    width: 100%;
  }

  .block--content {
    width: auto;
  }

  .sell-btn {
    margin-top: 20px;
    --button-padding: auto !important;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .dropdowns-before-button {
    flex-direction: column;
    .wrapper {
      margin: 0 0 8px 0;
    }
  }
}
@media (max-width: 375px) {
}
</style>
