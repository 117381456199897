import { getPriceFormatted } from "../../utils/currency";
import Vue from "vue";
import {
  getVehicleByPlate,
  getBrands,
  getModels,
  getValuation,
  saveValuation,
} from "../../api/retake";
import i18n from "../../i18n";

const WEEKLY_RETAKE_LIMIT = -10;

const brandInitialState = [{ text: "Marca", value: -1, disabled: true }];
const modelsInitialState = [{ text: "Modelos", value: -1, disabled: true }];
const versionsInitialState = [{ text: "Versões", value: -1, disabled: true }];

const setDefaults = (commit) => {
  commit("setBcaId", "");
  commit("setModelsResponse", []);
  commit("setModel", "");
  commit("setModels", modelsInitialState);
  commit("setVersions", versionsInitialState);
  commit("setKilometersRetake", "");
  commit("setPlateDate", "");
  commit("setIsPrefill", false);
};

const state = {
  successfullPlateCall: false,
  prefill: false,
  plate: "",
  brandObj: {},
  brandId: "",
  brand: "",
  brands: brandInitialState,
  model: "",
  models: modelsInitialState,
  modelsResponse: [],
  version: "",
  versions: versionsInitialState,
  kilometers: "",
  plateDate: "",
  bcaId: "",
  valuationRequest: {
    plate: "",
    brand: "",
    model: "",
    version: "",
    kilometers: "",
    plateDate: "",
    bcaId: "",
  },
  tradeInId: "",
  valuation: 0.0,
  allVersions: [],
};

const selectFormatted = (arr) =>
  arr?.length > 0 ? arr.map((el) => ({ value: el, text: el })) : [];

const getters = {
  getPlate: (state) => state.plate,
  getBrand: (state) => state.brand,
  getBrands: (state) => state.brands,
  getModell: (state) => state.model,
  getModels: (state) => state.models,
  getVersion: (state) => state.version,
  getVersions: (state) => state.versions,
  getKilometersRetake: (state) => state.kilometers,
  getPlateDate: (state) => state.plateDate,
  getBcaId: (state) => state.bcaId,
  getValuationRequest: (state) => state.valuationRequest,
  getValuation: (state) =>
    getPriceFormatted(Number(state.valuation), {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
  getTradeInId: (state) => state.tradeInId,
  isPrefill: (state) => state.prefill,
  getRetakeValue: (state) => state.valuation,
  getRetakeObj: (state) => {
    const { model, valuation, plate } = state;
    return {
      model,
      value: valuation,
      registration: plate,
      expire: 2,
    };
  },
};

const actions = {
  storeModelAndVersions({ commit, state }, model) {
    commit("setModel", model);
    // Check wether plate API call was successfull, or versions were loaded seperately
    if (state.successfullPlateCall) {
      commit("matchVersionsByModel", model);
      return;
    }
    commit("loadVersionsByModel", model);
  },
  async storeVehicleByPlate({ commit, state }) {
    try {
      commit("spinnerModule/setLoading", true, { root: true }); // -> 'someMutation'
      setDefaults(commit);
      const res = await getVehicleByPlate(state.plate);

      const resBrands = await getBrands();
      commit("setBrands", resBrands.data.data);

      if (res.data.data.bcaId) {
        commit("setSuccessfullPlateCall", true);
        commit("setPrefillBrand", res.data.data.brand);
        commit("setBcaId", res.data.data.bcaId);
        commit("setModels", res.data.data.model);
        commit("setVersions", res.data.data.version);
        commit("setFormContent");
        commit("setAllVersions", res.data.data.version);
      }
    } catch (err) {
      console.error("Could not fetch vehicle details from backend");

      const resBrands = await getBrands();
      setDefaults(commit);
      commit("setBrands", resBrands.data.data);
    } finally {
      commit("spinnerModule/setLoading", false, { root: true }); // -> 'someMutation'
    }
  },
  async storeBrands({ commit }) {
    try {
      const res = await getBrands();
      setDefaults(commit);
      commit("setBrands", res.data.data);
    } catch (err) {
      console.error("Could not fetch brands from backend");
    }
  },
  async storeModels({ commit, state }) {
    try {
      const res = await getModels(
        state.brand.value,
        state.plateDate.split("-")[0]
      );
      commit("setModels", res.data.data);
      commit("setModelsResponse", res.data.data);
    } catch (err) {
      console.error("Could not fetch models from backend");
    }
  },
  storeValuation({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit("spinnerModule/setLoading", true, { root: true }); // -> 'someMutation'
      getValuation(state.valuationRequest)
        .then((response) => {
          if (!response.data) {
            reject(response.response.data);
          }
          commit("setValuation", response.data.data);
          Vue.$toast.open({
            message: i18n.t("toast.valuation.success.submited"),
            dismissible: true,
            type: "info",
          });
          resolve(response.data.data);
        })
        .catch((error) => {
          console.error("Could not fetch valuation from backend");
          if (error.code === WEEKLY_RETAKE_LIMIT) {
            Vue.$toast.open({
              message: i18n.t("toast.valuation.weeklyLimit"),
              dismissible: true,
              type: "info",
            });
          } else {
            Vue.$toast.open({
              message: i18n.t("toast.valuation.error.generic"),
              dismissible: true,
              type: "error",
            });
          }
          reject(error);
        })
        .finally(() => {
          commit("spinnerModule/setLoading", false, { root: true }); // -> 'someMutation'
        });
    });
  },
  async assignValuationToUser({ state }) {
    try {
      await saveValuation({ tradeInId: state.tradeInId });
    } catch (err) {
      console.error("Could not assign valuation to user");
    }
  },
};

const mutations = {
  // Parse payload of models?year=x
  loadVersionsByModel(state, model) {
    const versions = state.modelsResponse.filter((obj) => obj.model === model);
    state.versions = [
      ...versionsInitialState,
      ...versions.map((v) => ({ text: v.version, value: v.version })),
    ];
  },
  setSuccessfullPlateCall(state, val) {
    state.successfullPlateCall = val;
  },
  matchVersionsByModel(state, model) {
    state.versions = [
      ...versionsInitialState,
      ...selectFormatted(state.allVersions.filter((v) => v.includes(model))),
    ];
  },
  setAllVersions(state, versions) {
    state.allVersions = versions;
  },
  setPrefillBrand(state, [value]) {
    state.brands = state.brands
      .filter((it) => it.text === value)
      .map((it) => ({ ...it, selected: true }));

    state.brand = state.brands[0];
  },
  setFormContent(state) {
    state.prefill = true;
  },
  setPlate(state, plate) {
    state.plate = plate.toUpperCase();
  },
  setBrand(state, brand) {
    state.brand = brand;
  },
  setBrandId(state, brandId) {
    state.brandId = brandId;
  },
  setBrandById(state, id) {
    state.brand = state.brands.find((b) => b.value == id);
  },
  setBrands(state, brands) {
    state.brands = [
      ...brandInitialState,
      ...brands.map((el) => ({ value: el.id, text: el.description })),
    ];
  },
  setModel(state, model) {
    state.model = model;
  },
  setModelsResponse(state, response) {
    state.modelsResponse = response;
  },
  setModels(state, models) {
    let formattedModels;
    var uniqModel;
    if (models.length === 1) {
      formattedModels = selectFormatted(models);
    } else if (models.length > 1 && typeof models[0] == "string") {
      formattedModels = [...modelsInitialState, ...selectFormatted(models)];
    } else {
      uniqModel = Array.from(new Set(models.map((it) => it.model)));
      formattedModels = [
        ...modelsInitialState,
        ...uniqModel.map((el) => ({ value: el, text: el })),
      ];
    }

    state.models = formattedModels;
    state.model = state.models[0].value;
  },
  setVersion(state, version) {
    state.version = version;
  },
  setVersions(state, versions) {
    var formattedVersions = [];
    if (
      state.modelsResponse[0] instanceof Object &&
      state.modelsResponse[0].bcaId !== undefined &&
      state.model != ""
    ) {
      formattedVersions = state.modelsResponse
        .filter((el) => el.model == state.model)
        .map((el) => ({ value: el.bcaId, text: el.version }));
      state.bcaId = formattedVersions[0].value;
    } else if (typeof versions[0] == "string") {
      formattedVersions = selectFormatted(versions);
    }

    if (formattedVersions.length !== 1) {
      formattedVersions = [...versionsInitialState, ...formattedVersions];
    }

    state.versions = formattedVersions;
    state.version = state.versions[0].text;
  },
  setPlateDate(state, plateDate) {
    state.plateDate = plateDate;
  },
  setKilometersRetake(state, kilometers) {
    state.kilometers = kilometers;
  },
  setBcaId(state, bcaId) {
    state.bcaId = bcaId;
  },
  setValuation(state, valuationResponse) {
    state.valuation = valuationResponse.valuation;
    state.tradeInId = valuationResponse.tradeInId;
  },
  setValuationRequest(state, valuationRequest) {
    state.valuationRequest = valuationRequest;
  },
  setIsPrefill(state, prefill) {
    state.prefill = prefill;
  },
  resetRetake(state) {
    state.successfullPlateCall = false;
    state.prefill = false;
    state.plate = "";
    state.brandObj = {};
    state.brandId = "";
    state.brand = "";
    state.brands = brandInitialState;
    state.model = "";
    state.models = modelsInitialState;
    state.modelsResponse = [];
    state.version = "";
    state.versions = versionsInitialState;
    state.kilometers = "";
    state.plateDate = "";
    state.bcaId = "";
    state.valuationRequest = {
      plate: "",
      brand: "",
      model: "",
      version: "",
      kilometers: "",
      plateDate: "",
      bcaId: "",
    };
    state.tradeInId = "";
    state.valuation = 0.0;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
