/*
  Data Contract

  Dealer:
    {
      id: number,
      name: string,
      address: string,
      image: string,
      contact: {
        cellphone: string,
        phoen: string,
        email: string,
        schedule: string,
      },
      location: [lat, long]  // to be used in google maps and waze
    }
*/
import { getDealers } from "../../api/dealers";
import { dealerNameFormat } from "../../utils/helpers";

const validIDs = process.env.VUE_APP_CARPLUS_DEALERS_IDS.split(",").map(Number);

const initialState = () => ({
  dealers: [],
});

const getters = {
  getDealers: (state) => {
    return state.dealers.filter((it) => validIDs.includes(it.id));
  },
  getDealersDropdowns: (state) => {
    const dealers = state.dealers.map((it) => ({ key: it.id, value: it.name }));
    return dealers.filter((it) => validIDs.includes(it.key));
  },
};

const mutations = {
  setDealers(state, val) {
    let dealers = val;
    dealers = dealers.map((it) => {
      let dealer = it;
      dealer.name = dealerNameFormat(dealer.name);
      return dealer;
    });
    state.dealers = dealers;
  },
};

const actions = {
  async fetchDealers({ commit }) {
    try {
      const res = await getDealers();
      commit("setDealers", res.data.data);
    } catch (err) {
      console.error(err);
    }
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions,
};
