<template>
  <Page id="page">
    <template v-slot:content>
      <Section id="search-form">
        <CpSearchForm />
      </Section>
      <template v-if="getLoadingState" class="loading">
        <Button loading :border="false" />
      </template>
      <Section id="summary">
        <FilterCarProduct />
      </Section>
      <template v-if="getRecent && getRecent.length > 0">
        <SearchResults />
      </template>
      <template v-else>
        <SearchNoResults />
      </template>
      <TrustpilotCarousel />
      <SaveSearchPanel
        :open="isOpenSaveSearch"
        @onSaveSearch="saveSearch"
        @onClose="closeSaveSearchPanel"
        :title="$t('panels.saveSearch.title')"
        :subtitle="$t('panels.saveSearch.subtitle')"
        :description="$t('panels.saveSearch.description')"
        :button="$t('panels.saveSearch.button')"
      />
      <ContentBlock :content="handleContentBlock" html />
    </template>
  </Page>
</template>

<script>
import { Page } from "../../templates";
import { mapGetters, mapMutations } from "vuex";
import CpSearchForm from "../../refactor/CpSearchForm.vue";
import Section from "../../refactor/atoms/Section/Section.vue";
import SearchResults from "./SearchResults.vue";
import SearchNoResults from "./SearchNoResults.vue";
import TrustpilotCarousel from "../../refactor/TrustpilotCarousel.vue";
import { Button, SaveSearchPanel } from "@sc/ds-ui-component-library";
import { getDynamicRoutes } from "../../../api/dynamicSearch";
import ContentBlock from "../../organisms/ContentBlock/ContentBlock.vue";
import { PAGES } from "../../../enums/pages";
import FilterCarProduct from "../../organisms/FilterCarProduct/FilterCarProduct.vue";
import { saveSearchEvent } from "../../../utils/googleTagManager";

export default {
  components: {
    Button,
    Page,
    Section,
    CpSearchForm,
    SearchResults,
    SearchNoResults,
    TrustpilotCarousel,
    SaveSearchPanel,
    ContentBlock,
    FilterCarProduct,
  },
  metaInfo() {
    return {
      title: this.metadataObj?.title,
      meta: [
        { charset: "utf-8" },
        {
          vmid: "description",
          name: "description",
          content: this.metadataObj?.description,
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: this.metadataObj?.keywords,
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.metadataObj?.title,
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.metadataObj?.description,
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: window.location.href,
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: this.metadataObj?.image,
        },
      ],
    };
  },
  data() {
    return {
      needle: "",
      metadataObj: {
        title: this.$t("meta.search.title"),
        description: this.$t("meta.search.description"),
        keywords: this.$t("meta.search.keywords"),
        image: this.$t("meta.search.image"),
      },
      usedDynamic: false,
    };
  },
  props: {
    quickSearchFilter: {
      type: Object,
    },
    tags: {
      type: Array[Object],
      default: () => [],
    },
    dropdowns: {
      type: Object,
      default: () => {},
    },
    contentBlock: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters(["getLoadingState"]),
    ...mapGetters({
      getRecent: "SearchModule/getRecent",
      isOpenSaveSearch: "SearchModule/isOpenSaveSearch",
      getTagGroups: "SearchModule/dropdown/getTagGroups",
      getDropdowns: "SearchModule/dropdown/getDropdowns",
      getKey: "SearchModule/dropdown/getKey",
      fromLogin: "SearchModule/filter/getFromLogin",
      saveTags: "SearchModule/filter/getSaveTags",
      getTags: "SearchModule/filter/getTags",
    }),
    handleContentBlock() {
      return this.contentBlock;
    },
  },
  methods: {
    ...mapMutations({
      setAlert: "setAlert",
      toggleCarAlert: "PanelModule/toggleCarAlert",
    }),
    closeSaveSearchPanel() {
      this.$store.commit("SearchModule/setOpenSaveSearch");
    },
    saveSearch(e) {
      this.$store.dispatch("SearchModule/requestSaveSearch", e);
      this.$store.commit("SearchModule/setOpenSaveSearch");
      this.setAlert({
        message: this.$t("toast.saveSearch.success"),
        type: "info",
        display: true,
        global: true,
      });

      saveSearchEvent(this.getTags);
    },
    dropdownSelection(itemProps, dropdowns) {
      Object.entries(itemProps).forEach(([key, value]) => {
        if (value) {
          dropdowns[key === "brand" ? "brands" : key]?.forEach((item) => {
            if (itemProps[key]?.map((v) => Number(v)).includes(item.key)) {
              item.selected = true;
            }
          });
          if (key === "model") {
            dropdowns["brands"]?.forEach((item) => {
              item.children.forEach((x) => {
                if (
                  itemProps["model"].find((v) => Number(v) == x.key) &&
                  itemProps.brands.includes(item.key)
                ) {
                  x.selected = true;
                  item.selected = true;
                }
              });
            });
          }
        }
      });
      return dropdowns;
    },
    getDynamicTagsArray(dropdowns) {
      let dynamicTags = [];
      Object.entries(dropdowns).forEach(([key, value]) => {
        value?.forEach((item) => {
          if (item.selected) {
            if (key == "brands") {
              item.children?.forEach((x) => {
                if (x.selected) {
                  dynamicTags.push({
                    id: x.key,
                    value: x.value,
                    type: "model",
                    parent: { ...item, type: key },
                    children: [],
                  });
                }
              });
              dynamicTags.push({
                id: item.key,
                value: item.value,
                type: key,
                children: item.children,
              });
            } else {
              dynamicTags.push({
                id: item.key,
                value: item.value,
                type: key,
              });
            }
          }
        });
      });
      return dynamicTags;
    },
    async handleDynamicRoute() {
      this.usedDynamic = false;
      if (this.$route.name === PAGES.SEARCH_PAGE_CUSTOM_ROUTE)
        try {
          const currentRoute = this.$router.currentRoute.path;
          const { data } = await getDynamicRoutes(currentRoute);
          if (data.data.length > 0 && data.data.length < 2) {
            let dynamicTags = [];
            let content = "";
            let dropdowns = {};
            let needleTag = "";
            let sitemap = false;
            data.data.forEach((item) => {
              if (item.internalRoute === currentRoute) {
                content = item.content;
                sitemap = item.addToSitemap;
                var needle = "";
                var searchBody = "";
                if (item.props) {
                  searchBody = item.props;
                  needle = item.props.needle;
                } else {
                  const parsedBody = JSON.parse(item.searchBody);
                  searchBody = parsedBody.filters;
                  needle = parsedBody.needle;
                }
                for (const [key, value] of Object.entries(searchBody)) {
                  key;
                  if (value) {
                    dropdowns = this.dropdownSelection(
                      searchBody,
                      this.getDropdowns
                    );
                  }
                }
                dynamicTags = this.getDynamicTagsArray(dropdowns);

                if (item?.metaData) {
                  this.metadataObj = item?.metaData;
                }

                if (searchBody.lowCost) {
                  dynamicTags.push({
                    type: "lowCost",
                    id: Number(searchBody.lowCost[0]),
                    value: "Low Cost",
                  });
                }

                if (needle) {
                  needleTag = needle;
                  this.$store.commit(
                    "SearchModule/filter/setNeedle",
                    needleTag
                  );
                  dynamicTags.push({
                    type: "needle",
                    id: 0,
                    value: needleTag,
                  });
                }
              }
            });

            this.contentBlock = content;

            if (!sitemap) {
              const canonicalLinks = document.head.querySelectorAll("link");
              if (canonicalLinks.length)
                canonicalLinks.forEach((link) => {
                  if (link.rel === "canonical") link.remove();
                });
            }
            this.$store.commit("SearchModule/filter/setTags", [
              ...this.tags,
              ...dynamicTags,
            ]);

            this.usedDynamic = true;
          } else {
            throw "Route not found!";
          }
        } catch (e) {
          console.error(e);
          this.$router.push({
            name: PAGES.ERROR,
          });
        }
    },
  },
  async beforeMount() {},
  async mounted() {
    this.$store.commit("spinnerModule/setLoading", true);
    await this.handleDynamicRoute();
    this.$store.commit("SearchModule/autocomplete/setResults", []);
    this.$store.dispatch("SearchModule/fetchRecent", {
      quickSearchItem: this.quickSearchFilter,
      dynamic: this.usedDynamic,
    });

    if (this.getTags.length === 0 && !this.saveTags) {
      this.$store.dispatch("SearchModule/filter/cleanAllTags");
      this.$store.commit("SearchModule/filter/setNeedle", "");
      await this.$store.dispatch("SearchModule/dropdown/resetDropdowns");
      await this.$store.dispatch("SearchModule/dropdown/fetchDropdowns");
    }

    if (this.saveTags) {
      this.$store.commit("SearchModule/filter/setSaveTags", false);
    }

    if (this.fromLogin) {
      this.$store.commit("SearchModule/setOpenSaveSearch");
      this.$store.commit("SearchModule/filter/setFromLogin", false);
    }

    if (this.dropdowns) {
      Object.entries(this.dropdowns).forEach(([key, value]) => {
        this.$store.commit("SearchModule/dropdown/setDropdownParent", {
          dropdown: key,
          val: value,
        });
      });
    }

    if (this.tags.length > 0 && !this.usedDynamic) {
      this.$store.commit("SearchModule/filter/setTags", this.tags);
      this.$store.commit("SearchModule/pagination/setCurrent", 1);
      this.$store.dispatch("SearchModule/fetchRecent", {
        quickSearchItem: [],
        dynamic: false,
      });
    }

    this.$store.commit("spinnerModule/setLoading", false);

    this.$meta().refresh();
    this.usedDynamic = false;
  },
};
</script>

<style lang="scss" scoped>
#page {
  --section-padding: 1rem;
}

::v-deep .section {
  overflow: visible;
  --section-bg-color: none;
}

#search-form {
  padding-top: 18px;
  padding-bottom: 0;
  border-top: 1px solid #eaeaea;
}

#page {
  background-color: var(--color-7);
}

#section-carousel {
  --section-bg-color: var(--color-7);
}
.content-block {
  padding: 1rem 3rem;
  padding-bottom: 3rem;
}

#summary {
  padding-top: 16px;
  padding-bottom: 28px;
}

@media (min-width: 768px) {
  #page {
    --section-padding: 4rem;
  }
  .content-block {
    padding: 1rem 15rem;
    padding-bottom: 5rem;
  }
}

::v-deep .content-block .heading.h1 {
  color: rgba(2, 34, 111, 1);
  margin-bottom: 2.75rem;
  --heading-xlarge-font-size: 21px;
  --heading-xlarge-line-height: 21px;
  --heading-weight-bold: 500;
}

::v-deep .content-block .heading.h2 {
  color: rgba(91, 141, 244, 1);
  margin-bottom: 1.5rem;
  --heading-large-font-size: 19px;
  --heading-large-line-height: 21px;
  --heading-weight-regular: 400;
}

::v-deep .content-block .heading.h3 {
  color: rgba(91, 141, 244, 1);
  --heading-medium-font-size: 16px;
  --heading-medium-line-height: 24px;
  --heading-weight-regular: 400;
}

@media (max-width: 1920px) {
}

@media (max-width: 1440px) {
  .content-block {
    padding: 1rem 52px;
    margin-top: 0rem;
  }
  #search-form {
    --section-padding: 52px;
  }

  #section-carousel {
    --section-padding: 52px;
    --heading-weight-bold: 700;
    --heading-large-font-size: 28px;
    --heading-large-line-height: 36px;
    gap: 28px;

    ::v-deep .carousel {
      gap: 28px;
    }
  }
  #summary {
    --section-padding: 52px;
  }
}
@media (max-width: 1366px) {
  .content-block {
    padding: 1rem 40px;
    margin-top: 0rem;
  }
  #search-form {
    --section-padding: 40px;
  }

  #section-carousel {
    --section-padding: 40px;
    --title-weight-bold: 700;
    --title-font-size: 28px;
    --title-line-height: 36px;
    gap: 28px;

    ::v-deep .carousel {
      gap: 28px;
    }
  }
  #summary {
    --section-padding: 40px;
  }
}
@media (max-width: 1280px) {
  .content-block {
    padding: 1rem 28px;
    margin-top: 0rem;
  }

  #search-form {
    --section-padding: 28px;
  }

  #section-carousel {
    --section-padding: 28px;
    --title-weight-bold: 700;
    --title-font-size: 28px;
    --title-line-height: 36px;
    gap: 28px;

    ::v-deep .carousel {
      gap: 28px;
    }
  }

  #summary {
    --section-padding: 28px;
  }
}
@media (max-width: 1024px) {
}

@media (max-width: 768px) {
}
</style>
