<template>
  <Block class="section-12-block section-12-block-1">
    <TextLabel
      class="block-title"
      :label="$t('pages.homepage.sections.contactsSection.contacts.title')"
    />
    <div class="section-12-block-1-links">
      <CaptionIcon
        class="caption"
        :label="$t('pages.homepage.sections.contactsSection.contacts.links.1')"
        icon="phone"
        :iconSize="40"
        href="#"
        @click.native="emitOpenLeadContact"
      />
      <TextLabel
        :label="
          $t('pages.homepage.sections.contactsSection.contacts.conjuction')
        "
        class="conjuction"
      />
      <CaptionIcon
        class="caption"
        :label="$t('pages.homepage.sections.contactsSection.contacts.links.2')"
        icon="message"
        :iconSize="40"
        href="#"
        @click.native="emitOpenLead"
      />
    </div>
  </Block>
</template>

<script>
import { Block, TextLabel, CaptionIcon } from "@sc/ds-ui-component-library";

export default {
  name: "CpContactBlock",
  components: { Block, TextLabel, CaptionIcon },
  props: {},
  methods: {
    emitOpenLeadContact() {
      this.$emit("openLeadContact");
    },
    emitOpenLead() {
      this.$emit("openLead");
    },
  },
};
</script>

<style lang="scss" scoped>
.section-12-block-1 {
  background-color: #fbfbfb;
}

.conjuction {
  --text-label-word-break: normal;
}

.section-12-block-1-links {
  display: flex;
  align-items: center;
  margin-top: 70px;

  .conjuction {
    margin: 0 24px;
  }

  .caption {
    text-decoration: underline;
    text-decoration-color: #6497ff;
    --caption-icon-gap: 0;

    ::v-deep .icon {
      margin: 0 16px 0 0;
    }
  }
}

.block-title {
  --text-label-font-size: 28px;
  --text-label-weight: 800;
  --text-label-color: #333333;
}

@media (max-width: 1920px) {
}

@media (max-width: 1440px) {
}

@media (max-width: 1366px) {
}
@media (max-width: 1280px) {
}
@media (max-width: 1024px) {
}

@media (max-width: 768px) {
  .section-12-block-1-links {
    margin-top: 30px;
    align-content: flex-start;
    align-items: flex-start;

    .caption:first-child {
      margin: 0 10px 0 0;
    }

    ::v-deep .text-label {
      display: none;
    }
    flex-direction: column;
  }
  ::v-deep h1 {
    --title-weight-bold: bold;
    --title-small-font-size: 18px;
    --title-small-line-height: 23px;
  }
}
</style>
