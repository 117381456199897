<template>
  <div class="autocomplete-dropdown" ref="autocomplete">
    <Input
      :customClass="inputCustomClass"
      inputType="text"
      name="search"
      :type="type"
      :placeholder="placeholder"
      :border="true"
      :value.sync="inputValue"
      @onInput="filterResults"
      @onFocus="filterResults(inputValue)"
      :autocomplete="false"
      @keyup.enter.native="setMySearch"
      label=""
      :key="inputKey"
    />
    <div
      v-if="getResults && dropdown"
      :class="['autocomplete-dropdown--results', Dropdown]"
      ref="searchDropdown"
    >
      <ul>
        <li v-if="loading">
          <TextLabel :color="colorResults" label="A pesquisar..." />
        </li>
        <!-- <li v-else-if="!getResults.length">
          <TextLabel :color="colorResults" label="Não existem resultados." />
        </li> -->
        <li
          v-else
          v-for="(r, index) in getResults"
          :key="`${index}-${r.title}`"
          @click="dropdownClick(r.value)"
        >
          <span class="needle-input">{{ inputValue }}</span>
          <span class="suggestion-input">{{
            r.title.toLowerCase().replace(inputValue.toLowerCase(), "")
          }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { TextLabel, Input } from "@sc/ds-ui-component-library";
import axios from "axios";
function debounce(f, delay) {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    const args = arguments;
    const ctx = this;
    timeoutID = setTimeout(function () {
      f.apply(ctx, args);
    }, delay);
  };
}
let source;
export default {
  name: "AutocompleteDropdown",
  components: {
    Input,
    TextLabel,
  },
  data() {
    return {
      dropdown: false,
      mySearch: "",
      inputKey: 0,
      inputValue: "",
    };
  },
  props: {
    inputCustomClass: {
      type: String,
    },
    type: {
      type: String,
      default: "tertiary",
      validator: (type) => {
        return ["primary", "secondary", "tertiary"].includes(type);
      },
    },
    placeholder: {
      type: String,
      default: "Pesquise por modelo, segmento ou palavra-chave...",
    },
    results: {
      type: Array[Object],
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    getNeedle(value) {
      this.inputValue = value;
      this.inputKey += 1;
    },
  },
  mounted() {
    document.addEventListener("click", this.onClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.onClickOutside);
  },
  computed: {
    ...mapGetters({
      getResults: "SearchModule/autocomplete/getResults",
      getDropdowns: "SearchModule/dropdown/getDropdowns",
      getNeedle: "SearchModule/filter/getNeedle",
    }),
    Dropdown() {
      return {
        "autocomplete-dropdown--primary": this.type === "primary",
        "autocomplete-dropdown--secondary": this.type === "secondary",
        "autocomplete-dropdown--tertiary": this.type === "tertiary",
      };
    },
    colorResults() {
      return this.type === "secondary" ? "secondary" : "primary";
    },
  },
  methods: {
    filterResults: debounce(function (e) {
      this.inputValue = e;
      this.$emit("onChange", e);
      if (source) source.cancel("Operation canceled by user input.");
      source = axios.CancelToken.source();
      this.$store.commit("SearchModule/autocomplete/setResults", []);
      if (e) {
        this.$store.dispatch("SearchModule/autocomplete/storeResults", {
          word: e,
          source,
        });
        if (e.length < 1) {
          this.dropdown = false;
        } else {
          this.dropdown = true;
          this.$emit("onSearch", e);
        }
      }
    }, 200),

    dropdownClick(value) {
      this.inputValue = value;
      this.$emit("onChange", value);
      this.setMySearch(value);
    },
    setMySearch(value = this.inputValue) {
      const tag = {
        type: "needle",
        id: 0,
        value: value,
      };
      this.$store.dispatch("SearchModule/filter/addOrReplaceTag", tag);
      this.$store.commit("SearchModule/filter/setNeedle", value);
      this.dropdown = false;
      this.$emit("onSubmitSearch");
    },
    onClickOutside(event) {
      const { searchDropdown, autocomplete } = this.$refs;
      const clickInsideAutocomplete = autocomplete.contains(event.target);
      if (!searchDropdown) {
        if (clickInsideAutocomplete && this.getNeedle !== "") {
          this.dropdown = true;
        }
      }
      const clickInsideDropdown = searchDropdown?.contains(event.target);
      if (!clickInsideDropdown && !clickInsideAutocomplete) {
        this.dropdown = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.autocomplete-dropdown {
  position: relative;

  ::v-deep {
    --baseinput-padding-top: 0px;
    --baseinput-padding-bottom: 0px;
  }
}
.autocomplete-dropdown ::v-deep .container {
  max-width: 100%;
}

.autocomplete-dropdown--results {
  background-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  position: absolute;
  width: 100%;
  z-index: 10;
  max-height: 170px;
  overflow: auto;
}

@media (max-width: 1024px) {
  .autocomplete-dropdown--results {
    max-height: 195px;
  }
}

.autocomplete-dropdown--results li {
  padding-left: 0.5rem /* 8px */;
  padding-right: 0.5rem /* 8px */;
  padding-top: 0.25rem /* 4px */;
  padding-bottom: 0.25rem /* 4px */;
}

.autocomplete-dropdown--results li:hover {
  background-color: #f5f7f9;
  cursor: pointer;
}

.autocomplete-dropdown--primary {
  border-color: rgba(2, 34, 111, 1);
}

.autocomplete-dropdown--secondary {
  border-color: rgba(15, 83, 251, 1);
}

.autocomplete-dropdown--tertiary {
  border-color: rgba(156, 163, 175, 1);
}

.needle-input {
  color: var(--text-label-color);
}

.suggestion-input {
  color: var(--color-1);
  font-weight: 600;
}
</style>
