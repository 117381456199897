<template>
  <Section id="section-carousel">
    <WidgetMini
      businessunitId="618d0f0432485112032d0938"
      href="https://pt.trustpilot.com/review/carplus.pt"
    />
    <div class="carousel">
      <Heading class="header" label="Opinião dos nossos clientes" tag="h2" />
      <WidgetCarousel
        businessunitId="618d0f0432485112032d0938"
        href="https://pt.trustpilot.com/review/carplus.pt"
      />
    </div>
  </Section>
</template>

<script>
import {
  Section,
  WidgetCarousel,
  WidgetMini,
  Heading,
} from "@sc/ds-ui-component-library";

export default {
  name: "TrustpilotCarousel",
  components: {
    Section,
    WidgetCarousel,
    WidgetMini,
    Heading,
  },
};
</script>

<style lang="scss" scoped>
#section-carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
  --heading-color: var(--color-1);
  --section-bg-color: var(--color-6);
  --section-padding: 64px 16px;
  text-align: center;

  .trustpilot-widget {
    width: 100%;
  }

  .carousel {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 64px;
    width: 100%;

    &:before {
      content: "";
      height: 1px;
      width: 90%;
      background-color: var(--color-4);
    }
  }
}
</style>
