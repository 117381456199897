<template>
  <div class="comparator">
    <div class="comparator--header">
      <div class="comparator--header-title">
        <Heading :label="$t('pages.ComparatorPage.title')" tag="h1"></Heading>
      </div>
      <div class="descriptionText">
        <Label size="small" color="tertiary" bold="normal">{{
          $t("pages.ComparatorPage.message1")
        }}</Label>
        <Icon class="icon-compare" size="24" icon="compare" />
        <Label size="small" color="tertiary" bold="normal">{{
          $t("pages.ComparatorPage.message2")
        }}</Label>
      </div>
      <div class="comparator--header-subtitle">
        <Label size="small" color="tertiary" bold="normal">{{
          $t("pages.ComparatorPage.subtitle", {
            number: compareCount,
          })
        }}</Label>
      </div>
      <div class="comparator--header-button">
        <Button
          v-if="!empty"
          :label="differencesButtonLabel"
          @onClick="clickShowDifferences"
        />
      </div>
    </div>
    <div class="comparator--cars">
      <div class="comparator--cars-car" v-for="v in compareList" :key="v.vin">
        <CpVehicleCard :v="v" />
        <Button
          customClass="detailBtn"
          :label="$t('pages.ComparatorPage.buttons.knowMore')"
          @onClick="clickInterest(v)"
        />
        <div v-if="v">
          <div v-if="!showDifferences" class="comparator--cars-specifications">
            <div class="comparator--specifications-title">
              <Label color="primary">{{
                $t("pages.ComparatorPage.specifications")
              }}</Label>
            </div>
            <div
              class="comparator--specifications-list-item"
              v-for="(specification, s) in v.specifications"
              :key="s"
            >
              <div>
                <Label
                  class="comparator--specifications-list-title"
                  color="primary"
                >
                  {{
                    specification.id
                      ? specification.title
                      : $t(
                          "pages.detailsPage.details.vehicleSpecifications." +
                            specification.title
                        )
                  }}</Label
                >
              </div>
              <div>
                <Label
                  class="comparator--specifications-list-title"
                  color="tertiary"
                  >{{
                    parseDesc(specification.title, specification.description)
                  }}</Label
                >
              </div>
            </div>
          </div>
          <div v-if="!showDifferences" class="comparator--cars-equipments">
            <div class="comparator--specifications-title">
              <Label color="primary">{{
                $t("pages.ComparatorPage.equipments")
              }}</Label>
            </div>
            <div
              class="comparator--specifications-list-item"
              v-for="(equipment, e) in v.equipments"
              :key="e"
            >
              <div>
                <Label
                  class="comparator--specifications-list-title"
                  color="tertiary"
                >
                  · {{ equipment }}</Label
                >
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="showDifferences && v.specificationsDifferences.length > 0"
          class="comparator--cars-specifications"
        >
          <div class="comparator--specifications-title">
            <Label color="primary">{{
              $t("pages.ComparatorPage.specifications")
            }}</Label>
          </div>
          <div
            class="comparator--specifications-list-item"
            v-for="(specification, s) in v.specificationsDifferences"
            :key="s"
          >
            <div>
              <Label
                class="comparator--specifications-list-title"
                color="primary"
              >
                {{
                  specification.id
                    ? specification.title
                    : $t(
                        "pages.detailsPage.details.vehicleSpecifications." +
                          specification.title
                      )
                }}</Label
              >
            </div>
            <div>
              <Label
                class="comparator--specifications-list-title"
                color="tertiary"
                >{{
                  parseDesc(specification.title, specification.description)
                }}</Label
              >
            </div>
          </div>
        </div>
        <div
          v-if="showDifferences && v.equipmentsDifferences.length > 0"
          class="comparator--cars-equipments"
        >
          <div class="comparator--specifications-title">
            <Label color="primary">{{
              $t("pages.ComparatorPage.equipments")
            }}</Label>
          </div>
          <div
            class="comparator--specifications-list-item"
            v-for="(equipment, e) in v.equipmentsDifferences"
            :key="e"
          >
            <div>
              <Label
                class="comparator--specifications-list-title"
                color="tertiary"
              >
                · {{ equipment }}</Label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Button, Heading, Icon } from "@sc/ds-ui-component-library";
import { mapGetters } from "vuex";
import { Label } from "../../atoms";
import CpVehicleCard from "../../refactor/CpVehicleCard.vue";
import { getPriceFormatted } from "../../../utils/currency.js";
import { PAGES } from "../../../enums/pages";
const SPECS_WITH_UNITS = ["power", "consumption"];

export default {
  name: "Comparator",
  components: {
    Label,
    CpVehicleCard,
    Button,
    Heading,
    Icon,
  },
  data() {
    return {
      showDifferences: false,
    };
  },
  computed: {
    ...mapGetters({
      empty: "CompareModule/empty",
      compareCount: "CompareModule/count",
      compareList: "CompareModule/list",
      numberOfDifferences: "CompareModule/numberOfDifferences",
    }),
    differencesButtonLabel() {
      return this.showDifferences
        ? this.$t("pages.ComparatorPage.buttons.showAll")
        : this.$tc(
            "pages.ComparatorPage.buttons.showDifferences",
            this.numberOfDifferences,
            {
              numberOfDifferences: this.numberOfDifferences,
            }
          );
    },
  },
  methods: {
    getPriceFormatted,
    clickShowDifferences() {
      this.showDifferences = !this.showDifferences;
      if (this.showDifferences) {
        const element = document.querySelector(
          ".comparator--cars-specifications"
        );
        element?.scrollIntoView();
      }
    },
    clickInterest(vehicle) {
      this.$router.push({
        name: PAGES.DETAILS,
        query: {
          fid: vehicle?.financeId,
        },
        params: {
          id: vehicle?.vin,
        },
      });
      this.$store.dispatch("storeVehicle", vehicle);
    },
    parseDesc(title, desc) {
      var obj = {};
      obj[title] = desc;
      return SPECS_WITH_UNITS.includes(title)
        ? this.$t(
            `pages.detailsPage.details.vehicleSpecifications.units.${title}`,
            obj
          )
        : desc;
    },
  },
  async mounted() {
    this.$store.commit("spinnerModule/setLoading", true, { root: true });
    await this.$store.dispatch("CompareModule/fetchVehicles");
    this.$store.commit("spinnerModule/setLoading", false, { root: true });
  },
};
</script>

<style lang="scss">
.detailBtn {
  width: 100%;
  --button-font-weight: 500;
  --button-display: inline-block;
}

.comparator--header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.comparator--header-title .label {
  font-size: 40px;
  line-height: 52px;
}

.comparator--header-subtitle {
  margin-top: 1.25rem;
}

.comparator--header-subtitle .label {
  font-size: 21px;
}

.comparator--header-button {
  margin-top: 1rem;
  --button-font-weight: 500;
  --button-display: inline-block;
}

.comparator--header-button .button {
  width: 100%;
  height: 60px;
}

.comparator--cars {
  margin-top: 2rem;
}

@media (min-width: 768px) {
  .comparator--cars {
    display: flex;
    flex-wrap: wrap;
  }

  .comparator--cars-car {
    width: 432px;
    margin-right: 1rem /* 16px */;
    margin-bottom: 2rem /* 32px */;
  }
}

.comparator--cars-car .button {
  width: 100%;
  margin-top: 1rem;
  height: 70px;
}

.comparator--specifications {
  margin-top: 2rem;
}

.comparator--specifications-title .label {
  font-size: 24px;
}

.comparator--specifications-title {
  border-bottom: 2px solid #e4ecf0;
  padding-bottom: 1rem /* 16px */;
  margin-bottom: 1rem /* 16px */;
}

.comparator--specifications-list-item {
  div:first-child .label {
    font-size: 19px;
    line-height: 21px;
    color: #333333;
  }
  div:last-child .label {
    font-size: 16px;
    line-height: 18px;
    color: #808080;
  }
}

.comparator--specifications {
  width: 100%;
}

.comparator--specifications-list {
  display: flex;
}

.comparator--specifications-list-item {
  margin-bottom: 1rem /* 16px */;
}

.comparator--cars-specifications {
  margin-top: 2rem /* 32px */;
}

.comparator--cars-equipments {
  margin-top: 3.5rem /* 56px */;
}

::v-deep .vehicleInfo--container {
  margin-top: 18px;
}

.right-sidebar ::v-deep .right-sidebar--content {
  display: block;
}

.interested--price-model-details {
  border-bottom: 2px solid #e5ecf0;
  padding-bottom: 5rem /* 80px */;
}

.interested--title {
  border-bottom: 2px solid #e5ecf0;
  padding-top: 0.5rem /* 8px */;
  padding-bottom: 1.25rem /* 20px */;
}

.accordion ::v-deep .accordion-item {
  padding-left: 0px;
}

.interested--footer {
  display: flex;
  bottom: 0px;
  position: absolute;
  margin-bottom: 1rem /* 16px */;
  margin: auto;
  justify-content: space-between;
}

.interested--footer-test-drive .button {
  height: 56px;
}

.interested--footer-reserve .button {
  height: 56px;
}

::v-deep .right-sidebar--footer {
  position: relative;
  width: 100%;
  margin-top: auto;
}

::v-deep .right-sidebar--footer .button {
  height: 56px;
}

.reserve {
  margin-top: 1rem /* 16px */;
}

::v-deep .accordion-item--icon-container svg {
  color: rgba(15, 83, 251, 1);
}

::v-deep .right-sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
}

::v-deep .title--content-title {
  font-size: 24px;
  line-height: 24px;
}

::v-deep .title--content-subtitle {
  font-size: 20px;
  line-height: 20px;
}

::v-deep .finance--container-title {
  font-size: 24px;
  line-height: 24px;
}

::v-deep .finance--container-subtitle {
  font-size: 20px;
  line-height: 20px;
}

::v-deep .default-car-image {
  margin: 0 auto;
}

::v-deep .details-container {
  width: 100%;
}

.comparator--header-title {
  --heading-xlarge-font-size: 56px;
  --heading-xlarge-font-height: 73px;
  --heading-xlarge-weight: 700;
}

.icon-compare {
  display: inline-block;
}

.descriptionText {
  margin-top: 16px;
}

.descriptionText .label {
  font-size: 21px;
  vertical-align: super;
  color: var(--color-2);
}

@media (max-width: 1680px) {
  .comparator--header-title {
    --heading-xlarge-font-size: 38px;
    --heading-xlarge-font-height: 49x;
    --heading-xlarge-weight: 700;
  }
}

@media (min-width: 1536px) {
  .comparator--cars-car {
    margin-bottom: 0px;
  }
}

@media (min-width: 1024px) {
  ::v-deep .right-sidebar--footer {
    display: flex;
    justify-content: space-between;
  }
  .reserve {
    margin-left: 1rem /* 16px */;
    margin-top: 0px;
  }
}
</style>
