<template>
  <div :class="['section-header', customClass]">
    <Title v-if="title" :label="title" />
    <TextLabel v-if="subtitle" :label="subtitle" />
    <slot />
  </div>
</template>

<script>
import { TextLabel } from "../../atoms";

export default {
  name: "SectionHeader",
  components: {
    TextLabel,
  },
  props: {
    customClass: {
      type: String,
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.section-header {
  width: var(--section-header-width);
  text-align: var(--section-header-text-align);
  margin: var(--section-header-margin);
  padding: var(--section-header-padding);
}
</style>
