export const Status = {
  NONE: 0,
  RESERVE: 1,
  TEST_DRIVE: 2,
  CONTACT: 3,
  PRICE_DROP: 4,
};

const state = {
  tradein: false,
  tradeInStep: 1,
  reserve: false,
  reserveStep: 1,
  carAlert: false,
  detailPageStatus: Status.NONE,
  detailPageComesFromReserve: {
    check: false,
    vehicle: {},
  },
  onEditing: false,
};

const getters = {
  isOpenTradein: (state) => state.tradein,
  getTradeInStep: (state) => state.tradeInStep,
  isOpenReserve: (state) => state.reserve,
  getReserveStep: (state) => state.reserveStep,
  isOpenCarAlert: (state) => state.carAlert,
  getDetailPageStatus: (state) => state.detailPageStatus,
  getDetailPageComesFromReserve: (state) => state.detailPageComesFromReserve,
  getOnEditing: (state) => state.onEditing,
};

const mutations = {
  toggleTradein(state) {
    state.tradein = !state.tradein;
  },
  toggleReserve(state) {
    state.reserve = !state.reserve;
  },
  toggleCarAlert(state) {
    state.carAlert = !state.carAlert;
  },
  setDetailPageStatus(state, val) {
    state.detailPageStatus = val;
  },
  setOnEditing(state, val) {
    state.onEditing = val;
  },
  setDetailPageComesFromReserve(state, val) {
    state.detailPageComesFromReserve = val;
  },
  setTradeinStep(state, val) {
    state.tradeInStep = val;
  },
  setReserveStep(state, val) {
    state.reserveStep = val;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
