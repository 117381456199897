const state = {
  responsivenessStatus: {
    windowWidth: window.innerWidth,
    maxMobileWidth: 375,
    maxTabletWidth: 768,
    maxTabletPlusWidth: 1024,
    isMobile: window.innerWidth <= 375,
    isTablet: window.innerWidth > 375 && window.innerWidth <= 768,
    isTabletPlus: window.innerWidth > 768 && window.innerWidth <= 1024,
  },
};

const getters = {
  windowWidth: (state) => state.responsivenessStatus.windowWidth,
  maxMobileWidth: (state) => state.responsivenessStatus.maxMobileWidth,
  maxTabletWidth: (state) => state.responsivenessStatus.maxTabletWidth,
  maxTabletPlusWidth: (state) => state.responsivenessStatus.maxTabletPlusWidth,
  isMobile: (state) => state.responsivenessStatus.isMobile,
  isTablet: (state) => state.responsivenessStatus.isTablet,
  isTablePlus: (state) => state.responsivenessStatus.isTabletPlus,
};

const mutations = {
  setReponsivenessStatus(state, status) {
    state.responsivenessStatus.windowWidth = status.windowWidth;
    state.responsivenessStatus.isMobile =
      status.windowWidth <= state.responsivenessStatus.maxMobileWidth;
    state.responsivenessStatus.isTablet =
      status.windowWidth <= state.responsivenessStatus.maxTabletWidth;
    state.responsivenessStatus.isTabletPlus =
      status.windowWidth <= state.responsivenessStatus.maxTabletPlusWidth;
  },
  clearResponsivenessStatus(state) {
    state.responsivenessStatus = {
      windowWidth: window.innerWidth,
      maxMobileWidth: 375,
      maxTabletWidth: 768,
      maxTabletPlusWidth: 1024,
      isMobile: window.innerWidth <= state.maxMobileWidth,
      isTablet: window.innerWidth <= state.maxTabletWidth,
      isTabletPlus: window.innerWidth <= state.maxTabletPlusWidth,
    };
  },
};

export default {
  state,
  getters,
  mutations,
};
