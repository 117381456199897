import user from "../common/user";
import payment from "../common/payment";
import vehicle from "../common/vehicle";
import {
  getPriceFormatted,
  formatNonCurrency,
  getPriceFormattedNoDecimal,
} from "../../../utils/currency";
import {
  getAllReservations,
  createPaymentIntent,
} from "../../../api/reservations";
import i18n from "../../../i18n";

function formatTooltip(finance, vehicle, tooltipDescription) {
  return i18n.t("pages.detailsPage.tooltip", {
    pricePvp: getPriceFormatted(finance.pricePvp),
    minDeposit: getPriceFormatted(finance.depositValue),
    capitalToFinance: getPriceFormatted(finance.financedValue),
    tan: formatNonCurrency(tooltipDescription?.tan),
    taeg: formatNonCurrency(tooltipDescription?.taeg, 1, 1, true),
    mtic: getPriceFormatted(tooltipDescription?.mtic),
    monthlyValue: getPriceFormatted(finance.monthlyValue),
    numMonths: finance.nbrMonths,
    brand: vehicle.brand,
    commercialDescription: vehicle.commercialDescription,
    model: vehicle.model,
    totalPrice: getPriceFormatted(vehicle.totalPrice),
    consumption: tooltipDescription?.consumptionCombine,
    emissions: tooltipDescription?.emissions,
    initialExpenses: getPriceFormatted(tooltipDescription?.initialExpenses),
    lastFee: getPriceFormatted(tooltipDescription?.lastFee) || "--",
    dtEndOfCampaign: tooltipDescription?.dtEndOfCampaign || "--",
  });
}

const state = {
  // FIXME: load from db
  reservationCost: 500,
  reservations: [],
  currReservation: {
    id: "",
    vehicle: {},
    user: {
      personal: {
        name: "",
        email: "",
        phone: "",
      },
      receipt: {
        nif: "",
        address: "",
        zipCode: "",
        location: "",
      },
      dealer: {
        district: "",
        name: "",
      },
    },
    retake: {
      plate: "",
      brand: "",
      model: "",
      plateDate: "",
      fuel: "",
      transmission: "",
    },
    deliveryType: "",
  },
};

const getters = {
  getVehicleTotalPriceOrPvpFormatted: (state) => {
    const price =
      state.currReservation?.vehicle?.pricePvp ||
      state.currReservation?.vehicle?.totalPrice;
    return getPriceFormattedNoDecimal(price);
  },
  getReservationWithFinance: (state) => {
    return !!state.currReservation?.vehicle?.carDetailFinance?.capitalToFinance;
  },
  getFormattedTimeLimit: (state) => {
    const date = state.timeLimit
      .toLocaleString("pt", {
        month: "long",
        weekday: "long",
        day: "numeric",
      })
      .split(" ");

    date[date.length - 1] =
      date[date.length - 1].charAt(0).toUpperCase() +
      date[date.length - 1].slice(1);

    return date.join(" ");
  },
  getReservationRetake: (state) => {
    return state.currReservation?.retake?.status === 1
      ? !!state.currReservation?.retake?.actualValuation
      : !!state.currReservation?.retake?.initialValuation;
  },
  getReservations: (state) => state.reservations,
  getCurrentReservation: (state) => state.currReservation,
  getReservationReceipt: (state) => state.currReservation.user.receipt,
  getReservationReceiptWithLabels: (state) => {
    const { nif, address, zipCode, location } =
      state.currReservation.user.receipt;
    return {
      NIF: nif,
      "Morada, Rua, Travessa": address,
      "Código Postal": zipCode,
      Localidade: location,
    };
  },
  getReservationDelivery: (state) => {
    const { address, zipCode, location } = state.currReservation.user.receipt;
    return {
      "Morada, Rua, Travessa": address,
      "Código Postal": zipCode,
      Localidade: location,
    };
  },
  getReservationDeliveryDealer: (state) => {
    const { deliveryDealer } = state.currReservation;
    return {
      Instalação: deliveryDealer,
    };
  },
  getReservationUser: (state) => state.currReservation.user.personal,
  getReservationUserWithLabel: (state) => {
    const { name, email, phone } = state.currReservation.user.personal;
    return {
      Nome: name,
      Email: email,
      Telemóvel: phone,
    };
  },
  getReservationFinanceWithLabels: (state) => {
    const { taeg, monthlyPrice } = state.currReservation.vehicle;
    const { nbr_payments_value, initial_deposit_value, capitalToFinance } =
      state.currReservation.vehicle.carDetailFinance;
    return {
      "Valor Mensal": getPriceFormatted(monthlyPrice),
      "Número de Meses": nbr_payments_value,
      TAEG: Number(taeg).toFixed(1) + "%",
      "Montante Financiado": getPriceFormattedNoDecimal(
        Number(capitalToFinance)
      ),
      "Entrada Inicial": getPriceFormattedNoDecimal(initial_deposit_value),
    };
  },
  getReservationVehicleGeneralWithLabel(state, getters) {
    return {
      "Valor PVP": getters.getVehicleTotalPriceOrPvpFormatted,
      "Valor Sinal de Reserva": "500 €",
    };
  },
  getReservationDealerWithLabels: (_, __, rootState) => (code) => {
    const store = rootState.StoreModule.dealers.find((it) => it.id === code);
    // const { name, district, address } = getters.getDealers?.find(
    //   (d) => d.dealerCode === code
    // );
    return {
      Distrito: store.district,
      Concessionario: `${store.name} - ${store.address}`,
    };
  },
  getReservationVehicle: (state) => state.currReservation.vehicle,
  getReservationRetakeLeftWithLabels: (state) => {
    const { plate, brand, model } = state.currReservation.retake;
    return {
      Matricula: plate,
      Marca: brand,
      Modelo: model,
    };
  },
  getReservationCurrentRetakeSubmitted: (state) => {
    return state.currReservation.retake.submitted;
  },
  getReservationRetakeRightWithLabels: (state) => {
    const { plateDate, version } = state.currReservation.retake;
    return {
      "Data de Matrícula": plateDate,
      Versão: version,
      // Combustivel: fuel,
      // Transmissao: transmission
    };
  },
  getReservationRetakeValue: (state) => {
    return Number(state.currReservation.retake.value).toFixed(0) + " €";
  },
  getReservationRetakeDiff: (state) => {
    const price =
      state.currReservation?.vehicle?.pricePvp ||
      state.currReservation?.vehicle?.totalPrice;
    const diff = state.currReservation.retake.value - price;
    return state.currReservation.retake.value > price
      ? Number(diff).toFixed(0) + " €"
      : Number(0).toFixed(0) + " €";
  },
  isRetakeGreaterThanReserve: (state) => {
    const price =
      state.currReservation?.vehicle?.pricePvp ||
      state.currReservation?.vehicle?.totalPrice;
    return state.currReservation.retake.value > price;
  },
  getReservationCost: (state) => state.reservationCost,
  getDeliveryType: (state) => {
    return state.currReservation.deliveryType;
  },
};

const mutations = {
  setReservations(state, reservations) {
    state.reservations = reservations.map(
      ({
        finance,
        receipt,
        retake,
        user,
        vehicle,
        tooltipDescription,
        id,
        payload,
        dealerCode,
        estimatedDeliveryDate,
        delivery,
        deliveryDealer,
      }) => ({
        id,
        user: {
          personal: {
            name: user.name ? user.name.replaceAll('"', "") : "",
            email: user.userEmail ? user.userEmail.replaceAll('"', "") : "",
            phone: user.phoneNumber ? user.phoneNumber.replaceAll('"', "") : "",
          },
          receipt,
        },
        dealerCode,
        deliveryType: delivery,
        deliveryDealer,
        retake: {
          ...retake,
          fuel: "NaN",
          transmission: "NaN",
          value:
            retake.status === 1
              ? retake.actualValuation
              : retake.initialValuation,
          plate: retake.licensePlate,
        },
        vehicle: {
          tooltip: formatTooltip(finance, vehicle, tooltipDescription),
          totalPrice: vehicle.totalPrice,
          commercialDescription: vehicle.description,
          fuel: "NaN",
          model: vehicle.model,
          monthlyPrice: finance.monthlyValue,
          pricePvp: finance.pricePvp,
          previousPvp: finance.previousPvp,
          taeg: finance.taeg,
          transmission: "NaN",
          vin: vehicle.vin,
          tooltipDescription: {
            capitalFinance: finance.financedValue,
            depositValue: finance.depositValue,
            months: finance.nbrMonths,
            mtic: finance.financedValue,
            tan: "NaN",
          },
          imageUrl: vehicle.imageUrl,
          carDetailCard: {
            year: "NaN",
            model: vehicle.model,
            brand: vehicle.brand,
            commercialDescription: vehicle.description,
            fuel: "NaN",
            transmission: "NaN",
            pricePvp: finance.pricePvp,
            previousPvp: finance.previousPvp,
            vin: vehicle.vin,
            imageUrl: "",
            taeg: finance.taeg,
            monthlyPrice: finance.monthlyValue,
            tooltipDescription: {
              depositValue: finance.depositValue,
              capitalFinance: finance.financedValue,
              mtic: finance.financedValue,
              tan: "NaN",
              months: finance.nbrMonths,
            },
            favorite: false,
          },
          carDetailFinance: {
            nbr_payments_value: finance.nbrMonths,
            initial_deposit_value: finance.depositValue,
            capitalToFinance: finance.financedValue,
          },
        },
        estimatedDeliveryDate,
        payload: payload,
      })
    );
  },
  setCurrentReservation(state, id) {
    state.currReservation = state.reservations.find((r) => r.id === id);
  },
};

const actions = {
  async storeReservations({ commit }) {
    try {
      const res = await getAllReservations();
      // FIXME: store data in user and vehicle?
      commit("setReservations", res.data.data);
    } catch (e) {
      console.error(`Failed fetching reservations with: ${e.toString()}`);
    }
  },
  async generatePaymentIntent({ commit }, payload) {
    console.log("Generating Payment Intent", payload, commit);

    try {
      return await createPaymentIntent(payload);
    } catch (err) {
      console.error(err);
    }
  },
};

export default {
  modules: {
    user,
    payment,
    vehicle,
  },
  state,
  getters,
  mutations,
  actions,
};
