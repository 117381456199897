import { name, version } from "../../../package.json";

const state = {
  name: "",
  version: 0,
};

const getters = {
  name: (state) => state.name,
  version: (state) => state.version,
};

const mutations = {
  setName(state, name) {
    state.name = name;
  },
  setVersion(state, version) {
    state.version = version;
  },
};

const actions = {
  loadConfiguration({ commit }) {
    commit("setName", name);
    commit("setVersion", version);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
