import { getFinance, getFin } from "../../api/financing";
import { CREDIT_TYPE } from "../../enums/creditType";

const state = {
  finance: {
    monthlyValue: 250,
    depositValue: null, // Starting Point,
    lastDepositValue: null, // Starting Point,
    monthlyStep: 50,
  },
  creditType: CREDIT_TYPE.CREDIT,
  isOpenDrive: false,
  withFinancing: true,
  includeRetake: false,
  includeRetakeChanged: false,
  // errors
  errorsFinance: false,
  // for loading spinner
  loadingFinance: false,
  pdfinBase64: null,
};

const getters = {
  getCreditType: (state) => state.creditType,
  getCalculateFinance: (state) => state.finance,
  getMonthlyValue: (state) => {
    return state.finance?.monthlyValue;
  },
  getDepositValue: (state) => {
    return state.finance?.depositValue;
  },
  getLastDepositValue: (state) => {
    return state.finance?.lastDepositValue;
  },
  getMonthlyStep: (state) => {
    return state.finance?.monthlyStep;
  },
  getCalculateFinanceError: (state) => state.errorsFinance,
  getLoadingFinance: (state) => state.loadingFinance,
  getWithFinancing: (state) => state.withFinancing,
  getIsOpenDrive: (state) => state.isOpenDrive,
  getIncludeRetake: (state) => state.includeRetake,
  getIncludeRetakeChanged: (state) => state.includeRetakeChanged,
  getPdfFins: (state) => state.pdfinBase64,
};

const actions = {
  // Fetch all results from the api
  async storeCalculateFinance({ commit, state }, finance) {
    commit("setLoadingFinance", true);
    try {
      const response = await getFinance(finance);

      // Update Vehicle Store
      commit("updateVehicleFinance", response.data.data, { root: true });
      commit("setCalculateFinance", response.data.data);
      commit("VehicleModule/setVehicleFinance", response.data.data, {
        root: true,
      });
      commit("setWithFinancing", true);
      commit("ReserveModule/setFinancing", true);
      state.errorsFinance = false;
      commit("VehicleModule/setTooltip", response.data.data.legalInfo, {
        root: true,
      });
    } catch (e) {
      commit("setErrorsFinance", true);
    } finally {
      commit("setLoadingFinance", false);
    }
  },
  async downloadFin({ commit }, finance) {
    commit("spinnerModule/setLoading", true, { root: true });
    try {
      const response = await getFin(finance);
      commit("setPdfFin", response.data.data.pdfinBase64);
    } catch (e) {
      commit("spinnerModule/setLoading", false, { root: true });
    } finally {
      commit("spinnerModule/setLoading", false, { root: true });
    }
  },
};

// serves to define state value
const mutations = {
  setPdfFin(state, pdfFin) {
    state.pdfinBase64 = pdfFin;
  },
  setCalculateFinance(state, finance) {
    state.finance = {
      ...state.finance,
      ...finance,
    };
  },
  setErrorsFinance(state, errors) {
    state.errorsFinance = errors;
  },
  setLoadingFinance(state, loading) {
    state.loadingFinance = loading;
  },
  setMonthlyValue(state, val) {
    state.finance.monthlyValue = val;
  },
  setDepositValue(state, val) {
    state.finance.depositValue = val;
  },
  setLastDepositValue(state, val) {
    state.finance.lastDepositValue = val;
  },
  setWithFinancing(state, val) {
    state.withFinancing = val;
  },
  setIsOpenDrive(state, val) {
    state.isOpenDrive = val;
  },
  setIncludeRetake(state, val) {
    state.includeRetake = val;
    state.includeRetakeChanged = true;
  },
  setIncludeRetakeChanged(state, val) {
    state.includeRetakeChanged = val;
  },
  setCreditType(state, ct) {
    state.creditType = ct;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
