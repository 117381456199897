<template>
  <div v-if="getVehicle && getVehicle.carDetailCard">
    <Page id="page" :class="backgroundFilter">
      <template v-slot:content>
        <Section id="back-button">
          <Link
            :label="$t('pages.detailsPage.backToSearch')"
            :uppercase="true"
            @onClick="backHistory"
            color="tertiary"
            icon="chevron-left"
            iconPosition="left"
            bold="bold"
            sizeIcon="large"
            customClass="back-link"
          />
        </Section>

        <Section id="car-image-player" class="slide-price">
          <VehicleImageViewer v-if="loaded" />
          <div class="price">
            <VehicleInfo
              :vehicle="processVehicle"
              :viewers="viewers"
              @openShareModal="openSharePopUp"
              @openFavoriteSideBar="handleFavorite"
              @openCompareSideBar="handleCompare"
              @interestFootprint="handleInterestFootprint"
            />
          </div>
        </Section>

        <Section class="taeg-details">
          <TextLabel :label="tooltip" />
          <TextLabel
            :label="$t('pages.detailsPage.disclaimerDespesasLogisticas')"
          />
        </Section>

        <ExperienceRow />

        <Section id="details" class="details--section mt-14" ref="details">
          <div class="details-container">
            <div class="details-header">
              <Heading
                :label="$t('pages.detailsPage.details.title')"
                tag="h2"
                class="sh-text-color-black sh-text-center sh-center heading-mb"
              />
              <SectionHeader
                :subtitle="$t('pages.detailsPage.details.subtitle')"
                class="sh-text-color-black sh-text-center sh-center"
              />
            </div>
            <div class="details-content">
              <div class="details-content-menu">
                <Button
                  :label="$t('pages.detailsPage.details.buttonSpecifications')"
                  :type="viewSpecifications ? 'secondary' : 'tertiary'"
                  icon="chevron-right"
                  withIcon
                  @onClick="clickSpecifications"
                />
                <Button
                  :label="$t('pages.detailsPage.details.buttonWarranty')"
                  :type="!viewSpecifications ? 'secondary' : 'tertiary'"
                  icon="chevron-right"
                  withIcon
                  @onClick="clickWarranty"
                >
                </Button>
              </div>
              <div class="details-content-content">
                <div v-show="viewWarranty">
                  <!-- <Label color="primary" size="medium" bold="bold"
                    >Garantia</Label
                  > -->
                  <WarrantyCard
                    :info="$t('pages.detailsPage.details.warrantyCard.info')"
                    :infoList="
                      $t('pages.detailsPage.details.warrantyCard.infoList')
                    "
                  />
                </div>
                <div class="panel-container">
                  <Panel :opened="viewSpecificationsPanel">
                    <PanelHeader
                      closeButton
                      @onClosePanel="
                        viewSpecificationsPanel = !viewSpecificationsPanel
                      "
                    />
                    <PanelContent>
                      <Label
                        class="specification-title"
                        color="primary"
                        size="medium"
                        bold="bold"
                        >{{
                          $t("pages.detailsPage.details.specifications")
                        }}</Label
                      >
                      <VehicleSpecifications :specificationsList="features" />
                      <SpecificationsContainer
                        :featuresList="features"
                        :isOpenCollapseContainer="isOpenCollapseContainer"
                        @onOpenCollapseContainer="onOpenCollapseContainer"
                      />
                    </PanelContent>
                  </Panel>
                </div>
                <div class="collapse-container" v-show="viewSpecifications">
                  <VehicleSpecifications :specificationsList="features" />

                  <div class="vehicle-equipments-wrapper">
                    <Label
                      class="pt-8 label-text-color-1 label-equipments"
                      color="primary"
                      size="medium"
                      bold="semibold"
                      >{{ $t("pages.detailsPage.details.equipments") }}</Label
                    >

                    <CollapseContainer
                      class="padding-top-40"
                      collapsedHeight="280px"
                      :expandButtonText="
                        isOpenCollapseContainer
                          ? $t('pages.detailsPage.details.collapse.showLess')
                          : $t('pages.detailsPage.details.collapse.showAll')
                      "
                      expandButtonColor="secondary"
                      @onOpen="onOpenCollapseContainer"
                    >
                      <template v-for="(equipment, index) in equipments">
                        <div :key="`${index}-equipment-1`">
                          <Label
                            class="pt-8"
                            color="primary"
                            size="medium"
                            bold="semibold"
                            >{{
                              $t(
                                `pages.detailsPage.equipments.${equipment.categoryName}`
                              )
                            }}</Label
                          >
                          <div class="flex-container padding-top-40">
                            <div class="flex-child">
                              <template
                                v-for="(
                                  equipment, index
                                ) in equipment.equipments"
                              >
                                <div
                                  :key="`${index}-equipment-2`"
                                  v-if="index % 2 === 0"
                                >
                                  <TextLabel :label="equipment" />
                                  <hr :key="equipment" class="divisor" />
                                </div>
                              </template>
                            </div>
                            <div class="flex-child">
                              <template
                                v-for="(
                                  equipment, index
                                ) in equipment.equipments"
                              >
                                <div
                                  :key="`${index}-equipment-3`"
                                  v-if="index % 2 !== 0"
                                >
                                  <TextLabel :label="equipment" />
                                  <hr :key="equipment" class="divisor" />
                                </div>
                              </template>
                            </div>
                          </div>
                        </div>
                      </template>
                    </CollapseContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>

        <Section
          id="financing"
          class="financing--section"
          ref="financing"
          v-if="loaded"
        >
          <Heading
            :label="$t('pages.detailsPage.financing.title')"
            tag="h2"
            class="financing--section-header sh-text-color-black sh-text-center sh-center heading-mb"
          />
          <SectionHeader
            :subtitle="$t('pages.detailsPage.financing.subtitle')"
            class="financing--section-header sh-text-color-black sh-text-center sh-center"
          />
          <div class="financing-content">
            <Financing
              :month="month"
              :deposit="deposit"
              :minRate="getCarFinance && getCarFinance.minRate"
              :rate="getCarFinance && getCarFinance.rate"
              :pvpCetelem="
                getSelectedVehicle.prices.find(
                  (p) => p.priceType === 'TotalPrice'
                ).actualValue
              "
              :pvpTotal="
                getSelectedVehicle.prices.find(
                  (p) => p.priceType === 'TotalPrice'
                )
                  ? getSelectedVehicle.prices.find(
                      (p) => p.priceType === 'TotalPrice'
                    ).actualValue
                  : 0
              "
              :monthlyPrice="
                getSelectedVehicle &&
                getSelectedVehicle.monthlyPrice &&
                getSelectedVehicle.monthlyPrice.toFixed(2)
              "
              :taeg="getCarFinance && `${getCarFinance.taeg}`"
              :capitalToFinance="
                getCarFinance && `${getCarFinance.capitalFinance}`
              "
              :car="getSelectedVehicle"
              :tooltipResult="tooltip"
              @onClickAddResume="onClickAddResume"
              @onClickInterest="openReservePanel"
              @onClickRateCard="onClickRateCard"
              @onCalculate="onCalculate"
              @interestFootprint="handleInterestFootprint"
            ></Financing>
          </div>
        </Section>

        <Section class="taeg-details">
          <TextLabel :label="tooltip" />
          <TextLabel
            :label="$t('pages.detailsPage.disclaimerDespesasLogisticas')"
          />
        </Section>

        <FaqSection @onActiveFootprint="(e) => faqsFootprint(e)" />

        <QualityAssurance
          id="quality"
          :hasQualityPoints="qualityAssuranceObj.hasQualityPoints"
          :navigationList="qualityAssuranceObj.navigationList"
          :qualityPoints="qualityAssuranceObj.qualityPoints"
          :title="qualityAssuranceObj.title"
          :subtitle="qualityAssuranceObj.subtitle"
          :subsubtitle="qualityAssuranceObj.subsubtitle"
          :details="qualityAssuranceObj.details"
          :qualityPointsImage="
            $t('resources.images.detailpage.quality') ||
            require(`@/assets/icons/Quality.svg`)
          "
        />

        <Section id="section-7" class="section-bg-white">
          <SectionHeader
            class="section-header-left section-header-margin"
            :subtitle="$t('pages.detailsPage.similar.title')"
          />

          <div class="vehicle-cards-margin">
            <Swiper
              :customClass="'recent-swiper'"
              :spaceBetween="0"
              :pagination="false"
            >
              <SwiperSlide
                v-for="(slide, index) in getCarSimilarVehicles.slice(0, 4)"
                :key="`${slide.vin}-${index}-slide`"
              >
                <CpVehicleCard
                  :v="slide"
                  :key="`${slide.vin}-${index}-slide-card`"
                  :tooltipPosition="dynamicIndex(index)"
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </Section>

        <Share
          id="share-popup"
          :close-button="true"
          :closeOnClickOutside="true"
          @close="closeSharePopUp"
          v-show="isShareOpen"
          :title="$t('pages.detailsPage.share')"
          :networks="networks"
          :sharing="sharing"
          @onOpen="socialNetworkFootprint"
        />
      </template>
    </Page>
    <ReservePanel :financingLabels="financingLabels" />
    <Spinner :loading="loading" />
  </div>
</template>

<script>
import ReservePanel from "../../refactor/ReservePanel.vue";
import FaqSection from "../../refactor/FaqSection.vue";
import { Label } from "../../atoms";
import ExperienceRow from "../../refactor/ExperienceRow.vue";
import { CollapseContainer, Spinner } from "../../molecules";
import { Page } from "./../../templates";
import {
  VehicleInfo,
  Financing,
  WarrantyCard,
  VehicleSpecifications,
  VehicleImageViewer,
  SpecificationsContainer,
} from "../../organisms";
import {
  Section,
  SectionHeader,
  Panel,
  PanelHeader,
  PanelContent,
  Link,
  Button,
  QualityAssurance,
  Share,
  Swiper,
  SwiperSlide,
  TextLabel,
  Heading,
} from "@sc/ds-ui-component-library";
import { mapMutations, mapGetters, mapState } from "vuex";
import {
  getPriceFormatted,
  formatNonDecimalNumber,
} from "../../../utils/currency";
import { FOOTPRINT_TYPES, registerEvent } from "../../../api/footprint";

import { CompareMixin, FavoritesMixin } from "../../../mixins";

import { RESERVE_STATUS } from "../../../enums/reserveStatus";
import { RETAKESTATUS } from "../../../enums/retakeStatus.js";

import CpVehicleCard from "../../refactor/CpVehicleCard.vue";

import { PAGES } from "../../../enums/pages";

import { shareEvent } from "../../../utils/googleTagManager";

export default {
  components: {
    Heading,
    ReservePanel,
    Label,
    Section,
    SpecificationsContainer,
    Panel,
    PanelHeader,
    PanelContent,
    Page,
    Link,
    VehicleInfo,
    SectionHeader,
    Button,
    VehicleSpecifications,
    CollapseContainer,
    Share,
    Spinner,
    Financing,
    WarrantyCard,
    VehicleImageViewer,
    FaqSection,
    ExperienceRow,
    QualityAssurance,
    Swiper,
    SwiperSlide,
    CpVehicleCard,
    TextLabel,
  },
  mixins: [CompareMixin, FavoritesMixin],
  metaInfo() {
    return {
      title: this.$t("meta.detail.title", {
        detail: `${this.getSelectedVehicle?.carDetailCard?.brand} ${this.getSelectedVehicle?.carDetailCard?.model}`,
      }),
      meta: [
        { charset: "utf-8" },
        {
          vmid: "description",
          name: "description",
          content: this.$t("meta.detail.description", {
            description: `${this.getSelectedVehicle?.carDetailCard?.brand} ${this.getSelectedVehicle?.carDetailCard?.model} - ${this.getSelectedVehicle?.carDetailCard?.commercialDescription}`,
          }),
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: this.$t("meta.detail.keywords", {
            keywords: `${this.getSelectedVehicle?.carDetailCard?.brand}, ${this.getSelectedVehicle?.carDetailCard?.model}, ${this.getSelectedVehicle?.carDetailCard?.commercialDescription}`,
          }),
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.$t("meta.detail.title", {
            detail: `${this.getSelectedVehicle?.carDetailCard?.brand} ${this.getSelectedVehicle?.carDetailCard?.model}`,
          }),
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.$t("meta.detail.description", {
            description: `${this.getSelectedVehicle?.carDetailCard?.brand} ${this.getSelectedVehicle?.carDetailCard?.model} - ${this.getSelectedVehicle?.carDetailCard?.commercialDescription}`,
          }),
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: window.location.href,
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: this.getSelectedVehicle?.carDetailCard?.imageUrl,
        },
      ],
    };
  },
  data() {
    return {
      loaded: false,
      id: this.$route.params.id,
      financeId: this.$route.params.fid,
      isOpenCollapseContainer: false,
      contact: false,
      vehicleDetails: {},
      productDetail: {},
      compare: false,
      compareRightSidebarOpened: false,
      favoriteRightSidebarOpened: false,
      isShareOpen: false,
      typeCheckboxCreateAccount: "tertiary",
      filtersList: [
        {
          name: "Caixa Manual",
          value: "manual",
        },
        {
          name: "Caixa Automática",
          value: "automatic",
        },
      ],
      imagesNormalSlide: [],
      sharing: {
        url: window.location.href,
        title: this.$t("pages.detailsPage.sharePopup.title"),
        description: this.$t("pages.detailsPage.sharePopup.description"),
        quote: this.$t("pages.detailsPage.sharePopup.quote"),
        hashtags: "digitalstore",
        twitterUser: "DigitalStore",
      },
      networks: [
        {
          network: "facebook",
          name: "Facebook",
          icon: "facebook",
          color: "#1877f2",
          iconColor: "#4267B2",
        },
        {
          network: "twitter",
          name: "Twitter",
          icon: "twitter",
          color: "#3FB5FD",
          iconColor: "#1DA1F2",
        },
        {
          network: "whatsapp",
          name: "Whatsapp",
          icon: "whatsapp",
          color: "#25d366",
          iconColor: "#128C7E",
        },
      ],
      loading: false,
      viewSpecifications: true,
      viewSpecificationsPanel: false,
      viewWarranty: false,
      activeIndex: -1,
      panelStatus: RESERVE_STATUS.START,
      renderComponent: true,
      qualityAssuranceObj: this.$t("pages.detailsPage.qualityAssurance"),
      initialValue: 0,
      fpSocialSharingLabel: "Social Sharing",
    };
  },
  methods: {
    dynamicIndex(index) {
      return index === 3 ? "left" : "right";
    },
    ...mapMutations({
      setReserveStep: "PanelModule/setReserveStep",
      toggleReserve: "PanelModule/toggleReserve",
      setDetailPageStatus: "PanelModule/setDetailPageStatus",
    }),
    getPriceFormatted,
    onOpenCollapseContainer(event) {
      if (event) {
        registerEvent(FOOTPRINT_TYPES.EXPAND_CAR_DETAILS, { vin: this.id });
      }
      this.isOpenCollapseContainer = event;
    },
    addToFavorites(favorite, vin, fid) {
      console.log("favorite: ", this.getVehicle.carDetailCard);
      console.log("vin: ", this.id);
      console.log("fid: ", this.$route.params.fid);

      if (this.isGuest) {
        return document.querySelectorAll("[data-idgo-login]")[0].click();
      }

      this.$store.state.isOpenFavoritePanel =
        !this.$store.state.isOpenFavoritePanel;

      //      this.toggleFavorite(favorite);

      console.log("favorite", favorite);
      return favorite
        ? this.$store.dispatch("removeFromFavorites", vin)
        : this.$store.dispatch("addToFavorites", { vin, fid });
    },
    backHistory() {
      const prev = JSON.parse(sessionStorage.getItem("searchPath"));
      var to = {
        params: {
          tags: JSON.parse(sessionStorage.getItem("searchFilters")) || {},
        },
      };
      if (prev) to["path"] = prev.path;
      else to["name"] = PAGES.SEARCH;
      this.$router.push(to);
    },
    openSharePopUp() {
      this.isShareOpen = true;
    },
    closeSharePopUp() {
      this.isShareOpen = false;
    },
    handleCompare() {
      this.toggleCompared({
        ...this.getSelectedVehicle.carDetailCard,
        tooltipDescription: {
          monthPrice: this.getSelectedVehicle.carDetailFinance.monthlyPayment,
          totalPrice: this.getSelectedVehicle.carDetailFinance.pvp,
        },
      });
    },
    handleInterestFootprint() {
      const withFinanceObj = {
        pvpValue: this.getSelectedVehicle?.prices.find(
          (p) => p.priceType === "TotalPrice"
        ).actualValue,
        monthlyPrice: this.getSelectedVehicle?.monthlyPrice,
        months: this.getSelectedVehicle?.carDetailFinance?.months,
        taeg: this.getSelectedVehicle?.taeg,
        capitalToFinance:
          this.getSelectedVehicle?.carDetailFinance?.capitalFinance,
        initialDeposit: this.getSelectedVehicle?.carDetailFinance?.depositValue,
      };

      const reserveDetails = {
        brand: this.getSelectedVehicle?.brand,
        model: this.getSelectedVehicle?.model,
        commercialDescription: this.getSelectedVehicle?.commercialDescription,
        financeDetails: {
          withFinance: this.withFinance,
          withFinanceDetails: this.withFinance
            ? withFinanceObj
            : {
                totalPrice: this.getSelectedVehicle?.prices.find(
                  (p) => p.priceType === "TotalPrice"
                )
                  ? this.getSelectedVehicle?.prices.find(
                      (p) => p.priceType === "TotalPrice"
                    ).actualValue
                  : 0,
              },
        },
        retake:
          this.getIncludeRetake && !this.isGuest
            ? {
                ...this?.getReturns[0],
              }
            : {},
      };
      const footprintEvent = {
        vin: this.getSelectedVehicle?.carDetailCard?.vin,
        reserveDetails: reserveDetails,
      };
      registerEvent(FOOTPRINT_TYPES.HAS_INTEREST, footprintEvent);
    },
    handleFavorite() {
      this.toggleFavorite({
        ...this.getSelectedVehicle.carDetailCard,
        tooltipDescription: {
          monthPrice: this.getSelectedVehicle.carDetailFinance.monthlyPayment,
          totalPrice: this.getSelectedVehicle.carDetailFinance.pvp,
        },
      });
    },
    onClickAddResume(event) {
      console.log(event);
    },
    onClickRateCard(event) {
      console.log(event);
    },
    onCalculate() {},
    clickSpecifications() {
      this.viewSpecifications = true;
      this.viewSpecificationsPanel = true;
      this.viewWarranty = false;
    },
    clickWarranty() {
      this.viewSpecifications = false;
      this.viewWarranty = true;
    },
    openReservePanel(v) {
      this.contact = v;
      this.$store.state.isOpenReservePanel = true;
    },
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    normalizeValue(value, offset) {
      return Math.ceil(value / offset) * offset;
    },
    faqsFootprint(e) {
      const footprintEvent = {
        from: PAGES.DETAILS,
        title: e?.title,
      };

      registerEvent(FOOTPRINT_TYPES.FAQS, footprintEvent);
    },
    socialNetworkFootprint(socialNetwork) {
      shareEvent(this.getVehicle.carDetailCard, socialNetwork.network);
      const footprintEvent = {
        from: this.fpSocialSharingLabel,
        socialNetworkInfo: {
          name: socialNetwork?.name,
          network: socialNetwork?.network,
        },
        vin: this.getSelectedVehicle?.vin,
      };
      registerEvent(FOOTPRINT_TYPES.SOCIAL_NETWORK, footprintEvent);
    },
  },
  watch: {
    windowWidth(newWidth, oldWidth) {
      newWidth;
      if (oldWidth > this.maxMobileWidth) {
        this.viewSpecificationsPanel = false;
      }
    },
  },
  computed: {
    ...mapState(["isLoading", "getInfoIconTooltip"]),
    ...mapGetters([
      "getVehicleInitialDeposit",
      "getDepositValue",
      "getCarSimilarVehicles",
      "isGuest",
      "getSelectedVehicle",
      "getCreditTypeDetails",
      "getReturns",
      "getIncludeRetake",
      "windowWidth",
      "maxMobileWidth",
    ]),
    ...mapGetters({
      viewers: "VehicleModule/getViews",
      getVehicle: "VehicleModule/getVehicle",
      getSimilar: "VehicleModule/getSimilar",
      tooltip: "VehicleModule/getTooltip",
      isOpenReserve: "PanelModule/isOpenReserve",
      getReserveStep: "PanelModule/getReserveStep",
      getFinanceDropdown: "SearchModule/dropdown/getFinanceDropdown",
      withFinance: "VehicleModule/withFinance",
      defaultDeposit: "VehicleModule/getDefaultDeposit",
    }),
    financingLabels() {
      return {
        entry: this.$t("vehicle.entry"),
        monthly: this.$t("vehicle.monthly"),
        financing: this.$t("vehicle.financing"),
        monthsLabel: "meses",
      };
    },
    backgroundFilter() {
      return {
        "background-filter":
          this.isAdditionalInformation ||
          this.isTestDrive ||
          this.isInterestedOpened ||
          this.compareRightSidebarOpened ||
          this.favoriteRightSidebarOpened,
      };
    },
    getCarFinance() {
      if (!this.getVehicle) return {};
      const {
        pvp,
        months,
        monthlyPayment,
        taeg,
        capitalFinance,
        depositValue,
      } = this.getVehicle?.carDetailFinance;
      const creditType = 3;
      const creditDetail = this.getCreditTypeDetails(creditType);
      const {
        maxInitialDepositPc,
        minNbrPayments,
        maxNbrPayments,
        minRate,
        nbrPaymentsStep,
        rate,
      } = creditDetail;
      return {
        initial_deposit_step: 500,
        max_initial_deposit: maxInitialDepositPc * pvp,
        min_nbr_payments: minNbrPayments,
        max_nbr_payments: maxNbrPayments,
        nbr_payment_step: nbrPaymentsStep,
        months,
        minRate: minRate,
        rate: rate,
        pvp,
        monthlyPrice: monthlyPayment,
        taeg,
        capitalFinance,
        depositValue,
      };
    },
    processVehicle() {
      if (!this.getVehicle) return {};
      const {
        carDetailCard: {
          brand,
          commercialDescription,
          model,
          fuel,
          year,
          kilometers,
          power,
          transmission,
          seats,
          reserveType,
          prices,
        },
        carDetailFinance: {
          taeg,
          monthlyPayment,
          months,
          depositValue,
          capitalFinance,
        },
      } = this.getVehicle;
      return {
        title: `${brand} ${model}`,
        designation: commercialDescription,
        taeg,
        monthlyRate: monthlyPayment,
        deposit: depositValue,
        months,
        financedAmount: capitalFinance,
        price: prices.find((p) => p.priceType === "TotalPrice").actualValue,
        previousPvp: prices.find((p) => p.priceType === "TotalPrice")
          .previousValue,
        reserveType: reserveType,
        tooltip: this.tooltip,
        withFinancing: this.withFinance,
        icons: {
          fuel,
          year,
          kilometers: formatNonDecimalNumber(kilometers),
          power,
          transmission,
          seats,
        },
      };
    },
    equipments() {
      if (!this.getVehicle) return {};
      return this.getVehicle?.carDetailEquipmentByCategory
        ?.equipmentsByCategory;
    },
    features() {
      if (!this.getVehicle) return [];
      return this.getVehicle?.carDetailSpecification?.specifications;
    },
    month() {
      const creditType = 3;
      const creditDetail = this.getCreditTypeDetails(creditType);
      return {
        min: creditDetail.minNbrPayments || 1,
        max: creditDetail.maxNbrPayments || 3,
        step: creditDetail.nbrPaymentsStep || 1,
        value: Number(this.getCarFinance.months) || 2,
      };
    },
    deposit() {
      const creditType = 3;
      const creditDetail = this.getCreditTypeDetails(creditType);
      const max =
        this.normalizeValue(
          this.getCarFinance.pvp * creditDetail.maxInitialDepositPc,
          Number(this.getFinanceDropdown.depositStep)
        ) || 3;
      var min = Number(this.getFinanceDropdown.depositStep) || 1;
      const retake =
        this.getReturns.length > 0
          ? this.getReturns[0].status === RETAKESTATUS.Q1_UPDATED ||
            this.getReturns[0].status === RETAKESTATUS.FINALIZED
            ? this.getReturns[0].actualValuation
            : this.getReturns[0].initialValuation
          : 0;
      var value =
        this.normalizeValue(
          this.getCarFinance.depositValue,
          Number(this.getFinanceDropdown.depositStep)
        ) || 2;

      value = value >= max ? max : value;

      if (this.getIncludeRetake && retake > min && retake < max) {
        min = this.normalizeValue(
          retake,
          Number(this.getFinanceDropdown.depositStep)
        );
        value = this.normalizeValue(
          retake,
          Number(this.getFinanceDropdown.depositStep)
        );
      }

      return {
        min,
        max,
        step: Number(this.getFinanceDropdown.depositStep) || 1,
        value: value,
        retake,
        defaultValue: this.normalizeValue(
          this.defaultDeposit,
          Number(this.getFinanceDropdown.depositStep)
        ),
      };
    },
    screenWidth() {
      return window.innerWidth;
    },
  },
  async mounted() {
    this.$store.commit("VehicleModule/setEmptyVehicleValues");
    const loginAction = "?action=login";
    if (!sessionStorage.getItem("previousUrl").includes(loginAction)) {
      if (this.isOpenReserve) {
        this.toggleReserve();
        this.setReserveStep(1);
        this.setDetailPageStatus(0);
      }
      this.$store.commit("ReserveModule/resetReserve");
    }

    const payload = {
      vin: this.id,
      fid: this.$route.params.fid,
    };

    const { vehicle, similar } = await this.$store.dispatch(
      "storeVehicle",
      payload
    );
    await this.$store.dispatch("VehicleModule/fetchVehicle", {
      ...payload,
      reuse: true,
      vehicle,
      similar,
    });
    this.initialValue = this.getDepositValue || this.getVehicleInitialDeposit;
    registerEvent(FOOTPRINT_TYPES.DETAIL_PAGE, this.getSelectedVehicle);
    this.loaded = true;

    if (this.$route.params.search) {
      this.$store.dispatch(
        "SearchModule/filter/updateFilter",
        this.$route.params.search
      );
    }

    this.$meta().refresh();
    window.scroll(-1, 0);
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
#page {
  overflow-x: hidden;
}

.details-content-menu {
  .btn {
    border: none;
  }
}

.label-text-color-1 {
  color: var(--color-1);
}

.flex-container {
  display: flex;
}

.flex-child {
  flex: 1;
}

.flex-child:first-child {
  margin-right: 20px;
}

.padding-top-40 {
  padding-top: 40px;
}

.divisor {
  margin: 16px 0px;
}

#back-button {
  --section-padding: 24px 0px 0 64px;
}

.financing-content {
  margin-top: 80px;
}

.sh-text-color-black {
  --heading-color: var(--color-1);
}

.sh-text-center {
  --section-header-text-align: center;
  text-align: center;
}

.sh-center {
  --section-header-margin: auto;
}

.details-header {
  --section-header-width: 100%;
}

.details-header ::v-deep .text-label {
  margin-top: 24px;
}

.financing--section-header {
  --section-header-width: 100%;
  --section-header-text-align: center;
  text-align: center;
}

.details-content {
  display: flex;
  justify-content: center;
  margin-top: 69px;
}

#back-button ::v-deep .baselink {
  display: flex;
  align-items: center;
  width: max-content;
  flex-direction: row-reverse;
}

::v-deep .collapse-container--wrapper > .collapse-container {
  display: flex;
  border: 2px solid red;
}

::v-deep .collapse-container--wrapper {
  margin-top: 40px;
}

.collapse-container-right {
  margin-left: 32px;
}

::v-deep .vehicle-specifications {
  margin-bottom: 1.25rem;
}

::v-deep .collapse-container--link-wrapper .link {
  text-decoration: underline;
}

::v-deep .collapse-container {
  padding-left: 0px;
}

.collapse-container-label {
  color: #757989;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-family: Mont;
}

::v-deep .vehicle-specifications--item {
  background-color: var(--color-6);
  max-width: 467px;
  height: 74px;
}

html {
  scroll-behavior: smooth;
}

.details-content-menu {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
}

.details-content-menu .button:hover {
  background-color: rgba(2, 34, 111, 1);
}

.details-content-menu .button:focus {
  background-color: rgba(2, 34, 111, 1);
  color: rgba(255, 255, 255, 1);
}

::v-deep .vehicle-specifications--title {
  margin: 0;
}

::v-deep .vehicle-info--label-container .label {
  font-size: 14px;
}

::v-deep .vehicle-info--label-container {
  flex-direction: column;
  justify-content: center;
}

.faqs--section {
  margin-top: 5rem;
  display: flex;
  flex-direction: column;

  margin: 5rem;
}

.info--container {
  display: flex;
  flex-direction: column;

  justify-content: center;
}

.questions--container {
  display: flex;
  flex-direction: column;
}

.info--container ::v-deep .info--title {
  text-align: center;
  font-family: Mont;
  font-size: 38px;
  line-height: 52px;
}

.info--container ::v-deep .info--description {
  font-family: Mont;
  font-size: 18px;
  line-height: 28px;
}

.info--container ::v-deep .questions--container .label {
  font-family: Mont;
  font-size: 16px;
  line-height: 28px;
}

.info--container ::v-deep .contact-container .label {
  font-size: 18px;
  line-height: 28px;
}

::v-deep .accordion-item--trigger .label {
  font-family: Mont;
  font-weight: 500;
  font-size: 20px;
}

::v-deep .accordion-item--content .label {
  font-family: Mont;
}

.accordion--container {
  width: 100%;
}

.quality--container {
  display: flex;
  flex-direction: column;

  width: 100%;

  padding-right: 0px;

  min-height: 645px;
}

::v-deep .icon--color {
  color: rgba(91, 141, 244, 1);
}

.quality--container .quality--labels {
  width: 100%;

  background-color: rgba(2, 34, 111, 1);
}

.quality-image--container ::v-deep img {
  width: 100%;
  height: 100%;

  background-repeat: no-repeat;
  background-size: contain;
}

.quality--labels {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 79px 72px;
}

.quality--title {
  text-align: left;
  color: #ffffff;
  font-family: Mont;
  font-size: 28px;
  line-height: 33px !important;
  font-weight: 700;
  font-style: normal !important;
  letter-spacing: normal !important;
  text-align: left;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}

.quality--content {
  color: #ffffff;
  font-family: Mont;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}

.quality--icon-label {
  display: flex;
  align-items: center;
}

.quality--icon {
  margin-right: 1.5rem;
  width: 41px;
  height: 41px;
}

.quality--icon-label {
  margin-bottom: 1rem;
}

.quality--icon-label ::v-deep .quality--labels .label {
  color: #ffffff;
  font-family: Mont;
  font-size: 26px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 52px;
  text-align: left;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}

.quality--labels ::v-deep .label:not(:first-child) {
  color: rgba(255, 255, 255, 1);
  font-family: Mont;
}

.quality--labels ::v-deep .label:last-child {
  font-family: Mont;
}

.quality--labels ::v-deep .label:first-child {
  color: rgba(255, 255, 255, 1);
  font-family: Mont;
}

.quality--content {
  margin-top: 1.5rem;
  text-align: left;
  font-size: 20px;
  line-height: 30px;
  font-family: Mont;
}

.quality--labels ::v-deep .label:nth-child(3) {
  color: rgba(255, 255, 255, 1);
  margin-top: 0px;
  font-family: Mont;
  text-align: left;
  margin-bottom: 61px;
  font-size: 20px;
  line-height: 30px;
}

::v-deep .label.quality--label {
  display: flex;
  align-items: center;
  margin-top: 0 !important;
  font-weight: 500;
}

.quality--labels ::v-deep span {
  color: rgba(15, 83, 251, 1);
}

.quality--section {
  margin: 5rem;
}

.slide-price {
  display: flex;
  flex-direction: column;
  --section-padding: 0px 64px 0px 64px;
  --section-margin: 28px 0px 40px 0px;
}

.price {
  flex: 1;
  margin-top: 0.5rem;
}

.price.utility-buttons--container ::v-deep .link {
  justify-content: flex-start;
}

::v-deep .utility-buttons--container a {
  font-size: 14px;
  line-height: 14px;
}

.price .utility-buttons--container ::v-deep .link:not(:first-child) {
  margin-left: 0.5rem;
}

.price ::v-deep .utility-buttons--container {
  display: flex;
}

::v-deep .vehicleInfo--container {
  margin-top: 18px;
}

.right-sidebar ::v-deep .right-sidebar--content {
  display: block;
}

.interested--price-model-details {
  border-bottom: 2px solid #e5ecf0;
  padding-bottom: 2rem;
}

.interested--title {
  border-bottom: 2px solid #e5ecf0;
  padding-top: 0.5rem;
  padding-bottom: 1.25rem;
}

.accordion ::v-deep .accordion-item {
  padding-left: 0px;
}

.interested--footer {
  display: flex;
  bottom: 0px;
  position: absolute;
  margin-bottom: 1rem;
  margin: auto;
  justify-content: space-between;
}

.interested--footer-test-drive .button {
  height: 56px;
}

.interested--footer-reserve .button {
  height: 56px;
}

::v-deep .right-sidebar--footer {
  position: relative;
  width: 100%;
  margin-top: auto;
}

::v-deep .right-sidebar--footer .button {
  height: 56px;
}

.reserve {
  margin-top: 1rem;
}

::v-deep .accordion-item--icon-container svg {
  color: rgba(15, 83, 251, 1);
}

::v-deep .right-sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
}

::v-deep .title--content {
  width: 50%;
}

::v-deep .finance--container {
  width: 50%;
}

::v-deep .title--content-title {
  font-size: 20px;
  line-height: 20px;
}

::v-deep
  .info--wrapper
  > .block--content
  > .container--wrapper
  > .finance--container
  > .finance--wrapper
  > .label.modality--margin
  + .label.taeg--margin {
  font-size: 16px;
}

::v-deep .title--content-subtitle {
  font-size: 18px;
  line-height: 18px;
}

::v-deep .finance--container-title {
  font-size: 24px;
  line-height: 24px;
}

::v-deep .finance--container-subtitle {
  font-size: 18px;
  line-height: 18px;
}

::v-deep .default-car-image {
  margin: 0 auto;
}

::v-deep .details-container {
  width: 100%;
}

#financing {
  display: block;
}

.vehicle-equipments-wrapper {
  text-align: left;
}

.vehicle-equipments-wrapper .label {
  font-weight: bold;
  font-size: 21px;
  line-height: 21px;
}

.vehicle-equipments-wrapper .label-equipments {
  font-weight: 600;
  font-size: 28px;
  line-height: 28px;
}

::v-deep .right-sidebar--header {
  margin-bottom: 1rem;
}

.balance-icon {
  height: 20px;
  width: 25px;
  margin-left: 8px;
  margin-right: -4px;
  cursor: pointer;
}

.utility-buttons--container-action {
  display: flex;
  cursor: pointer;
  font-family: Mont;
}

::v-deep .vehicle-info--confirm-wrapper .label {
  font-size: 14px !important;
}

::v-deep .favorite-icon {
  color: rgba(117, 121, 137, 1);
}

::v-deep .favorite-icon.selected {
  color: rgba(2, 34, 111, 1);
}

.faqs-icon {
  width: 30px;
  height: 30px;
  margin-right: 1rem;
}

.icon--wrapper {
  height: 44px;
  width: 20px;
  display: grid;
  place-items: center;
}

.icon--favorite,
.icon--compare,
.icon--share {
  height: 21px;
  width: 24px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.icon--share {
  background-image: url("../../../assets/images/share.png");
  height: 25px;
  width: 25px;
}

.quality--label-list-item {
  font-family: Mont;
  font-weight: 500;
  font-size: 18px;
  line-height: 34px;
  text-align: left;
}

::v-deep .accordion-item--tertiary {
  border-color: #e5ecf0;
}

::v-deep .accordion-item--content .label {
  font-size: 16px;
}

::v-deep .label .label--tertiary {
  padding-top: 20px;
}

::v-deep .accordion-item--content {
  padding-top: 10px;
  padding-bottom: 10px;
}

::v-deep .car-row--container {
  padding: 0;
  margin: 64px 0 112px;
}

.section-bg-white {
  --section-bg-color: var(--color-7);
}

#section-7 {
  ::v-deep {
    --section-padding: 64px;

    .section-header {
      text-align: center;
      --section-header-width: 100%;
      padding: 64px 0;
      --text-label-weight: 700;
      --text-label-font-size: 40px;
      --text-label-line-height: 51px;

      .text-label {
        color: var(--color-1);
      }
    }

    .swiper-slide {
      max-width: 448px;
      --swiper-slide-background: var(--color-7);
      --swiper-slide-padding: 8px;

      &:first-child {
        --swiper-slide-padding: 8px 8px 8px 0px;
      }

      &:last-child {
        --swiper-slide-padding: 8px 0px 8px 8px;
      }
    }
  }
}

.collapse-container {
  display: inline;
}

.panel-container {
  display: none;
}

.vehicle-equipments-wrapper .label-equipments {
  font-weight: 600;
  font-size: 28px;
  line-height: 28px;
}

::v-deep .quality--content {
  --text-label-line-height: 24px;
}

::v-deep {
  .section-header {
    display: flex;
    --text-label-font-size: 21px;
    --text-label-line-height: 27px;
    --text-label-weight: 600;
    --text-label-color: var(--color-1);
    gap: 16px;
    flex-direction: column;
  }
}

#details {
  --section-bg-color: var(--color-7);
}

.slide-price {
  --section-bg-color: var(--color-7);
}

#back-button {
  --section-bg-color: var(--color-7);
}

#page {
  background-color: var(--color-7);
}

#page > .taeg-details {
  --section-padding: 0px;
  --text-label-font-size: 12px;
  --section-bg-color: var(--color-7);
  --section-margin: 40px 64px;
}

@media (min-width: 768px) {
  ::v-deep .similar-card--item {
    margin-right: 1rem;
    margin-bottom: 0px;
  }

  .details-header {
    --section-header-width: 60%;
  }

  .details-header ::v-deep .text-label {
    margin-top: 0px;
  }

  .financing--section-header {
    --section-header-width: 60%;
  }

  .financing--section-header ::v-deep h1 {
    margin-bottom: 0px;
  }
}

@media (min-width: 1024px) {
  .quality--container {
    flex-direction: row;
  }

  .faqs--section {
    flex-direction: row;
  }

  ::v-deep .vehicle-info--label-container {
    flex-direction: row;
  }

  ::v-deep .vehicle-info--label-container {
    justify-content: flex-start;
  }

  .info--container {
    justify-content: flex-start;
    width: 50%;
    padding: 2rem;
  }

  .info--container ::v-deep .info--title {
    text-align: left;
  }

  .accordion--container {
    width: 50%;
    padding: 2rem;
  }

  .quality--container {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }

  .quality--container .quality--labels {
    width: 50%;
  }

  ::v-deep .right-sidebar--footer {
    display: flex;
    justify-content: space-between;
  }

  .reserve {
    margin-left: 1rem;
    margin-top: 0px;
  }
}

@media (min-width: 1280px) {
  .price {
    margin-top: 0px;
    width: 35%;
  }

  .slide-price {
    flex-direction: row;

    & > * {
      margin: 0 28px;
    }

    & > *:first-child {
      margin: 0 28px 0 0;
    }

    & > *:last-child {
      margin: 0 0 0 28px;
    }
  }

  .info--container ::v-deep .info--title {
    font-size: 38px;
    line-height: 52px;
  }

  .info--container ::v-deep .info--description {
    font-size: 18px;
    line-height: 28px;
  }

  .search-vehicle-result-card-link:last-child {
    display: none;
  }

  .collapse-container-label {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (min-width: 1440px) {
  .vehicle-equipments-wrapper .label {
    font-weight: bold;
    font-size: 21px;
    line-height: 21px;
  }

  .vehicle-equipments-wrapper .label-equipments {
    font-weight: 600;
    font-size: 28px;
    line-height: 28px;
  }

  .info--container ::v-deep .info--title {
    font-size: 38px;
    line-height: 52px;
  }

  .info--container ::v-deep .info--description {
    font-size: 20px;
    line-height: 28px;
  }

  .details-content-menu .button {
    font-size: 16px;
    line-height: 72px;
  }

  .collapse-container-label {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (min-width: 1920px) {
  .quality--title {
    font-size: 42px;
    line-height: 52px !important;
  }

  .quality--content {
    font-size: 20px;
    line-height: 32px;
  }

  .quality--labels ::v-deep .label:nth-child(3) {
    font-size: 20px;
    line-height: 32px;
  }

  .quality--label-list-item {
    font-size: 26px;
    line-height: 52px;
  }

  .vehicle-equipments-wrapper .label {
    font-weight: bold;
    font-size: 21px;
    line-height: 21px;
  }

  .vehicle-equipments-wrapper .label-equipments {
    font-weight: 600;
    font-size: 28px;
    line-height: 28px;
  }

  ::v-deep .title--content-title {
    font-size: 34px !important;
    line-height: 34px !important;
  }

  ::v-deep .title--content-subtitle {
    font-size: 26px !important;
    line-height: 30px !important;
  }

  ::v-deep .finance--container-title {
    font-size: 42px !important;
    line-height: 42px !important;
  }

  ::v-deep .finance--container-subtitle {
    font-size: 24px !important;
    line-height: 24px !important;
  }

  .accordion--container {
    padding-right: 10rem;
  }

  .info--container {
    padding-left: 10rem;
  }

  .info--container ::v-deep .info--title {
    font-size: 40px;
    line-height: 52px;
  }

  .info--container ::v-deep .info--description {
    font-size: 20px;
    line-height: 28px;
  }

  .details-content-menu .button {
    font-size: 16px;
    line-height: 72px;
  }

  .search-vehicle-result-card-link:last-child {
    display: block;
  }

  .collapse-container-label {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 1680px) {
  #section-7 {
    --section-padding: 52px;

    .section-header {
      --text-label-weight: 700;
      --text-label-font-size: 28px;
      --text-label-line-height: 36px;
      padding: 52px 0;
    }

    ::v-deep {
      .swiper-slide {
        max-width: 355px;
        --swiper-slide-background: var(--color-7);
        --swiper-slide-padding: 8px;

        &:first-child {
          --swiper-slide-padding: 8px 8px 8px 0px;
        }

        &:last-child {
          --swiper-slide-padding: 8px 0px 8px 8px;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  #section-7 {
    --section-padding: 52px;

    .section-header {
      --text-label-weight: 700;
      --text-label-font-size: 28px;
      --text-label-line-height: 36px;
      padding: 52px 0;
    }

    ::v-deep {
      .swiper-slide {
        max-width: 333px;
        --swiper-slide-background: var(--color-7);
        --swiper-slide-padding: 8px;

        &:first-child {
          --swiper-slide-padding: 8px 8px 8px 0px;
        }

        &:last-child {
          --swiper-slide-padding: 8px 0px 8px 8px;
        }
      }
    }
  }

  .details-content-content {
    max-width: 750px;
  }

  .slide-price {
    & > * {
      margin: 0 9px;
    }

    & > *:first-child {
      margin: 0 9px 0 0;
    }

    & > *:last-child {
      margin: 0 0 0 9px;
    }
  }

  #page > #car-image-player {
    --section-padding: 0px 48px;
  }

  .ds-viewer {
    width: 730px;
    margin: auto;

    ::v-deep .main-image {
      height: 340px;
    }
  }

  ::v-deep .vehicle-info--container .vehicle-price {
    flex-direction: row;
  }

  ::v-deep .vehicle-info--container .vehicle-price .vehicle-price--right {
    align-items: flex-end;
    text-align: right;

    .new-price {
      --subtitle-font-size: 48px;
    }

    .details--row {
      --subtitle-font-size: 18px;
    }
  }

  ::v-deep .vehicle-info--container .vehicle-price .vehicle-price--left {
    --title-font-size: 34px;
    --subtitle-font-size: 16px;
  }

  ::v-deep .vehicle-info--container .caption-icon--list {
    margin-top: 21px;

    .caption-icon {
      --text-label-font-size: 12px;
    }
  }

  ::v-deep .vehicle-info--container .redirection--row {
    flex-direction: row;
    margin-top: 20px;
  }

  ::v-deep .vehicle-info--container .redirection--row .caption-icon {
    --caption-icon-gap: 4px;
    --text-label-font-size: 12px;
  }

  ::v-deep .vehicle-info--container .actions--row {
    flex-direction: row;
  }

  ::v-deep .vehicle-info--container .actions--left {
    --text-label-font-size: 12px;
    --caption-icon-gap: 4px;
  }

  ::v-deep .vehicle-info--container .user-interaction .caption-icon {
    --text-label-font-size: 12px;
    --caption-icon-gap: 4px;
  }

  ::v-deep
    .actions--row
    .user-interaction
    .caption-icon
    .text-label
    .viewers-description {
    font-size: 12px;
  }

  ::v-deep .vehicle-info--container .button {
    margin-top: 20px;
  }

  ::v-deep .vehicle-info--container .link {
    margin-top: 20px;
    --baselink-md-font-size: 14px;
    --baselink-md-line-height: 14px;
  }

  #page > #back-button {
    --section-padding: 16px 0px 0px 20px;
  }

  #page > #experience {
    --section-padding: 0px 52px;
    --text-label-font-size: 16px;
    --text-label-line-height: 16px;
    --section-margin: 0px 52px;
  }

  #page > #details {
    --section-padding: 52px;
    --heading-large-font-size: 28px;
    --heading-large-line-height: 36px;

    ::v-deep .section-header .text-label {
      --text-label-font-size: 18px;
      --text-label-line-height: 23px;
    }

    ::v-deep .specification--label {
      --subtitle-2-font-size: 14px;
      --text-2-line-height: 14px;
    }

    ::v-deep .specification--title .label {
      font-size: 16px;
      --text-2-line-height: 18px;
      margin-left: 0;
    }

    ::v-deep .vehicle-equipments-wrapper .label-equipments {
      font-size: 21px;
      line-height: 24px;
    }

    ::v-deep .vehicle-equipments-wrapper .label {
      font-size: 18px;
      line-height: 21px;
    }

    ::v-deep .vehicle-equipments-wrapper {
      --text-label-font-size: 14px;
      --text-label-line-height: 16px;
      --text-label-weight: 500;
      --text-label-color: var(--color-1);
    }
  }

  .details-content-menu {
    --button-font-size: 16px;
    --button-line-height: 16px;
  }

  .details-content-menu {
    --button-font-size: 16px;
    --button-line-height: 16px;

    ::v-deep .specification--title .label {
      font-size: 18px;
      --text-2-line-height: 18px;
    }
  }

  #page > #financing {
    --section-padding: 52px;
    --heading-large-font-size: 28px;
    --heading-large-line-height: 36px;

    ::v-deep .section-header .text-label {
      --text-label-font-size: 18px;
      --text-label-line-height: 23px;
    }

    ::v-deep
      .financing
      .financing--options
      .financing--options-row
      .buy-with-financing-title
      .label {
      font-size: 18px;
      line-height: 21px;
    }
  }

  #page > #faqs {
    --section-padding: 52px;
    --heading-large-font-size: 28px;
    --heading-large-line-height: 36px;

    ::v-deep .section-header .text-label {
      --text-label-font-size: 18px;
      --text-label-line-height: 23px;
      --quality-text-label-margin-top: 14px;
    }

    ::v-deep .link-row {
      margin-top: 41px;
    }
  }

  #page > #quality {
    ::v-deep .quality--content-img {
      --quality-points-before-width: var(
        --quality-points-responsive-before-width
      );
      --quality-points-before-height: var(
        --quality-points-responsive-before-height
      );
      background-size: var(--quality-points-responsive-background-size);
      --quality-points-before-top: var(--quality-points-responsive-before-top);
      --quality-points-before-right: var(
        --quality-points-responsive-before-right
      );
    }

    ::v-deep .quality--content {
      --quality-points-margin-top: var(
        --quality-points-content-responsive-margin-top
      );
      --quality-points-text-label-font-size: var(
        --quality-points-content-responsive-text-label-font-size
      );
      --quality-text-label-margin-top: var(
        --quality-points-content-responsive-text-label-margin-top
      );
      --quality-points-padding: var(
        --quality-points-content-responsive-padding
      );
      --quality-points-total-padding-top: var(
        --quality-points-content-responsive-total-padding-top
      );
      --quality-points-total-margin-top: var(
        --quality-points-content-responsive-total-margin-top
      );
      --quality-points-width: var(--quality-points-content-responsive-width);
    }

    --section-padding: 52px;
    --heading-large-font-size: 28px;
    --heading-large-line-height: 36px;

    ::v-deep .section-header .text-label {
      --text-label-font-size: 18px;
      --text-label-line-height: 23px;
      --quality-text-label-margin-top: 14px;
    }

    ::v-deep .quality--block {
      --quality-subtitle-font-size: 18px;
      --button-font-size: 14px;
      --button-line-height: 14px;
      --quality-nav-list-item-padding-top: 28px;
      --quality-nav-list-item-padding-bottom: 28px;
      --quality-details-padding: 28px;

      .block--content {
        position: relative;
        --quality-details-button-bottom: 0;
        --quality-details-button-width: 100%;
        --button-display: block;
        --quality-details-content-padding: 0px;
      }
    }

    --quality-points-content-responsive-width: 850px;
    --quality-details-responsive-width: 850px;
  }

  ::v-deep #quality h3:first-child {
    font-size: 18px;
    line-height: 24px;
  }

  #page > .taeg-details {
    --text-label-font-size: 12px;
    --text-label-line-height: 16px;
    --section-bg-color: var(--color-7);
    --text-label-color: #808080;
    --section-padding: 0px;
    --section-margin: 30px 52px;
  }
}

@media (max-width: 1367px) {
  #section-7 {
    --section-padding: 40px;

    .section-header {
      padding: 52px 0;
    }

    ::v-deep {
      .swiper-slide {
        max-width: 320px;
      }
    }
  }
}

@media (max-width: 1366px) {
  .ds-viewer {
    width: 690px;

    ::v-deep .main-image {
      height: 340px;
    }
  }

  #page > #quality {
    --quality-points-content-responsive-width: 800px;

    --quality-details-responsive-width: 800px;
  }
}

@media (max-width: 1280px) {
  #section-7 {
    --section-padding: 28px;

    .section-header {
      padding: 28px 0;
    }

    ::v-deep {
      .swiper-slide {
        max-width: 305px;
      }
    }
  }

  #page > #quality,
  #page > #faqs,
  #page > #financing,
  #page > #details,
  #page > #experience,
  #page > #car-image-player,
  #page > #experience {
    --section-margin: 0px 28px;
  }

  #page > .taeg-details {
    --section-bg-color: var(--color-7);
    margin-top: 8px;
    margin-bottom: 24px;
    --section-margin: 10px 28px;
  }

  #page > #quality {
    --quality-points-content-responsive-width: 750px;

    --quality-details-responsive-width: 750px;
  }

  .ds-viewer {
    width: 635px;

    ::v-deep .main-image {
      height: 340px;
    }
  }
}

@media (max-width: 1024px) {
  .slide-price {
    & > * {
      margin: 18px 0;
    }

    & > *:first-child {
      margin: 9px 0;
    }

    & > *:last-child {
      margin: 9px 0;
    }
  }

  .details-content {
    flex-direction: column;
    align-items: center;
  }

  ::v-deep .collapse-container--wrapper > .collapse-container {
    flex-direction: column;
    align-items: center;
  }

  .collapse-container-right {
    margin-left: 0;
  }

  ::v-deep .vehicle-specifications {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
  }

  .details-content-content {
    max-width: 600px;
    margin-top: 20px;
  }

  .details-content-menu {
    margin-top: 20px;
  }

  ::v-deep #quality h3:first-child {
    font-size: 18px;
    line-height: 24px;
  }

  #page > #quality {
    ::v-deep .accordion--item-title {
      --text-label-font-size: 18px;
      --quality-text-label-margin-top: 0px;
    }

    ::v-deep .accordion--item-content {
      --text-label-font-size: 16px;
    }

    ::v-deep .block--content {
      --text-label-font-size: 16px;
    }

    ::v-deep .quality--content-img {
      --quality-points-before-width: var(
        --quality-points-responsive-before-width
      );
      --quality-points-before-height: var(
        --quality-points-responsive-before-height
      );
      background-size: var(--quality-points-responsive-background-size);
      --quality-points-before-top: var(--quality-points-responsive-before-top);
      --quality-points-before-right: var(
        --quality-points-responsive-before-right
      );
    }

    ::v-deep .quality--content {
      --quality-points-margin-top: var(
        --quality-points-content-responsive-margin-top
      );
      --quality-points-text-label-font-size: 16px;
      --quality-text-label-margin-top: var(
        --quality-points-content-responsive-text-label-margin-top
      );
      --quality-points-padding: 0px;
      --quality-points-total-padding-top: var(
        --quality-points-content-responsive-total-padding-top
      );
      --quality-points-total-margin-top: var(
        --quality-points-content-responsive-total-margin-top
      );
      --quality-points-width: var(--quality-points-content-responsive-width);
    }

    --section-padding: 28px;
    --heading-large-font-size: 28px;
    --heading-large-line-height: 36px;

    ::v-deep .section-header .text-label {
      --text-label-font-size: 18px;
      --text-label-line-height: 23px;
      --quality-text-label-margin-top: 14px;
    }

    ::v-deep .quality--block {
      --quality-subtitle-font-size: 18px;
      --button-font-size: 14px;
      --button-line-height: 14px;
      --quality-nav-list-item-padding-top: 28px;
      --quality-nav-list-item-padding-bottom: 28px;
      --quality-details-padding: 28px;

      .block--content {
        position: relative;
        --quality-details-button-bottom: 0;
        --quality-details-button-width: 100%;
        --button-display: block;
        --quality-details-content-padding: 0px;
      }
    }

    --quality-points-content-responsive-width: 100%;
    --quality-details-responsive-width: 100%;
  }
}

@media (max-width: 768px) {
  ::v-deep .rate-card {
    margin-bottom: 1.25rem;
  }

  #page > #quality,
  #page > #faqs,
  #page > #financing,
  #page > #details,
  #page > #experience,
  #page > #car-image-player,
  #page > #taeg-detail {
    --section-padding: 16px;
    display: flex;
    flex-direction: column;

    & > * {
      margin: 16px 0;
    }

    & > *:first-child {
      margin: 0 0 16px 0;
    }

    & > *:last-child {
      margin: 16px 0 0 0;
    }
  }

  #section-7 {
    .section-header {
      --text-label-weight: 700;
      --text-label-font-size: 24px;
      --text-label-line-height: 31px;
    }
  }

  #page > #experience {
    --section-margin: 0px 16px;
  }

  #page > #car-image-player {
    --section-margin: 0px;
  }

  #page > .taeg-details {
    margin-top: 8px;
    margin-bottom: 24px;
    --text-label-font-size: 10px;
    --section-padding: 0px;
    --section-margin: 5px 16px;
  }

  .details-header {
    padding: 0 16px;
  }

  .ds-viewer {
    width: 100%;

    ::v-deep .main-image {
      height: auto;
    }
  }

  ::v-deep .vehicle-info--container .vehicle-price,
  ::v-deep .vehicle-info--container .redirection--row,
  ::v-deep .vehicle-info--container .actions--row {
    flex-direction: column;
  }

  ::v-deep .vehicle-info--container .vehicle-price .vehicle-price--right {
    text-align: left;
    align-items: flex-start;
  }

  ::v-deep .vehicle-info--container .caption-icon--list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
    justify-items: center;
    align-items: center;
  }

  ::v-deep .section-header {
    --section-header-width: 100%;
  }

  .flex-container {
    flex-direction: column;
  }

  ::v-deep #quality h2:first-child {
    font-size: 24px;
    line-height: 31px;
  }

  ::v-deep #quality h3:first-child {
    font-size: 16px;
    line-height: 21px;
  }

  ::v-deep #quality .quality--block-mobile .nav-title {
    font-size: 18px;
    line-height: 24px;
  }

  ::v-deep #quality .section-header p {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
  }

  #page > #details {
    --section-padding: 50px 0px;
  }

  .details-content-menu {
    width: 100%;
    margin-right: 0px;
  }

  .details-content-menu .btn {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
  }

  .panel-container {
    display: grid;
  }

  .collapse-container {
    display: none;
  }

  .panel-container ::v-deep .panel--content .panel--content-inner {
    --panel-content-inner-padding: 16px;
    display: grid;
    flex-direction: column;
  }

  .panel-container
    ::v-deep
    .panel--content
    .panel--content-inner
    .vehicle-specifications {
    margin-bottom: 24px;
  }

  .specification-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
    margin-bottom: 24px;
  }

  .panel-container
    ::v-deep
    .panel--content
    .panel--content-inner
    .vehicle-specifications
    .specification
    .block--content
    .specification--title
    .label {
    width: fit-content;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
  }

  .panel-container
    ::v-deep
    .panel--content
    .panel--content-inner
    .vehicle-specifications
    .specification
    .block--content
    .specification--label {
    font-weight: 500;
    font-size: 14px;
    text-align: end;
    line-height: 14px;
    float: right;
  }

  .panel-container
    ::v-deep
    .panel--content
    .panel--content-inner
    .vehicle-specifications
    .specification
    .block--content {
    display: inline;
  }

  ::v-deep .controls {
    margin: auto;
    bottom: 160px;
    left: 0;
    right: 0;
  }

  ::v-deep .swiper-container {
    margin-top: -2rem;
  }

  ::v-deep .vue-pannellum {
    margin: 5rem;
    height: 75%;
  }

  ::v-deep .slide {
    height: 550px;
  }

  ::v-deep .utility-buttons--container a {
    font-size: 12px !important;
  }

  .details-content-content {
    width: 100%;
  }

  .vehicle-equipments-wrapper {
    margin-top: 2rem;
  }

  ::v-deep .vehicle-info--confirm-wrapper {
    margin-top: -0.5rem;
  }

  ::v-deep .vehicle-info--linkList-wrapper .link {
    font-size: 14px;
    line-height: 16px;
  }

  ::v-deep .padding-container .button {
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {
  .quality--labels {
    padding: 2rem;
    padding-left: 2.5rem;
  }
}

.heading-mb {
  margin-bottom: 16px;
}
</style>
