<template>
  <button
    type="button"
    :class="['button', customClass, classes, disabled ? 'disabled' : '']"
    @click="clickHandler"
    :disabled="disabled"
  >
    {{ label }}
    <slot />
  </button>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    customClass: {
      type: [String, Array],
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "default",
      validator: (size) => {
        return ["default", "large", "ghost"].includes(size);
      },
    },
    type: {
      type: String,
      default: "primary",
      validator: (type) => {
        return ["primary", "secondary", "tertiary"].includes(type);
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clickHandler(event) {
      this.$emit("onClick", event);
    },
  },
  computed: {
    sizeClasses() {
      switch (this.size) {
        case "large":
          return "large";
        case "ghost":
          return "ghost";
        default:
          return "";
      }
    },
    typeClasses() {
      switch (this.type) {
        case "secondary":
          return "secondary";
        case "tertiary":
          return "tertiary";
        default:
          return "primary";
      }
    },
    classes() {
      return `${this.typeClasses} ${this.sizeClasses}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  /* Reset */
  border: 0;
  padding: 0;
  color: var(--button-text-color);
  height: var(--button-height);
  padding-left: var(--button-padding);
  padding-right: var(--button-padding);
  font-size: var(--button-font-size);
  line-height: var(--button-line-height);
  font-weight: var(--button-font-weight);
}

.primary {
  background-color: var(--button-primary-bg-color);

  &:hover,
  &:active {
    background-color: var(--button-primary-hover);
  }
}
.secondary {
  background-color: var(--button-secondary-bg-color);

  &:hover,
  &:active {
    background-color: var(--button-secondary-hover);
  }
}
.tertiary {
  border: var(--button-tertiary-border);
  background-color: var(--button-tertiary-bg-color);
  color: var(--button-tertiary-color);

  &:hover,
  &:active {
    background-color: var(--button-tertiary-hover);
    color: var(--button-text-color);
  }
}

.large {
  height: var(--button-lg-height);
  font-size: var(--button-lg-font-size);
  line-height: var(--button-lg-line-height);
}

.disabled {
  pointer-events: none;
  background-color: var(--button-disabled-bg-color);
}

.ghost {
  background-color: var(--button-ghost-bg-color);
  color: var(--button-ghost-text-color);
  padding: var(--button-ghost-padding);
  height: var(--button-ghost-height);

  &:hover,
  &:active {
    color: var(--button-ghost-hover);
    background-color: var(--button-ghost-bg-hover);
  }
}

.ghost.disabled {
  color: var(--button-ghost-disabled);
}
</style>
