<template>
  <button
    type="button"
    :class="['button', classes, customClass]"
    :style="customStyles"
    @click="clickHandler"
    :disabled="disabled"
  >
    <div class="button--icon-container">
      <div v-if="loading">
        <svg
          class="button--spinner"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
      <img
        v-if="filename"
        :src="require(`../../../assets/images/${filename}`)"
        width="24px"
        height="24px"
      />
      <font-awesome-icon
        :class="[label.length ? 'button--icon' : '', '']"
        v-if="icon"
        :icon="[iconPrefix, icon]"
      />
    </div>
    <!-- {{ label }} -->
    <div v-html="label"></div>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      },
    },
    customClass: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      validator: (size) => {
        return ["x-small", "small", "medium", "large", "x-large"].includes(
          size
        );
      },
    },
    type: {
      type: String,
      default: "primary",
      validator: (type) => {
        return ["primary", "secondary"].includes(type);
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    circular: {
      type: Boolean,
      default: false,
    },
    fill: {
      type: Boolean,
      default: false,
    },
    uppercase: {
      type: Boolean,
      default: false,
    },
    dashed: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: String,
      validator: (shadow) => {
        return ["sm", "normal", "md", "lg", "xl", "2xl"].includes(shadow);
      },
    },
    icon: {
      type: String,
      default: "",
    },
    filename: {
      type: String,
    },
    iconPosition: {
      type: String,
      default: "left",
      validator: (position) => {
        return ["left", "right"].includes(position);
      },
    },
    bgColor: {
      type: String,
      default: "",
    },
    iconPrefix: {
      type: String,
      default: "fas",
    },
    border: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    clickHandler(event) {
      this.$emit("onClick", event);
    },
  },
  computed: {
    classes() {
      return {
        "button--primary": this.type === "primary",
        "button--secondary": this.type === "secondary",
        "button--bg-primary button--text-primary":
          this.fill && this.type === "primary",
        "button--bg-secondary button--text-primary":
          this.fill && this.type === "secondary",
        "button--uppercase": this.uppercase,
        "button--round": this.rounded,
        "button--circular": this.circular,
        "button--dashed": this.dashed,
        "button--icon-reverse":
          (this.icon.length || this.loading) && this.iconPosition === "right",
        // size
        [`button--${this.circular ? "circular-" : ""}${this.size}`]: this.size,
        // shadow
        [`button--shadow-${this.shadow}`]: this.shadow,
        // disabled
        "button--disabled": this.disabled,
        //border
        "button--border-primary": this.border && this.type === "primary",
        "button--border-secondary": this.border && this.type === "secondary",
      };
    },
    customStyles() {
      return {
        ...this.styles,
        backgroundColor: this.bgColor,
      };
    },
  },
};
</script>
<style lang="css" scoped src="./Button.css"></style>
