<template>
  <div class="car-images-slider">
    <CoolLightBox
      :items="images"
      :index="selectedImage"
      @close="selectedImage = null"
      :effect="'fade'"
    >
    </CoolLightBox>
    <!-- Slider main container -->
    <div class="swiper-container swiper-container-2">
      <!-- Additional required wrapper -->
      <div class="swiper-wrapper">
        <!-- Slides -->
        <div
          class="swiper-slide"
          v-for="(image, index) in images"
          :key="index"
          @click="selectedImage = index"
        >
          <div
            class="vehicle-image"
            :style="`background-image: url(${image})`"
          ></div>
        </div>
      </div>

      <!-- If we need navigation buttons -->
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
  </div>
</template>

<script>
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

// import Swiper JS
import Swiper from "swiper";

// core version + navigation, pagination modules:
import SwiperCore, { Navigation, Pagination } from "swiper/core";

// configure Swiper to use modules
SwiperCore.use([Navigation, Pagination]);

export default {
  components: {
    CoolLightBox,
  },
  data() {
    return {
      opened: false,
      selectedImage: null,
      swiper: null,
    };
  },
  props: {
    images: {
      type: Array[String],
      default: [],
    },
  },
  mounted() {
    this.swiper = new Swiper(".swiper-container-2", {
      centerInsufficientSlides: true,
      // Navigation arrows,
      slidesPerView: 4,
      spaceBetween: 16,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  },
};
</script>

<style lang="scss" scoped>
.car-images-slider {
  .swiper-container-2 {
    width: 100%;
    height: 150px;

    .swiper-wrapper {
      align-items: center;

      .swiper-slide {
        width: 120px;
        height: 120px;

        .vehicle-image {
          width: 100%;
          height: 100%;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
  }

  ::v-deep .cool-lightbox .cool-lightbox__slide img {
    box-shadow: none;
  }
}
</style>
