<template>
  <Section id="search-box" class="searchbox">
    <div class="hero-search">
      <div id="autocomplete-container" class="search-box-autocomplete">
        <AutocompleteDropdown
          type="tertiary"
          :placeholder="$t('pages.homepage.hero.autocomplete.placeholder')"
          class="searchbar-width"
          @onSubmitSearch="search"
          @onChange="setValue"
        />
        <Button
          type="secondary"
          :withIcon="true"
          icon="search"
          class="searchbutton-height"
          @onClick="search"
        />
      </div>

      <div class="tags-center">
        <TextLabel
          size="normal"
          :label="$t('pages.homepage.quicksearch.label')"
        />

        <template v-for="item in tagsList">
          <FilterTag
            :key="item.key"
            :label="item.value"
            customClass="tag-quicksearch"
            labelSize="small"
            labelWeight="regular"
            rounded
            iconColor="white"
            @onClick="onQuickSearch(item)"
          />
        </template>
      </div>
    </div>
  </Section>
</template>

<script>
import {
  Section,
  FilterTag,
  Button,
  TextLabel,
} from "@sc/ds-ui-component-library";
import AutocompleteDropdown from "../AutocompleteDropdown/AutocompleteDropdown.vue";
import { mapGetters } from "vuex";
import { PAGES } from "../../../enums/pages";
export default {
  name: "SearchBox",
  components: {
    Section,
    FilterTag,
    Button,
    TextLabel,
    // NOT IMPLEMENTED IN LIB
    AutocompleteDropdown,
  },
  props: {
    filterTags: {
      type: Array,
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  data() {
    return {
      searchFilters: [],
      dropdownType: "segment",
      inputValue: "",
    };
  },
  methods: {
    setValue(value) {
      this.inputValue = value;
    },
    setNeedle(value) {
      this.$store.commit("SearchModule/filter/setNeedle", value);
    },
    search() {
      this.setNeedle(this.inputValue);
      if (this.getNeedle) {
        const tag = {
          type: "needle",
          id: 0,
          value: this.getNeedle,
        };
        this.$store.dispatch("SearchModule/filter/addOrReplaceTag", tag);
        this.$store.commit("SearchModule/filter/setSaveTags", true);
      }
      this.$emit("onSearch");

      this.$store.commit("SearchModule/setSearchEventDue", true);
    },
    onQuickSearch(item) {
      item = {
        type: this.dropdownType,
        id: item.key,
        value: item.value,
        selected: true,
      };

      if (item.id == "lowCost") {
        item = { type: "lowCost", id: "1", value: "Low Cost", selected: true };
      }

      this.$store.dispatch("SearchModule/filter/cleanAllTags");
      this.$store.commit("SearchModule/filter/setNeedle", "");
      this.$store.dispatch("SearchModule/filter/addOrReplaceTag", item);
      //Verifica se botão lowCost foi carregado
      item.id == "lowCost"
        ? this.$store.dispatch("SearchModule/dropdown/updateDropdown", item)
        : null;
      this.$store.commit("SearchModule/filter/setSaveTags", true);

      this.$router.push({
        name: PAGES.SEARCH,
      });
    },
    filterCustomTags(obj) {
      const wantedTags = [
        "Citadino",
        "Hatchback",
        "Carrinha",
        "Suv / Tt",
        "Monovolume",
      ];
      return wantedTags
        .map((tag) => tag.toLowerCase())
        .includes(obj.value.toLowerCase());
    },
    isInViewport(element) {
      var top_of_element = element.offsetTop;
      var bottom_of_element =
        element.offsetTop + element.offsetHeight + element.style.marginTop;
      var bottom_of_screen = window.scrollY + window.innerHeight;
      var top_of_screen = window.scrollY;
      return (
        (bottom_of_screen > top_of_element &&
          top_of_screen < bottom_of_element) ||
        top_of_screen < 600
      );
    },
    handleScroll() {
      const autocomplete = document.getElementById("autocomplete-container");

      if (window.innerWidth <= 768 && !this.isInViewport(autocomplete)) {
        autocomplete.className = "fixed-autocomplete";
      } else {
        autocomplete.className = "search-box-autocomplete";
      }
    },
  },
  computed: {
    ...mapGetters({
      getDropdowns: "SearchModule/dropdown/getDropdowns",
      getNeedle: "SearchModule/filter/getNeedle",
      getResults: "SearchModule/pagination/getResults",
    }),
    tagsList() {
      const tagsArray = this.getDropdowns[this.dropdownType];
      const order = {
        Citadino: 1,
        Hatchback: 2,
        Carrinha: 3,
        "Suv / Tt": 4,
        Monovolume: 5,
      };
      const orderedTags = tagsArray
        ?.filter(this.filterCustomTags)
        .sort((a, b) => {
          if (order[a.value] && order[b.value])
            return order[a.value] - order[b.value];
        })
        .map((tag) => {
          return {
            ...tag,
            value: tag.value.charAt(0).toUpperCase() + tag.value.slice(1),
          };
        });
      //Botão LowCost
      orderedTags.push({ key: "lowCost", value: "Low Cost" });
      return tagsArray && tagsArray.length ? orderedTags : [];
    },
  },
};
</script>

<style lang="scss" scoped>
.searchbox {
  --section-bg-color: var(--color-5);
  --section-padding: 48px 16px 40px 16px;
  --section-overflow: visible;
}

.tags-center {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  text-align: center;
  --text-label-color: var(--color-1);
  --text-label-font-size: 16px;
  --text-label-line-height: 20px;

  & > * {
    margin: 8px 12px 8px 0;
  }

  .tag-quicksearch {
    cursor: pointer;
    border-radius: 50px;
    border: 1px solid #02226f;
    --text-label-small-font-size: 16px;
    --text-label-small-line-height: 16px;
    --font-weight-regular: 700;
  }
}

.hero-search {
  width: 100%;
  max-width: 1024px;
  padding: 0px 50px;
  margin: auto;
}

.search-box-autocomplete {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  & > *:first-child {
    margin-right: 8px;
  }
}

.searchbar-width {
  width: 100%;
}

.searchbutton-height {
  --button-height: 56px;
  --button-padding: 0px;
  width: 64px !important;

  ::v-deep .icon {
    margin: auto;
  }
}

@media (max-width: 1440px) {
}
@media (max-width: 1366px) {
}
@media (max-width: 1280px) {
}
@media (max-width: 1024px) {
}
@media (max-width: 768px) {
  .searchbox {
    --section-bg-color: var(--color-5);
    --section-padding: 0px;
    --section-overflow: visible;
  }
  .hero-search {
    width: 100%;
    height: 100%;
    padding: 0px;
    padding-top: 20px;
    max-width: 100%;
  }
  .search-box-autocomplete {
    width: 100%;
    padding: 8px 16px;
    position: relative;
    background-color: var(--color-5);
    z-index: 0;
  }

  .fixed-autocomplete {
    display: flex;
    flex-direction: row;
    position: fixed;
    z-index: 999;
    top: 0;
    width: 100%;
    padding: 8px 16px;
    background-color: var(--color-5);
  }

  .tags-center {
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px 16px 20px 16px;
    --text-label-font-size: 12px;
    --text-label-line-height: 12px;
    --tag-normal-height: 24px;

    & > * {
      margin: 8px 6px 8px 0;
    }

    p {
      margin-top: 15px;
    }

    .tag-quicksearch {
      --text-label-small-font-size: 14px;
      --text-label-small-line-height: 14px;
      --font-weight-regular: 700;
    }
  }
}
@media (max-width: 375px) {
}
</style>
