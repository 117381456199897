<template>
  <div class="footer--container">
    <div class="top--row">
      <div class="top--left">
        <div div class="logo" />
        <div class="links--top">
          <span
            :key="`link-list-${idx}`"
            v-for="(it, idx) in links.top"
            class="top--link"
          >
            {{ it.name }}
            <LinkList
              class="list-link"
              :list="it.goToList"
              bold="normal"
              display="vertical"
              size="large"
              @onClick="redirectToSection"
            />
          </span>
        </div>
      </div>
      <div class="top--right">
        <span class="follow">{{ $t("footer.follow") }}</span>
        <div class="social-media">
          <Icon
            v-for="(item, idx) in linksGoTo"
            :key="`icon-${idx}`"
            color="white"
            :customIcon="item.socialMedia"
            @onClick="goTo(item.socialMedia)"
          >
            <img
              :src="require(`@/assets/images/${item.socialMedia}.svg`)"
              :alt="item.socialMedia"
            />
          </Icon>
        </div>
      </div>
    </div>
    <div class="bottom--row">
      <span class="copyright">{{ $t("footer.copyright", { year }) }}</span>
      <div class="links">
        <span
          :key="`link-${idx}`"
          v-for="(it, idx) in links.bottom"
          class="bottom--link"
        >
          <Link
            :class="linkClass(idx)"
            @onClick="onClick(idx, it)"
            :label="it.name"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { LinkList } from "../../molecules";
import { Link } from "../../atoms";
import { Icon } from "@sc/ds-ui-component-library";

export default {
  name: "Footer",
  components: {
    LinkList,
    Link,
    Icon,
  },
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  computed: {
    links() {
      return {
        top: [
          {
            name: this.$t("footer.search.title"),
            goToList: [
              {
                label: this.$t("footer.search.content.0.label"),
                url: this.$t("footer.search.content.0.path"),
              },
              {
                label: this.$t("footer.search.content.1.label"),
                url: this.$t("footer.search.content.1.path"),
              },
            ],
          },
          {
            name: this.$t("footer.tradein.title"),
            goToList: [
              {
                label: this.$t("footer.tradein.content.0.label"),
                url: this.$t("footer.tradein.content.0.path"),
              },
              {
                label: this.$t("footer.tradein.content.1.label"),
                url: this.$t("footer.tradein.content.1.path"),
              },
            ],
          },
          {
            name: this.$t("footer.howitworks.title"),
            goToList: [
              {
                label: this.$t("footer.howitworks.content.0.label"),
                url: this.$t("footer.howitworks.content.0.path"),
              },
            ],
          },
        ],
        topLinks: ["Ver Viaturas"],
        bottom: Object.entries(this.$t("footer.bottomLinks")).map((e) => {
          return { path: e[1].path, name: e[1].title, target: e[1].target };
        }),
      };
    },
    linksGoTo() {
      return [
        {
          socialMedia: this.$t("footer.socialMedia.fb.name"),
          link: this.$t("footer.socialMedia.fb.link"),
        },
        {
          socialMedia: this.$t("footer.socialMedia.ig.name"),
          link: this.$t("footer.socialMedia.ig.link"),
        },
        {
          socialMedia: this.$t("footer.socialMedia.lk.name"),
          link: this.$t("footer.socialMedia.lk.link"),
        },
      ];
    },
  },
  methods: {
    onClick(idx, it) {
      if (idx != 1) this.onClickHandler(it);
    },
    linkClass(idx) {
      return idx === 1 ? "js-rgc-open-cookies-settings" : "";
    },
    goTo(socialMedia) {
      const socialMediaSelected = this.linksGoTo.find(
        (i) => i.socialMedia === socialMedia
      );
      window.open(socialMediaSelected.link, "_blank");
    },
    onClickHandler(it) {
      if (!it.target) {
        this.$router.push(it.path).catch((e) => {
          e;
          this.$router.push(it.path);
        });
      } else {
        window.open(it.path, "_blank");
      }
    },
    redirectToSection() {
      const currentLocationHash = window.location.pathname + location.hash;
      if (currentLocationHash !== this.$t("footer.search.content.1.path")) {
        setTimeout(() => {
          const locationHash = window.location.pathname + location.hash;
          if (locationHash === this.$t("footer.search.content.1.path"))
            this.$router
              .push({ path: this.$t("footer.search.content.1.path") })
              .catch(() => {});
        }, 2000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.footer--container {
  position: relative;
  height: 421px;
  background-color: #0053ff;
  padding-top: 80px;
  padding-left: 64px;
  padding-right: 64px;
}

.top--row,
.bottom--row,
.top--left {
  display: flex;
  align-items: center;
}

.bottom--row {
  position: absolute;
  bottom: 0;
  width: calc(100% - 120px);
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 40px;
  border-top: 1px solid rgba(255, 255, 255, 0.16);
}

span {
  color: #fff;
}

.copyright,
.bottom--link {
  font-size: 16px;
  line-height: 21px;
  font-weight: 600;
}

.bottom--link {
  opacity: 0.5;
}

.links {
  display: flex;

  & > * {
    margin: 0 20px;
  }

  & > *:first-child {
    margin: 0 20px 0 0;
  }

  & > *:last-child {
    margin: 0 0 0 20px;
  }
}

.logo {
  width: 180px;
  height: 40px;
  background-image: url("../../../assets/logoWhite.svg");
  background-position: center;
  background-size: contain;
}

.top--row {
  justify-content: space-between;
}

.top--left {
  gap: 140px;
}

.links--top {
  display: flex;

  & > * {
    margin: 0 28px;
  }

  & > *:first-child {
    margin: 0 28px 0 0;
  }

  & > *:last-child {
    margin: 0 0 0 28px;
  }
}

.top--link {
  font-size: 18px;
  line-height: 23px;
  font-weight: 600;
}

.follow {
  color: #5b8df4;
  font-size: 16px;
  line-height: 21px;
  font-weight: 600;
  text-transform: uppercase;
}

.list-link ::v-deep .link {
  color: white;
  width: fit-content;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.01px;
  line-height: 14px;
  margin-bottom: 10px;
}

.bottom--link ::v-deep .link {
  color: white;
  font-size: 16px;
  line-height: 21px;
  font-weight: 600;
}

.list-link {
  margin-top: 15px;
}

@media (max-width: 1000px) {
  .top--left {
    gap: 0px;
  }

  .footer--container {
    height: 100%;
  }

  .bottom--row {
    position: relative;
    width: unset;
  }

  .top--row,
  .bottom--row,
  .top--left,
  .links,
  .links--top {
    flex-direction: column;
    text-align: center;

    & > * {
      margin: 20px 0;
    }

    & > *:first-child {
      margin: 0 0 20px 0;
    }

    & > *:last-child {
      margin: 20px 0 0 0;
    }
  }

  .list-link ::v-deep .link {
    width: 100%;
    margin-bottom: 0px;
    text-align: center;
  }

  .list-link {
    margin-top: 0px;
    text-align: center;
  }
}

.social-media {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 4px;
}

.social-media ::v-deep .touch-wrapper {
  cursor: pointer;
}

.top--right {
  text-align: center;
}
</style>
