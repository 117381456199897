<template>
  <vue-slider
    :class="['slider--wrapper', customClass, classes]"
    :value="currentValue"
    :dotSize="dotSize"
    :min="min"
    :max="max"
    :interval="interval"
    contained
    @change="onChange"
    @mouseup.native="onMouseUp"
    :disabled="disabled"
  ></vue-slider>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

export default {
  name: "Slider",
  components: {
    VueSlider,
  },
  data() {
    return {};
  },
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      },
    },
    customClass: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "primary",
      validator: (type) => {
        return ["primary", "secondary"].includes(type);
      },
    },
    value: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    interval: {
      type: Number,
      default: 1,
    },
    dotSize: {
      type: Number,
      default: 24,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onChange(value) {
      this.currentValue = value;
      this.$emit("onChange", value);
    },
    onMouseUp() {
      this.$emit("onMouseUp");
    },
  },
  computed: {
    classes() {
      return {
        "slider--wrapper-primary": this.type === "primary",
        "slider--wrapper-secondary": this.type === "secondary",
      };
    },
    currentValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
};
</script>
<style lang="css" scoped src="./Slider.css"></style>
