<template>
  <SavedCard
    :labelFooterButton="
      $t('pages.personalPage.reservations.savedReservation.labelFooterButton')
    "
    :showButton="false"
    @onClick="onClick"
  >
    <template slot="header">
      <div class="header--container">
        <div
          class="car-img"
          :style="{
            backgroundImage: `url('${img}')`,
          }"
        />
        <PriceModelDetails
          class="mb-6"
          icon-name="info-circle"
          :vehicle="vehicle"
          :absolutePrice="!vehicle.pricePvp"
          tooltipPosition="left"
          :product-title="vehicle.model"
          :product-description="vehicle.commercialDescription"
          :tooltip-description="vehicle.tooltip"
          :price-label="formattedPrice"
          :modality="formattedMonthlyPrice"
          :finance-taeg="formattedTaeg"
          :showActionsMissingTag="showActionsMissingTag"
        />
      </div>
    </template>
    <template slot="footer">
      <div class="footer--container">
        <div class="card-footer-label">
          <Label customClass="card-footer-label-title" size="small">
            {{
              $t(
                "pages.personalPage.reservations.savedReservation.DeliveryFooter"
              )
            }}
          </Label>
          <Label customClass="card-footer-label-value" size="small">
            {{ formattedDeliveryDate }}
          </Label>
        </div>
      </div>
    </template>
  </SavedCard>
</template>

<script>
import { Label } from "../../../atoms";
import { SavedCard } from "../../../organisms";
import { PriceModelDetails } from "../../../molecules";
import {
  getPriceFormattedNoDecimal,
  formatNonCurrency,
} from "../../../../utils/currency.js";
export default {
  name: "SavedReservation",
  components: {
    Label,
    SavedCard,
    PriceModelDetails,
  },
  props: {
    reservationId: {
      type: Number,
    },
    vehicle: {
      type: Object,
      default: () => ({}),
    },
    deliveryDate: {
      type: Number,
    },
    retake: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    onClick() {
      this.$emit("onClick");
    },
  },
  computed: {
    img() {
      return (
        this.vehicle?.imageUrl ||
        require("../../../../assets/images/placeholder.jpg")
      );
    },
    formattedPrice() {
      const price = this.vehicle?.finance?.pricePvp || this.vehicle.totalPrice;
      return getPriceFormattedNoDecimal(price);
    },
    formattedTaeg() {
      return "TAEG: " + formatNonCurrency(this.vehicle?.taeg, 1, 1, true) + "%";
    },
    formattedMonthlyPrice() {
      return Number(this.vehicle?.monthlyPrice) + "€";
    },
    formattedDeliveryDate() {
      const dateObject = new Date(this.deliveryDate);
      const year = new Intl.DateTimeFormat(
        process.env.VUE_APP_I18N_LOCALE.replace("_", "-"),
        {
          year: "numeric",
        }
      ).format(dateObject);
      const month = new Intl.DateTimeFormat(
        process.env.VUE_APP_I18N_LOCALE.replace("_", "-"),
        {
          month: "long",
        }
      ).format(dateObject);
      const day = new Intl.DateTimeFormat(
        process.env.VUE_APP_I18N_LOCALE.replace("_", "-"),
        {
          day: "2-digit",
        }
      ).format(dateObject);
      return `${day} ${month} ${year}`;
    },
    showActionsMissingTag() {
      const retake =
        (this.retake?.status === 1
          ? !!this.retake?.actualValuation
          : !!this.retake?.initialValuation) && !this.retake.submitted;
      return retake;
    },
  },
};
</script>

<style scoped>
.header--container {
  display: flex;
  align-items: center;
}

.car-img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 155px;
  margin-right: 1rem;
}

.footer--container {
  display: flex;
}

.tooltip-footer {
  margin-left: 0.25rem;
}

.card-footer-label-title {
  color: var(--color-2);
}

.card-footer-label-value {
  color: var(--color-1);
  font-weight: var(--font-weight-semibold);
}
</style>
