<template>
  <Block class="section-12-block section-12-block-2">
    <CaptionIcon
      class="caption"
      :label="$t('pages.homepage.sections.contactsSection.faq.title')"
      icon="faq"
      :iconSize="60"
    />
    <TextLabel
      class="text"
      :label="$t('pages.homepage.sections.contactsSection.faq.text')"
    />
    <Button
      v-if="false"
      classe="button"
      size="ghost"
      :label="$t('pages.homepage.sections.contactsSection.faq.button')"
      withIcon
      icon="arrow-right"
    />
  </Block>
</template>

<script>
import {
  Block,
  CaptionIcon,
  TextLabel,
  Button,
} from "@sc/ds-ui-component-library";

export default {
  name: "CpFAQBlock",
  components: { Block, CaptionIcon, TextLabel, Button },
  props: {},
};
</script>

<style lang="scss" scoped>
.section-12-block-2 {
  .text {
    margin-top: 24px;
    --text-label-color: #333333;
    --text-label-font-size: 18px;
    --text-label-weight: 600;
  }

  .caption {
    --text-label-font-size: 38px;
    --text-label-weight: 800;
    --text-label-color: #333333;
  }

  .button {
    justify-content: flex-end;
    margin-top: 55px;
  }
}

@media (max-width: 1920px) {
}

@media (max-width: 1440px) {
}
@media (max-width: 1366px) {
}
@media (max-width: 1280px) {
}
@media (max-width: 1024px) {
}

@media (max-width: 768px) {
}

@media (max-width: 768px) {
  .section-12-block-2 {
    .text {
      margin-top: 21px;
    }
    .caption {
      --text-label-font-size: 24px;
      --text-label-line-height: 31px;
      --text-label-weight: bold;
      --text-label-color: #333333;
    }
    .button {
      justify-content: flex-start;
      margin-top: 27px;
    }
  }
}
</style>
