<template>
  <section
    :class="['section-row', customClass, classes]"
    :style="customStyles"
    :id="id"
  >
    <slot />
  </section>
</template>

<script>
export default {
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      },
    },
    customClass: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    bgColor: {
      type: String,
      default: "white",
    },
  },
  computed: {
    customStyles() {
      return {
        ...this.styles,
        backgroundColor: this.bgColor,
      };
    },
    classes() {
      return {};
    },
  },
};
</script>

<style scoped lang="css" src="./Section.css"></style>
