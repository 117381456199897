<template>
  <div class="saved-card">
    <div class="saved-card--header">
      <div class="saved-card--header-content">
        <slot name="header"></slot>
      </div>
      <div class="saved-card--header-close" @click="onDelete">
        <Icon
          v-if="showButton"
          :border="false"
          icon="close"
          color="#808080"
        ></Icon>
      </div>
    </div>
    <div class="saved-card--header-footer">
      <div class="saved-card--header-footer-left">
        <slot name="footer"></slot>
      </div>
      <div class="saved-card--header-footer-button">
        <Button
          :label="labelFooterButton"
          type="tertiary"
          @onClick="onClick"
        ></Button>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon, Button } from "@sc/ds-ui-component-library";
export default {
  name: "SavedCard",
  components: {
    Icon,
    Button,
  },
  props: {
    labelFooterButton: {
      type: String,
      default: "Button",
    },
    showButton: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onClick() {
      this.$emit("onClick");
    },
    onDelete() {
      this.$emit("onDelete");
    },
  },
};
</script>

<style lang="scss" scoped>
.saved-card {
  border: 2px solid #e4ecf0;
  padding: 1.5rem;
}

.saved-card--header {
  display: flex;
  justify-content: space-between;
}

.saved-card--header-content {
  width: 100%;
}

.saved-card--header-close {
  height: max-content;
  cursor: var(--button-cursor);
}

.saved-card--header-footer {
  margin-top: 1rem;
  border-top: 2px solid #e4ecf0;
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.saved-card--header-footer-left {
  vertical-align: middle;
}

@media (max-width: 1024px) {
  .saved-card--header-footer {
    width: 100%;
  }

  .saved-card--header-footer-button {
    width: 100%;

    .btn {
      width: 100%;
      justify-content: center;
    }
  }
}
</style>
