<template>
  <div class="filters-mobile--panel">
    <Panel
      :opened="isFilterPanelOpen"
      @clickOutside="handlePanel, $emit('toggleOpen')"
    >
      <PanelHeader
        :title="$t('searchform.mobilePanel.title')"
        closeButton
        @onClosePanel="handlePanel"
      />
      <PanelContent>
        <SearchFormInputs
          @toggleOpen="$emit('toggleOpenMobile')"
          @onItemClick="onItemClick"
          ref="formInputs"
        />
        <!-- <SearchFormExtra showExtended /> -->
      </PanelContent>
      <PanelFooter>
        <Button
          :label="$t('searchform.mobilePanel.clearBtn')"
          @onClick="cleanAll"
          type="tertiary"
        />
        <Button
          :label="$t('searchform.mobilePanel.filterBtn', { value: getTotal })"
          @onClick="handlePanel"
          type="secondary"
        />
      </PanelFooter>
    </Panel>

    <Button
      id="filter-panel--btn"
      :label="moreFiltersLabel"
      withIcon
      @onClick="handlePanel"
      :icon="iconShowExtended"
      type="tertiary"
    />
  </div>
</template>

<script>
import { Button } from "@sc/ds-ui-component-library";
import { mapGetters } from "vuex";
import {
  Panel,
  PanelHeader,
  PanelContent,
  PanelFooter,
} from "@sc/ds-ui-component-library";
// import SearchFormExtra from "./SearchFormExtra.vue";
import SearchFormInputs from "./SearchFormInputs.vue";

export default {
  name: "SearchPanelFilter",
  components: {
    Button,
    Panel,
    PanelContent,
    // SearchFormExtra,
    PanelHeader,
    SearchFormInputs,
    PanelFooter,
  },
  props: {
    isFilterPanelOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showExtended: false,
      dropdowns: ["segment", "brands", "year", "fuel"],
    };
  },
  methods: {
    handlePanel() {
      this.isFilterPanelOpen = !this.isFilterPanelOpen;
    },
    onItemClick(e) {
      this.$emit("onItemClick", e);
    },
    cleanAll() {
      this.$emit("onClean");
    },
  },
  computed: {
    ...mapGetters({
      getDropdowns: "SearchModule/dropdown/getDropdowns",
      getTotal: "SearchModule/pagination/getResults",
      getTagsLength: "SearchModule/filter/getTagsLength",
    }),
    iconShowExtended() {
      return this.showExtended ? "chevron-up" : "chevron-down";
    },
    moreFiltersLabel() {
      return this.getTagsLength > 0
        ? this.$t("searchform.extend") + " (" + this.getTotal + ")"
        : this.$t("searchform.extend");
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
::v-deep .panel--footer-inner {
  --panel-footer-inner-padding: 16px;
  background-color: #ffffff;
}
::v-deep .panel--header-inner-title h1 {
  --panel-header-inner-title-font-size: 16px;
  --panel-header-inner-title-line-height: 16px;
  --panel-header-inner-title-font-weight: 600;
  --panel-header-inner-title-color: #333;
}
::v-deep .panel--footer .btn {
  font-size: 16px;
  --button-padding: 0px;
  width: 47%;
  line-height: 18px;
  font-weight: 700px;
}
::v-deep .wrapper span {
  font-size: 11px;
}
::v-deep .filters--row .dropdown--wrapper:first-child .dropdown-menu {
  width: 100%;
}
.filters-mobile--panel,
#filter-panel--btn {
  margin-top: 5px;
  width: 100%;
  --button-padding: 0px;
  --panel-header-inner-title-font-size: 18px;
  --panel-header-inner-title-line-height: 18px;
  --panel-header-inner-title-font-weight: 800;
  --button-tertiary-bg-color: transparent;
}
.filters-mobile--panel ::v-deep .panel {
  background-color: var(--color-5);
}

.filters-mobile--panel ::v-deep .panel--content-inner {
  display: flex;
  flex-direction: column;
  --panel-content-inner-padding: 16px;
}

::v-deep .filters--row .btn {
  display: none;
}

::v-deep .filters--row {
  display: grid;
  flex-direction: column;
}

::v-deep .complete {
  height: 56px;
  border: none;
}

::v-deep .megabig--price {
  align-items: center;
  padding: 20px;
  height: 56px;
  border: none;
  color: #333;
}

::v-deep .megabig--container {
  background-color: white;
}

::v-deep .dropdown--title-label {
  color: #333;
  line-height: 20px;
}

::v-deep .description {
  line-height: 16px;
  font-size: 12px;
  color: #848484;
}

::v-deep .subtitle {
  line-height: 14px;
  font-size: 14px;
  color: #333;
}

::v-deep .button {
  width: 203px;
  display: flex;
  justify-content: center !important; /* Missing variable */
  flex-shrink: 0;
}

::v-deep .button--primary {
  height: 40px;
}

::v-deep .button--primary div {
  font-size: 14px;
  line-height: 18px;
}

::v-deep .dropdown--wrapper {
  width: 100%;
  --dropdown-header-box-background-color: var(--color-7);
}

::v-deep .megabig--price {
  align-items: center;
  padding: 20px;
  height: 56px;
  border: none;
  color: #333;
}

::v-deep .megabig--container {
  background-color: white;
}

::v-deep .description {
  line-height: 16px;
  font-size: 12px;
  color: #848484;
}

::v-deep .subtitle {
  line-height: 14px;
  font-size: 14px;
  color: #333;
}

::v-deep .button--primary {
  height: 40px !important;
}

::v-deep .button--primary div {
  font-size: 14px;
  line-height: 18px;
}

::v-deep .extended-form--wrapper .filters--row,
::v-deep .extended-form--wrapper .tag-category--row,
::v-deep .extended-form--wrapper .filters-checkboxes--row {
  display: flex;
  flex-direction: column;
}

::v-deep .extended-form--wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
  padding: 0;
  z-index: 0;
  position: relative;
  background-color: var(--color-5);
}
::v-deep .container {
  max-width: 100%;
}

@media (max-width: 510px) {
  .filters-mobile--panel
    ::v-deep
    .panel--content-inner
    .month-price-dropdown
    .dropdown-menu {
    max-width: 100%;
    .dropdown-content {
      padding: 5px;
    }
    .price {
      flex-direction: column;
      --price-tab-width: 100%;
    }
  }
  @media (max-width: 340px) {
    .filters-mobile--panel
      ::v-deep
      .panel--content-inner
      .month-price-dropdown
      .dropdown-menu
      .price-input {
      flex-direction: column;
    }
  }
}
</style>
