const PAGES = {
  HOME: "Home",
  SEARCH: "SearchPage",
  DETAILS: "DetailPage",
  COMPARATOR: "Comparator",
  PERSONAL: "Personal",
  IMPORT: "Import VIN",
  ABOUT: "About",
  RETAKE: "Retake Evaluation",
  HOW_IT_WORKS: "HowItWorksPage",
  COOKIES: "cookies-terms",
  TERMS_CONDITIONS: "terms-conditions",
  POLICY: "Policy",
  RESERVATIONS: "reservations",
  PERSONAL_RETAKE: "returns",
  SEARCH_PAGE_CUSTOM_ROUTE: "SearchPageCustomRoute",
  ERROR: "error",
};

export { PAGES };
