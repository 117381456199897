<template>
  <div class="container">
    <div
      class="block"
      :class="spaceBetween ? 'spacing' : ''"
      v-for="(val, key) in data"
      :key="key"
    >
      <Label customClass="gray-label">{{ key }}:</Label>
      <Label :customClass="'black-label ' + spaceBetween ? 'text-left' : ''">{{
        val
      }}</Label>
    </div>
  </div>
</template>

<script>
import { Label } from "../../atoms";

export default {
  name: "DisplayLabelValue",
  components: { Label },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    spaceBetween: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.container {
  margin-top: 10px;
}
::v-deep .label {
  margin-right: 0.5rem;
}

::v-deep .label.text-left {
  text-align: right;
  color: var(--color-1);
}

.spacing {
  display: flex;
  justify-content: space-between;
}

.block {
  margin-bottom: 4px;
}

::v-deep .label.gray-label {
  color: var(--color-2);
}
::v-deep .label.black-label {
  color: var(--color-1);
}
</style>
