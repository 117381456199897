import httpClient from "./httpClient";

const PRICE_DROP = "/notifications/price-drop";
const CAR_ALERT = "/notifications/car-alert";

function getUserPriceDropNotifications() {
  return httpClient.get(PRICE_DROP);
}

function activatePriceDropNotification(vin) {
  return httpClient.patch(PRICE_DROP + `?vin=${vin}`);
}

function deletePriceDropNotification(vin) {
  return httpClient.delete(PRICE_DROP + `?vin=${vin}`);
}

function getUserCarAlertNotifications() {
  return httpClient.get(CAR_ALERT);
}

function activateCarAlertNotification(id) {
  return httpClient.patch(CAR_ALERT + `?id=${id}`);
}

function deleteCarAlertNotification(id) {
  return httpClient.delete(CAR_ALERT + `?id=${id}`);
}

export {
  getUserPriceDropNotifications,
  activatePriceDropNotification,
  deletePriceDropNotification,
  getUserCarAlertNotifications,
  activateCarAlertNotification,
  deleteCarAlertNotification,
};
