<template>
  <div class="no-results">
    <div class="no-results--title">
      <Label size="medium" color="primary">{{ title }}</Label>
    </div>
    <div v-if="description" class="no-results--description">
      <Label size="small" color="tertiary">{{ description }}</Label>
    </div>
    <div v-if="labelButton" class="no-results--button">
      <Button
        type="secondary"
        fill
        :border="false"
        uppercase
        :label="labelButton"
        @onClick="onClickHandler"
      ></Button>
    </div>
  </div>
</template>

<script>
import { Label, Button } from "../../atoms";
export default {
  name: "NoResults",
  components: {
    Label,
    Button,
  },
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    labelButton: {
      type: String,
    },
  },
  methods: {
    onClickHandler() {
      this.$emit("onClick");
    },
  },
};
</script>

<style lang="scss" scoped>
.no-results {
  text-align: center;
}

.no-results--title {
  margin-bottom: 0.5rem;
}

.no-results--title .label {
  font-weight: 500;
}

.no-results--description {
  margin-bottom: 1.5rem;
}

.no-results--description .label {
  font-family: Mont;
}

.no-results--button .button {
  width: 241px;
  height: 56px;
  margin: auto;
  font-family: Mont;
}

::v-deep .label {
  font-family: Mont;
}
@media (max-width: 1024px) {
  .no-results--button {
    .button {
      width: 100%;
      justify-content: center;
      margin: 0;
    }
  }
}
</style>
