<template>
  <Page>
    <template v-slot:content>
      <div class="cookiepage--container">
        <Section id="content">
          <SectionHeader
            :title="$t('pages.cookiePage.title')"
            type="primary"
            titleSize="large"
            class="header"
          ></SectionHeader>
          <div class="container">
            <div class="form--container">
              <p>
                {{ $t("pages.cookiePage.subtitle") }}
              </p>
              <div class="checkboxes">
                <CheckboxLabel
                  :title="$t('pages.cookiePage.checkboxLabelList[0].title')"
                  :subtitle="
                    $t('pages.cookiePage.checkboxLabelList[0].subtitle')
                  "
                  type="primary"
                  disabled
                  :checked.sync="necessaryCheck"
                  @onChange="onCheckboxChanged($event, COOKIE_TYPE.NECESSARY)"
                />
                <CheckboxLabel
                  :title="$t('pages.cookiePage.checkboxLabelList[1].title')"
                  :subtitle="
                    $t('pages.cookiePage.checkboxLabelList[1].subtitle')
                  "
                  type="primary"
                  :checked.sync="performanceCheck"
                  @onChange="onCheckboxChanged($event, COOKIE_TYPE.PERFORMANCE)"
                />
                <CheckboxLabel
                  :title="$t('pages.cookiePage.checkboxLabelList[2].title')"
                  :subtitle="
                    $t('pages.cookiePage.checkboxLabelList[2].subtitle')
                  "
                  type="primary"
                  :checked.sync="targetingCheck"
                  @onChange="onCheckboxChanged($event, COOKIE_TYPE.TARGETING)"
                />
                <Button
                  customClass="save--cookies"
                  type="primary"
                  :label="$t('pages.cookiePage.button')"
                  :disabled="isButtonDisable"
                  class="button-save"
                  :loading="loading"
                  @onClick="onClickHandler"
                ></Button>
              </div>
            </div>
            <div class="info--container">
              <div class="info--content">
                <p v-html="$t('pages.cookiePage.aboutCookies.description')"></p>
              </div>
            </div>
          </div>
        </Section>
      </div>
    </template>
  </Page>
</template>

<script>
import { Section, SectionHeader, Button } from "@sc/ds-ui-component-library";

import { CheckboxLabel } from "../../molecules";
import { Page } from "../../templates";
import { FOOTPRINT_TYPES, registerEvent } from "../../../api/footprint";
import { COOKIE_TYPE } from "../../../enums/cookieType";
import { getCookie, setCookie } from "../../../utils/cookies";

export default {
  name: "CookiePage",
  components: { SectionHeader, Section, Page, CheckboxLabel, Button },
  metaInfo() {
    return {
      title: this.$t("meta.cookies.title"),
      meta: [
        { charset: "utf-8" },
        {
          vmid: "description",
          name: "description",
          content: this.$t("meta.cookies.description"),
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: this.$t("meta.cookies.keywords"),
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.$t("meta.cookies.title"),
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.$t("meta.cookies.description"),
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: window.location.href,
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: this.$t("meta.cookies.image"),
        },
      ],
    };
  },
  data() {
    return {
      necessaryCheck: true,
      performanceCheck: false,
      targetingCheck: false,
      loading: false,
      isButtonDisable: false,
      COOKIE_TYPE,
    };
  },
  methods: {
    onCheckboxChanged(event, position) {
      if (position === COOKIE_TYPE.PERFORMANCE) {
        this.performanceCheck = event;
        setCookie("dsperformance", this.performanceCheck);
      }
      if (position === COOKIE_TYPE.TARGETING) {
        this.targetingCheck = event;
        setCookie("dstargeting", this.targetingCheck);
      }
      document.cookie;
    },
    onClickHandler() {
      this.isButtonDisable = false;
      this.loading = true;
      const result = {
        necessary: this.necessaryCheck,
        performance: this.performanceCheck,
        targeting: this.targetingCheck,
      };
      registerEvent(FOOTPRINT_TYPES.COOKIE_PAGE, result);
      this.loading = false;
      this.isButtonDisable = false;

      //Reload to activate GMT
      window.location.reload();

      this.$toast.open({
        message: this.$t("toast.cookies.savedSettings"),
        dismissible: true,
        type: "info",
      });
    },
  },
  mounted() {
    setCookie("dsnecessary", true);

    this.performanceCheck = JSON.parse(getCookie("dsperformance")) || false;
    this.targetingCheck = JSON.parse(getCookie("dstargeting")) || false;
  },
};
</script>

<style scoped lang="scss">
#content {
  display: flex;
  flex-direction: column;
  --section-padding: 50px 215px;
}

.header {
  --section-header-text-align: center;
  --section-header-margin: auto;
}

.checkboxes {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .button-save {
    width: max-content;
  }
}

.form--container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.info--container {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

p {
  margin-bottom: 2rem;
}

h4 {
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

.save--cookies {
  margin-top: 2rem;
  width: auto;
}

@media (max-width: 1024px) {
  #content {
    --section-padding: 0px 16px 30px 16px;
    --section-header-width: 100%;

    .header {
      --section-header-margin: 30px auto;
    }

    ::v-deep {
      .btn {
        width: 100%;
        --button-justify-content: center;
      }
    }
  }
}

.cookiepage--container {
  --section-bg-color: var(--color-7);
}

@media (max-width: 768px) {
  ::v-deep .title {
    --title-font-size: var(--title-3-font-size);
    --title-line-height: var(--title-3-line-height);
    --title-weight: var(--font-weight-bold);
  }

  .cookiepage--container {
    --section-header-width: 100%;
  }
}
</style>
