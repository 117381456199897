<template>
  <div class="topbar--container">
    <div class="icons">
      <div class="contact-phrase" @click.prevent="openTab">
        <span class="title">{{ $t("pages.homepage.questions.title") }}</span>
        <div>
          <Icon
            class="expander"
            :icon="`${expanded ? 'chevron-up' : 'chevron-down'}`"
          />
        </div>
      </div>
      <div :class="[expanded ? 'expanded' : 'not-expanded', 'contact-items']">
        <Icon icon="phone" />
        <a @click.prevent="emitOpenLeadContact" class="link">{{
          $t("pages.homepage.questions.callme")
        }}</a>
        <span class="or">{{ $t("pages.homepage.questions.or") }}</span>
        <Icon icon="message" />
        <a @click.prevent="emitOpenLead" class="link">{{
          $t("pages.homepage.questions.contact")
        }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from "@sc/ds-ui-component-library";

export default {
  name: "TopBar",
  components: {
    Icon,
  },
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    emitOpenLeadContact() {
      this.$emit("openLeadContact");
    },
    emitOpenLead() {
      this.$emit("openLead");
    },
    openTab() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style scoped lang="scss">
@media (max-width: 768px) {
  .icons {
    flex-direction: column;

    .not-expanded {
      display: none;
    }

    .contact-phrase {
      .expander {
        display: inline-flex;
      }
      .title {
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        margin: 0 10px 0 0;
      }
      ::v-deep .icon {
        transform: scale(0.7);
      }
    }
    .expanded {
      width: 100%;
      justify-content: start;
      margin: 18px 0 0 0;
      .link {
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
      }
      .or {
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        margin: 0px 10px;
      }
    }
  }
}

.topbar--container {
  display: flex;
  justify-content: center;
  padding: 28px;
  border-bottom: 1px solid #eaeaea;
}

.contact-items {
  display: flex;
  justify-content: center;
  align-items: center;

  * {
    margin: 0px 5px;
  }
}

.contact-phrase {
  display: flex;
  justify-content: center;
  align-items: center;

  .title {
    margin: 0 30px 0 0;
  }

  .expander {
    display: none;
    vertical-align: middle;
  }
}

.title {
  color: #333;
  font-size: 16px;
  line-height: 21px;
  font-weight: 600;
}

.icons {
  display: flex;
  align-items: center;
}

.link {
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  color: #5b8df4;
  text-decoration-line: underline;
  cursor: pointer;

  &:hover,
  &:active {
    font-weight: 800;
    color: #6497ff;
  }
}

.or {
  color: #333;
  line-height: 21px;
  font-size: 16px;
  font-weight: 600;
}

.phone,
.chat,
.question--icon {
  height: 24px;
  width: 24px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.question--icon {
  background-image: url("../../assets/icons/faq.svg");
  height: 60px;
  width: 60px;
}

.row {
  display: flex;
  align-items: center;
  gap: 18px;
  margin-bottom: 34px;
}

.big--title {
  color: #333;
  font-size: 38px;
  font-weight: 800;
  line-height: 49px;
}

.description {
  font-size: 18px;
  color: #333;
  line-height: 24px;
  font-weight: 600;
}

.input {
  background-color: white;
  padding: 20px 16px;
  margin-bottom: 16px;
}

.small--title {
  color: #333;
  font-size: 21px;
  line-height: 27px;
  font-weight: 600;
  margin-bottom: 16px;
}

.checkbox {
  height: 24px;
  width: 24px;
  border: 1px solid #333;
}

.conditions {
  font-size: 14px;
  color: #333;
  line-height: 18px;
  font-weight: 500;
}

.send--button {
  height: 56px;
  width: 185px;
}

.topbar--container {
  background-color: var(--color-7);
}
</style>
