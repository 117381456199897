<template>
  <Block class="section-12-block section-12-block-3">
    <TextLabel
      class="newsletter-label"
      :label="$t('pages.homepage.sections.contactsSection.subscribe.title')"
    />
    <div class="section-12-block-3-input">
      <InputField
        class="name-field"
        :label="$t('pages.homepage.sections.contactsSection.subscribe.input.1')"
        @onInput="updateName"
        mode="filled"
      />
      <InputField
        :label="$t('pages.homepage.sections.contactsSection.subscribe.input.2')"
        @onInput="updateEmail"
        mode="email"
      />
    </div>
    <div class="submit-newsletter">
      <Checkbox
        :label="
          $t('pages.homepage.sections.contactsSection.subscribe.checkbox')
        "
        :checked="privacyChecked"
        @onInput="(e) => (privacyChecked = e)"
      />
      <Button
        class="subscribe-button"
        type="secondary"
        @onClick="emitClick"
        :disabled="isDisabled"
        :label="$t('pages.homepage.sections.contactsSection.subscribe.button')"
      />
    </div>
  </Block>
</template>

<script>
import {
  Block,
  TextLabel,
  InputField,
  Checkbox,
  Button,
} from "@sc/ds-ui-component-library";

export default {
  name: "CpNewsletterBlock",
  components: { Block, TextLabel, InputField, Checkbox, Button },
  props: {},
  data() {
    return {
      name: "",
      email: "",
      privacyChecked: false,
    };
  },
  computed: {
    isDisabled() {
      return !this.name[1] || !this.email[1] || !this.privacyChecked;
    },
  },
  methods: {
    emitClick() {
      this.$emit("subscribeNewsletter", {
        name: this.name[0],
        email: this.email[0],
      });
    },
    updateName(value) {
      this.name = value;
    },
    updateEmail(value) {
      this.email = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.section-12-block-3 {
  .newsletter-label {
    --text-label-font-size: 21px;
    --text-label-weight: 600;
    --text-label-color: #333333;
  }

  .section-12-block-3-input {
    margin-top: 16px;
    display: flex;
    flex-direction: column;

    .name-field {
      margin: 0 0 16px 0;
    }
  }

  .submit-newsletter {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;

    .choice--container {
      margin: 0 32px 0 0;
    }

    ::v-deep {
      --choice-label-font-size: 14px;
    }
  }

  .subscribe-button {
    width: 185px;
    display: inline;
  }
}

@media only screen and (max-width: 1650px) and (min-width: 1025px) {
  .section-12-block-3 {
    .submit-newsletter {
      flex-direction: column;

      .choice--container {
        margin: 0 0 32px 0;
      }

      .btn {
        width: 100%;
      }
    }
  }
}

@media (max-width: 1000px) {
  .section-12-block-3 {
    .submit-newsletter {
      flex-direction: column;

      .choice--container {
        margin: 0 0 32px 0;
      }

      ::v-deep .button {
        width: 100%;
      }
    }
  }
}
</style>
