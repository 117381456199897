const defaults = {
  equipments: [
    {
      text: "ABS",
      value: "ABS",
      checked: false,
    },
    {
      text: "Bancos Aquecidos",
      value: "HeatedSeats",
      checked: false,
    },
    {
      text: "Espelhos Elétricos",
      value: "RetractableElectricMirrors",
      checked: false,
    },
    {
      text: "Rádio com CD",
      value: "AutoRadioWithCD",
      checked: false,
    },
    {
      text: "Vidros Elétricos",
      value: "ElectricFrontGlass",
      checked: false,
    },
    {
      text: "Bancos Elétricos",
      value: "ElectricallyAdjustableSeats",
      checked: false,
    },
    {
      text: "Cruise Control",
      value: "CruiseControl",
      checked: false,
    },
    {
      text: "GPS",
      value: "NavigationSystem",
      checked: false,
    },
    {
      text: "Bluetooth",
      value: "Bluetooth",
      checked: false,
    },
    {
      text: "Controlo de Estabilidade (ESP)",
      value: "StabilityControl",
      checked: false,
    },
    {
      text: "Faróis de Xénon",
      value: "XenonHeadlamps",
      checked: false,
    },
    {
      text: "Jantes em Liga Leve",
      value: "AlloyWheels",
      checked: false,
    },
    {
      text: "Teto de Abrir",
      value: "OpeningGlassRoof",
      checked: false,
    },
    {
      text: "Sensores de Parqueamento Frontais",
      value: "FrontParkingSensors",
      checked: false,
    },
    {
      text: "Sensores de Parqueamento Traseiros",
      value: "RearParkingSensors",
      checked: false,
    },
  ],
  accessories: [
    {
      text: "Livro de Instruções",
      value: "InstructionsManual",
      checked: false,
    },
    {
      text: "Livro de Revisões",
      value: "ServiceBook",
      checked: false,
    },
    {
      text: "Segunda Chave",
      value: "SecondaryKey",
      checked: false,
    },
  ],
  interiorList: [
    {
      value: "-1",
      text: "Interior",
      disabled: true,
      selected: true,
    },
    { value: "tela", text: "Tecido" },
    { value: "cuero", text: "Pele" },
  ],
  externalColorList: [
    {
      value: "-1",
      text: "Cor Exterior",
      disabled: true,
      selected: true,
    },
    { value: "Amarelo", text: "Amarelo" },
    { value: "Azul", text: "Azul" },
    { value: "Bege", text: "Bege" },
    { value: "Bordeaux", text: "Bordeaux" },
    { value: "Branco", text: "Branco" },
    { value: "Castanho", text: "Castanho" },
    { value: "Cinzento", text: "Cinzento" },
    { value: "Cinzento Claro", text: "Cinzento Claro" },
    { value: "Cinzento Escuro", text: "Cinzento Escuro" },
    { value: "Dourado", text: "Dourado" },
    { value: "Laranja", text: "Laranja" },
    { value: "Preto", text: "Preto" },
    { value: "Rosa", text: "Rosa" },
    { value: "Verde", text: "Verde" },
    { value: "Vermelho", text: "Vermelho" },
  ],
  provenance: {
    radioGroupName: "provenance",
    radioGroupValue: "NAT",
    radioGroupList: [
      {
        label: "Sim",
        radioValue: "IMPORT",
        radioId: "imported-vehicle-yes",
        checked: false,
      },
      {
        label: "Não",
        radioValue: "NAT",
        radioId: "imported-vehicle-no",
        checked: false,
      },
    ],
  },
  generalStatus: [
    {
      title: "Conservação exterior e interior",
      subtitle:
        "Carroçaria, vidros, os espelhos e as luzes. Estofos, tapetes, forras e plásticos, tabelier e seus componentes. Acessórios e peças várias - manuais, livros de revisão, macaco, etc.",
      radioGroupName: "InteriorAndExteriorConservation",
      radioGroupValue: "AVERAG",
      radioGroupList: [
        {
          label: "Bom",
          radioValue: "GOOD",
          radioId: "conservation-good",
          checked: false,
        },
        {
          label: "Razoável",
          radioValue: "AVERAG",
          radioId: "conservation-reasonable",
          checked: false,
        },
        {
          label: "Mau",
          radioValue: "POOR",
          radioId: "conservation-bad",
          checked: false,
        },
      ],
    },
  ],

  mechanicalState: [
    {
      title: "Desempenho do Motor",
      subtitle:
        "Luzes de gestão do motor, Ignição, progresso, fuma excessivamente ou anormalmente barulhento",
      radioGroupName: "EnginePerformance",
      radioGroupValue: "AVERAG",
      radioGroupList: [
        {
          label: "Bom",
          radioValue: "GOOD",
          radioId: "engine-performance-good",
          checked: false,
        },
        {
          label: "Razoável",
          radioValue: "AVERAG",
          radioId: "engine-performance-reasonable",
          checked: false,
        },
        {
          label: "Mau",
          radioValue: "POOR",
          radioId: "engine-performance-bad",
          checked: false,
        },
      ],
    },
    {
      title: "Desempenho na Condução",
      subtitle:
        "Embraiagem, caixa de velocidades, suspensão, direção, transmissão ou travagem",
      radioGroupName: "DrivePerformance",
      radioGroupValue: "AVERAG",
      radioGroupList: [
        {
          label: "Bom",
          radioValue: "GOOD",
          radioId: "driving-performance-good",
          checked: false,
        },
        {
          label: "Razoável",
          radioValue: "AVERAG",
          radioId: "driving-performance-reasonable",
          checked: false,
        },
        {
          label: "Mau",
          radioValue: "POOR",
          radioId: "driving-performance-bad",
          checked: false,
        },
      ],
    },
  ],
};

export { defaults };
