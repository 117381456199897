import httpClient from "./httpClient";

const FINANCE = "/finance";

function getFinance({ initialValue, nrPayments, vin }) {
  return httpClient.get(
    `${FINANCE}/${vin}?initialValue=${initialValue}&nrPayments=${nrPayments}&pdf=false`
  );
}

function getFin({ initialValue, nrPayments, vin }) {
  return httpClient.get(
    `${FINANCE}/${vin}?initialValue=${initialValue}&nrPayments=${nrPayments}&pdf=true`
  );
}

export { getFinance, getFin };
