<template>
  <Block customClass="specification">
    <div class="specification--title">
      <Label size="small" color="primary" bold="thin">{{ title }}</Label>
    </div>
    <div>
      <Checkbox
        v-if="checked"
        class="specification--checkbox"
        :checked="true"
        :disabled="true"
        :fill="true"
        type="success"
      />
      <Label
        class="specification--label"
        size="small"
        color="tertiary"
        bold="normal"
        align="right"
        >{{ specification }}</Label
      >
    </div>
  </Block>
</template>

<script>
import { Block, Checkbox, Label } from "../../atoms";
export default {
  name: "Specification",
  components: {
    Block,
    Checkbox,
    Label,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    specification: {
      type: String,
      default: "",
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="css" scoped src="./Specification.css"></style>
