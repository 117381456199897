const state = {
  phone: "",
  name: "",
  email: "",
  pass: "",
};

const getters = {
  getUser: (state) => state,
};

const mutations = {
  setUser(state, user) {
    state = {
      ...state,
      ...user,
    };
  },
};

export default {
  state,
  getters,
  mutations,
};
