import httpClient from "./httpClient";
import QueryString from "query-string";

const RESERVATIONS = "/users/me/reservations";
const PAYMENT = "/payments";

const RESERVE_TYPE = 35;

const buildQueryString = (type) => {
  const utms = JSON.parse(sessionStorage.getItem("utms")) || {};
  utms.type = type;

  let queryString = "?";

  queryString += QueryString.stringify(utms);

  return queryString;
};

function getAllReservations() {
  return httpClient.get(RESERVATIONS);
}

function createPaymentIntent(payload) {
  const queryString = buildQueryString(RESERVE_TYPE);
  return httpClient.post(PAYMENT + queryString, payload);
}

export { getAllReservations, createPaymentIntent };
