<template>
  <div class="returns">
    <div class="header">
      <Heading
        tag="h1"
        customClass="page-title"
        class="reserved-area-titles title"
        :label="$t('pages.personalPage.returns.title')"
      ></Heading>
    </div>
    <div class="content">
      <div v-if="getReturns.length > 0">
        <div v-for="(item, index) in getReturns" :key="index">
          <SavedReturn
            class="saved-return"
            :vehicle="item"
            @onDelete="deletePopup(item)"
            @onSearch="resumeSearch(item, index)"
          />
          <Popup
            v-show="isDeletePopupOpen"
            :closeButton="false"
            :closeOnClickOutside="true"
            @close="dismissDeletePopup"
            customClass="alert-test"
          >
            <template slot="body" class="dealers-list-popup--wrapper">
              <div class="main-section">
                <TextLabel
                  class="popup-title"
                  :label="$t('pages.personalPage.returns.deletePopup.title')"
                />
                <TextLabel
                  class="popup-content"
                  :label="$t('pages.personalPage.returns.deletePopup.subtitle')"
                />
                <div class="bottom--btns">
                  <Button
                    class="exit-btn"
                    :label="
                      $t('pages.personalPage.returns.deletePopup.dismissBtn')
                    "
                    type="tertiary"
                    @onClick="dismissDeletePopup"
                  />
                  <Button
                    class="exit-btn"
                    :label="
                      $t('pages.personalPage.returns.deletePopup.deleteBtn')
                    "
                    type="secondary"
                    @onClick="deleteRetakeBtn"
                  />
                </div>
              </div>
            </template>
          </Popup>
        </div>
      </div>
      <div v-else>
        <NoResults
          class="mt-10"
          :title="$t('pages.personalPage.returns.noResults.title')"
          :description="$t('pages.personalPage.returns.noResults.description')"
          :labelButton="$t('pages.personalPage.returns.noResults.button')"
          @onClick="onClickNoResultsHandler"
        ></NoResults>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Popup } from "../../../atoms";
import { TextLabel, Button, Heading } from "@sc/ds-ui-component-library";
import { SavedReturn, NoResults } from "../../../organisms";
export default {
  name: "Returns",
  components: {
    Heading,
    SavedReturn,
    NoResults,
    Popup,
    TextLabel,
    Button,
  },
  data() {
    return {
      openDeletePopup: false,
      itemToDelete: null,
    };
  },
  methods: {
    resumeSearch(item, index) {
      this.$store.commit("setReturnByIndex", index);
      this.$router.push({ name: "returnsDetails", params: { id: item.id } });
    },
    onClickNoResultsHandler() {
      this.$router.push({
        name: "Retake Evaluation",
      });
    },
    deleteRetakeBtn() {
      this.$store.dispatch("storeDeleteReturn", this.itemToDelete.id);
      this.dismissDeletePopup();
    },
    dismissDeletePopup() {
      this.openDeletePopup = false;
    },
    deletePopup(item) {
      this.openDeletePopup = true;
      this.itemToDelete = item;
    },
  },
  computed: {
    ...mapGetters(["getReturns"]),
    isDeletePopupOpen() {
      this.openDeletePopup
        ? document.body.classList.add("no-scroll-with-scrollbar")
        : document.body.classList.remove("no-scroll-with-scrollbar");
      return this.openDeletePopup;
    },
  },
  mounted() {
    this.$store.dispatch("storeGetAllReturns");
  },
};
</script>

<style lang="css" scoped>
.header {
  border-bottom: 2px solid #e4ecf0;
  padding-bottom: 1.25rem;
}

.content {
  margin-top: 1.25rem;
}

.page-title {
  font-size: 24px;
  font-weight: 500;
  font-family: Mont;
}

.saved-return {
  margin-bottom: 0.75rem;
}

.reserved-area-titles {
  --heading-xlarge-font-size: 24px;
  --heading-xlarge-line-height: 31px;
  --heading-color: var(--theme-color-secondary);
  --heading-xlarge-weight: 700;
  text-transform: uppercase;
}

.popup-title {
  --text-label-font-size: 18px;
  --text-label-line-height: 23px;
  --text-label-weight: bold;
  --text-label-color: #0a3fd7;
  padding-bottom: 8px;
  padding-top: 32px;
}
.popup-content {
  --text-label-font-size: 16px;
  --text-label-line-height: 21px;
  --text-label-weight: 500;
  --text-label-color: #333333;
  margin-top: 16px;
  margin-bottom: 36px;
}
.bottom--btns {
  display: flex;
  gap: 15px;
}
.exit-btn {
  padding: 19px 64px;
  width: 177px;
  height: 56px;
  --button-padding: 0px;
  --button-justify-content: center;
}
.alert-test ::v-deep .popup {
  width: 436px;
  height: 264px;
  text-align: center;
  align-content: center;
  overflow: hidden;
}

.alert-test ::v-deep .popup .popup-body {
  padding: 2rem;
  padding-top: 0px;
}

@media (max-width: 480px) {
  .alert-test ::v-deep .popup {
    width: auto;
    height: auto;
  }
  .bottom--btns {
    flex-direction: column;
  }
  .exit-btn {
    width: 100%;
  }
}
</style>
