<template>
  <div class="page">
    <Hero />
    <SearchBox
      :filterTags="filterTags"
      @onSearch="goToSearchPage(false, false)"
    />

    <CpQuicklinks
      @onClickSearch="goToSearchPage(true, false)"
      @onClickRetake="goToRetakePage"
      @onClickCampaign="goToCampaignPage"
      @onMonthlyValue="updateMonthlyValue"
      @onEntryValue="updateEntryValue"
      :submitDisabled="!!!monthlyValue || !+monthlyValue"
      :isMobile="isMobile"
      :isTablet="isTablet"
      :isTabletPlus="isTabletPlus"
    />

    <TrustpilotMini />

    <CpOurDifference class="differences-section" />

    <!-- TODO: Ja ha um componente para isto, chama-se CarRow -->
    <Section id="section-6" class="section-bg-white">
      <SectionHeader
        class="section-header-left section-header-margin"
        :subtitle="$t('pages.homepage.alsointerested.title')"
      />

      <div class="vehicle-cards-margin">
        <Swiper
          :customClass="'interested-swiper'"
          :spaceBetween="0"
          :pagination="false"
          freeMode
        >
          <SwiperSlide v-for="(slide, index) in getInterest" :key="slide.vin">
            <CpVehicleCard
              :v="slide"
              :key="slide.vin + 1"
              :tooltipPosition="dynamicIndex(index)"
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </Section>

    <!-- TODO: Ja ha um componente para isto, chama-se CarRow -->
    <Section id="section-7" class="section-bg-white">
      <SectionHeader
        class="section-header-left section-header-margin"
        :subtitle="$t('pages.homepage.recent.title')"
      />

      <div class="vehicle-cards-margin">
        <Swiper
          :customClass="'recent-swiper'"
          :spaceBetween="0"
          :pagination="false"
          freeMode
        >
          <SwiperSlide v-for="(slide, index) in getLatest" :key="slide.vin">
            <CpVehicleCard
              :v="slide"
              :key="slide.vin + 1"
              :tooltipPosition="dynamicIndex(index)"
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </Section>

    <Section id="section-8">
      <Heading
        class="header"
        :label="$t('pages.homepage.ourstores.title')"
        tag="h2"
      />
      <template v-if="isTablet">
        <div class="store-cards">
          <template v-for="store in stores">
            <StoreCard
              :store="store"
              :image="store.image"
              :wazeImage="wazeImage"
              :googleMapsImage="googleMapsImage"
              :key="store.id"
            ></StoreCard>
          </template>
        </div>
      </template>
      <template v-else>
        <StoreSwiper
          :wazeImage="require(`@/assets/icons/waze.svg`)"
          :googleMapsImage="require(`@/assets/icons/googleMaps.svg`)"
          :class="
            stores.length < 4
              ? 'stores-swiper-desktop-3'
              : 'stores-swiper-desktop-4'
          "
          :slides="stores"
        ></StoreSwiper>
      </template>
    </Section>

    <Section id="experiences" class="section-bg-white">
      <Heading
        class="header"
        :label="$t('pages.homepage.sections.experience.title')"
        tag="h2"
      />
      <SectionHeader
        class="section-header-left experience-section"
        :subtitle="$t('pages.homepage.sections.experience.description')"
      />
      <ExperiencesSwiper :slides="experienceCards" />
    </Section>

    <TrustpilotCarousel />

    <Section id="section-12" class="section-bg-white search-button-section">
      <div class="header-container">
        <Heading
          class="header"
          :label="$t('pages.homepage.sections.searchButtonSection.title')"
          tag="h2"
        />
        <p
          v-html="$t('pages.homepage.sections.searchButtonSection.subtitle')"
          class="subtitle"
        />
        <Button
          class="search-button"
          type="secondary"
          @onClick="goToSearchPage(false, true)"
          :label="
            $t('pages.homepage.sections.searchButtonSection.button.label')
          "
        />
      </div>
      <img
        :src="
          $t('resources.images.homepage.searchVehicleSection') ||
          require('@/assets/images/stuff/hp_bottom.webp')
        "
        class="search-button-section-content"
        width="650"
        height="500"
        :alt="$t('pages.homepage.sections.searchButtonSection.alt')"
      />
    </Section>
  </div>
</template>

<script>
import {
  Section,
  SectionHeader,
  Button,
  StoreSwiper,
  ExperiencesSwiper,
  Swiper,
  SwiperSlide,
  StoreCard,
  Heading,
} from "@sc/ds-ui-component-library";

import CpVehicleCard from "../../refactor/CpVehicleCard.vue";
import Hero from "../../refactor/Hero.vue";
import CpQuicklinks from "../../refactor/CpQuicklinks.vue";
import CpOurDifference from "../../refactor/CpOurDifference.vue";
import TrustpilotCarousel from "../../refactor/TrustpilotCarousel.vue";
import TrustpilotMini from "../../refactor/TrustpilotMini.vue";
import { PAGES } from "../../../enums/pages";

import { getPriceFormatted } from "../../../utils/currency";

import { mapGetters } from "vuex";
import { getUtmParams, getUtmURL } from "../../../utils/helpers";
import SearchBox from "../../organisms/SearchBox/SearchBox.vue";

export default {
  components: {
    Section,
    SectionHeader,
    Button,
    StoreSwiper,
    ExperiencesSwiper,
    Swiper,
    SwiperSlide,
    StoreCard,
    Heading,
    // NOT IMPLEMENTED IN LIB
    CpVehicleCard,
    // REFACTORED
    Hero,
    CpQuicklinks,
    CpOurDifference,
    TrustpilotCarousel,
    TrustpilotMini,
    SearchBox,
  },
  metaInfo() {
    return {
      title: this.$t("meta.homepage.title"),
      meta: [
        { charset: "utf-8" },
        {
          vmid: "description",
          name: "description",
          content: this.$t("meta.homepage.description"),
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: this.$t("meta.homepage.keywords"),
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.$t("meta.homepage.title"),
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.$t("meta.homepage.description"),
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: window.location.href,
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: this.$t("meta.homepage.image"),
        },
      ],
    };
  },
  data() {
    return {
      filterTags: ["citadino", "monovolume", "SUV / TT", "coupé", "comercial"],
      monthlyValue: "",
      entryValue: 500,
    };
  },
  methods: {
    updateMonthlyValue(value) {
      this.monthlyValue = value;
    },
    updateEntryValue(value) {
      this.entryValue = value;
    },
    dynamicIndex(index) {
      return index === 3 ? "left" : "right";
    },
    goToSearchPage(fromQl = false, fromBottomDiv = false) {
      if (fromQl) {
        this.$store.dispatch("SearchModule/filter/cleanAllTags");
        this.$store.commit("SearchModule/filter/setNeedle", "");
        const tag = {
          type: "monthlyDeposit",
          id: {
            monthlyPayment: `${this.monthlyValue}`,
            depositValue: `${this.entryValue}`,
          },
          value: this.$t("searchform.tags.monthlyDeposit", {
            value1: getPriceFormatted(this.monthlyValue),
            value2: getPriceFormatted(this.entryValue),
          }),
        };
        this.$store.dispatch("SearchModule/filter/addOrReplaceTag", tag);
        this.$store.commit("SearchModule/filter/setSaveTags", true);
      }

      if (fromBottomDiv) {
        this.$store.dispatch("SearchModule/filter/cleanAllTags");
        this.$store.commit("SearchModule/filter/setNeedle", "");
      }

      this.$router.push({
        name: PAGES.SEARCH,
      });
    },
    goToRetakePage() {
      this.$router.push({
        name: PAGES.RETAKE,
      });
    },
    goToCampaignPage() {
      const utms = getUtmParams();
      const utmUrl = getUtmURL(utms);
      const campaignLink =
        this.$t("pages.homepage.quicklinks.campain.campaignLink") + utmUrl;
      window.open(campaignLink);
    },
  },
  computed: {
    ...mapGetters({
      getLatest: "SearchModule/getLatest",
      getInterest: "SearchModule/getInterest",
      dealers: "StoreModule/getDealers",
      isMobile: "isMobile",
      isTablet: "isTablet",
      isTabletPlus: "isTablePlus",
    }),
    stores() {
      if (this.dealers && this.dealers.length) {
        return this.dealers.map((dealer) => {
          return {
            ...dealer,
            labels: {
              contactCost: this.$t(
                "pages.homepage.sections.dealers.contactCost"
              ),
            },
          };
        });
      }
      return [];
    },
    experienceCards() {
      return [
        {
          image:
            this.$t("resources.images.homepage.experiencesSwiperCard1") ||
            require("@/assets/images/experience-card/exp1.webp"),
          title: this.$t("pages.homepage.sections.experience.cards.0.title"),
          subtitle: this.$t(
            "pages.homepage.sections.experience.cards.0.subtitle"
          ),
          description: this.$t(
            "pages.homepage.sections.experience.cards.0.description"
          ),
          alt: this.$t("pages.homepage.sections.experience.cards.0.alt"),
        },
        {
          image:
            this.$t("resources.images.homepage.experiencesSwiperCard2") ||
            require("@/assets/images/experience-card/exp2.webp"),
          title: this.$t("pages.homepage.sections.experience.cards.1.title"),
          subtitle: this.$t(
            "pages.homepage.sections.experience.cards.1.subtitle"
          ),
          description: this.$t(
            "pages.homepage.sections.experience.cards.1.description"
          ),
          alt: this.$t("pages.homepage.sections.experience.cards.1.alt"),
        },
        {
          image:
            this.$t("resources.images.homepage.experiencesSwiperCard3") ||
            require("@/assets/images/experience-card/exp3.webp"),
          title: this.$t("pages.homepage.sections.experience.cards.2.title"),
          subtitle: this.$t(
            "pages.homepage.sections.experience.cards.2.subtitle"
          ),
          description: this.$t(
            "pages.homepage.sections.experience.cards.2.description"
          ),
          alt: this.$t("pages.homepage.sections.experience.cards.2.alt"),
        },
        {
          image:
            this.$t("resources.images.homepage.experiencesSwiperCard4") ||
            require("@/assets/images/experience-card/exp4.webp"),
          title: this.$t("pages.homepage.sections.experience.cards.3.title"),
          subtitle: this.$t(
            "pages.homepage.sections.experience.cards.3.subtitle"
          ),
          description: this.$t(
            "pages.homepage.sections.experience.cards.3.description"
          ),
          alt: this.$t("pages.homepage.sections.experience.cards.3.alt"),
        },
      ];
    },
    wazeImage() {
      return (
        this.$t("resources.images.homepage.wazeImage") ||
        require(`@/assets/icons/waze.svg`)
      );
    },
    googleMapsImage() {
      return (
        this.$t("resources.images.homepage.googleMapsImage") ||
        require(`@/assets/icons/googleMaps.svg`)
      );
    },
  },
  mounted() {
    this.$store.dispatch("SearchModule/fetchLatest");
    this.$store.dispatch("SearchModule/fetchInterest");

    this.$meta().refresh();
  },
};
</script>

<style lang="scss" scoped>
.section-bg-white {
  --section-bg-color: var(--color-7);
}

.section-header-left {
  display: flex;
  --text-label-font-size: 21px;
  --text-label-line-height: 27px;
  --text-label-weight: 600;
  --text-label-color: var(--color-1);
  gap: 16px;

  &.experience-section {
    flex-direction: column;
    align-items: flex-start;
  }
}

.swiper-slide-background-color {
  --swiper-slide-background: var(--color-6);
}

.swiper-background-color {
  background-color: var(--color-6);
}

.stores-margin {
  margin-top: 20px;
}

.stores-button-margin {
  margin-top: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.opinions-button-margin {
  margin-top: 40px;
}

.city-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.city-img {
  background-color: #c4c4c4;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.city-name-color {
  color: var(--color-1);
}

.experience-card-margin {
  margin: 0px 5px;
}

.experiences-cards-margin {
  margin: 40px 0px;
}

.search-button-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .header-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    width: var(--section-header-width);

    .header {
      --heading-color: var(--color-1);
    }

    .subtitle {
      margin-top: 24px;
      --text-label-font-size: 21px;
      font-size: var(--text-label-font-size);
      line-height: var(--text-label-line-height);
      font-weight: var(--text-label-weight);
      color: var(--text-label-color);
      word-break: var(--text-label-word-break);
    }

    .search-button {
      margin-top: 48px;
    }
  }
}

.page {
  --section-padding: 4rem;
}

#quicklinks {
  --section-padding: 16px;
}

#section-12 {
  padding-bottom: 112px;
  padding-top: 112px;
  display: flex;

  img {
    margin: 0 0 0 72px;
  }
}

.differences-section {
  ::v-deep {
    .swiper-slide {
      max-width: 448px;
      --diff-swiper-slide-max-width: 452px;
      --diff-swiper-slide-background: var(--color-7);
      --diff-swiper-slide-padding: 8px;

      &:first-child {
        --diff-swiper-slide-padding: 8px 8px 8px 4px;
      }

      &:last-child {
        --diff-swiper-slide-padding: 8px 4px 8px 8px;
      }
    }
  }
}

#section-6,
#section-7 {
  ::v-deep {
    .section-header {
      .text-label {
        color: var(--color-1);
      }
    }
    .swiper-slide {
      max-width: 448px;
      --swiper-slide-background: var(--color-7);
      --swiper-slide-padding: 8px;

      &:first-child {
        --swiper-slide-padding: 8px 8px 8px 0px;
      }

      &:last-child {
        --swiper-slide-padding: 8px 0px 8px 8px;
      }
    }
  }
}

#experiences {
  --exp-card-max-width: 100%;
  --exp-card-wrapper-max-width: 100%;
  --section-header-margin: 0 0 32px 0;
  --card-header-image-fit: cover;

  .header {
    --heading-color: var(--color-1);
    margin-bottom: 16px;
  }

  ::v-deep {
    .section-header {
      --section-header-width: 100%;
      --title-color: var(--color-1);
      --text-label-color: var(--color-1);
    }

    .swiper-slide {
      max-width: 452px;
      --exp-swiper-slide-max-width: 452px;
      --exp-swiper-slide-background: var(--color-7);
      --exp-swiper-slide-padding: 8px;

      --text-label-color: var(--color-1);
      --text-label-font-weight: 500;
      --text-label-font-size: 18px;
      --text-label-line-height: 23px;

      .header {
        .subtitle {
          --subtitle-weight-bold: 800;
          --subtitle-line-height: 28px;
          min-height: 60px;
        }
      }

      &:first-child {
        max-width: 446px;
        --exp-swiper-slide-max-width: 446px;
        --exp-swiper-slide-padding: 8px 8px 8px 4px;
      }

      &:last-child {
        max-width: 446px;
        --exp-swiper-slide-max-width: 446px;
        --exp-swiper-slide-padding: 8px 4px 8px 8px;
      }
    }
  }
}

#section-8 {
  padding-top: 64px;
  padding-bottom: 64px;
  --store-swiper-slide-background: var(--color-6);
  --section-header-margin: 0 auto 32px auto;
  --card-background-color: var(--color-6);
  --swiper-container-background: var(--color-6);
  --accordion--item-content-bg-color: var(--color-6);

  .header {
    --heading-color: var(--color-1);
    margin: var(--section-header-margin);
    text-align: center;
    width: var(--section-header-width);
  }

  ::v-deep {
    .accordion--item .accordion--item-title:hover {
      --accordion--item-title-bg-hover: var(--color-6);
    }

    .title {
      --title-color: var(--color-1);
      text-align: center;
    }

    .card {
      width: 436px;
    }
  }
}

.vehicle-cards-margin {
  margin-top: 20px;
  ::v-deep .tooltip {
    .left {
      transform: translate(80%, 0%);
    }
    .top {
      --tooltip-transform-x: translateX(-5%);
    }
  }
}

.stores-swiper-desktop-3 {
  --swiper-wrapper-justify: center;
  --store-swiper-slide-max-width: 452px;
  --store-card-max-width: 452px;
  --card-padding: 8px;
}

.stores-swiper-desktop-4 {
  --swiper-wrapper-justify: left;
  --store-swiper-slide-max-width: 452px;
  --store-card-max-width: 452px;
  --card-padding: 8px;
}

.store-cards {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  ::v-deep {
    --store-card-max-width: 436px;
    --card-padding: 16px 0px;
  }
}

.quicklinks-section {
  --text-label-font-size: 28px;
  --text-label-line-height: 36px;
  --text-label-weight: 700;
}

@media (max-width: 1680px) {
  #section-1 {
    --section-padding: 80px 52px;
    --heading-xlarge-font-size: 38px;
    --heading-xlarge-line-height: 49px;
    --heading-weight-bold: 700;
  }

  .quicklinks-section {
    --block-padding: 0px;
    --text-label-font-size: 21px;
    --text-label-line-height: 27px;
    --text-label-font-weight: 700;

    ::v-deep .block--content {
      padding: 16px;
    }
  }

  .stores-swiper-desktop-3 {
    --swiper-wrapper-justify: center;
  }

  #section-mini {
    --section-padding: 52px;
  }

  #section-5 {
    --heading-weight-bold: 700;
    --heading-large-font-size: 28px;
    --heading-large-line-height: 36px;
    --section-padding: 52px;
    --section-header-margin: 52px;
  }

  ::v-deep .carousel {
    --heading-weight-bold: 700;
    --heading-large-font-size: 28px;
    --heading-large-line-height: 36px;
  }

  .differences-section {
    ::v-deep {
      .swiper-slide {
        max-width: 333px;
        --diff-swiper-slide-max-width: 333px;
        --diff-swiper-slide-background: var(--color-7);
        --diff-swiper-slide-padding: 8px;
        --heading-medium-font-size: 21px;
        --heading-medium-line-height: 27px;
        --heading-weight-bold: 700;
        --diff-card-vertical-padding: 20px;
        --diff-card-horizontal-padding: 20px;
        --diff-card-height: 415px;
        --text-label-font-size: 16px;
        --text-label-line-height: 20px;
        --text-label-weight: 500;

        &:first-child {
          --diff-swiper-slide-padding: 8px 8px 8px 4px;
        }

        &:last-child {
          --diff-swiper-slide-padding: 8px 4px 8px 8px;
        }
      }
    }
  }

  #section-6,
  #section-7 {
    --section-padding: 52px;
    ::v-deep {
      .swiper-slide {
        max-width: 333px;
        --swiper-slide-background: var(--color-7);
        --swiper-slide-padding: 8px;

        &:first-child {
          --swiper-slide-padding: 8px 8px 8px 0px;
        }

        &:last-child {
          --swiper-slide-padding: 8px 0px 8px 8px;
        }
      }
    }
  }

  #section-8 {
    --heading-weight-bold: 700;
    --heading-large-font-size: 28px;
    --heading-large-line-height: 36px;
    --section-padding: 52px;
    padding-top: 52px;
    padding-bottom: 52px;

    ::v-deep {
      .card {
        width: 322px;
      }

      .swiper-slide {
        max-width: 322px;
      }

      .swiper-wrapper {
        gap: 16px;
      }
    }
  }

  #experiences {
    --heading-weight-bold: 700;
    --heading-large-font-size: 28px;
    --heading-large-line-height: 36px;
    --section-padding: 52px;

    .experience-section {
      --text-label-font-size: 18px;
      --text-label-line-height: 23px;
      --text-label-weight: 600;
    }

    ::v-deep {
      .section-header {
        --section-header-width: 100%;
        --title-color: var(--color-1);
        --text-label-color: var(--color-1);
      }

      .swiper-slide {
        max-width: 333px;
        --exp-swiper-slide-max-width: 333px;
        --exp-swiper-slide-background: var(--color-7);
        --exp-swiper-slide-padding: 8px;

        --text-label-color: var(--color-1);
        --text-label-font-weight: 500;
        --text-label-font-size: 18px;
        --text-label-line-height: 23px;

        .header {
          .subtitle {
            --subtitle-weight-bold: 700;
            --subtitle-font-size: 21px;
            --subtitle-line-height: 21px;
            min-height: 45px;
          }
        }

        .content {
          --exp-card-content-margin-top: 8px;
          --text-label-font-size: 16px;
          --text-label-line-height: 21px;
          --text-label-weight: 500;
        }

        &:first-child {
          max-width: 329px;
          --exp-swiper-slide-max-width: 329px;
          --exp-swiper-slide-padding: 8px 8px 8px 4px;
        }

        &:last-child {
          max-width: 329px;
          --exp-swiper-slide-max-width: 329px;
          --exp-swiper-slide-padding: 8px 4px 8px 8px;
        }
      }
    }
  }

  ::v-deep {
    #experiences {
      flex-direction: column;

      .section-header {
        --section-header-width: 100%;
      }
    }
  }

  #section-carousel {
    --section-padding: 52px;
    --heading-weight-bold: 700;
    --heading-large-font-size: 28px;
    --heading-large-line-height: 36px;
    gap: 52px;

    ::v-deep .carousel {
      gap: 52px;
    }
  }

  #section-12 {
    --section-padding: 52px;
    padding-top: 52px;
    padding-bottom: 52px;
    --heading-large-font-size: 28px;
    --heading-large-line-height: 36px;
    --heading-weight-bold: 700;

    img {
      margin: 0 0 0 52px;
    }

    ::v-deep .subtitle {
      --text-label-font-size: 18px;
      --text-label-line-height: 23px;
      --text-label-weight: 600;
    }

    img {
      width: 550px;
    }
  }
}
@media (max-width: 1367px) {
  #section-1 {
    --section-padding: 80px 40px;
  }

  #section-mini {
    --section-padding: 40px;
  }

  #section-5 {
    --section-padding: 40px;
    --section-header-margin: 40px;
  }

  .differences-section {
    ::v-deep {
      .swiper-slide {
        max-width: 320px;
        --diff-swiper-slide-max-width: 320px;
      }
    }
  }

  #section-6,
  #section-7 {
    --section-padding: 40px;
    ::v-deep {
      .swiper-slide {
        max-width: 320px;
      }
    }
  }

  #section-8 {
    --section-padding: 40px;
    padding-top: 40px;
    padding-bottom: 40px;

    ::v-deep {
      .card {
        width: 310px;
      }

      .swiper-slide {
        max-width: 310px;
      }
    }
  }

  #experiences {
    --section-padding: 40px;

    ::v-deep {
      .swiper-slide {
        max-width: 320px;
        --exp-swiper-slide-max-width: 320px;

        &:first-child {
          max-width: 316px;
          --exp-swiper-slide-max-width: 316px;
        }

        &:last-child {
          max-width: 316px;
          --exp-swiper-slide-max-width: 316px;
        }
      }
    }
  }

  #section-carousel {
    --section-padding: 40px;
    gap: 40px;

    ::v-deep .carousel {
      gap: 40px;
    }
  }

  #section-12 {
    --section-padding: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
    img {
      margin: 0 0 0 40px;
    }
  }
}
@media (max-width: 1280px) {
  #section-1 {
    --section-padding: 80px 28px;
  }

  #section-mini {
    --section-padding: 28px;
  }

  #section-5 {
    --section-padding: 28px;
    --section-header-margin: 28px;
  }

  .differences-section {
    ::v-deep {
      .swiper-slide {
        max-width: 305px;
        --diff-swiper-slide-max-width: 305px;
      }
    }
  }

  #section-6,
  #section-7 {
    --section-padding: 28px;
    ::v-deep {
      .swiper-slide {
        max-width: 305px;
      }
    }
  }

  #section-8 {
    --section-padding: 28px;
    padding-top: 28px;
    padding-bottom: 28px;

    ::v-deep {
      .card {
        width: 294px;
      }

      .swiper-slide {
        max-width: 294px;
      }
    }
  }

  #experiences {
    --section-padding: 28px;

    ::v-deep {
      .swiper-slide {
        max-width: 305px;
        --exp-swiper-slide-max-width: 305px;

        &:first-child {
          max-width: 301px;
          --exp-swiper-slide-max-width: 301px;
        }

        &:last-child {
          max-width: 301px;
          --exp-swiper-slide-max-width: 301px;
        }
      }
    }
  }

  #section-carousel {
    --section-padding: 28px;
    gap: 28px;

    ::v-deep .carousel {
      gap: 28px;
    }
  }

  #section-12 {
    --section-padding: 28px;
    padding-top: 28px;
    padding-bottom: 28px;
    img {
      margin: 0 0 0 28px;
    }
  }
}
@media (max-width: 1024px) {
  .stores-swiper-desktop-3 {
    --swiper-wrapper-justify: left;
  }
}
@media (max-width: 768px) {
  .differences-section {
    ::v-deep {
      .swiper-slide {
        --diff-card-height: 470px;
      }
    }
  }
  .page {
    --section-header-width: 100%;
    --exp-card-height: 580px;
    --section-padding: 64px 16px;

    #section-12 {
      padding-bottom: 80px;
      padding-top: 80px;

      img {
        margin: 28px 0 0 0;
      }
    }
    #section-1 {
      --section-padding: 64px 28px 32px 28px;
    }

    #section-5 {
      padding-bottom: 16px;
    }

    #section-6 {
      padding-bottom: 16px;
    }

    #section-7 {
      padding-bottom: 80px;
    }

    #section-8 {
      padding-top: 80px;
      padding-bottom: 80px;
    }

    #section-mini {
      padding-bottom: 16px;
    }

    #experiences {
      padding-top: 80px;
      margin-top: 0px;
    }

    .swiper-slide {
      width: 327px;
      --swiper-slide-padding: 8px 0px;
      --exp-swiper-slide-padding: 8px 0px;
      --diff-swiper-slide-padding: 8px 0px 8px 2px;
      margin-right: 0px;
    }

    .search-button-section {
      .search-button {
        width: 100%;
      }

      .btn {
        justify-content: center;
      }
    }
  }

  #section-8 {
    ::v-deep {
      .card {
        width: 343px;
      }

      .btn {
        width: 100%;
        --button-justify-content: center;
      }
    }
  }

  .search-button-section {
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  .stores-swiper-desktop-3 {
    --store-swiper-slide-max-width: 343px;
    --store-card-max-width: 343px;
    --card-padding: 12px;

    &:first-child {
      max-width: 367px;
      --exp-swiper-slide-max-width: 367px;
      --exp-swiper-slide-padding: 8px 8px 8px 2px;
    }

    &:last-child {
      max-width: 446px;
      --exp-swiper-slide-max-width: 367px;
      --exp-swiper-slide-padding: 8px 2px 8px 8px;
    }
  }

  .stores-swiper-desktop-4 {
    --store-swiper-slide-max-width: 343px;
    --store-card-max-width: 343px;
    --card-padding: 8px 12px;
  }

  ::v-deep .tooltip .tooltip--text {
    --tooltip-width-large: 360px;
  }

  ::v-deep .tooltip {
    .left {
      --tooltip-postion-y: -550%;
    }
    .top {
      transform: translate(-5%, 18%);
    }
  }

  ::v-deep {
    .store-cards {
      flex-direction: column;
      align-content: center;

      & > * {
        margin: 12px 0;
      }

      & > *:first-child {
        margin: 0 0 12px 0;
      }

      & > *:last-child {
        margin: 12px 0 0 0;
      }
    }
  }

  .header {
    --heading-large-font-size: var(--subtitle-1-font-size);
    --heading-large-line-height: var(--subtitle-1-line-height);
  }
}
@media (max-width: 375px) {
}

/**
* * For smaller height resolutions
*/

@media (max-height: 750px) {
  ::v-deep {
    #section-1 {
      --section-padding: 30px 28px 15px 28px;
    }
    .hero-btns {
      margin-top: 10px;
    }
  }
}

#section-1 {
  --section-overflow: unset !important;
  overflow: initial;
}
</style>
