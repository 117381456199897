import ArrowDown from "./ArrowDown.vue";
import ArrowLeft from "./ArrowLeft.vue";
import ArrowRight from "./ArrowRight.vue";
import Avatar from "./Avatar.vue";
import Calendar from "./Calendar.vue";
import Check from "./Check.vue";
import CheckboxDefault from "./CheckboxDefault.vue";
import CheckboxFilled from "./CheckboxFilled.vue";
import ChevronLeft from "./ChevronLeft.vue";
import ChevronDown from "./ChevronDown.vue";
import ChevronRight from "./ChevronRight.vue";
import ChevronUp from "./ChevronUp.vue";
import Clock from "./Clock.vue";
import Close from "./Close.vue";
import Compare from "./Compare.vue";
import ExclamationMark from "./ExclamationMark.vue";
import Eye from "./Eye.vue";
import EyeSlash from "./EyeSlash.vue";
import Faq from "./Faq.vue";
import Filter from "./Filter.vue";
import Heart from "./Heart.vue";
import Info from "./Info.vue";
import Like from "./Like.vue";
import Message from "./Message.vue";
import Phone from "./Phone.vue";
import RadioButtonOff from "./RadioButtonOff.vue";
import RadioButtonOn from "./RadioButtonOn.vue";
import Search from "./Search.vue";
import Share from "./Share.vue";
import Success from "./Success.vue";
import WarningIcon from "./Warning.vue";

export default {
  "arrow-down": ArrowDown,
  "arrow-left": ArrowLeft,
  "arrow-right": ArrowRight,
  avatar: Avatar,
  calendar: Calendar,
  check: Check,
  checkbox: CheckboxDefault,
  "checkbox-filled": CheckboxFilled,
  "chevron-left": ChevronLeft,
  "chevron-down": ChevronDown,
  "chevron-right": ChevronRight,
  "chevron-up": ChevronUp,
  clock: Clock,
  close: Close,
  compare: Compare,
  "exclamation-mark": ExclamationMark,
  eye: Eye,
  "eye-slash": EyeSlash,
  faq: Faq,
  filter: Filter,
  heart: Heart,
  info: Info,
  like: Like,
  message: Message,
  phone: Phone,
  "radio-button-off": RadioButtonOff,
  "radio-button-on": RadioButtonOn,
  search: Search,
  share: Share,
  success: Success,
  warning: WarningIcon,
};
