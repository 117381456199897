var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['vehicle-specifications', _vm.classes, _vm.customClass],attrs:{"styles":_vm.customStyles}},[_c('div',{staticClass:"vehicle-specifications--title"},[_c('Label',{attrs:{"bold":"normal","color":"primary","size":"medium"}},[_vm._v(_vm._s(_vm.title))])],1),_c('div',{staticClass:"vehicle-specifications--list"},[_c('div',{staticClass:"vehicle-specifications--left"},_vm._l((_vm.itemsHead),function(item,index){return _c('Specification',{key:index,staticClass:"vehicle-specifications--item",attrs:{"title":item.id
            ? item.title
            : _vm.$t(
                `pages.detailsPage.details.vehicleSpecifications.${item.title}`
              ),"specification":_vm.parseDesc(item.title, item.description),"checked":item.checked}})}),1),_c('div',{staticClass:"vehicle-specifications--right"},_vm._l((_vm.itemsTail),function(item,index){return _c('Specification',{key:index,staticClass:"vehicle-specifications--item",attrs:{"title":item.id
            ? item.title
            : _vm.$t(
                `pages.detailsPage.details.vehicleSpecifications.${item.title}`
              ),"specification":_vm.parseDesc(item.title, item.description),"checked":item.checked}})}),1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }