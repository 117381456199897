export const GOOGLE_TAG_MANAGER_EVENT_TYPES = {
  PRODUCT_CLICK: "eec.productClick",
  PRODUCT_VIEW: "eec.productView",
  PRODUCT_ADD_TO_CARD: "eec.productAddtoCart",
  PRODUCT_REMOVE_FROM_CART: "eec.productRemoveCart",
  PRODUCT_PURCHASE: "eec.purchase",

  SEARCH_BOX: "eec.searchBox",
  SEARCH_FORM: "eec.searchForm",
  SAVE_SEARCH: "eec.saveSearch",
  ORDER_BY: "eec.orderBy",
  COMPARE: "eec.compare",
  ADD_TO_COMPARE: "eec.addToCompare",
  REMOVE_FROM_COMPARE: "eec.removeFromCompare",
  ADD_TO_FAV: "eec.addToFavorite",
  REMOVE_FROM_FAV: "eec.removeFromFavorite",
  UPDATE_SIM: "eec.updateSimulation",
  EVAL_NO_LOGIN: "eec.evaluationStepNoLogin",
  EVAL_STEP_1: "eec.evaluationStep1",
  EVAL_STEP_2: "eec.evaluationStep2",
  EVAL_STEP_3: "eec.evaluationStep3",
  EVAL_STEP_4: "eec.evaluationStep4",
  RESERVE_STEP_1: "eec.reserveStep1",
  RESERVE_STEP_2: "eec.reserveStep2",
  RESERVE_STEP_3: "eec.reserveStep3",
  RESERVE_STEP_NO_LOGIN: "eec.reserveStepNoLogin",
  RESERVE_STEP_5: "eec.reserveStep5",
  RESERVE_STEP_6: "eec.reserveStep6",
  RESERVE_STEP_7: "eec.reserveStep7",
  SHARE: "eec.share",
  NEWSLETTER: "eec.newsletter",
  QUANDO_DISPONIVEL: "eec.quandoDisponivel",
  FINANCIAMENTO: "eec.financiamento",
  REGISTRATION: "eec.registo",
  LOGIN: "eec.login",
};

const ACTIONS = {
  [GOOGLE_TAG_MANAGER_EVENT_TYPES.PRODUCT_CLICK]: "click",
  [GOOGLE_TAG_MANAGER_EVENT_TYPES.PRODUCT_VIEW]: "detail",
  [GOOGLE_TAG_MANAGER_EVENT_TYPES.PRODUCT_ADD_TO_CARD]: "add",
  [GOOGLE_TAG_MANAGER_EVENT_TYPES.PRODUCT_REMOVE_FROM_CART]: "remove",
  [GOOGLE_TAG_MANAGER_EVENT_TYPES.PRODUCT_PURCHASE]: "purchase",
};

export const buildGoogleTagManagerPayload = (
  eventType,
  currentPage,
  vehicle
) => {
  return {
    currencyCode: "EUR",
    [ACTIONS[eventType]]: {
      actionField: {
        list: [currentPage],
      },
      products: [
        {
          id: vehicle.vin,
          name: `${vehicle.model} + ${vehicle.commercialDescription}`,
          category: vehicle.segment,
          quantity: 1,
          price: vehicle.pricePvp,
        },
      ],
    },
  };
};

export const pushNewEvent = (eventType, currentPage, vehicle) => {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: eventType,
    ecommerce: buildGoogleTagManagerPayload(eventType, currentPage, vehicle),
  });
};

export const pushEvent = (type, e) => {
  let event = e;
  window.dataLayer = window.dataLayer || [];
  event["event"] = type;
  event["timestamp"] = new Date().getTime();
  window.dataLayer.push(event);
  window.dataLayer.push(function () {
    this.reset();
  });
};

export const ecommerceBuilder = (
  vehicle,
  rendered_page = undefined,
  card_position = undefined
) => {
  return {
    id: vehicle.vin,
    brand: vehicle.brand,
    model: vehicle.model,
    version: vehicle.version,
    price: vehicle.totalPrice,
    transmission_type: vehicle.transmission,
    fuel_type: vehicle.fuel,
    vehicle_year: vehicle.year,
    kilometers: vehicle.kilometers,
    rendered_page: rendered_page,
    card_position: card_position,
    installment_value: vehicle.tooltipDescription.monthPrice,
    taeg: vehicle.tooltipDescription.taeg,
    term: vehicle.tooltipDescription.months,
    down_payment: vehicle.tooltipDescription.depositValue,
    total_amount: vehicle.tooltipDescription.mtic,
  };
};
export const searchBuilder = (term, items_found, filters) => {
  let search = {
    term,
    items_found,
  };
  if (filters) {
    search["filters"] = filtersBuilder(filters);
  }
  return search;
};
export const filtersBuilder = (filters) => {
  const monthly_payment = {
    installment_value: filters.find((f) => f.type == "monthlyDeposit")?.id
      ?.monthlyPayment,
    down_payment: filters.find((f) => f.type == "monthlyDeposit")?.id
      ?.depositValue,
  };

  const price = {
    min: filters.find((f) => f.type == "price")?.id?.priceInf,
    max: filters.find((f) => f.type == "price")?.id?.priceSup,
  };

  const segment = filters
    .filter((f) => f.type == "segment")
    .map((f) => f.value);
  const brand = filters.filter((f) => f.type == "brands").map((f) => f.value);
  const model = filters
    .filter((f) => f.type == "brands")
    .map((f) => f.children.filter((c) => c.selected))
    .flat(1)
    .map((c) => c.value);
  const year = {
    min: filters.find((f) => f.type == "year")?.id?.yearInf,
    max: filters.find((f) => f.type == "year")?.id?.yearSup,
  };
  const kilometers = {
    min: filters.filter((f) => f.type == "km").map((f) => f.id?.kmInf)[0],
    max: filters.filter((f) => f.type == "km").map((f) => f.id?.kmSup)[0],
  };

  const fuel_type = filters.filter((f) => f.type == "fuel").map((f) => f.value);

  return {
    monthly_payment,
    price,
    segment,
    brand,
    model,
    year,
    kilometers,
    fuel_type,
  };
};

export const fieldsBuilder = (fields) => {
  return {
    brand: fields.brand,
    model: fields.model,
    version: fields.version,
    vehicle_year: fields.vehicle_year,
    kilometers: fields.kilometers,
    intention: fields.intention,
    dealer: fields.dealer,
  };
};

export const productClickEvent = (vehicle, rendered_page, card_position) => {
  let event = {};
  const type = GOOGLE_TAG_MANAGER_EVENT_TYPES.PRODUCT_CLICK;

  event["ecommerce"] = ecommerceBuilder(vehicle, rendered_page, card_position);

  pushEvent(type, event);
};

export const searchBoxEvent = (needle, pagination) => {
  let event = {};
  const type = GOOGLE_TAG_MANAGER_EVENT_TYPES.SEARCH_BOX;
  event["search"] = searchBuilder(needle, pagination.totalResults, null);
  pushEvent(type, event);
};

export const searchFormEvent = (needle, pagination, filters) => {
  let event = {};
  const type = GOOGLE_TAG_MANAGER_EVENT_TYPES.SEARCH_FORM;
  event["search"] = searchBuilder(needle, pagination.totalResults, filters);
  pushEvent(type, event);
};

export const searchEvent = (needle, pagination, filters) => {
  const filtersArray = filters.filter((f) => f.type != "needle");
  if (needle && filtersArray.length == 0) {
    searchBoxEvent(needle, pagination);
  } else if (filtersArray.length > 0) {
    searchFormEvent(needle, pagination, filtersArray);
  }
};

export const saveSearchEvent = (filters) => {
  let event = {};
  const type = GOOGLE_TAG_MANAGER_EVENT_TYPES.SAVE_SEARCH;
  event["search"] = searchBuilder(undefined, undefined, filters);

  pushEvent(type, event);
};

export const orderByEvent = (orderBy, items_found) => {
  let event = {};
  const type = GOOGLE_TAG_MANAGER_EVENT_TYPES.ORDER_BY;
  event["search"] = searchBuilder(orderBy, items_found, null);

  pushEvent(type, event);
};

export const compareEvent = (comparedVehicles) => {
  let event = {};
  let items = [];
  for (let i = 0, len = comparedVehicles.length; i < len; i++) {
    items[i] = ecommerceBuilder(comparedVehicles[i]);
  }
  const type = GOOGLE_TAG_MANAGER_EVENT_TYPES.COMPARE;
  event["compare"] = {
    number_of_items_compared: comparedVehicles.length,
    items,
  };

  pushEvent(type, event);
};

export const addRemoveCompareEvent = (vehicle, add) => {
  let event = {};
  const type = add
    ? GOOGLE_TAG_MANAGER_EVENT_TYPES.ADD_TO_COMPARE
    : GOOGLE_TAG_MANAGER_EVENT_TYPES.REMOVE_FROM_COMPARE;
  event["ecommerce"] = ecommerceBuilder(vehicle);

  pushEvent(type, event);
};

export const addRemoveFavoritesEvent = (vehicle, add) => {
  let event = {};
  const type = add
    ? GOOGLE_TAG_MANAGER_EVENT_TYPES.ADD_TO_FAV
    : GOOGLE_TAG_MANAGER_EVENT_TYPES.REMOVE_FROM_FAV;
  event["ecommerce"] = ecommerceBuilder(vehicle);

  pushEvent(type, event);
};

export const updateSimulationEvent = (
  vehicle,
  { down_payment, number_of_months, retake_added },
  rendered_page
) => {
  let event = {};
  const type = GOOGLE_TAG_MANAGER_EVENT_TYPES.UPDATE_SIM;
  event["down_payment"] = down_payment;
  event["number_of_months"] = number_of_months;
  event["retake_added"] = retake_added;
  event["ecommerce"] = ecommerceBuilder(vehicle, rendered_page, undefined);

  pushEvent(type, event);
};
export const evaluationStepEvent = (vehicle, step, login) => {
  let event = {};
  const type = login
    ? GOOGLE_TAG_MANAGER_EVENT_TYPES[`EVAL_STEP_${step}`]
    : GOOGLE_TAG_MANAGER_EVENT_TYPES.EVAL_NO_LOGIN;
  event["fields"] = fieldsBuilder(vehicle);

  pushEvent(type, event);
};

export const reserveStepEvent = (
  vehicle,
  step,
  { retake_added, simulated_financing, delivery_details },
  login
) => {
  let event = {};
  const type = login
    ? GOOGLE_TAG_MANAGER_EVENT_TYPES[`RESERVE_STEP_${step}`]
    : GOOGLE_TAG_MANAGER_EVENT_TYPES[`RESERVE_STEP_NO_LOGIN`];
  event["ecommerce"] = ecommerceBuilder(vehicle);
  event["fields"] = {
    retake_added: retake_added,
    simulated_financing: simulated_financing,
    delivery_details: delivery_details,
  };

  pushEvent(type, event);
};

export const shareEvent = (vehicle, source) => {
  let event = {};
  const type = GOOGLE_TAG_MANAGER_EVENT_TYPES.SHARE;
  event["source"] = source;
  event["ecommerce"] = ecommerceBuilder(vehicle);

  pushEvent(type, event);
};

export const purchaseEvent = (vehicle, { value, id }) => {
  let event = {};
  const type = GOOGLE_TAG_MANAGER_EVENT_TYPES.PRODUCT_PURCHASE;
  event["currency"] = "eur";
  event["payment_type"] = "card";

  event["value"] = value;
  event["transaction_id"] = id;
  event["ecommerce"] = [ecommerceBuilder(vehicle)];
  if (event?.ecommerce[0]?.brand && event?.ecommerce[0]?.model) {
    event.ecommerce[0].name =
      event.ecommerce[0].brand +
      " " +
      event.ecommerce[0].model +
      " - " +
      event.ecommerce[0].version;
  }

  pushEvent(type, event);
};

export const newsLetterEvent = () => {
  let event = {};
  const type = GOOGLE_TAG_MANAGER_EVENT_TYPES.NEWSLETTER;

  pushEvent(type, event);
};

export const quandoDisponivelEvent = () => {
  let event = {};
  const type = GOOGLE_TAG_MANAGER_EVENT_TYPES.QUANDO_DISPONIVEL;

  pushEvent(type, event);
};

export const financiamentoEvent = ({ brand, model }) => {
  let event = {};
  const type = GOOGLE_TAG_MANAGER_EVENT_TYPES.FINANCIAMENTO;

  event["brand"] = brand;
  event["model"] = model;

  pushEvent(type, event);
};

export const registrationEvent = () => {
  let event = {};
  const type = GOOGLE_TAG_MANAGER_EVENT_TYPES.REGISTRATION;
  pushEvent(type, event);
};

export const loginEvent = () => {
  let event = {};
  const type = GOOGLE_TAG_MANAGER_EVENT_TYPES.LOGIN;
  pushEvent(type, event);
};
