import httpClient from "./httpClient";

function getDynamicRoutes(route) {
  return httpClient.get("/routes/search", {
    params: {
      route,
      fe: true,
    },
  });
}

export { getDynamicRoutes };
