<template>
  <div v-if="showExtended" class="extended-form--wrapper">
    <div class="filters--row">
      <Dropdown
        multiple
        mode="check"
        :items="getDropdowns.transmission"
        :name="$t('searchform.dropdowns.transmission')"
        @onItemClick="(e) => onItemClick('transmission', e)"
        ref="transmission"
        :key="`transmission-${getKey('transmission')}`"
      />
      <Dropdown
        multiple
        mode="check"
        :items="getDropdowns.power"
        :name="$t('searchform.dropdowns.power')"
        @onItemClick="(e) => onItemClick('power', e)"
        ref="power"
        :key="`power-${getKey('power')}`"
      />
      <Dropdown
        multiple
        mode="check"
        :items="getDropdowns.seats"
        :name="$t('searchform.dropdowns.seats')"
        @onItemClick="(e) => onItemClick('seats', e)"
        ref="seats"
        :key="`seats-${getKey('seats')}`"
      />
      <Dropdown
        mode="check"
        :items="getDropdowns.dealers"
        :name="$t('searchform.dropdowns.dealer')"
        @onItemClick="(e) => onItemClick('dealer', e)"
        ref="dealer"
        :key="`dealer-${getKey('dealer')}`"
      />
    </div>
    <div class="tag-category--row">
      <TagGroup
        v-for="(tags, tag) in getTagGroups"
        :key="tag"
        customClass="tag-group"
        customTagClass="tag-group--tag"
        roundTags
        :tags="tags"
        :label="$t('searchform.taggroups.' + tag)"
        @onClick="(e) => handleTagGroupClick(tag, e)"
      />
    </div>
    <div class="filters-checkboxes--row">
      <Dropdown
        mode="check"
        :items="getDropdowns.district"
        :name="$t('searchform.dropdowns.location')"
        @onItemClick="(e) => onItemClick('location', e)"
        ref="location"
        :key="`location-${getKey('location')}`"
      />
    </div>
  </div>
</template>

<script>
import { TagGroup, Dropdown } from "@sc/ds-ui-component-library";
import { mapGetters } from "vuex";

export default {
  name: "SearchFormExtra",
  components: { TagGroup, Dropdown },
  props: {
    showExtended: {
      type: Boolean,
    },
  },
  data() {
    return {
      dropdowns: ["transmission", "power", "seats"],
    };
  },
  methods: {
    calcIndex(idx, dropdown) {
      return `${idx}-${dropdown}-${this.getDropdowns[dropdown]?.length}`;
    },
    onItemClick(dropdown, e) {
      this.$emit("onItemClick", {
        dropdown,
        value: {
          ...e,
          value: this.$t(`searchform.tags.${dropdown}`, { value: e.value }),
        },
      });
    },
  },
  computed: {
    ...mapGetters({
      getColors: "SearchModule/dropdown/getColors",
      getTagGroups: "SearchModule/dropdown/getTagGroups",
      getDropdowns: "SearchModule/dropdown/getDropdowns",
      getKey: "SearchModule/dropdown/getKey",
    }),
  },
};
</script>

<style lang="scss" scoped>
.filters--row {
  display: flex;
  align-items: center;
  gap: 16px;
}

.tag-category--row {
  display: flex;
  align-items: center;
  gap: 30px;
}

::v-deep .tag-group {
  --group-tag-display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px 20px;
  margin-top: 10px;
}

::v-deep .tag-group--tag {
  padding: 8px 23px;
  width: 100%;
  justify-content: center;
}

.filters-checkboxes--row {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  gap: 16px;
}

::v-deep .complete {
  height: 56px;
  border: none;
}

::v-deep .dropdown--wrapper {
  width: 100%;
  --dropdown-header-box-background-color: var(--color-7);
}
.extended-form--wrapper {
  width: 100%;
  z-index: 100;
  padding: 48px;
  gap: 48px;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  background-color: var(--color-5);
  border-top: 2px solid var(--color-7);
}

@media (min-width: 1467px) {
  .extended-form--wrapper {
    z-index: 100;
    padding: 48px;
    gap: 48px;
    display: flex;
    flex-direction: column;
    width: 1340px;
    position: absolute;
    right: 0;
    background-color: var(--color-5);
    border-top: 2px solid var(--color-7);
  }
}
</style>
