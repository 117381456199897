<template>
  <div class="search-grid--container">
    <template v-for="(v, idx) in vehicles">
      <CpVehicleCard
        :v="v"
        :key="`${idx}-${v.vin}`"
        :relativePos="idx + 1 + (getCurrentPage - 1) * vehicles.length"
        @click.native="onClick(idx, v.vin)"
      />
      <div
        v-if="(idx + 1) % 5 === 0"
        :key="`${v.vin}-${idx}`"
        class="special--box"
      >
        <div class="special--box-container">
          <img :src="getImg(idx)" class="image" :alt="getAlt(idx)" />
          <TextLabel customClass="text" :label="getLabel(idx)" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import CpVehicleCard from "../../refactor/CpVehicleCard.vue";
import { TextLabel } from "@sc/ds-ui-component-library";
import { mapGetters } from "vuex";
import { FOOTPRINT_TYPES, registerEvent } from "../../../api/footprint";

export default {
  name: "SearchGrid",
  components: { CpVehicleCard, TextLabel },
  props: {
    vehicles: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      firstImg:
        this.$t("resources.images.searchpage.warrantyCard") ||
        require("@/assets/images/search-page/Garantia.svg"),
      secondImg:
        this.$t("resources.images.searchpage.deliveryCard") ||
        require("@/assets/images/search-page/Entrega_Casa.svg"),
      firstAlt: this.$t("pages.searchpage.specialBoxAlt.0"),
      secondAlt: this.$t("pages.searchpage.specialBoxAlt.1"),
    };
  },
  methods: {
    checkTypeSpecialBox(index) {
      return index === 4;
    },
    getImg(index) {
      return this.checkTypeSpecialBox(index) ? this.firstImg : this.secondImg;
    },
    getAlt(index) {
      return this.checkTypeSpecialBox(index) ? this.firstAlt : this.secondAlt;
    },
    getLabel(index) {
      return this.checkTypeSpecialBox(index)
        ? this.$t("pages.searchpage.specialBox.0")
        : this.$t("pages.searchpage.specialBox.1");
    },
    onClick(index, vin) {
      const footprintEvent = {
        page: this.getCurrentPage,
        result: index,
        vin: vin,
      };
      registerEvent(FOOTPRINT_TYPES.SEARCH_RESULT_CLICK, footprintEvent);
    },
  },
  computed: {
    ...mapGetters({
      getCurrentPage: "SearchModule/pagination/getCurrent",
    }),
  },
};
</script>

<style lang="scss" scoped>
.special--box {
  width: auto;
  height: auto;
  background-color: #f4f4f4;
  display: table;
}
.special--box-container {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  img {
    margin: auto;
  }
  .text {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 52px;
    text-align: center;
    color: #09246b;
  }
}

.search-grid--container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 16px;
}

@media (max-width: 1440px) {
}

@media (max-width: 1366px) {
}

@media (min-width: 768px) {
  .search-grid--container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1024px) {
  .search-grid--container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 1280px) {
  .search-grid--container {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .search-grid--container {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 505px) {
  ::v-deep .tooltip .tooltip--text {
    --tooltip-width-large: 300px;
    transform: translate(-10%, 50%);
  }
}
</style>
