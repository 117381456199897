const state = {
  contactPanelStatus: {
    display: false,
    vehicle: {},
    personal: {},
    type: "",
    optionalButton: "",
    optionalButtonType: "",
    optionalButtonDisabled: "",
  },
};

const getters = {
  getContactPanel: (state) => state.contactPanelStatus,
};

const mutations = {
  setContactPanel(state, status) {
    state.contactPanelStatus = status;
  },
  clearContactPanel(state) {
    state.contactPanelStatus = {
      display: false,
      vehicle: {},
      personal: {},
      type: "",
      optionalButton: "",
      optionalButtonType: "",
      optionalButtonDisabled: "",
    };
  },
};

export default {
  state,
  getters,
  mutations,
};
