<template>
  <div class="car-features">
    <div class="car-features--title">
      <Label color="primary" size="medium">{{
        $t("pages.personalPage.returnDetails.carFeatures.title")
      }}</Label>
    </div>
    <div class="car-features--content">
      <div class="car-features--general">
        <div class="car-features--general-title">
          <Label color="tertiary" size="small" bold="semibold">
            {{
              $t("pages.personalPage.returnDetails.carFeatures.generalFeatures")
            }}
          </Label>
        </div>
        <div class="car-features--general-input">
          <Input
            :isDisabled="getReturn.submitted"
            label="Quilómetros"
            inputType="text"
            type="tertiary"
            :value="getReturnKilometers"
            @onChange="onChangeKmHandler"
          />
          <div class="validate-message">
            <Label
              v-if="validateKm"
              class="validate-message--label"
              color="tertiary"
              bold="thin"
              size="small"
            >
              {{
                $t(
                  "pages.personalPage.returnDetails.carFeatures.kilometersWarning"
                )
              }}
            </Label>
          </div>
        </div>
        <div class="car-features--general-input">
          <Select
            :disabled="getReturn.submitted"
            id="external-color"
            type="primary"
            :border="false"
            :options="exteriorColor"
            @onChange="onChangeExternalColorHandler"
          />
          <div class="validate-message">
            <Label
              class="validate-message--label"
              v-if="emptyColor"
              color="tertiary"
              bold="thin"
              size="small"
            >
              {{
                $t("pages.personalPage.returnDetails.carFeatures.colorWarning")
              }}
            </Label>
          </div>
        </div>
        <div class="car-features--general-input">
          <Select
            :disabled="getReturn.submitted"
            id="interior"
            type="primary"
            :border="false"
            :options="cabinTrimInlay"
            @onChange="onChangeInteriorHandler"
          />
          <div class="validate-message">
            <Label
              v-if="emptyInterior"
              color="tertiary"
              bold="thin"
              size="small"
              class="validate-message--label"
            >
              {{
                $t(
                  "pages.personalPage.returnDetails.carFeatures.interiorWarning"
                )
              }}
            </Label>
          </div>
        </div>
      </div>
      <div class="car-features--equipment">
        <div class="car-features--equipment-title">
          <Label color="tertiary" size="small" bold="semibold">
            {{ $t("pages.personalPage.returnDetails.carFeatures.isImported") }}
          </Label>
        </div>
        <div class="car-features--equipment-list">
          <RadioGroup
            :disabled="getReturn.submitted"
            :options="provenance.radioGroupList"
            @onInput="onChangeProvenance"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Label, Select } from "../../atoms";
import Input from "../../refactor/molecules/Input/Input.vue";
import { mapGetters } from "vuex";
import { RadioGroup } from "@sc/ds-ui-component-library";
const selectArgsWithSelected = (arr, val) => {
  return arr.map((it) => ({
    ...it,
    selected: it.value === val,
  }));
};

export default {
  name: "CarFeatures",
  components: {
    Label,
    Select,
    Input,
    RadioGroup,
  },
  data() {
    return {
      features: {
        kilometers: this.getReturnKilometers,
        externalColor: "",
        cabinTrimInlay: "",
        equipments: this.equipmentList,
      },
      provenance: this.provenanceValue,
      renderComponent: true,
      getInteriorList: [],
      getExternalColorList: [],
    };
  },
  props: {
    returs: {
      type: Object,
      default: () => ({}),
    },
    airConditioningList: {
      type: Array[Object],
    },
    provenanceValue: {
      type: Object,
    },
    equipmentList: {
      type: Array[Object],
    },
    hasSubmitted: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {
    await this.$store.dispatch("fetchExteriorColors", 2);
    await this.$store.dispatch("fetchInteriorColors", 2);
    this.getInteriorList = this.$store.getters.getInteriorList;
    this.getExternalColorList = this.$store.getters.getExternalColorList;
    this.features.externalColor = this.getReturn?.phaseTwoDTOV3?.exteriorColor;
    this.features.cabinTrimInlay = this.getReturn?.phaseTwoDTOV3?.interiorColor;
  },
  methods: {
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      // If you like promises better you can
      // also use nextTick this way
      this.$nextTick().then(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    onChangeEquipment(value, event) {
      const eq = this.features.equipments.findIndex((x) => x.value === value);
      if (eq >= 0) {
        this.features.equipments[eq].checked = event;
      }
      this.$store.commit("setEquipments", [value, event]);

      this.emitFeatures();
    },
    onChangeInteriorHandler(event) {
      this.features.cabinTrimInlay = event;
      this.$store.commit("setInteriorColor", event);
      this.emitFeatures();
    },
    onChangeExternalColorHandler(event) {
      this.features.externalColor = event;
      this.$store.commit("setExteriorColor", event);
      this.emitFeatures();
    },
    onChangeProvenance(event) {
      this.provenance.radioGroupList = event;

      this.provenance.radioGroupValue = event.find(
        (e) => e.checked
      )?.radioValue;

      this.$store.commit("setProvenance", this.provenance);
    },
    onChangeKmHandler(event) {
      this.$store.commit("setReturnKilometers", event);
      this.emitFeatures();
    },
    emitFeatures() {
      this.setFeatures();
      this.$store.commit("setFeatures", this.features);
      this.$emit("onFeatures", this.features);
    },
    setFeatures() {
      this.features.kilometers = this.kilometers;
      this.features.plateDate =
        this.getReturn.year + "-" + this.getReturn.month + "-01";
      this.features.plate = this.getReturn.licensePlate;
      this.features.bcaId = this.getReturn.bcaId;
      this.features.tradeInId = this.getReturn.id;
      this.features.evaluation =
        this.getReturn.status === 1
          ? this.getReturn.actualValuation
          : this.getReturn.initialValuation;
    },
    checkUndefinedOrNull(x) {
      return x === "-1" || x === undefined;
    },
    validateKilometers(km) {
      return isNaN(km) || !km || km < 100 ? true : false;
    },
  },
  computed: {
    ...mapGetters(["getReturn", "getReturnKilometers"]),
    itemsLength() {
      return Number((this.equipmentList.length / 2).toFixed(0));
    },
    itemsHead() {
      return this.equipmentList
        ? this.equipmentList.slice(0, this.itemsLength)
        : [];
    },
    itemsTail() {
      return this.equipmentList
        ? this.equipmentList.slice(this.itemsLength)
        : [];
    },
    exteriorColor() {
      return selectArgsWithSelected(
        this.getExternalColorList,
        this.getReturn?.tradeInPayload?.features?.externalColor
      );
    },
    cabinTrimInlay() {
      return selectArgsWithSelected(
        this.getInteriorList,
        this.getReturn?.tradeInPayload?.features?.cabinTrimInlay
      );
    },
    airConditioning() {
      return selectArgsWithSelected(
        this.airConditioningList,
        this.getReturn?.tradeInPayload?.features?.airConditioning
      );
    },
    kilometers() {
      return this.getReturnKilometers;
    },
    emptyInterior() {
      return this.checkUndefinedOrNull(this.features?.cabinTrimInlay) &&
        this.checkUndefinedOrNull(
          this.getReturn?.tradeInPayload?.features?.cabinTrimInlay
        ) &&
        this.hasSubmitted
        ? true
        : false;
    },
    emptyColor() {
      return this.checkUndefinedOrNull(this.features?.externalColor) &&
        this.checkUndefinedOrNull(
          this.getReturn?.tradeInPayload?.features?.externalColor
        ) &&
        this.hasSubmitted
        ? true
        : false;
    },
    emptyAirConditioning() {
      return this.checkUndefinedOrNull(this.features?.airConditioning) &&
        this.checkUndefinedOrNull(
          this.getReturn?.tradeInPayload?.features?.airConditioning
        ) &&
        this.hasSubmitted
        ? true
        : false;
    },
    validateKm() {
      return this.validateKilometers(this.getReturnKilometers) &&
        this.validateKilometers(
          this.getReturn?.tradeInPayload?.features?.kilometers
        ) &&
        this.hasSubmitted
        ? true
        : false;
    },
  },
};
</script>

<style lang="css" scoped src="./CarFeatures.css"></style>
