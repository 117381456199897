<template>
  <label
    :for="forType"
    :form="formId"
    :class="['label', customClass, classes]"
    :style="customStyles"
  >
    <slot />
  </label>
</template>

<script>
export default {
  name: "Label",
  props: {
    styles: {
      type: Object,
      default: () => {},
    },
    customClass: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "primary",
      validator: (value) => {
        return ["primary", "secondary", "tertiary"].includes(value);
      },
    },
    size: {
      type: String,
      default: "small",
      validator: (size) => {
        return ["x-small", "small", "medium", "large"].includes(size);
      },
    },
    forType: {
      type: String,
      default: "",
    },
    formId: {
      type: String,
      default: "",
    },
    bold: {
      type: String,
      default: "normal",
      required: false,
      validator: (value) => {
        return [
          "thin",
          "extra-light",
          "normal",
          "medium",
          "semibold",
          "bold",
          "extra-bold",
        ].includes(value);
      },
    },
    italic: {
      type: Boolean,
      default: false,
    },
    capitalize: {
      type: Boolean,
      default: false,
    },
    uppercase: {
      type: Boolean,
      default: false,
    },
    lowercase: {
      type: Boolean,
      default: false,
    },
    align: {
      type: String,
      default: "left",
      validator: (align) => {
        return ["left", "center", "right", "justify"].includes(align);
      },
    },
    lineThrough: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        "label--uppercase": this.uppercase,
        "label--capitalize": this.capitalize,
        "label--italic": this.italic,
        "label--lowercase": this.lowercase,
        "label--x-small": this.size === "x-small",
        "label--small": this.size === "small",
        "label--medium": this.size === "medium",
        "label--large": this.size === "large",
        "label--bold--thin": this.bold === "thin",
        "label--bold--extralight": this.bold === "extra-light",
        "label--bold--normal": this.bold === "normal",
        "label--bold--medium": this.bold === "medium",
        "label--bold--semibold": this.bold === "semibold",
        "label--bold": this.bold === "bold",
        "label--bold--extrabold": this.bold === "extra-bold",
        "label--primary": this.color === "primary",
        "label--secondary": this.color === "secondary",
        "label--tertiary": this.color === "tertiary",
        "label--text-left": this.align === "left",
        "label--text-center": this.align === "center",
        "label--text-right": this.align === "right",
        "label--text-justify": this.align === "justify",
        "label--text-line-through": this.lineThrough,
      };
    },
    customStyles() {
      return {
        ...this.styles,
      };
    },
  },
};
</script>

<style lang="css" scoped src="./Label.css"></style>
