<template>
  <Panel :opened="getIsPanelOpened" @clickOutside="closePanel">
    <PanelHeader
      :title="$t('panels.compare.title')"
      :subtitle="subtitle"
      @onClosePanel="closePanel"
      closeButton
    />
    <PanelContent>
      <EmptyResults
        v-if="empty"
        :title="$t('panels.compare.empty.title')"
        :button="$t('panels.compare.buttons.search')"
        @onClick="goToSearch"
      >
        <template slot="subtitle">
          {{
            $t("panels.compare.empty.message1", {
              minimum: getMinimumVehicles,
            })
          }}
          <Icon class="icon-compare" size="13" icon="compare" />
          {{ $t("panels.compare.empty.message2") }}
        </template>
      </EmptyResults>
      <div class="content" v-else>
        <div class="grid-wrapper">
          <CpVehicleCard
            :scale="0.825"
            customClass="panel-vehicle-card"
            v-for="(v, index) in compareList"
            :v="v"
            :key="v.vin"
            :tooltipPosition="dynamicIndex(index)"
          />
        </div>
        <div class="btn--wrapper">
          <Button
            @onClick="handleClick"
            customClass="low-btn"
            :label="$t('panels.compare.buttons.compare')"
            :disabled="checkDisableBtn"
          />
        </div>
      </div>
    </PanelContent>
  </Panel>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex";

import { compareEvent } from "../../../utils/googleTagManager";
import CpVehicleCard from "../../refactor/CpVehicleCard.vue";
import {
  Icon,
  EmptyResults,
  Panel,
  PanelHeader,
  PanelContent,
  Button,
} from "@sc/ds-ui-component-library";

import { PAGES } from "../../../enums/pages";
import { MINIMUM_VEHICLES } from "../../../enums/compare";

export default {
  name: "ComparePanel",
  components: {
    Icon,
    EmptyResults,
    Panel,
    CpVehicleCard,
    Button,
    PanelHeader,
    PanelContent,
  },
  methods: {
    ...mapMutations({
      closePanel: "CompareModule/closePanel",
    }),
    ...mapActions({
      fetchVehiclesToCompare: "CompareModule/fetchVehicles",
    }),
    async handleClick() {
      this.closePanel();
      if (this.$route.name !== PAGES.COMPARATOR)
        this.$router.push({
          name: PAGES.COMPARATOR,
        });

      compareEvent(this.compareList);
    },
    goToSearch() {
      this.closePanel();

      if (this.$route.name !== PAGES.SEARCH)
        this.$router.push({ name: PAGES.SEARCH });
    },
    dynamicIndex(index) {
      return index % 2 == 0 ? "right" : "left";
    },
  },
  computed: {
    ...mapGetters({
      empty: "CompareModule/empty",
      readyToCompare: "CompareModule/readyToCompare",
      compareList: "CompareModule/list",
      isPanelOpened: "CompareModule/isPanelOpened",

      compareCount: "CompareModule/count",
      numberOfDifferences: "CompareModule/numberOfDifferences",
    }),
    subtitle() {
      return !this.readyToCompare && !this.empty
        ? this.$t("panels.compare.subtitleMinimum", {
            minimum: this.getMinimumVehicles,
          })
        : this.$t("panels.compare.subtitle");
    },
    getMinimumVehicles() {
      return MINIMUM_VEHICLES;
    },
    checkDisableBtn() {
      return this.compareList.length > 1 ? false : true;
    },
    getIsPanelOpened() {
      this.isPanelOpened
        ? document.body.classList.add("no-scroll")
        : document.body.classList.remove("no-scroll");
      return this.isPanelOpened;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .text-label,
.text-label > .icon {
  display: inline-block;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.icon-compare {
  display: inline-block;
}

.grid-wrapper {
  --text-label-font-size: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

  gap: 10px;

  ::v-deep {
    .tootltip-container .tooltip--text {
      z-index: 99;
    }
    .tooltip .left {
      transform: translate(40%, 20%);
    }
    .tooltip .right {
      transform: translate(0%, 20%);
    }
  }
}

::v-deep {
  .financing-details-light {
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    color: var(--color-2);
  }
  .finance-details-tab {
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    color: var(--color-1);
  }
  .financing-details-dark {
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    color: var(--color-1);
  }
}

//Mobile
@media (max-width: 768px) {
  .grid-wrapper {
    ::v-deep {
      .tootltip-container .tooltip--text {
        z-index: 99;
        --tooltip-width-large: 270px;
      }
      .tooltip .left {
        transform: translate(70%, 42%);
      }
      .tooltip .right {
        transform: translate(-10%, 40%);
      }
      .price {
        flex-direction: column-reverse;
        align-items: start;
      }
      .content {
        height: 210px;
      }
    }
  }
  // 3 Columns
  @media (min-width: 668px) {
    .grid-wrapper {
      ::v-deep {
        .tooltip .left {
          transform: translate(95%, 40%);
        }
        .tooltip .right {
          transform: translate(-25%, 50%);
        }
        .price {
          flex-direction: column-reverse;
          align-items: start;
        }
        .content {
          height: 210px;
        }
      }
    }
  }

  //1 Column
  @media (max-width: 473px) {
    .grid-wrapper {
      ::v-deep {
        .tooltip .left {
          transform: translate(95%, 20%);
        }
        .tooltip .right {
          transform: translate(-10%, 50%);
        }
        .price {
          flex-direction: row;
          align-items: center;
        }
      }
    }
  }
}

@media screen and (max-height: 1024px) {
  .btn--wrapper {
    margin-top: 20px;
    padding-bottom: 20px;
  }
}

::v-deep .card--container > .header {
  max-height: 150px;
}

::v-deep .panel-vehicle-card > .content {
  max-height: 210px !important;
}

::v-deep .model {
  --title-font-size: 18px;
  --title-line-height: 18px;
}

::v-deep .designation {
  --subtitle-font-size: 14px;
  --subtitle-line-height: 14px;
}

::v-deep .ellipse {
  margin: 8px;
}

::v-deep .left {
  --text-label-font-size: 12px;
  --text-label-line-height: 12px;
}

::v-deep .now {
  --text-label-font-size: 21px;
  --text-label-line-height: 21px;
}

::v-deep .reserved {
  --tag-padding: 5px 15px;
  --text-label-font-size: 16px;
}

::v-deep .icon-link {
  margin: 10px 10px 0 0;
}
</style>
