export const Sort = {
  Price: "price",
  Monthly: "monthly",
  Segment: "segment",
  Model: "model",
  Date: "createTime",
};

export const Order = {
  Asc: "asc",
  Desc: "desc",
};

const state = {
  order: Order.Asc,
  sort: Sort.Date,
  tags: [],
  needle: "",
  saveTags: false,
  fromLogin: false,
};

const getters = {
  getOrder: (state) => state.order,
  getSort: (state) => state.sort,
  getTags: (state) => state.tags,
  getNeedle: (state) => state.needle,
  getTagsLength: (state) => state.tags.length,
  getSaveTags: (state) => state.saveTags,
  getFromLogin: (state) => state.fromLogin,
  getFilterState: (state) => ({
    order: state.order,
    sort: state.sort,
    tags: state.tags,
    needle: state.needle,
    saveTags: state.saveTags,
    fromLogin: state.fromLogin,
  }),
};

const mutations = {
  setOrder(state, val) {
    state.order = val;
  },
  setSort(state, val) {
    state.sort = val;
  },
  addTag(state, val) {
    state.tags = [...state.tags, val];
  },
  removeTag(state, val) {
    state.tags = state.tags.filter(
      (tag) => tag.id !== val.id || tag.type !== val.type
    );
  },
  removeReplaceTag(state, val) {
    state.tags = state.tags.filter((tag) => tag.type !== val.type);
  },
  replaceTag(state, val) {
    var idx = state.tags.findIndex((tag) => tag.type === val.type);
    state.tags.splice(idx, 1);
    state.tags = [...state.tags, val];
  },
  setTags(state, val) {
    state.tags = val;
  },
  setNeedle(state, val) {
    state.needle = val;
  },
  setSaveTags(state, val) {
    state.saveTags = val;
  },
  setFromLogin(state, fromLogin) {
    state.fromLogin = fromLogin;
  },
};

const actions = {
  cleanAllTags({ commit }) {
    commit("setTags", []);
  },
  addOrRemoveTag({ commit, state }, val) {
    const found = state.tags.find(
      (it) => it.type === val.type && it.id == val.id
    );
    if (found) {
      commit("removeTag", val);
    } else {
      commit("addTag", val);
    }
  },
  addOrReplaceTag({ commit, state }, val) {
    const found = state.tags.find((it) => it.type === val.type);
    if (found) {
      commit("replaceTag", val);
    } else {
      commit("addTag", val);
    }
  },
  changeOrder({ commit }, order) {
    commit("setOrder", order);
    // FIXME: call api
  },
  changeTag({ commit }, tag) {
    commit("addOrRemoveTag", tag);
    // FIXME: call api
  },
  saveTags({ commit, state }) {
    commit("setSavedTags", state.tags);
  },
  clearSavedTags({ commit }) {
    commit("setSavedTags", []);
  },
  updateFilter({ commit }, search) {
    commit("setOrder", search.order);
    commit("setSort", search.sort);
    commit("setTags", search.tags);
    commit("setNeedle", search.needle);
    commit("setSaveTags", search.saveTags);
    commit("setFromLogin", search.fromLogin);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
