<template>
  <h2 :class="['subtitle', customClass, size, weight]" v-html="label"></h2>
</template>

<script>
export default {
  name: "Subtitle",
  props: {
    customClass: {
      type: String,
    },
    label: {
      type: String,
      default: "",
    },
    size: {
      type: String,
    },
    weight: {
      type: String,
      default: "bold",
    },
  },
};
</script>
<style lang="scss" scoped>
.subtitle {
  font-size: var(--subtitle-font-size);
  line-height: var(--subtitle-line-height);
  font-weight: var(--subtitle-weight);
  color: var(--subtitle-color);

  &.large {
    font-size: var(--subtitle-large-font-size);
    line-height: var(--subtitle-large-line-height);
  }

  &.regular {
    font-weight: var(--subtitle-weight-regular);
  }

  &.semibold {
    font-weight: var(--subtitle-weight-semibold);
  }

  &.bold {
    font-weight: var(--subtitle-weight-bold);
  }
}
</style>
