import { getDropdowns } from "../../api/dropdowns";
import { dealerNameFormat } from "../../utils/helpers";

const validIDs = process.env.VUE_APP_CARPLUS_DEALERS_IDS.split(",").map(Number);

const state = {
  tags: [],
  fuel: [],
  model: [],
  transmission: [],
  segment: [],
  dealers: [],
  finance: {},
  // errors
  errors: [],
  // for loading spinner
  loadingDropdown: false,
  pvpRange: { min: 0, max: 0 },
  isLeftSelected: true,
};

const getters = {
  getDropdownFuel: (state) => state.fuel,
  getDropdownModel: (state) => state.model,
  getDropdownTransmission: (state) => state.transmission,
  getDropdownSegment: (state) => state.segment,
  getDropdownFinance: (state) => state.finance,
  getDealers: (state) => {
    return state.dealers.filter((it) => validIDs.includes(it.id));
  },
  getTags: (state) => state.tags,
  getPvpMin: (state) => state.pvpRange?.min,
  getPvpMax: (state) => state.pvpRange?.max,
  isLeftSelected: (state) => state.isLeftSelected,
};

const actions = {
  // Fetch all results from the api
  async storeDropdowns({ commit }) {
    commit("setLoadingDropdown", true);
    try {
      const response = await getDropdowns();

      commit("setFuel", response.data.data.fuel);
      commit("setModel", response.data.data.model);
      commit("setTransmission", response.data.data.transmission);
      commit("setSegment", response.data.data.segment);
      commit("setFinance", response.data.data.finance);
      commit("setDealers", response.data.data.dealers);
    } catch (e) {
      commit("setErrors", e);
    } finally {
      commit("setLoadingDropdown", false);
    }
  },
};

// serves to define state value
const mutations = {
  setFuel(state, fuel) {
    state.fuel = fuel;
  },
  setModel(state, model) {
    state.model = model;
  },
  setTransmission(state, transmission) {
    state.transmission = transmission;
  },
  setSegment(state, segment) {
    state.segment = segment;
  },
  setFinance(state, finance) {
    state.finance = finance;
  },
  setDealers(state, dealers) {
    let val = dealers;
    val = val.map((it) => {
      let dealer = it;
      dealer.name = dealerNameFormat(it.name);
      return dealer;
    });
    state.dealers = val;
  },
  setErrors(state, errors) {
    state.errors = errors;
  },
  setLoadingDropdown(state, loading) {
    state.loadingDropdown = loading;
  },
  setTags(state, tags) {
    state.tags = tags;
  },
  setPvpRange(state, val) {
    state.pvpRange = { ...state.pvpRange, ...val };
  },
  setLeftSelected(state, val) {
    state.isLeftSelected = val;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
