<template>
  <div :style="customStyles" :class="['navbar', customClass, classes]">
    <div class="navbar--inner">
      <div class="navbar--left">
        <slot name="left"></slot>
        <div @click="toggleMenu">
          <Icon
            @click="toggleMenu"
            class="hamburguer"
            :name="opened ? 'times' : 'bars'"
          ></Icon>
        </div>
      </div>
      <div class="navbar--center">
        <slot name="center"></slot>
      </div>
      <div class="navbar--right-1">
        <slot name="right-1"></slot>
      </div>
      <div class="navbar--right">
        <slot name="right-2"></slot>
      </div>
      <div class="mobile-menu">
        <div class="navbar--center">
          <slot name="center"></slot>
        </div>
        <div class="navbar--right">
          <slot name="right-2"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from "../../atoms";
export default {
  name: "Navbar",
  components: {
    Icon,
  },
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      },
    },
    customClass: {
      type: String,
      default: "",
    },
    bgColor: {
      type: String,
      required: false,
    },
  },
  computed: {
    classes() {
      return {};
    },
    customStyles() {
      return {
        ...this.styles,
        backgroundColor: this.bgColor,
      };
    },
    opened() {
      return this.$store.state.isOpenNavbar;
    },
  },
  methods: {
    toggleMenu() {
      let menu = document.querySelector(".mobile-menu");

      if (!menu.classList.contains("active")) {
        menu.classList.add("active");
        document.body.classList.add("no-scroll");
      } else {
        menu.classList.remove("active");
        document.body.classList.remove("no-scroll");
      }
      this.$store.state.isOpenNavbar = menu.classList.contains("active");
    },
  },
};
</script>

<style lang="css" scoped src="./Navbar.css"></style>
