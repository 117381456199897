import { saveSearch, deleteSearch, getSearches } from "@/api/search";

import Vue from "vue";

const state = {
  results: [],
  tags: [],
  needle: "",
  // errors
  errors: [],
  // for loading spinner
  loadingSearch: false,
};

const getters = {
  getAllSavedSearches: (state) => state.results,
};

const actions = {
  async storeSaveSearch({ commit }, search) {
    commit("setLoadingSearch", true);
    try {
      const response = await saveSearch(search);
      commit("setSaveSearch", response.data.data);
      Vue.$toast.open({
        message: "Pesquisa guardada com sucesso",
        dismissible: true,
        type: "info",
      });
    } catch (e) {
      commit("setErrors", e);
      commit("setLoading", false);
      Vue.$toast.open({
        message:
          "Lamentamos mas ocorreu um erro a guardar a pesquisa, por favor tente novamente mais tarde",
        dismissible: true,
        type: "error",
      });
    }
    commit("setLoadingSearch", false);
  },
  async storeDeleteSearch({ commit, dispatch }, id) {
    commit("setLoadingSearch", true);
    try {
      await deleteSearch(id);
      dispatch("storeGetSearch");
      // const response = await deleteSearch(id);
      // commit("setDeleteSearch", response.data.data);
    } catch (e) {
      commit("setErrors", e);
      commit("setLoading", false);
    }
    commit("setLoadingSearch", false);
  },

  async storeGetSearch({ commit }) {
    commit("setLoadingSearch", true);
    try {
      const response = await getSearches();

      commit("setAllSearchs", response.data.data);
    } catch (e) {
      commit("setErrors", e);
      commit("setLoading", false);
    }
    commit("setLoadingSearch", false);
  },
};

// serves to define state value
const mutations = {
  setSearchResults(state, results) {
    state.results = results;
  },
  setSaveSearch(state, tags) {
    state.tags = tags;
  },
  setErrors(state, errors) {
    state.errors = errors;
  },
  setLoadingSearch(state, loading) {
    state.loadingSearch = loading;
  },
  setAllSearchs(state, searchs) {
    state.results = searchs;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
