<template>
  <Page id="credit-intermediary">
    <template v-slot:content>
      <Section>
        <SectionHeader :title="$t(`pages.CreditIntermediary.title`)" />
        <div class="credit-intermediary-container">
          <div
            v-for="section in sections"
            :key="section"
            class="credit-intermediary-block"
          >
            <Subtitle
              :label="
                $t(`pages.CreditIntermediary.${Object.keys(section)[0]}.title`)
              "
              class="credit-intermediary-title"
            />
            <div
              v-for="subsection in section"
              :key="subsection"
              class="credit-intermediary-block"
            >
              <div v-for="subsubsection in subsection" :key="subsubsection">
                <Subtitle
                  :label="
                    $t(
                      `pages.CreditIntermediary.${
                        Object.keys(section)[0]
                      }.subsections.${subsubsection}.title`
                    )
                  "
                />
                <TextLabel
                  v-html="
                    $t(
                      `pages.CreditIntermediary.${
                        Object.keys(section)[0]
                      }.subsections.${subsubsection}.textLabel`
                    )
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </Section>
    </template>
  </Page>
</template>

<script>
import { Page } from "../../templates";
import {
  Section,
  SectionHeader,
  Subtitle,
  TextLabel,
} from "@sc/ds-ui-component-library";

export default {
  name: "CreditIntermediaryPage",
  components: {
    Page,
    SectionHeader,
    Subtitle,
    TextLabel,
    Section,
  },
  metaInfo() {
    return {
      title: this.$t("meta.creditIntermediary.title"),
      meta: [
        { charset: "utf-8" },
        {
          vmid: "description",
          name: "description",
          content: this.$t("meta.credit-intermediary.description"),
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: this.$t("meta.credit-intermediary.keywords"),
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.$t("meta.credit-intermediary.title"),
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.$t("meta.credit-intermediary.description"),
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: window.location.href,
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: this.$t("meta.credit-intermediary.image"),
        },
      ],
    };
  },
  data() {
    return {
      sections: [
        {
          section1: [
            "subsection1",
            "subsection2",
            "subsection3",
            "subsection4",
            "subsection5",
          ],
        },
        {
          section2: [
            "subsection1",
            "subsection2",
            "subsection3",
            "subsection4",
            "subsection5",
            "subsection6",
            "subsection7",
          ],
        },
        {
          section3: ["subsection1", "subsection2"],
        },
        {
          section4: ["subsection1"],
        },
      ],
    };
  },
  mounted() {
    this.$meta().refresh();
  },
};
</script>

<style lang="scss" scoped>
#credit-intermediary {
  --section-header-width: 100%;
  --section-header-text-align: center;
  --section-header-margin: 0 0 64px 0;
  white-space: pre-line;
  --section-bg-color: var(--color-7);
}

.credit-intermediary-block,
.credit-intermediary-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: justify;
  white-space: pre-line;
}

.credit-intermediary-title {
  --subtitle-font-size: 1.65rem;
  --subtitle-weight: 600;
  --subtitle-line-height: 24px;
}

.credit-intermediary-container {
  gap: 32px;
}

.banner-container {
  width: 100%;
  height: 100%;
  margin: 30px 0px;
}

::v-deep .baselink {
  color: var(--theme-color-tertiary);
  text-decoration: underline;
}

@media (max-width: 1024px) {
  #credit-intermediary {
    --section-padding: 0px 16px 30px 16px;
    --section-header-width: 100%;
    --section-header-margin: 30px auto;

    ::v-deep {
      .btn {
        width: 100%;
        --button-justify-content: center;
      }
    }
  }
}

@media (max-width: 768px) {
  ::v-deep .title {
    --title-font-size: var(--title-3-font-size);
    --title-line-height: var(--title-3-line-height);
    --title-weight: var(--font-weight-bold);
  }

  #credit-intermediary {
    --section-header-width: 100%;
  }
}
</style>
