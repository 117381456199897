<template>
  <div class="indicator--container">
    <Icon
      v-if="withIcon"
      :customIcon="customIcon"
      :customClass="iconColor"
      :icon="icon"
    />
    <TextLabel :customClass="textColor" :label="label" />
  </div>
</template>

<script>
import Icon from "../../atoms/Icon/Icon.vue";
import TextLabel from "../../atoms/TextLabel/TextLabel.vue";

export default {
  name: "Indicator",
  components: { Icon, TextLabel },
  props: {
    icon: {
      type: String,
    },
    label: {
      type: String,
    },
    customIcon: {
      type: String,
    },
    withIcon: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      validator: (t) => ["error", "success", "info", "warning"].includes(t),
    },
  },
  computed: {
    iconColor() {
      return `indicator--${this.type}`;
    },
    textColor() {
      return `indicator--${this.type}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.indicator--container {
  display: var(--indicator-display);
  align-items: var(--indicator-align-items);
  gap: var(--indicator-gap);
  margin-top: var(--indicator-margin-top);
}

.indicator--success {
  --text-label-color: var(--color-success);
  fill: var(--color-success);
}
.indicator--error {
  --text-label-color: var(--color-error);
  fill: var(--color-error);
}
.indicator--warning {
  --text-label-color: var(--color-warning);
  fill: var(--color-warning);
}
.indicator--info {
  --text-label-color: var(--color-info);
  fill: var(--color-info);
}
</style>
