<template>
  <div class="basic-information">
    <div class="basic-information--title">
      <Label color="primary" size="medium">{{
        $t("pages.personalPage.returns.sections.basic.title")
      }}</Label>
    </div>
    <div class="basic-information--subtitle">
      <TextLabel
        customClass="subtitle"
        :label="$t('pages.personalPage.returns.sections.basic.description')"
      >
      </TextLabel>
    </div>
    <div class="basic-information--content">
      <div class="basic-information--content-left">
        <div class="basic-info-item">
          <TextLabel
            customClass="grey-label"
            size="normal"
            :label="
              $t('pages.personalPage.returns.sections.basic.labels.plate')
            "
          />
          <TextLabel
            customClass="dark-label"
            size="normal"
            :label="vehicle.registration"
          />
        </div>
        <div class="basic-info-item">
          <TextLabel
            customClass="grey-label"
            size="normal"
            :label="
              $t('pages.personalPage.returns.sections.basic.labels.brand')
            "
          />
          <TextLabel
            customClass="dark-label"
            size="normal"
            :label="vehicle.brand"
          />
        </div>
        <div class="basic-info-item">
          <TextLabel
            customClass="grey-label"
            size="normal"
            :label="
              $t('pages.personalPage.returns.sections.basic.labels.model')
            "
          />
          <TextLabel
            customClass="dark-label"
            size="normal"
            :label="vehicle.model"
          />
        </div>
      </div>
      <div class="basic-information--content-right">
        <div class="basic-info-item">
          <TextLabel
            customClass="grey-label"
            size="normal"
            :label="
              $t('pages.personalPage.returns.sections.basic.labels.plateDate')
            "
          />
          <TextLabel
            customClass="dark-label"
            size="normal"
            :label="vehicle.registrationDate"
          />
        </div>
        <div class="basic-info-item">
          <TextLabel
            customClass="grey-label"
            size="normal"
            :label="$t('pages.personalPage.returns.sections.basic.labels.fuel')"
          />
          <TextLabel
            customClass="dark-label"
            size="normal"
            :label="vehicle.fuel || '-'"
          />
        </div>
        <div class="basic-info-item">
          <TextLabel
            customClass="grey-label"
            size="normal"
            :label="
              $t(
                'pages.personalPage.returns.sections.basic.labels.transmission'
              )
            "
          />
          <TextLabel
            customClass="dark-label"
            size="normal"
            :label="vehicle.transmission || '-'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Label } from "../../atoms";
import TextLabel from "../../refactor/atoms/TextLabel/TextLabel.vue";
export default {
  name: "BasicInformation",
  components: {
    Label,
    TextLabel,
  },
  props: {
    vehicle: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.basic-information--content {
  display: flex;
  justify-content: space-between;
}

::v-deep .label span {
  color: var(--color-2);
  font-weight: 500;
}

::v-deep .label {
  margin-bottom: 16px;
}

.basic-information--content-left,
.basic-information--content-right {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.basic-information--title .label {
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
}

.basic-information--subtitle .label {
  font-size: 14px;
  line-height: 18px;
  color: var(--color-2);
}

.subtitle {
  font-size: 14px;
  line-height: 18px;
  color: var(--color-2);
  margin-top: 16px;
  margin-bottom: 56px;
}

::v-deep .grey-label {
  --text-label-color: var(--color-2);
  --text-label-font-weight: 600;
  --text-label-font-size: 16px;
}

::v-deep .dark-label {
  --text-label-color: var(--color-1);
  --text-label-font-weight: 700;
  --text-label-font-size: 16px;
}

.basic-info-item {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 475px) {
  .basic-information--content {
    flex-direction: column;
    width: 100%;

    & > * {
      margin: 9px 0;
    }

    & > *:first-child {
      margin: 0 0 9px 0;
    }

    & > *:last-child {
      margin: 9px 0 0 0;
    }
  }

  .basic-information--content-left,
  .basic-information--content-right {
    width: 100%;
  }
}
</style>
