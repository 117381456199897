import { autocomplete } from "@/api/autocomplete";

const state = {
  results: [],
  // errors
  errors: [],
  // for loading spinner
  loadingAutocomplete: false,
};

const getters = {
  getAllResults: (state) => state.results,
  getAutoCompleteLoadingState: (state) => state.loadingAutocomplete,
};

const actions = {
  // Fetch all results from the api
  async storeAllResults({ commit }, word) {
    commit("setAutoCompleteLoading", true);
    try {
      const response = await autocomplete(word);
      commit("setResults", response.data.data);
      commit("setAutoCompleteLoading", false);
    } catch (e) {
      commit("setErrors", e);
    }
  },
};

// serves to define state value
const mutations = {
  setResults(state, results) {
    let flags = [],
      output = [],
      l = results.length,
      i;
    for (i = 0; i < l; i++) {
      if (flags[results[i]]) continue;
      flags[results[i]] = true;
      output.push(results[i]);
    }

    state.results = output;
  },
  setErrors(state, errors) {
    state.errors = errors;
  },
  setAutoCompleteLoading(state, loading) {
    state.loadingAutocomplete = loading;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
