import httpClient from "./httpClient";

const FAVORITE = "/favorite";

function getAllUserFavorites() {
  return httpClient.get(`${FAVORITE}`);
}

function addToUserFavorites({ vin, financeId }) {
  const fav = {
    fid: financeId,
    vin,
  };
  return httpClient.post(FAVORITE, fav);
}

function removeFromUserFavorites({ vin }) {
  const fav = {
    vin,
  };
  return httpClient.put(FAVORITE, fav);
}

export { getAllUserFavorites, addToUserFavorites, removeFromUserFavorites };
