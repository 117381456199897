<template>
  <div>
    <div class="pagination">
      <Button
        :disabled="!getPrev"
        @onClick="previousPage"
        iconPosition="left"
        withIcon
        :type="previousButtonType"
        label="Anterior"
        :key="'previousButtonKey' + previousButtonKey"
      />
      <TextLabel
        weight="semibold"
        customClass="pagination--label"
        :label="label"
      />
      <Button
        :disabled="!getNext"
        @onClick="nextPage"
        withIcon
        :type="nextButtonType"
        label="Próxima"
        :key="'nextButtonKey' + nextButtonKey"
      />
    </div>
    <div class="pagination--mobile">
      <div class="buttons--row">
        <Button
          :disabled="!getPrev"
          @onClick="previousPage"
          iconPosition="left"
          withIcon
          :type="previousButtonType"
          label="Anterior"
          :key="'previousButtonKey' + previousButtonKey"
        />
        <Button
          :disabled="!getNext"
          @onClick="nextPage"
          withIcon
          :type="nextButtonType"
          label="Próxima"
          :key="'nextButtonKey' + nextButtonKey"
        />
      </div>
      <TextLabel
        weight="semibold"
        customClass="pagination--label"
        :label="label"
      />
    </div>
  </div>
</template>

<script>
import { Button } from "@sc/ds-ui-component-library";
import TextLabel from "../../refactor/atoms/TextLabel/TextLabel.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Button,
    TextLabel,
  },
  data() {
    return {
      previousButtonKey: 0,
      nextButtonKey: 0,
    };
  },

  methods: {
    async changePage(offset = 1) {
      window.scroll(0, 0);
      this.$store.commit(
        "SearchModule/pagination/setCurrent",
        this.getCurrentPage + offset
      );
      await this.$store.dispatch("SearchModule/fetchRecent", {
        quickSearchItem: [],
        dynamic: false,
      });
      window.scroll(0, 0);
      this.previousButtonKey += 1;
      this.nextButtonKey += 1;
    },
    previousPage() {
      this.changePage(-1);
    },
    nextPage() {
      this.changePage();
    },
  },
  computed: {
    ...mapGetters({
      getCurrentPage: "SearchModule/pagination/getCurrent",
      getPages: "SearchModule/pagination/getPages",
      getTotal: "SearchModule/pagination/getResults",
      getNext: "SearchModule/pagination/getNext",
      getPrev: "SearchModule/pagination/getPrev",
    }),
    label() {
      return `Página ${this.getCurrentPage} - ${this.getPages} (${this.getTotal} Resultados)`;
    },
    nextButtonType() {
      return this.getCurrentPage === this.getPages + 1
        ? "secondary"
        : "secondary";
    },
    previousButtonType() {
      return this.getCurrentPage < 2 ? "secondary" : "secondary";
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  --button-padding: 30px;
  display: none;
}

.pagination--mobile {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    margin: 10px 0;
  }

  & > *:first-child {
    margin: 0 0 10px 0;
  }

  & > *:last-child {
    margin: 10px 0 0 0;
  }

  .buttons--row {
    display: flex;
    width: 100%;

    & > * {
      margin: 0 5px;
    }

    & > *:first-child {
      margin: 0 5px 0 0;
    }

    & > *:last-child {
      margin: 0 0 0 5px;
    }

    ::v-deep .button {
      --button-padding: 19px;
      display: flex;
      flex: 1;
      justify-content: center;
    }
  }
}

@media (min-width: 768px) {
  .pagination {
    display: flex;
  }
  .pagination--mobile {
    display: none;
  }
}

.pagination--label {
  --text-label-color: var(--color-2);
}
</style>
