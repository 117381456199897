const CARPLUS_PAGE_SIZE = 14;

const state = {
  current: 1,
  pages: 0,
  results: 0,
  nextPage: 0,
  prevPage: 0,
};

const getters = {
  getCurrent: (state) => state.current,
  getPages: (state) => state.pages,
  getResults: (state) => state.results,
  getNext: (state) => state.nextPage,
  getPrev: (state) => state.prevPage,
};

const mutations = {
  setCurrent(state, val) {
    state.current = val;
  },
  setPages(state, val) {
    state.pages = val;
  },
  setResults(state, val) {
    state.results = val;
  },
  setPagination(state, pagination) {
    const { totalResults, currentPage, next, prev } = pagination;
    state.results = totalResults;
    state.current = currentPage;
    state.pages = Math.ceil(totalResults / CARPLUS_PAGE_SIZE);
    state.nextPage = next;
    state.prevPage = prev;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
