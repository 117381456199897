var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['container--wrapper', _vm.customClass, _vm.classes],style:(_vm.customStyles)},[_c('div',{staticClass:"title--content"},[_c('div',{staticClass:"tags"},[(_vm.showActionsMissingTag)?_c('StatusTag',{staticClass:"status-tag",attrs:{"type":_vm.$t(
            `pages.personalPage.reservations.savedReservation.labels.missingActions.type`
          ),"label":_vm.$t(
            `pages.personalPage.reservations.savedReservation.labels.missingActions.description`
          )}}):_vm._e()],1),_c('Label',{staticClass:"price-model",attrs:{"color":"primary","bold":"bold","size":_vm.titleSize,"customClass":_vm.size === 'medium' ? 'title--content-title' : ''}},[_vm._v(" "+_vm._s(_vm.productTitle)+" ")]),_c('Label',{staticClass:"price-model-description",attrs:{"color":"tertiary","size":_vm.smallSize,"customClass":_vm.size === 'medium' ? 'title--content-subtitle' : ''}},[_vm._v(" "+_vm._s(_vm.productDescription)+" ")])],1),_c('div',{staticClass:"finance--container"},[_c('Label',{staticClass:"finance-model-price",attrs:{"color":"secondary","bold":"bold","size":_vm.smallSize,"align":"right","customClass":_vm.size === 'medium' ? 'finance--container-title' : ''}},[_vm._v(" "+_vm._s(_vm.priceLabel)+" ")]),_c('div',{staticClass:"monthtaeg"},[(!_vm.absolutePrice && _vm.hasFinancing)?_c('div',{staticClass:"finance--wrapper"},[_c('div',{staticClass:"tootltip-container"},[(!_vm.noTooltip)?_c('Tooltip',{attrs:{"tooltip":_vm.tooltipDescription,"position":_vm.tooltipPosition,"size":"large"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('Icon',{staticClass:"icon--margin",attrs:{"type":"dark","size":"18","icon":"info"}})]},proxy:true}],null,false,675452510)}):_vm._e()],1),_c('Label',{staticClass:"finance-model-modality modality--margin",attrs:{"color":"secondary","size":_vm.smallSize,"align":"right","customClass":_vm.size === 'medium' ? 'finance--container-subtitle' : ''}},[_vm._v(" "+_vm._s(_vm.modality + _vm.$t("pages.detailsPage.modality"))+" ")])],1):_vm._e(),(!_vm.absolutePrice && _vm.hasFinancing)?_c('Label',{staticClass:"finance-model-taeg taeg--margin",attrs:{"color":"secondary","bold":"normal","size":_vm.smallSize,"align":"right","customClass":_vm.size === 'medium'
            ? 'finance--container-subtitle'
            : 'finance-model-taeg'}},[_vm._v(" "+_vm._s(_vm.financeTaeg)+" ")]):_vm._e()],1),(_vm.hasFinancing)?_c('div',{staticClass:"financing-details"},[_c('div',[_c('p',{staticClass:"financing-details-block"},[_c('span',{staticClass:"financing-details-light"},[_vm._v(_vm._s(`${this.$t("vehicle.monthly")}: `))]),_c('span',{staticClass:"financing-details-dark"},[_vm._v(_vm._s(_vm.monthlyFinancing))])])]),_c('div',[_c('p',{staticClass:"financing-details-block"},[_c('span',{staticClass:"financing-details-light"},[_vm._v(_vm._s(`${this.$t("vehicle.entry")}: `))]),_c('span',{staticClass:"financing-details-dark"},[_vm._v(_vm._s(_vm.entryFinancing))])])]),_c('div',[_c('p',{staticClass:"financing-details-block"},[_c('span',{staticClass:"financing-details-light"},[_vm._v(_vm._s(`${this.$t("vehicle.financing")}: `))]),_c('span',{staticClass:"financing-details-dark"},[_vm._v(_vm._s(_vm.totalValueFinancing))])])])]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }