<template>
  <div
    :class="['dropdown--menu-item', customClass, isOpen ? 'onOpen' : '']"
    :style="customStyles"
    :id="id"
  >
    <div
      :class="['dropdown--title', highlight, customClassHighlight]"
      @click="onClick"
    >
      <Label
        class="dropdown--title-label"
        bold="medium"
        color="tertiary"
        size="x-small"
        :uppercase="uppercase"
        >{{ title }}</Label
      >

      <icon
        color="secondary"
        class="dropdown--title-icon"
        :name="dropdownIcon"
      ></icon>
    </div>
    <transition name="fade" appear>
      <div
        :style="customItemStyles"
        :class="['submenu', classes]"
        v-if="isOpen"
      >
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
import { Icon, Label } from "../../atoms";
export default {
  data() {
    return {
      isOpen: false,
      elDropdownCustom: null,
    };
  },
  components: {
    Icon,
    Label,
  },
  props: {
    customClassHighlight: {
      type: String,
    },
    styles: {
      type: Object,
      default: () => {
        return {};
      },
    },
    customClass: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "Dropdown",
      required: true,
    },
    type: {
      type: String,
      default: "default",
      validator: (type) => {
        return ["default", "primary", "secondary"].includes(type);
      },
    },
    closeOnClickOutside: {
      type: Boolean,
      default: true,
    },
    closeOnClickInside: {
      type: Boolean,
      default: true,
    },
    uppercase: {
      type: Boolean,
      default: false,
    },
    bgColor: {
      type: String,
    },
  },
  computed: {
    classes() {
      return {
        "dropdown--background dropdown--background-default":
          this.type === "default",
        "dropdown--background dropdown--background-primary":
          this.type === "primary",
        "dropdown--background dropdown--background-secondary":
          this.type === "secondary",
      };
    },
    highlight() {
      return {
        "dropdown--highlight-primary": this.isOpen,
        "dropdown--highlight-secondary":
          this.isOpen && this.type === "secondary",
      };
    },
    customStyles() {
      return {
        ...this.styles,
      };
    },
    customItemStyles() {
      return {
        backgroundColor: this.bgColor,
      };
    },
    dropdownIcon() {
      return this.isOpen ? "chevron-up" : "chevron-down";
    },
  },
  methods: {
    onClick() {
      this.isOpen = !this.isOpen;
      document.addEventListener("click", this.watchClickOutside);
      this.$emit("onOpen", this.isOpen);
    },
    close() {
      this.isOpen = false;
      document.removeEventListener("click", this.watchClickOutside);
      this.$emit("close");
    },
    // backdropClick(event) {
    //   if (!this.closeOnClickOutside) return;

    //   if (event.target.classList.contains("submenu")) this.close();
    // },
    watchClickOutside() {
      const didClickedOutside = !this.elDropdownCustom.contains(event.target);
      if (didClickedOutside) {
        this.close();
      }
    },
  },
  mounted() {
    this.elDropdownCustom = document.getElementById(this.id);
  },
};
</script>
<style lang="css" scoped src="./Dropdown.css"></style>
