<template>
  <div class="wrapper">
    <div class="search-form--wrapper">
      <TextLabel
        class="filter-label"
        weight="semibold"
        :label="$t('searchform.filters')"
      />
      <SearchFormAutocomplete />
      <div class="filter-content">
        <SearchFormInputs
          ref="formInputs"
          @toggleOpen="$emit('toggleOpen')"
          @onItemClick="onItemClick"
        />
        <SearchFormTags
          @closeTag="closeTag"
          @onClean="handleClean"
          @onSaveSearch="handleSave"
          :key="getSelectedTags.length"
        />
      </div>
      <div class="filter-content--mobile">
        <SearchFormTags
          @closeTag="closeTag"
          @onClean="handleClean"
          @onSaveSearch="handleSave"
          :key="getSelectedTags.length"
        />
        <SearchPanelFilter
          @onItemClick="onItemClick"
          @toggleOpenMobile="$emit('toggleOpen')"
          @onClean="handleClean"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { TextLabel } from "@sc/ds-ui-component-library";
import SearchFormInputs from "./SearchFormInputs.vue";
import SearchFormTags from "./SearchFormTags.vue";
import SearchFormAutocomplete from "./SearchFormAutocomplete.vue";
import { mapGetters } from "vuex";

import SearchPanelFilter from "./SearchPanelFilter.vue";

export default {
  name: "SearchFormMain",
  components: {
    TextLabel,
    SearchFormInputs,
    SearchFormTags,
    SearchFormAutocomplete,
    SearchPanelFilter,
  },
  methods: {
    onItemClick(e) {
      this.$emit("onItemClick", e);
    },
    closeTag(tag) {
      this.$emit("onCloseTag", { ...tag, selected: false });
    },
    handleClean() {
      this.closeTag(this.getSelectedTags);
      history.pushState({}, null, "/carros-usados");
    },
    handleSave() {
      if (!this.getToken || this.isGuest) {
        this.$store.commit("SearchModule/filter/setSaveTags", true);
        this.$store.commit("SearchModule/filter/setFromLogin", true);
        document.querySelectorAll("[data-idgo-login]")[0].click();
      } else {
        this.$store.commit("SearchModule/setOpenSaveSearch");
      }
    },
  },
  computed: {
    ...mapGetters({
      getSelectedTags: "SearchModule/filter/getTags",
    }),
    ...mapGetters(["getToken", "isGuest"]),
  },
};
</script>

<style lang="scss" scoped>
.search-form--wrapper {
  z-index: 1000;
  padding: 16px;
  width: 100%;
  background-color: var(--color-5);
  display: flex;
  flex-direction: column;

  & > * {
    margin: 8px 0;
  }

  & > *:first-child {
    margin: 0 0 8px 0;
  }

  & > *:last-child {
    margin: 8px 0 0 0;
  }

  ::v-deep {
    .filter-label {
      --text-label-color: var(--color-1);
    }
  }
}
.filter-content {
  display: none;
}

.tags--row ::v-deep .baselink {
  --baselink-md-font-size: 14px;
  --baselink-md-line-height: 14px;
  text-decoration: underline;
}
_::-webkit-full-page-media,
.tags--row ::v-deep .baselink:last-child {
  margin-left: 8px;
}

.filter-content--mobile {
  display: flex;
  flex-direction: column;

  & > * {
    margin: 8px 0;
  }

  & > *:first-child {
    margin: 0 0 8px 0;
  }

  & > *:last-child {
    margin: 8px 0 0 0;
  }
}
@media (min-width: 768px) {
  .search-form--wrapper {
    padding: 32px;
  }
  .filter-content {
    display: flex;
    flex-direction: column;

    & > * {
      margin: 8px 0;
    }

    & > *:first-child {
      margin: 0 0 8px 0;
    }

    & > *:last-child {
      margin: 0 0 0 0;
    }
  }

  .filter-content--mobile {
    display: none;
  }

  .tags--row ::v-deep .baselink {
    --baselink-md-font-size: 16px;
    --baselink-md-line-height: 16px;
    text-decoration: none;
  }
}
</style>
