<template>
  <div class="ds-viewer">
    <div v-if="!getAOS" class="ds-viewer">
      <img class="main-image" :src="mainImage" alt="" />
      <NormalSlide :images="externalImages"></NormalSlide>
    </div>

    <div v-else id="aos-box">
      <div v-html="getAOS"></div>
    </div>
  </div>
</template>

<script>
import { NormalSlide } from "../../organisms";
import { mapGetters } from "vuex";

export default {
  components: {
    NormalSlide,
  },
  data() {
    return {
      aosLoaded: false,
    };
  },
  computed: {
    ...mapGetters({
      mainImage: "VehicleModule/getMainImage",
      externalImages: "VehicleModule/getExternalImages",
      getAOS: "VehicleModule/getAOS",
    }),
  },
  mounted() {
    let script = document.createElement("script");
    script.defer = "defer";
    script.src = "https://media-player.aos.tv/app.js?cbb1164a0797f1ecd59f";
    document.head.append(script);

    if (this.getAOS) {
      setTimeout(() => {
        this.aosLoaded = true;
      }, 1000);
    }
  },
  beforeDestroy() {
    const scripts = document.querySelectorAll(
      `script[src^='https://media-player.aos.tv/app.js?cbb1164a0797f1ecd59f']`
    );
    if (scripts.length) scripts.forEach((script) => script.remove());
  },
};
</script>

<style lang="scss" scoped>
.ds-viewer {
  width: 100%;
  max-width: 999px;
}

.main-image {
  width: 100%;
  max-width: 999px;
  max-height: 500px;
  object-fit: cover;
  object-position: center;
}

.loader-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1700px) and (min-width: 1280px) {
  .ds-viewer {
    max-width: 800px;
  }
}

#aos-box {
  width: 100%;
  height: 100%;
}
</style>
