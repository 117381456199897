import httpClient from "./httpClient";
import QueryString from "query-string";

const LEAD_URL = "/lead";

const LEAD_TYPES = {
  CONTACT_VIDEO: 31,
  TEST_DRIVE: 32,
  CONTACT_VEHICLE: 33,
  CONTACT: 34,
  NEWSLETTER: 36,
  CAR_ALERT: 37,
  PRICE_DROP: 38,
};

const buildQueryString = (type) => {
  const utms = JSON.parse(sessionStorage.getItem("utms")) || {};
  utms.type = type;

  let queryString = "?";

  queryString += QueryString.stringify(utms);

  return queryString;
};

function addReserve(payload) {
  const queryString = buildQueryString(LEAD_TYPES.RESERVE);
  return httpClient.post(LEAD_URL + queryString, payload);
}

function reserveTestDrive(payload) {
  const queryString = buildQueryString(LEAD_TYPES.TEST_DRIVE);
  return httpClient.post(LEAD_URL + queryString, payload);
}

function reserveContact(payload) {
  const queryString = buildQueryString(LEAD_TYPES.CONTACT);
  return httpClient.post(LEAD_URL + queryString, payload);
}

function reserveContactVechile(payload) {
  const queryString = buildQueryString(LEAD_TYPES.CONTACT_VEHICLE);
  return httpClient.post(LEAD_URL + queryString, payload);
}

function reservePriceDrop(payload) {
  const queryString = buildQueryString(LEAD_TYPES.PRICE_DROP);
  return httpClient.post(LEAD_URL + queryString, payload);
}

function reserveContactVideo(payload) {
  const queryString = buildQueryString(LEAD_TYPES.CONTACT_VIDEO);
  return httpClient.post(LEAD_URL + queryString, payload);
}

function subscribeNewsletter(payload) {
  const queryString = buildQueryString(LEAD_TYPES.NEWSLETTER);
  return httpClient.post(LEAD_URL + queryString, payload, {
    validateStatus: false,
  });
}

function submitCarAlert(payload) {
  const queryString = buildQueryString(LEAD_TYPES.CAR_ALERT);
  return httpClient.post(LEAD_URL + queryString, payload);
}

export {
  addReserve,
  reserveTestDrive,
  reserveContact,
  reserveContactVechile,
  reservePriceDrop,
  reserveContactVideo,
  subscribeNewsletter,
  submitCarAlert,
};
