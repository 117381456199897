import axios from "axios";

import { getGuestToken } from "./authentication";

const HTTP_STATUS = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
};

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_DIGITAL_STORE_BASE_URL,
  timeout: process.env.VUE_APP_REQUEST_TIMEOUT,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    companyId: "2",
  },
});

const getAuthToken = () => localStorage.getItem("token");

const authInterceptor = (config) => {
  if (getAuthToken()) {
    config.headers["Authorization"] =
      config.headers && config.headers["Authorization"] !== undefined
        ? config.headers["Authorization"]
        : `Bearer ` + getAuthToken();
  }
  return config;
};

const sessionInterceptor = (config) => {
  config.headers["sessionId"] = localStorage.getItem("dssessionId");

  return config;
};

const responseInterceptor = (response) => {
  return response;
};

const errorResponseInterceptor = (error) => {
  if (
    !error ||
    error.response.status === HTTP_STATUS.FORBIDDEN ||
    error.response.status === HTTP_STATUS.UNAUTHORIZED
  ) {
    const isGuest = () => JSON.parse(localStorage.getItem("isGuest"));

    if (isGuest) getGuestToken();
  }

  return error;
};

httpClient.interceptors.request.use(authInterceptor);

httpClient.interceptors.request.use(sessionInterceptor);

httpClient.interceptors.response.use(
  responseInterceptor,
  errorResponseInterceptor
);

export default httpClient;
