import {
  registerUser,
  getUserInfo,
  logoutUser,
  loginUser,
  getGuestToken,
  updatePersonalData,
} from "../../api/authentication";

import router from "./../../router/index";

import { setCookie } from "../../utils/cookies";

import { uuid } from "uuidv4";
import Vue from "vue";
import {
  changeUserDealer,
  recoverPassword,
  recoverUpdatePassword,
  verificationAccount,
} from "../../api/userMe";

import { resendActivationCode } from "../../api/authentication";
import i18n from "../../i18n";
import digestMessage from "../../utils/hash";
import { loginEvent, registrationEvent } from "../../utils/googleTagManager";

const state = {
  token: "",
  userInfo: {},
  loadingAuthentication: false,
  isGuest: true,
  passwordChanged: false,
  userObj: {},
  userStatus: 0,
  verifiedAccount: false,
};

const getters = {
  getUserInfoDealer: (state) => state.userInfo?.dealer,
  getDealerName: (state, getters) => (code) =>
    getters.getDealers.find((d) => d.code === code)?.designation,
  getDealerDistrict: (state, getters) => (code) =>
    getters.getDealers.find((d) => d.code === code)?.district,
  getDealerAddress: (state, getters) => (code) =>
    getters.getDealers.find((d) => d.code === code)?.address,
  getToken: (state) => {
    return state.token;
  },
  getUserInfoDifferentKeys: (state) => {
    const { name, userEmail, phoneNumber } = state.userInfo;
    return {
      email: userEmail,
      phone: phoneNumber,
      name,
    };
  },
  getUserInfo: (state) => state.userInfo,
  getWelcomeUser: (state) => {
    return state.userInfo?.name
      ? i18n.t("pages.personalPage.hi") + state.userInfo?.name?.split(" ")[0]
      : i18n.t("pages.personalPage.hi");
  },
  getLoadingAuthentication: (state) => state.loadingAuthentication,
  getUserName: (state) => state.userInfo?.name,
  getUserPhoneNumber: (state) => state.userInfo?.phoneNumber,

  getUserAddress: (state) => state.userInfo?.deliveryLocation,
  getUserDealership: (state) => state.userInfo?.favoriteDealership,
  getUserEmail: (state) => state.userInfo?.userEmail,
  isGuest: (state) => {
    return state.isGuest;
  },
  getUserObj: (state) => state.userObj,
  getUserStatus: (state) => state.userStatus,
  getVerifiedAccount: (state) => state.verifiedAccount,
};

const mutations = {
  setUserInfoDealer(state, { dealer, dealerCode, district }) {
    state.userInfo = {
      ...state.userInfo,
      favoriteDealership: dealer,
      dealer: dealerCode,
      district,
    };
  },
  setToken(state, token) {
    state.token = token;
  },
  setErrors(state, errors) {
    state.errors = errors;
  },
  setUserInfo(
    state,
    { name, phoneNumber, deliveryLocation, favoriteDealership, userEmail }
  ) {
    state.userInfo = {
      ...state.userInfo,
      name,
      phoneNumber,
      deliveryLocation,
      favoriteDealership,
      userEmail,
    };
  },
  setLoadingAuthentication(state, loading) {
    state.loadingAuthentication = loading;
  },
  setIsGuest(state, isGuest) {
    state.isGuest = isGuest;
  },
  setPhoneNumber(state, phoneNumber) {
    state.userInfo = {
      ...state.userInfo,
      phoneNumber,
    };
  },
  setPasswordChanged(state, changed) {
    state.passwordChanged = changed;
  },
  setUserObj(state, obj) {
    state.userObj.email = obj.email;
    state.userObj.password = obj.password;
    state.userObj.providerId = 5;
  },
  setUserStatus(state, status) {
    state.userStatus = status;
  },
  setVerifiedAccount(state, verified) {
    state.verifiedAccount = verified;
  },
  setUserPhoneNumber(state, phone) {
    state.userInfo.phoneNumber = phone;
  },
};

const actions = {
  async resendActivationCode({ state }) {
    try {
      await resendActivationCode(state.userObj);
      Vue.$toast.open({
        message: "O código de ativação foi enviado para o seu E-mail.",
        dismissible: true,
        type: "info",
      });
    } catch (err) {
      console.error(err);
      Vue.$toast.open({
        message:
          "Infelizmente ocorreu um erro ao enviar o código. Tente novamente mais tarde.",
        dismissible: true,
        type: "error",
      });
    }
  },
  async storeRegisterToken({ commit, dispatch }, userObj) {
    commit("setLoadingAuthentication", true);
    commit("setUserObj", userObj);
    try {
      const response = await registerUser(userObj);
      if (response.status === 200) {
        const token = response.data.data.token;
        commit("setToken", token);
        commit("setIsGuest", false);
        localStorage.setItem("token", token);
        localStorage.setItem("isGuest", false);
        setCookie("dssession", token);
        setCookie("dsguest", false);
        await dispatch("storeUserInfo");
        commit("setLoadingAuthentication", false);
        Vue.$toast.open({
          message: "Registo efetuado com sucesso",
          dismissible: true,
          type: "info",
        });
        return true;
      } else {
        if (response.status === 409) {
          Vue.$toast.open({
            message:
              response.data.data === "User already exists!"
                ? "Esta conta já está registada"
                : response.data.data,
            dismissible: true,
            type: "error",
          });
        }
        return false;
      }
    } catch (e) {
      Vue.$toast.open({
        message:
          "Lamentamos mas ocorreu um erro a efetuar o seu registo, por favor tente novamente mais tarde",
        dismissible: true,
        type: "error",
      });
      return false;
    } finally {
      commit("spinnerModule/setLoading", false, { root: true });
    }
  },
  async storeUserInfo({ commit, dispatch }) {
    commit("setLoadingAuthentication", true);
    try {
      const response = await getUserInfo();
      let userInfo = response.data.data;

      commit("setUserStatus", userInfo.userStatus);

      if (userInfo.userStatus === 0) {
        await dispatch("storeLogout");
        commit("setOpenVerificationAccount", true);
        commit("setVerifiedAccount", false);
      } else {
        commit("setVerifiedAccount", true);
        commit("setUserInfo", userInfo);
        commit("setLoadingAuthentication", false);
        commit("setIsGuest", false);
        await dispatch("storeReservations");
        await dispatch("storeGetAllReturns");
        await dispatch("storeGetSearch");
        await dispatch("NotificationModule/fetchPriceDropNotifications");
        await dispatch("FavoritesModule/fetchFavorites");
        commit("UserModule/setName", [userInfo.name, !!userInfo.name]);
        commit("UserModule/setEmail", [
          userInfo.userEmail,
          !!userInfo.userEmail,
        ]);
        commit("UserModule/setPhone", [
          userInfo.phoneNumber,
          !!userInfo.phoneNumber,
        ]);
        const sessionHash = await digestMessage(
          userInfo.name + userInfo.userEmail + userInfo.phoneNumber
        );
        localStorage.setItem("sessionStatus", sessionHash);
      }
    } catch (error) {
      if (error.response?.status === 401) {
        localStorage.removeItem("token");
        router.push("/");
      }
      commit("setErrors", error);
    }
  },
  async storeLogout({ commit, dispatch }) {
    commit("setLoadingAuthentication", true);
    try {
      await logoutUser();
      localStorage.removeItem("token");
      localStorage.removeItem("isGuest");

      commit("setToken", "");
      commit("setUserInfo", {});
      commit("setLoadingAuthentication", false);

      // remove all compared vehicles
      commit("CompareModule/reset", null, { root: true });
      commit("FavoritesModule/reset", null, { root: true });

      await dispatch("fetchGuestToken");
    } catch (e) {
      commit("setErrors", e);
    }
    localStorage.setItem("dssessionId", uuid());
  },
  async storeLogin({ commit, dispatch }, userObj) {
    commit("setLoadingAuthentication", true);
    commit("setUserObj", userObj);
    try {
      const response = await loginUser(userObj);

      if (!response.data && response.response.statusCode !== 200) {
        Vue.$toast.open({
          message:
            response.response.data.message ===
            "No user found or password incorrect"
              ? "Utilizador não está registado ou a palavra passe está incorreta"
              : response.response.data.message,
          dismissible: true,
          type: "error",
        });
        return;
      }

      if (response.data.data.registration) {
        registrationEvent();
      }

      let token = response.data.data.token;
      localStorage.setItem("token", token);
      localStorage.setItem("isGuest", false);
      commit("setToken", token);
      commit("setIsGuest", false);
      setCookie("dssession", token);
      setCookie("dsguest", false);
      await dispatch("storeUserInfo");
      commit("setLoadingAuthentication", false);
      loginEvent();
      Vue.$toast.open({
        message: "Login efetuado com sucesso",
        dismissible: true,
        type: "info",
      });
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        const response = await loginUser(userObj);
        let token = response.data.data.token;
        localStorage.setItem("token", token);
        localStorage.setItem("isGuest", false);
        commit("setToken", token);
        commit("setIsGuest", false);
        setCookie("dssession", token);
        setCookie("dsguest", false);
        await dispatch("storeUserInfo");
        commit("setLoadingAuthentication", false);
        Vue.$toast.open({
          message:
            "Lamentamos mas ocorreu um erro a efetuar a sua autenticação, por favor tente novamente mais tarde",
          dismissible: true,
          type: "error",
        });
      }
    }
  },
  async revalidateLogin({ commit, dispatch }, userObj) {
    commit("setLoadingAuthentication", true);
    commit("setUserObj", userObj);
    try {
      const response = await loginUser(userObj);

      if (!response.data && response.response.statusCode !== 200) {
        return;
      }

      let token = response.data.data.token;
      localStorage.setItem("token", token);
      localStorage.setItem("isGuest", false);
      commit("setToken", token);
      commit("setIsGuest", false);
      setCookie("dssession", token);
      setCookie("dsguest", false);
      await dispatch("storeUserInfo");
      commit("setLoadingAuthentication", false);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        const response = await loginUser(userObj);
        let token = response.data.data.token;
        localStorage.setItem("token", token);
        localStorage.setItem("isGuest", false);
        commit("setToken", token);
        commit("setIsGuest", false);
        setCookie("dssession", token);
        setCookie("dsguest", false);
        await dispatch("storeUserInfo");
        commit("setLoadingAuthentication", false);
      }
    }
  },
  async storeSyncToken({ commit, dispatch }, token) {
    commit("setLoadingAuthentication", true);
    commit("setToken", token);
    commit("setIsGuest", false);
    setCookie("dssession", token);
    setCookie("dsguest", false);
    await dispatch("storeUserInfo");
    commit("setLoadingAuthentication", false);
  },
  async fetchGuestToken({ commit }) {
    commit("setLoadingAuthentication", true);
    const response = await getGuestToken();
    let token = response.data.data.token;
    localStorage.setItem("token", token);
    commit("setToken", token);
    commit("setIsGuest", true);
    setCookie("dssession", token);
    setCookie("dsguest", true);
    localStorage.setItem("isGuest", true);

    commit("setLoadingAuthentication", false);
  },
  async updateUser({ state, commit }, userInfo) {
    try {
      const personalData = {
        ...userInfo,
      };

      updatePersonalData(personalData);

      const mergeUserInfo = { ...state.userInfo, ...personalData };

      commit("setUserInfo", mergeUserInfo);
    } catch (err) {
      console.error("Failed Personal Data Update in Backend", err);
    }
  },
  updateDealer({ commit }, d) {
    commit("setUserInfoDealer", d);
    // eslint-disable-next-line
    const { dealer, ...rest } = d;
    changeUserDealer(rest);
  },
  recoverPassword({ commit }, email) {
    commit("spinnerModule/setLoading", true, { root: true });
    try {
      const response = recoverPassword(email);
      return response;
    } catch (err) {
      console.error(err);
      return Promise.reject();
    } finally {
      commit("spinnerModule/setLoading", false, { root: true });
    }
  },
  recoverUpdatePassword({ commit }, payload) {
    commit("spinnerModule/setLoading", true, { root: true });
    try {
      const response = recoverUpdatePassword(payload);
      return response;
    } catch (err) {
      console.error(err);
      return Promise.reject();
    } finally {
      commit("spinnerModule/setLoading", false, { root: true });
    }
  },
  async verificationAccount({ commit }, obj) {
    commit("spinnerModule/setLoading", true, { root: true });
    try {
      const responseToken = await loginUser(obj.userObj);

      let token = responseToken.data.data.token;
      localStorage.setItem("token", token);

      const response = verificationAccount(obj.payload);

      return response;
    } catch (err) {
      console.error(err);
      return Promise.reject();
    } finally {
      commit("spinnerModule/setLoading", false, { root: true });
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
