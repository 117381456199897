<template>
  <div :class="['link-list', classes]">
    <div class="item" v-for="(l, index) in list" :key="index">
      <router-link :to="l.url">
        <Link
          @onClick="onClickHandler"
          :label="l.label"
          :bold="bold"
          :color="color"
          :size="size"
          :uppercase="uppercase"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
import { Link } from "../../atoms";
export default {
  name: "LinkList",
  components: {
    Link,
  },
  props: {
    list: {
      type: Array[Object],
      required: true,
    },
    display: {
      type: String,
      default: "vertical",
    },
    bold: {
      type: String,
      default: "normal",
    },
    color: {
      type: String,
      default: "primary",
    },
    size: {
      type: String,
      default: "medium",
    },
    uppercase: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        horizontal: this.display === "horizontal",
      };
    },
  },
  methods: {
    onClickHandler() {
      this.$emit("onClick");
    },
  },
};
</script>
<style lang="css" scoped src="./LinkList.css"></style>
