<template>
  <a
    :href="href"
    :class="['link', classes, customClass]"
    :style="customStyles"
    @click="clickHandler"
  >
    <slot name="teste" />
    <div class="link--icon-container">
      <font-awesome-icon
        :class="[
          label.length ? 'link--icon' : '',
          '',
          iconClass,
          iconSizeClass,
        ]"
        v-if="icon"
        :icon="icon"
      />
    </div>
    {{ label }}
  </a>
</template>
<script>
export default {
  name: "Link",
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      },
    },
    customClass: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "primary",
      validator: (value) => {
        return ["primary", "secondary", "tertiary"].includes(value);
      },
    },
    size: {
      type: String,
      default: "medium",
      validator: (value) => {
        return ["x-small", "small", "medium", "large"].includes(value);
      },
    },
    sizeIcon: {
      type: String,
      default: "small",
      validator: (sizeIcon) => {
        return ["x-small", "small", "medium", "large", "x-large"].includes(
          sizeIcon
        );
      },
    },
    uppercase: {
      type: Boolean,
      default: false,
    },
    lowercase: {
      type: Boolean,
      default: false,
    },
    stroked: {
      type: Boolean,
      default: false,
    },
    underline: {
      type: Boolean,
      default: false,
    },
    capitalize: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    bold: {
      type: String,
      default: "normal",
      validator: (value) => {
        return [
          "thin",
          "extra-light",
          "normal",
          "medium",
          "bold",
          "extra-bold",
        ].includes(value);
      },
    },
    href: {
      type: String,
      default: "#",
    },
    icon: {
      type: String,
      default: "",
    },
    iconPosition: {
      type: String,
      default: "left",
      validator: (position) => {
        return ["left", "right"].includes(position);
      },
    },
    iconClass: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    clickHandler(event) {
      this.$emit("onClick", event);
    },
  },
  computed: {
    classes() {
      return {
        "link--x-small": this.size === "x-small",
        "link--small": this.size === "small",
        "link--medium": this.size === "medium",
        "link--large": this.size === "large",
        "link--underline": this.underline,
        "link--bold": this.bold === "bold",
        "link--bold-extralight": this.bold === "extra-light",
        "link--bold-extra": this.bold === "extra-bold",
        "link--bold-medium": this.bold === "medium",
        "link--bold-normal": this.bold === "normal",
        "link--stroked": this.stroked,
        "link--uppercase": this.uppercase,
        "link--lowercase": this.lowercase,
        "link--capitalize": this.capitalize,
        "link--primary": this.color === "primary",
        "link--secondary": this.color === "secondary",
        "link--tertiary": this.color === "tertiary",
        "link--icon-reverse": this.icon.length && this.iconPosition === "right",
      };
    },
    iconSizeClass() {
      return {
        // size
        "icon--container-x-small": this.sizeIcon === "x-small",
        "icon--container-small": this.sizeIcon === "small",
        "icon--container-medium": this.sizeIcon === "medium",
        "icon--container-large": this.sizeIcon === "large",
        "icon--container-x-large": this.sizeIcon === "x-large",
      };
    },
    customStyles() {
      return {
        ...this.styles,
      };
    },
  },
};
</script>

<style lang="css" scoped src="./Link.css"></style>
