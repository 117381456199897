<template>
  <div class="filter-car-product--wrapper">
    <div class="filter-car-left-labels">
      <div
        v-for="(tag, index) in tagList"
        :class="{ tagButtonSelected: tag.key == currentTag, tagButton: true }"
        :key="index"
      >
        <Button @click="handleTagFilter(tag)">{{ tag.value }}</Button>
      </div>
    </div>
    <div class="filter-car-product--type">
      <TextLabel
        customClass="filter-car-results--label"
        weight="semibold"
        :label="$t('pages.searchpage.results', { results: this.getTotal })"
      />
      <TextLabel customClass="breakLabel" label="|" />
      <div class="order-by">
        <TextLabel
          customClass="filter-car-product--label"
          weight="semibold"
          label="ORDENAR POR:"
        />
        <Dropdown
          :box="false"
          type="secondary"
          @onSelectedItems="sort"
          id="sortList"
          :items="listOrder"
          :name="currentSortName"
          :border="false"
          class="order-select"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Dropdown, TextLabel } from "@sc/ds-ui-component-library";
import { mapGetters } from "vuex";
import { orderByEvent } from "../../../utils/googleTagManager";

export default {
  components: { TextLabel, Dropdown },
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      },
    },
    customClass: {
      type: String,
      default: "",
    },
    labelOrder: {
      type: String,
      default: "Ordenar por",
    },
    labelResults: {
      type: String,
      default: "Resultados",
    },
    selectedOrder: {
      type: String,
      default: "Recomendado",
    },
    listOrder: {
      type: Array[String],
      default: () => [
        { value: "Data Asc.", key: { order: "asc", sort: "createTime" } },
        { value: "Data Desc.", key: { order: "desc", sort: "createTime" } },
        { value: "Preço Asc.", key: { order: "asc", sort: "price" } },
        { value: "Preço Desc.", key: { order: "desc", sort: "price" } },
        {
          value: "Mensalidade Asc.",
          key: { order: "asc", sort: "monthlyPayment" },
        },
        {
          value: "Mensalidade Desc.",
          key: { order: "desc", sort: "monthlyPayment" },
        },
        { value: "Segmento", key: { order: "asc", sort: "segment" } },
        { value: "Modelo", key: { order: "asc", sort: "model" } },
      ],
    },
    resultCount: {
      //Number of searched cars
      type: Number,
      default: 0,
    },
    orderBy: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      currentSortName: this.listOrder[0].value,
      tagList: this.$t("searchform.tagList"),
    };
  },
  computed: {
    ...mapGetters({
      getTotal: "SearchModule/pagination/getResults",
      getSearch: "SearchModule/getFormattedFilters",
    }),
    currentTag() {
      return Object.keys(this.getSearch).includes("lowCost")
        ? "lowCost"
        : "Todos";
    },
    classes() {
      return {};
    },
    customStyles() {
      return {
        ...this.styles,
      };
    },
  },
  methods: {
    sort(item) {
      const selected = item.find((i) => i.selected);
      this.$store.commit("SearchModule/filter/setOrder", selected.key.order);
      this.$store.commit("SearchModule/filter/setSort", selected.key.sort);
      this.$store.dispatch("SearchModule/fetchRecent", {
        quickSearchItem: [],
        dynamic: false,
      });
      this.$emit("onSort", selected.key.order, selected.key.sort);
      this.currentSortName = selected.value;

      orderByEvent(item.filter((i) => i.selected)[0].value, this.getTotal);
    },
    handleTagFilter(tag) {
      const filter = {
        type: "lowCost",
        id: 1,
        value: "Low Cost",
        selected: true,
      };
      if (tag.key == "lowCost") {
        this.$store.dispatch("SearchModule/filter/addOrReplaceTag", filter);
        this.$store.commit("SearchModule/filter/setSaveTags", true);
      } else if (tag.key == "Todos") {
        this.$store.commit("SearchModule/filter/removeTag", filter);
      }
      this.$store.commit("SearchModule/pagination/setCurrent", 1);
      this.$store.dispatch("SearchModule/fetchRecent", {
        quickSearchItem: [],
        dynamic: false,
      });
    },
    tagButtonClasses() {
      return this.$store.state.tagFilter;
    },
  },
};
</script>

<style lang="scss" scoped>
.breakLabel {
  margin: 0px 10px;
}
.tagButton {
  margin: 0;
  font-family: "Mont";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  width: 151px;
  padding: 16px;
  color: #02226f;
  border-bottom: 2px solid white;
}
.tagButton:hover {
  border-bottom: 2px solid #0053ff;
  color: #0053ff;
}
.tagButton:active {
  color: #0053ff;
}
.tagButtonSelected {
  border-bottom: 2px solid #0053ff;
  color: #0053ff;
}
.filter-car-product--wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eaeaea;
}

.filter-car-product--type {
  display: flex;
  align-items: center;
  & > * {
    margin: 0 18px;
  }
  ::v-deep {
    .order-select {
      .text-label {
        text-transform: uppercase;
        --text-label-color: var(--color-1);
        --text-label-weight: var(--font-weight-semibold);
      }
      .dropdown-header .dropdown-header-inner {
        display: flex;
        padding: 8px;
      }
    }
  }
}

::v-deep .selectCustom-trigger {
  background-color: var(--color-7);
}

.filter-car-results--label {
  --text-label-color: var(--color-2);
}

.breakLabel,
.filter-car-product--label {
  display: none;
}

.order-by {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .filter-car-product--wrapper {
    flex-direction: column;
  }
  .filter-car-product--type {
    justify-content: space-between;
    width: 100%;
  }

  .filter-car-left-labels {
    width: 100%;
    justify-content: space-evenly;
  }
}
@media (min-width: 768px) {
  .breakLabel,
  .filter-car-product--label {
    display: inline;
    --text-label-color: var(--color-2);
  }
}

@media (max-width: 820px) {
  .filter-car-product--wrapper {
    flex-direction: column;
  }
  .filter-car-product--type {
    justify-content: space-between;
    width: 100%;
  }
  .breakLabel {
    display: none;
  }
}

::v-deep .dropdown-header-inner {
  --dropdown-header-color: var(--color-1);
}

.filter-car-left-labels {
  display: flex;
  flex-direction: row;

  & > * {
    margin: 0 2px;
  }

  & > *:first-child {
    margin: 0 2px 0 0;
  }

  & > *:last-child {
    margin: 0 0 0 2px;
  }

  ::v-deep {
    --font-weight-bold: 700;
  }
}
</style>
