<template>
  <transition name="popup-fade">
    <div
      :class="['popup-backdrop', customClass, classes]"
      @mousedown="backdropClick"
      :style="customStyles"
    >
      <div
        class="popup"
        role="dialog"
        :aria-labelledby="title"
        aria-describedby=""
      >
        <header
          v-if="title || closeButton"
          class="popup-header"
          id="popupTitle"
        >
          <div class="popup-header-title">
            <slot name="header">
              <Label
                class="popup-header-title-label"
                color="primary"
                size="medium"
                >{{ title }}</Label
              >
            </slot>
          </div>
          <div class="popup-header-right">
            <Button
              class="close-button"
              icon="times"
              size="small"
              :fill="true"
              bgColor="#F5F7F9"
              :border="false"
              @onClick.prevent="close"
            ></Button>
          </div>
        </header>

        <section class="popup-body" id="popupDescription">
          <slot name="body"> </slot>
        </section>

        <footer class="popup-footer">
          <slot name="footer"> </slot>
        </footer>
      </div>
    </div>
  </transition>
</template>
<script>
import { Button, Label } from "../../atoms";

export default {
  name: "Popup",
  components: {
    Button,
    Label,
  },
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      },
    },
    customClass: {
      type: String,
      default: "",
    },
    closeButton: {
      type: Boolean,
      default: false,
    },
    closeOnClickOutside: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
  },
  computed: {
    customStyles() {
      return {
        ...this.styles,
      };
    },
    classes() {
      return {};
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    backdropClick(event) {
      if (!this.closeOnClickOutside) return;

      if (event.target.classList.contains("popup-backdrop")) this.close();
    },
  },
};
</script>
<style lang="css" scoped src="./Popup.css"></style>
