<template>
  <div class="autocomplete--row">
    <div class="autocomplete--wrapper">
      <AutocompleteDropdown
        inputCustomClass="complete"
        :placeholder="$t('searchform.autocomplete')"
        :results="[]"
        @onChange="setValue"
        @onSubmitSearch="handleSubmitNeedle"
      />
    </div>
    <div class="btn-search">
      <Button
        type="secondary"
        :label="$t('searchform.search')"
        @onClick="handleSubmitNeedle"
      />
    </div>
    <div class="btn-search--mobile">
      <Button class="" withIcon icon="search" @onClick="handleSubmitNeedle" />
    </div>
  </div>
</template>

<script>
import AutocompleteDropdown from "../../organisms/AutocompleteDropdown/AutocompleteDropdown.vue";
import { Button } from "@sc/ds-ui-component-library";
import { mapGetters } from "vuex";

export default {
  name: "SearchFormAutocomplete",
  components: { AutocompleteDropdown, Button },
  data() {
    return {
      inputValue: "",
    };
  },
  methods: {
    setValue(value) {
      this.inputValue = value;
    },
    setNeedle(value) {
      this.$store.commit("SearchModule/filter/setNeedle", value);
    },
    async handleSubmitNeedle() {
      this.setNeedle(this.inputValue);
      var tag = {
        type: "needle",
        id: 0,
        value: this.getNeedle,
      };
      if (this.getNeedle !== "") {
        await this.$store.dispatch("SearchModule/filter/addOrReplaceTag", tag);
      } else {
        await this.$store.commit("SearchModule/filter/removeTag", tag);
      }

      await this.$store.commit("SearchModule/pagination/setCurrent", 1);
      await this.$store.dispatch("SearchModule/fetchRecent", {
        quickSearchItem: [],
        dynamic: false,
      });
    },
  },
  computed: {
    ...mapGetters({
      getNeedle: "SearchModule/filter/getNeedle",
      getResults: "SearchModule/pagination/getResults",
      getSearchEventDue: "SearchModule/getSearchEventDue",
    }),
  },
};
</script>

<style lang="scss" scoped>
.btn-search {
  display: none;
}

.btn-search--mobile ::v-deep .btn {
  width: 56px;
  padding: 0;
}
.btn-search--mobile {
  display: flex;
  --button-primary-bg-color: var(--theme-color);
}

@media (min-width: 768px) {
  .btn-search {
    display: flex;
  }
  .btn-search--mobile {
    display: none;
  }
}
.autocomplete--row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: stretch;

  & > * {
    margin: 0 8px;
  }

  & > *:first-child {
    margin: 0 8px 0 0;
  }

  & > *:last-child {
    margin: 0 0 0 8px;
  }
}

.autocomplete--wrapper {
  flex: 1;
}

::v-deep .complete {
  height: 56px;
  border: none;
}

::v-deep .button {
  width: 203px;
  display: flex;
  justify-content: center !important; /* Missing variable */
  flex-shrink: 0;
}

::v-deep .button--primary {
  height: 40px;
}

::v-deep .button--primary div {
  font-size: 14px;
  line-height: 18px;
}
</style>
