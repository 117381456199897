<template>
  <div
    :class="['collapse-container', customClass]"
    :style="customStyles"
    ref="container"
  >
    <div
      :class="['collapse-container--wrapper', classes]"
      :style="customWrapperStyles"
      ref="wrapper"
    >
      <slot />
    </div>
    <div class="collapse-container--link-wrapper" v-if="!used">
      <Button
        type="tertiary"
        :icon="icon"
        iconPosition="right"
        :label="expandButtonText"
        @onClick.prevent="onCollapseHandler"
        withIcon
      />
    </div>
  </div>
</template>

<script>
import { Button } from "@sc/ds-ui-component-library";

export default {
  name: "CollapseContainer",
  components: {
    Button,
  },
  data() {
    return {
      collapsed: false,
      used: false,
      expandedHeight: 0,
    };
  },
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      },
    },
    customClass: {
      type: String,
      default: "",
    },
    useOnce: {
      type: Boolean,
      default: false,
    },
    collapsedHeight: {
      type: String,
      default: "",
      required: true,
    },
    bgColor: {
      type: String,
      default: "",
    },
    expandButtonText: {
      type: String,
      default: "Show more",
    },
    expandButtonColor: {
      type: String,
      default: "secondary",
      validator: (value) => {
        return ["primary", "secondary", "tertiary"].includes(value);
      },
    },
    expandButtonUppercase: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        "collapse-container--wrapper-close": !this.collapsed,
        "collapse-container--wrapper-open": this.collapsed,
      };
    },
    customStyles() {
      return {
        ...this.styles,
        backgroundColor: this.bgColor,
      };
    },
    customWrapperStyles() {
      return {
        height: !this.collapsed ? this.collapsedHeight : this.expandedHeight,
      };
    },
    icon() {
      return this.collapsed ? "chevron-up" : "chevron-down";
    },
  },
  methods: {
    onCollapseHandler() {
      this.collapsed = !this.collapsed;
      if (this.collapsed) if (this.useOnce) this.used = true;
      this.$emit("onOpen", this.collapsed);
    },
  },
  mounted() {
    // get default height of uncollapsed div
    this.expandedHeight = this.$refs.wrapper.scrollHeight + "px";
  },
};
</script>

<style lang="css" scoped src="./CollapseContainer.css"></style>
