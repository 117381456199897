import { addRemoveFavoritesEvent } from "../utils/googleTagManager";

const FavoritesMixin = {
  methods: {
    toggleFavorite(vehicle) {
      if (this.$store.getters.isGuest) {
        return document.querySelectorAll("[data-idgo-login]")[0].click();
      }

      this.$store.commit("CompareModule/closePanel");
      this.$store.commit("FavoritesModule/openPanel");

      const existingFavorite =
        this.$store.getters["FavoritesModule/exists"](vehicle);

      if (existingFavorite) {
        this.$store.dispatch("FavoritesModule/removeFromFavorites", vehicle);
        addRemoveFavoritesEvent(vehicle, false);
      } else {
        this.$store.dispatch("FavoritesModule/addToFavorites", vehicle);
        addRemoveFavoritesEvent(vehicle, true);
      }

      this.$toast.open({
        message: existingFavorite
          ? this.$t("toast.favorites.removedFavorite")
          : this.$t("toast.favorites.addedFavorite"),
        dismissible: true,
        type: "info",
      });
    },
  },
};

export { FavoritesMixin };
