const vehicle = {
  carDetailCard: {
    year: "",
    model: "KAUAI",
    commercialDescription: "1.0 T-GDi Premium MY21 (TT)",
    fuel: "GASOLINA",
    transmission: "Manual 6 velocidades",
    pricePvp: 24064.7532,
    vin: "KMHK3812AMU670322",
    imageUrl: "",
    taeg: 9.274954591330609,
    monthlyPrice: 385.19,
    financeId: 973,
    tooltipDescription: {
      depositValue: 4500.0,
      capitalFinance: 19564.7532,
      mtic: 24077.91,
      tan: 6.5,
      months: 60,
      totalPrice: 22996.449,
      taeg: 9.274954591330609,
      monthPrice: 385.19,
      consumptionCombine: 6.0,
      emissions: 136.0,
    },
    totalPrice: 22996.449,
    updateTime: null,
    favorite: false,
  },
  carDetailSpecification: {
    specifications: [
      {
        title: "Motor",
        description: "1.0 T-GDi (120/ cv(kW))",
      },
      {
        title: "Potência",
        description: "120/ cv(kW)",
      },
      {
        title: "Cor Exterior",
        description: "",
      },
      {
        title: "Cor Interior",
        description: "",
      },
      {
        title: "Transmissão",
        description: "Manual 6 velocidades",
      },
      {
        title: "Consumo (WLTP)",
        description: "6 (l/100km)",
      },
    ],
  },
  carDetailSpecificationV2: {
    specifications: [
      {
        key: "fuel",
        value: "GASOLINA",
        unit: null,
      },
      {
        key: "CO2Combinado",
        value: "136",
        unit: "g/km",
      },
      {
        key: "Cilindrada",
        value: "998",
        unit: "cc",
      },
      {
        key: "PotenciaMaximaCV+PotenciaMaximaRPM",
        value: "120 + 6000",
        unit: "CV/rpm",
      },
      {
        key: "BinarioMaximo",
        value: "172/1.500",
        unit: "Nm/rpm",
      },
      {
        key: "ConsumoCombinadoWLTP",
        value: "6",
        unit: "l/100km",
      },
      {
        key: "Transmissao",
        value: "Manual 6 velocidades",
        unit: null,
      },
      {
        key: "NationalColorDesig",
        value: null,
        unit: null,
      },
      {
        key: "InteriorColorSupplierDesc",
        value: null,
        unit: null,
      },
    ],
  },
  carDetailEquipment: {
    equipments: [
      "Luzes de circulação diurna em LED",
      "Apoio para braços dianteiro com compartimento para arrumações",
      "6 Altifalantes",
      "Ar condicionado automático 1 zona com sistema de desembaciamento automático",
      "Banco do condutor com ajuste elétrico do apoio lombar",
      "Cruise Control",
      "Chave Inteligente com botão de ignição",
      "Vidros dianteiros elétricos",
      "Vidros traseiros elétricos",
      "Barras no tejadilho",
      "Espelhos exteriores aquecidos",
      "Espelhos exteriores com indicadores de mudança de direção em LED",
      "Jantes em liga leve de 18''",
      "Vidros laterais traseiros e óculo traseiro privativos",
      "Banco traseiro rebatível assimetricamente (60/40)",
      "Bluetooth",
      "Integração de smartphone Apple Carplay e Android Auto",
      "Porta-copos",
      "Portas USB",
      "Câmara de auxílio ao estacionamento traseira",
    ],
  },
  carDetailEquipmentByCategory: {
    equipmentsByCategory: [
      {
        categoryId: 32,
        categoryName: "Exterior",
        equipments: [
          "Luzes de circulação diurna em LED",
          "Barras no tejadilho",
          "Espelhos exteriores aquecidos",
          "Espelhos exteriores com indicadores de mudança de direção em LED",
          "Jantes em liga leve de 18''",
          "Vidros laterais traseiros e óculo traseiro privativos",
          "Espelhos exteriores com recolha elétrica",
          "Espelhos exteriores com regulação elétrica",
          "Faróis em Halogéneo",
          "Pneus Continental",
          "Two Tone ",
        ],
      },
      {
        categoryId: 33,
        categoryName: "Conforto",
        equipments: [
          "Apoio para braços dianteiro com compartimento para arrumações",
          "6 Altifalantes",
          "Ar condicionado automático 1 zona com sistema de desembaciamento automático",
          "Banco do condutor com ajuste elétrico do apoio lombar",
          "Cruise Control",
          "Chave Inteligente com botão de ignição",
          "Vidros dianteiros elétricos",
          "Vidros traseiros elétricos",
          "Banco do condutor com ajustamento manual em altura",
          "Comandos no volante",
          "Direção assistida eletricamente",
          "Vidros dianteiros com regulação dianteira (condutor: função 'um toque')",
        ],
      },
      {
        categoryId: 34,
        categoryName: "Interior",
        equipments: [
          "Banco traseiro rebatível assimetricamente (60/40)",
          "Bluetooth",
          "Integração de smartphone Apple Carplay e Android Auto",
          "Porta-copos",
          "Portas USB",
          "Ecrã touchscreen de 8'",
          "Estofos em tecido",
          "Volante e manete de mudanças em pele",
          "Apoios de cabeça (condutor e passageiro) com regulação em altura",
          "DAB",
          "Computador de bordo com ecrã 'Supervision Cluster' de 10,25' policromático",
        ],
      },
      {
        categoryId: 35,
        categoryName: "Segurança",
        equipments: [
          "Câmara de auxílio ao estacionamento traseira",
          "Sistema de alerta de fadiga do condutor (DAW)",
          "Sistema de Manutenção à faixa de rodagem (LKA)",
          "6 Airbags - Condutor, passageiro, laterais dianteiros e de cortina ",
          "Alarme com imobilizador",
          "Bloqueio automático das portas (após o arranque)",
          "Faróis de nevoeiro dianteiros",
          "Controlo de Arranque em Subida (HAC)",
          "Controlo de Descida (DBC)",
          "Sensor de chuva (Limpa para-brisas de ativação automática)",
          "Sensor de luz (Faróis de acendimento automático)",
          "Sensores de estacionamento traseiros",
          "Controlo Eletrónico de Estabilidade (ESC)",
          "Travagem Autónoma de Emergência (FCA)",
          "Indicador de perda de pressão dos pneus (TPMS) 'Highline' indica pneu afetado",
          "Pneu de socorro temporário",
          "Alerta de passageiros nos bancos traseiros",
          "ABS E ESP",
          "Alerta de arranque do veículo dianteiro (LVDA)",
        ],
      },
    ],
  },
  carDetailImage: {
    staticImages: {
      externalImages: [
        {
          id: 4552,
          url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/version/idVersion_1438_idColorExt_458_2021-09-03-15-27-13.png",
          main: false,
        },
        {
          id: 4556,
          url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/version/idVersion_1438_idColorExt_458_2021-09-03-15-27-18.png",
          main: false,
        },
        {
          id: 4555,
          url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/version/idVersion_1438_idColorExt_458_2021-09-03-15-27-17.png",
          main: false,
        },
        {
          id: 4554,
          url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/version/idVersion_1438_idColorExt_458_2021-09-03-15-27-16.png",
          main: false,
        },
        {
          id: 4553,
          url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/version/idVersion_1438_idColorExt_458_2021-09-03-15-27-14.png",
          main: false,
        },
      ],
      internalImages: [
        {
          id: 143,
          url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/version/idVersion_1438_idInternColor_10000.png",
          main: false,
        },
      ],
    },
    // rotationImages: {
    //   rotatingExternalImages: [
    //     {
    //       id: 12955,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/external360/idVersion1438_color_458_0.webp",
    //       priority: 0,
    //     },
    //     {
    //       id: 12956,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/external360/idVersion1438_color_458_1.webp",
    //       priority: 1,
    //     },
    //     {
    //       id: 12967,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/external360/idVersion1438_color_458_2.webp",
    //       priority: 2,
    //     },
    //     {
    //       id: 12978,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/external360/idVersion1438_color_458_3.webp",
    //       priority: 3,
    //     },
    //     {
    //       id: 12985,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/external360/idVersion1438_color_458_4.webp",
    //       priority: 4,
    //     },
    //     {
    //       id: 12986,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/external360/idVersion1438_color_458_5.webp",
    //       priority: 5,
    //     },
    //     {
    //       id: 12987,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/external360/idVersion1438_color_458_6.webp",
    //       priority: 6,
    //     },
    //     {
    //       id: 12988,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/external360/idVersion1438_color_458_7.webp",
    //       priority: 7,
    //     },
    //     {
    //       id: 12989,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/external360/idVersion1438_color_458_8.webp",
    //       priority: 8,
    //     },
    //     {
    //       id: 12990,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/external360/idVersion1438_color_458_9.webp",
    //       priority: 9,
    //     },
    //     {
    //       id: 12957,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/external360/idVersion1438_color_458_10.webp",
    //       priority: 10,
    //     },
    //     {
    //       id: 12958,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/external360/idVersion1438_color_458_11.webp",
    //       priority: 11,
    //     },
    //     {
    //       id: 12959,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/external360/idVersion1438_color_458_12.webp",
    //       priority: 12,
    //     },
    //     {
    //       id: 12960,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/external360/idVersion1438_color_458_13.webp",
    //       priority: 13,
    //     },
    //     {
    //       id: 12961,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/external360/idVersion1438_color_458_14.webp",
    //       priority: 14,
    //     },
    //     {
    //       id: 12962,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/external360/idVersion1438_color_458_15.webp",
    //       priority: 15,
    //     },
    //     {
    //       id: 12963,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/external360/idVersion1438_color_458_16.webp",
    //       priority: 16,
    //     },
    //     {
    //       id: 12964,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/external360/idVersion1438_color_458_17.webp",
    //       priority: 17,
    //     },
    //     {
    //       id: 12965,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/external360/idVersion1438_color_458_18.webp",
    //       priority: 18,
    //     },
    //     {
    //       id: 12966,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/external360/idVersion1438_color_458_19.webp",
    //       priority: 19,
    //     },
    //     {
    //       id: 12968,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/external360/idVersion1438_color_458_20.webp",
    //       priority: 20,
    //     },
    //     {
    //       id: 12969,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/external360/idVersion1438_color_458_21.webp",
    //       priority: 21,
    //     },
    //     {
    //       id: 12970,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/external360/idVersion1438_color_458_22.webp",
    //       priority: 22,
    //     },
    //     {
    //       id: 12971,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/external360/idVersion1438_color_458_23.webp",
    //       priority: 23,
    //     },
    //     {
    //       id: 12972,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/external360/idVersion1438_color_458_24.webp",
    //       priority: 24,
    //     },
    //     {
    //       id: 12973,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/external360/idVersion1438_color_458_25.webp",
    //       priority: 25,
    //     },
    //     {
    //       id: 12974,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/external360/idVersion1438_color_458_26.webp",
    //       priority: 26,
    //     },
    //     {
    //       id: 12975,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/external360/idVersion1438_color_458_27.webp",
    //       priority: 27,
    //     },
    //     {
    //       id: 12976,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/external360/idVersion1438_color_458_28.webp",
    //       priority: 28,
    //     },
    //     {
    //       id: 12977,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/external360/idVersion1438_color_458_29.webp",
    //       priority: 29,
    //     },
    //     {
    //       id: 12979,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/external360/idVersion1438_color_458_30.webp",
    //       priority: 30,
    //     },
    //     {
    //       id: 12980,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/external360/idVersion1438_color_458_31.webp",
    //       priority: 31,
    //     },
    //     {
    //       id: 12981,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/external360/idVersion1438_color_458_32.webp",
    //       priority: 32,
    //     },
    //     {
    //       id: 12982,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/external360/idVersion1438_color_458_33.webp",
    //       priority: 33,
    //     },
    //     {
    //       id: 12983,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/external360/idVersion1438_color_458_34.webp",
    //       priority: 34,
    //     },
    //     {
    //       id: 12984,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/external360/idVersion1438_color_458_35.webp",
    //       priority: 35,
    //     },
    //   ],
    //   rotatingInternalImages: [
    //     {
    //       id: 796,
    //       url: "https://democardbhyundai.rigorcg.pt/files//bdproduto_3/internal360/idVersion_1438_color_10000_1000.webp",
    //       priority: 1000,
    //     },
    //   ],
    // },
  },
  carDetailFinance: {
    pvp: 24064.7532,
    depositValue: 4500.0,
    months: 60,
    tan: 6.5,
    taeg: 9.274954591330609,
    monthlyPayment: 385.19,
    capitalFinance: 19564.7532,
    mtic: 24077.91,
    residualValue: 0.0,
  },
  carDetailFaqs: {
    faqs: [
      {
        id: 13,
        title: "Que tipo de viaturas existem na Digital Store?",
        description:
          "Na Digital Store, todas as viaturas são novas, oferecendo assim a comodidade de uma compra online com o selo de qualidade da Hyundai.\nTodas as nossas viaturas contam com a melhor garantia do mercado. Damos-lhe 7 anos de garantia, sem limite de quilómetros, em todos os veículos. 7 anos de segurança, para que possa usufruir do seu Hyundai com toda a tranquilidade.",
        faqType: 1,
        active: true,
      },
      {
        id: 14,
        title: "Como posso comprar uma viatura?",
        description:
          "Encontrou uma  viatura que lhe agrada? Perfeito! Inicie o processo de compra da viatura clicando no botão “Tenho Interesse”, no topo da página, e siga os passos do nosso processo de reserva rápido. Valide as informações de financiamento e retoma, crie ou inicie sessão na sua conta e, por fim, proceda ao pagamento do valor de reserva. Rápido, cómodo e com total segurança.",
        faqType: 1,
        active: true,
      },
      {
        id: 15,
        title: "Posso financiar uma viatura na Digital Store?",
        description:
          "Sim, disponibilizamos soluções para financiar a compra da sua nova viatura. Comece por simular online a configuração de financiamento que melhor se adequa às suas possibilidades e avance para a reserva da viatura sem compromissos. Depois de reservada a sua viatura, terá a possibilidade de pedir uma pré-aprovação ao seu processo de crédito sem necessidade de documentação ou deslocações a um Concessionário Hyundai, com resposta no período de 2 dias úteis. No caso de o financiamento ser recusado, devolvemos o valor da reserva pago anteriormente.",
        faqType: 1,
        active: true,
      },
      {
        id: 16,
        title:
          "Posso experimentar uma viatura equivalente à que vi na Digital Store?",
        description:
          "Sim, é possível fazer um test-drive a uma viatura que viu na Digital Store, mediante marcação. Para fazer a marcação, clique em “Tenho Interesse”, no topo da página, e em seguida no botão “Marcar Test Drive”. Depois de partilhar a informação necessária para a marcação do test-drive, será contatado pela concessão escolhida por si para definir o dia e hora para realizar o test drive.",
        faqType: 1,
        active: true,
      },
      {
        id: 17,
        title: "Como posso comprar esta viatura?",
        description:
          "Já encontrou o seu Hyundai de sonho? Não o perca de vista! Através do pagamento de um valor de reserva de 500€ , garante já a reserva do seu novo veículo durante 48h, enquanto finaliza os detalhes do contrato com o seu consultor comercial. Inicie o processo de Reserva clicando no botão 'Iniciar Reserva'.",
        faqType: 2,
        active: true,
      },
      {
        id: 18,
        title: "Durante quanto tempo a viatura fica reservada?",
        description:
          "Depois de finalizar o pagamento do sinal, a sua viatura fica reservada durante 48H.",
        faqType: 2,
        active: true,
      },
      {
        id: 19,
        title: "Posso cancelar a minha reserva?",
        description:
          "Sim, é possível cancelar a sua reserva. Caso pretenda cancelar a sua reserva, entre em contacto connosco através do email apoioaocliente@hyundai.pt e procederemos à devolução do valor de reserva pago.",
        faqType: 2,
        active: true,
      },
      {
        id: 20,
        title: "Depois de finalizada a compra, posso devolver a viatura?",
        description:
          "Sim, pode. Queremos que adore o seu novo veículo. Se não for esse o caso, tem 14 dias para o devolver e encontrar outro modelo que se adeque mais ao seu estilo de vida e preferências.",
        faqType: 2,
        active: true,
      },
    ],
  },
  carCardSimilar: [],
  creditTypes: [
    {
      id: 1,
      creditName: "Credit to Drive",
      maxNbrPayments: 96,
      minNbrPayments: 12,
      nbrPaymentsStep: 12,
      minRate: 5.5,
      rate: 6.5,
      maxInitialDepositPc: 0.2,
      residualValues: null,
    },
    {
      id: 2,
      creditName: "Open Drive",
      maxNbrPayments: 60,
      minNbrPayments: 24,
      nbrPaymentsStep: 12,
      minRate: 4.5,
      rate: 4.5,
      maxInitialDepositPc: 0.2,
      residualValues: [
        {
          id: 1,
          productName: "HOD",
          vehicleType: "N",
          months: 24,
          valueType: "P",
          value: 253.0,
        },
        {
          id: 1,
          productName: "HOD",
          vehicleType: "N",
          months: 36,
          valueType: "P",
          value: 254.0,
        },
        {
          id: 1,
          productName: "HOD",
          vehicleType: "N",
          months: 48,
          valueType: "P",
          value: 255.0,
        },
        {
          id: 1,
          productName: "HOD",
          vehicleType: "N",
          months: 60,
          valueType: "P",
          value: 256.0,
        },
        {
          id: 1,
          productName: "HOD",
          vehicleType: "N",
          months: 72,
          valueType: "P",
          value: 257.0,
        },
        {
          id: 1,
          productName: "HOD",
          vehicleType: "N",
          months: 84,
          valueType: "P",
          value: 258.0,
        },
      ],
    },
  ],
};

export default {
  vehicle,
};
