<template>
  <div class="returns">
    <div class="header">
      <Heading
        tag="h1"
        customClass="page-title"
        class="reserved-area-titles title"
        :label="$t('pages.personalPage.returns.title')"
      ></Heading>
    </div>
    <div class="content">
      <BasicInformation :vehicle="getReturnProcessed"></BasicInformation>
      <div class="separator"></div>
      <CarFeatures
        :airConditioningList="getAirConditioningList"
        :provenanceValue="getProvenance"
        :equipmentList="getEquipments"
        :hasSubmitted="hasErrors"
        @onFeatures="onFeaturesHandler"
      ></CarFeatures>
      <div class="separator"></div>
      <MechanicalAndGeneralCondition
        :mechanicalStateList="getMechanicalState"
        :generalStatusList="getGeneralStatus"
        :key="stateKey"
        :hasSubmitted="hasErrors"
      ></MechanicalAndGeneralCondition>
      <div class="separator"></div>
      <PhotosAndDocuments @onUpload="onUploadHandler"></PhotosAndDocuments>
      <Label class="validate-message" v-if="errs.images">{{
        errs.images
      }}</Label>
      <div class="separator"></div>
      <EstimatedValue :price="getCurrentValuation"></EstimatedValue>
      <div class="footer">
        <Button
          v-if="!getReturn.submitted"
          label="Gravar"
          uppercase
          class="button-save"
          type="primary"
          @onClick="onClickSaveHandler(false)"
        ></Button>
        <Button
          :disabled="getReturn.submitted"
          class="button-save"
          label="Submeter avaliação"
          uppercase
          type="primary"
          fill
          @onClick="onClickSaveHandler(true)"
        ></Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { Label, Button } from "../../../atoms";
import {
  BasicInformation,
  CarFeatures,
  MechanicalAndGeneralCondition,
  PhotosAndDocuments,
  EstimatedValue,
} from "../../../organisms";
import v from "../../../../utils/validator.js";
import { PAGES } from "../../../../enums/pages";
import { Heading } from "@sc/ds-ui-component-library";

const flatten = (obj) => {
  const {
    mechanicalAndGeneralStatus,
    images,
    features: { externalColor, cabinTrimInlay, kilometers },
  } = obj;

  return {
    state: [
      ...mechanicalAndGeneralStatus.generalStatusList,
      ...mechanicalAndGeneralStatus.mechanicalStateList,
    ],
    images: Object.values(images),
    externalColor,
    cabinTrimInlay,
    kilometers,
  };
};

export default {
  name: "Returns",
  components: {
    Label,
    Button,
    BasicInformation,
    CarFeatures,
    MechanicalAndGeneralCondition,
    PhotosAndDocuments,
    EstimatedValue,
    Heading,
  },
  data() {
    return {
      id: this.$route.params.id,
      returnDetails: [],
      vehicle: {},
      features: {},
      errorLaunch: false,
      mechanicalAndGeneralStatus: {},
      additionalInformation: {},
      files: [],
      errs: {
        images: "",
        financing: "",
        state: "",
        externalColor: "",
        interiorColor: "",
        cabinTrimInlay: "",
        kilometers: "",
      },
      additionalKey: 1000,
      stateKey: 2000,
    };
  },
  methods: {
    ...mapMutations(["setAlert"]),
    preparePayload(submitted) {
      this.$store.commit("formatPlateDate");
      return {
        basicInfo: {
          plate: this.getReturn.plate,
          brand: this.getReturn.brand,
          brandId: this.getReturn.brandId,
          model: this.getReturn.model,
          version: this.getReturn.version,
          kilometers: this.getReturn.kilometers,
          plateDate: this.getReturn.plateDate,
          versionId: this.getReturn.bcaId,
          firstName: this.getUserInfo.name.split(" ")[0],
          lastName: this.getUserInfo.name.split(" ")[1],
          emailAddress: this.getUserInfo.email,
          phoneNumber: this.getUserInfo.phoneNumber,
          fuel: this.getReturn.fuel,
          transmission: this.getReturn.transmission,
          vin: this.getReturn.vin,
        },
        tradeInId: this.id,
        features: {
          ...this.getFeatures,
          provenance: this.getProvenance.radioGroupValue,
        },
        mechanicalAndGeneralStatus: {
          mechanicalStateList: this.getMechanicalState,
          generalStatusList: this.getGeneralStatus,
        },
        images: this.fileListToObj([...this.files]),
        submitted,
        carculatorId: this.getCarculatorId,
      };
    },
    fileListToObj(fileList) {
      fileList = this.getReturn?.phaseTwoDTOV3?.files
        ? [...this.getReturn?.phaseTwoDTOV3?.files, ...fileList]
        : fileList;
      const hash = fileList.reduce((acc, curr) => {
        acc[curr.name] = { file: curr.file, category: curr.category };
        return acc;
      }, {});
      return Object.entries(hash).map((it) => ({
        name: it[0],
        file: it[1].file,
        category: it[1].category,
      }));
    },
    onFeaturesHandler(event) {
      this.features = event;
    },
    onUploadHandler(event) {
      this.files = event;
    },
    onScrollToElement(errs) {
      if (
        errs.externalColor !== "" ||
        errs.cabinTrimInlay !== "" ||
        errs.kilometers !== ""
      ) {
        const element = document.querySelector(".header");
        element?.scrollIntoView();
      } else if (errs.state !== "") {
        const element = document.querySelector(".mechanical-general-condition");
        element?.scrollIntoView();
      } else if (errs.images !== "") {
        const element = document.querySelector(".photos-documents");
        element?.scrollIntoView();
      }
    },
    onClickSaveHandler(submitted = true) {
      const returnDetails = this.preparePayload(submitted);
      if (submitted) {
        this.errs = v("returnPhase2", flatten(returnDetails));

        if (Object.values(this.errs).some(Boolean)) {
          this.errorLaunch = true;
          this.onScrollToElement(this.errs);
          return;
        }
      }

      this.errorLaunch = false;

      this.$store
        .dispatch("storeUpdateReturnDetails", returnDetails)
        .then(() => {
          this.setAlert({
            message: submitted
              ? this.$t("toast.valuation.success.submited")
              : this.$t("toast.valuation.success.saved"),
            type: "info",
            display: true,
            global: true,
          });

          setTimeout(
            () =>
              this.$router.push({
                name: PAGES.PERSONAL_RETAKE,
              }),
            500
          );
        })
        .catch(() => {
          this.setAlert({
            message: this.$t("toast.valuation.error.generic"),
            type: "error",
            display: true,
            global: true,
          });
        });
    },
  },
  computed: {
    ...mapGetters([
      "getReturn",
      "getMechanicalState",
      "getGeneralStatus",
      "getAirConditioningList",
      "getProvenance",
      "getFeatures",
      "getEquipments",
      "getCarculatorId",
      "getUserInfo",
    ]),
    getReturnProcessed() {
      return {
        ...this.getReturn,
        registration: this.getReturn.licensePlate,
        registrationDate: `${this.getReturn.month}/${this.getReturn.year}`,
        fuel: this.getReturn.fuel ?? "-",
        transmission: this.getReturn.transmission ?? "-",
        details: this.getReturn.tradeInPayload,
        version: this.getReturn.version,
      };
    },
    hasErrors() {
      return this.errorLaunch;
    },
    getCurrentValuation() {
      return this.getReturn.status === 1
        ? this.getReturn.actualValuation
        : this.getReturn.initialValuation;
    },
  },
  async mounted() {
    await this.$store.dispatch("storeGetReturnDetails", this.id);
    setTimeout(() => {
      this.additionalKey++;
      this.stateKey++;
    }, 500);
  },
};
</script>

<style lang="scss" scoped>
.header {
  border-bottom: 2px solid #e4ecf0;
  padding-bottom: 1rem;
}

::v-deep .basic-information--content-left {
  & > * {
    margin: 9px 0;
  }

  & > *:first-child {
    margin: 0 0 9px 0;
  }

  & > *:last-child {
    margin: 9px 0 0 0;
  }
}

::v-deep .estimated-value {
  & > * {
    margin: 0 12px;
  }

  & > *:first-child {
    margin: 0 12px 0 0;
  }

  & > *:last-child {
    margin: 0 0 0 12px;
  }
}

::v-deep .basic-information--content-right {
  & > * {
    margin: 9px 0;
  }

  & > *:first-child {
    margin: 0 0 9px 0;
  }

  & > *:last-child {
    margin: 9px 0 0 0;
  }
}

.content {
  margin-top: 1.25rem;
}

.page-title {
  font-size: 24px;
  font-weight: 800;
}

.separator {
  padding-top: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 2px solid #e4ecf0;
}

.footer {
  margin-top: 2.5rem;
  justify-content: flex-start;
}

.validate-message {
  color: #e63312;
  font-weight: 400;
}

.button-save {
  margin-bottom: 1rem;
}

.footer .button {
  width: 100%;
  height: 50px;
  font-family: Mont;
  font-weight: 500;
  font-size: 16px;
  line-height: 72px;
}

::v-deep .car-features--general {
  .car-features--general-input {
    margin-top: 12px;
  }
}

@media (min-width: 340px) {
  .footer .button {
    font-size: 18px;
  }
}
@media (min-width: 1024px) {
  .footer {
    display: flex;
    justify-content: flex-end;
  }

  .button-save {
    margin-bottom: 0px;
    margin-left: 1.5rem;
  }

  .footer .button {
    width: 16rem;
  }
}
@media (max-width: 1680px) {
  .returns {
    .header {
      .label {
        line-height: 18px;
      }
      padding-bottom: 16px;
    }
  }

  .content {
    margin-top: 16px;
  }

  .separator {
    margin-bottom: 16px;
    padding-top: 16px;
  }

  ::v-deep .subtitle {
    margin-top: 8px;
    margin-bottom: 16px;
  }

  ::v-deep .photos-documents--subtitle {
    margin-top: 8px;
    margin-bottom: 16px;
  }

  ::v-deep .photos-documents--content-left {
    .section-title {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }

  ::v-deep .photos-documents--content-right {
    .section-title {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }

  ::v-deep .info {
    .labels {
      & > * {
        margin: 0 2px;
      }

      & > *:first-child {
        margin: 0 2px 0 0;
      }

      & > *:last-child {
        margin: 0 0 0 2px;
      }

      .subtitle {
        margin: 0;
      }
    }
  }

  ::v-deep .mechanical-general-condition--content-left-title {
    margin-bottom: 8px;
  }

  ::v-deep .mechanical-general-condition--content-right-title {
    margin-bottom: 8px;
  }

  ::v-deep .car-features--title {
    margin-bottom: 8px;
  }

  ::v-deep .basic-information--content-left {
    & > * {
      margin: 4px 0;
    }

    & > *:first-child {
      margin: 0 0 4px 0;
    }

    & > *:last-child {
      margin: 4px 0 0 0;
    }
  }

  ::v-deep .basic-information--content-right {
    & > * {
      margin: 4px 0;
    }

    & > *:first-child {
      margin: 0 0 4px 0;
    }

    & > *:last-child {
      margin: 4px 0 0 0;
    }
  }

  ::v-deep .basic-information--title {
    .label {
      font-size: 16px;
      line-height: 16px;
    }
  }

  ::v-deep .basic-information--subtitle {
    .subtitle {
      font-size: 12px;
      line-height: 16px;
    }
  }

  ::v-deep .basic-information--content {
    .basic-information--content-left {
      .basic-info-item {
        .grey-label {
          --text-label-font-size: 14px;
          --text-label-line-height: 14px;
        }

        .dark-label {
          --text-label-font-size: 14px;
          --text-label-line-height: 14px;
        }
      }
    }

    .basic-information--content-right {
      .basic-info-item {
        .grey-label {
          --text-label-font-size: 14px;
          --text-label-line-height: 14px;
        }

        .dark-label {
          --text-label-font-size: 14px;
          --text-label-line-height: 14px;
        }
      }
    }
  }

  ::v-deep .car-features--title {
    .label {
      font-size: 16px;
      line-height: 16px;
    }
  }

  ::v-deep .car-features--general-title {
    .label {
      font-size: 14px;
      line-height: 16px;
    }
  }

  ::v-deep .car-features--equipment-title {
    .label {
      font-size: 14px;
      line-height: 16px;
    }
  }

  ::v-deep .mechanical-general-condition--title {
    .label {
      font-size: 16px;
      line-height: 16px;
    }
  }

  ::v-deep .mechanical-general-condition--subtitle {
    .subtitle {
      font-size: 12px;
      line-height: 16px;
    }
  }

  ::v-deep .mechanical-general-condition--content-left-title {
    .label {
      font-size: 14px;
      line-height: 16px;
    }
  }

  ::v-deep .mechanical-general-condition--content-right-title {
    .label {
      font-size: 14px;
      line-height: 16px;
    }
  }

  ::v-deep .choice-group-header {
    .choice-group-title {
      --choice-group-title-font-size: 12px;
      --choice-group-title-line-height: 16px;
    }
  }

  ::v-deep .choice-group-header {
    .choice-group-subtitle {
      --choice-group-subtitle-font-size: 12px;
      --choice-group-subtitle-line-height: 16px;
      margin-top: 0px;
    }
  }

  ::v-deep .choice--container {
    .choice--label {
      --choice-label-font-size: 14px;
      --choice-label-line-height: 16px;
    }
  }

  ::v-deep .radio-group-options {
    --radio-group-margin-top: 0;
  }

  ::v-deep .photos-documents--title {
    .label {
      font-size: 16px;
      line-height: 16px;
    }
  }

  ::v-deep .photos-documents--subtitle {
    .subtitle {
      font-size: 12px;
      line-height: 16px;
    }
  }

  ::v-deep .photos-documents--content-left {
    .section-title {
      font-size: 14px;
      line-height: 16px;
    }
  }

  ::v-deep .photos-documents--content-right {
    .section-title {
      font-size: 14px;
      line-height: 16px;
    }
  }

  ::v-deep .upload {
    margin-bottom: 8px;

    & > * {
      margin: 0 2.5px;
    }

    & > *:first-child {
      margin: 0 2.5px 0 0;
    }

    & > *:last-child {
      margin: 0 0 0 2.5px;
    }
  }

  ::v-deep .upload-header {
    .info {
      .filename {
        font-size: 14px;
        line-height: 16px;
      }

      .labels {
        .text-label {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }

  ::v-deep .estimated-value {
    & > * {
      margin: 0 4px;
    }

    & > *:first-child {
      margin: 0 4px 0 0;
    }

    & > *:last-child {
      margin: 0 0 0 4px;
    }

    .subtitle {
      --subtitle-font-size: 16px;
      --subtitle-line-height: 16px;
      margin: 0;
    }

    .title {
      --text-label-font-size: 24px;
      --text-label-line-height: 24px;
    }
  }

  ::v-deep .footer {
    margin-top: 52px;
  }

  ::v-deep .upload-button {
    .button {
      --button-height: 30px;
      --button-padding: 16px;
      --button-font-size: 14px;
    }
  }

  ::v-deep .car-features--general {
    .car-features--general-input {
      margin-top: 12px;
    }
  }

  ::v-deep .car-features--general {
    .car-features--general-input {
      margin-top: 12px;
    }
  }

  ::v-deep .car-features--general-input {
    --baseinput-font-size: 12px;

    .container {
      height: 38px;
    }
    .input--input {
      height: 0px;
      padding-left: 16px;
      padding-top: 28px;
      padding-bottom: 12px;
    }
    .text-label {
      top: 10px;
      font-size: 12px !important;
      line-height: 12px !important;
    }
    .text-label {
      &.focused {
        top: 6px;
        font-size: 10px !important;
        line-height: 10px !important;
      }
    }
  }

  ::v-deep .selectNative {
    height: 38px !important;
  }

  ::v-deep .selectCustom-trigger {
    height: 38px !important;
  }

  ::v-deep {
    .selectNative,
    .selectCustom {
      height: 38px !important;
      font-size: 12px !important;
    }
  }

  ::v-deep .status-select {
    .trigger-text {
      font-size: 12px !important;
      line-height: 12px !important;
    }
  }

  ::v-deep .status-selected {
    .trigger-text {
      font-size: 12px !important;
      line-height: 12px !important;
    }
  }

  ::v-deep .selectCustom-trigger {
    .trigger-text {
      font-size: 12px !important;
    }
  }
}
@media (max-width: 1366px) {
  .separator {
    margin-bottom: 8px;
    padding-top: 8px;
  }

  ::v-deep .basic-information--title {
    .label {
      font-size: 14px;
      line-height: 14px;
    }
  }

  ::v-deep .basic-information--subtitle {
    .subtitle {
      font-size: 10px;
      line-height: 14px;
    }
  }

  ::v-deep .basic-information--content {
    .basic-information--content-left {
      .basic-info-item {
        .grey-label {
          --text-label-font-size: 12px;
          --text-label-line-height: 12px;
        }

        .dark-label {
          --text-label-font-size: 12px;
          --text-label-line-height: 12px;
        }
      }
    }

    .basic-information--content-right {
      .basic-info-item {
        .grey-label {
          --text-label-font-size: 12px;
          --text-label-line-height: 12px;
        }

        .dark-label {
          --text-label-font-size: 12px;
          --text-label-line-height: 12px;
        }
      }
    }
  }

  ::v-deep .car-features--title {
    .label {
      font-size: 14px;
      line-height: 14px;
    }
  }

  ::v-deep .car-features--general-title {
    .label {
      font-size: 12px;
      line-height: 14px;
    }
  }

  ::v-deep .car-features--equipment-title {
    .label {
      font-size: 12px;
      line-height: 14px;
    }
  }

  ::v-deep .mechanical-general-condition--title {
    .label {
      font-size: 14px;
      line-height: 14px;
    }
  }

  ::v-deep .mechanical-general-condition--subtitle {
    .subtitle {
      font-size: 10px;
      line-height: 14px;
    }
  }

  ::v-deep .mechanical-general-condition--content-left-title {
    .label {
      font-size: 12px;
      line-height: 14px;
    }
  }

  ::v-deep .mechanical-general-condition--content-right-title {
    .label {
      font-size: 12px;
      line-height: 14px;
    }
  }

  ::v-deep .choice-group-header {
    .choice-group-title {
      --choice-group-title-font-size: 10px;
      --choice-group-title-line-height: 14px;
    }
  }

  ::v-deep .choice-group-header {
    .choice-group-subtitle {
      --choice-group-subtitle-font-size: 10px;
      --choice-group-subtitle-line-height: 14px;
    }
  }

  ::v-deep .choice--container {
    .choice--label {
      --choice-label-font-size: 12px;
      --choice-label-line-height: 14px;
    }
  }

  ::v-deep .photos-documents--title {
    .label {
      font-size: 14px;
      line-height: 14px;
    }
  }

  ::v-deep .photos-documents--subtitle {
    .subtitle {
      font-size: 10px;
      line-height: 14px;
    }
  }

  ::v-deep .photos-documents--content-left {
    .section-title {
      font-size: 12px;
      line-height: 14px;
    }
  }

  ::v-deep .photos-documents--content-right {
    .section-title {
      font-size: 12px;
      line-height: 14px;
    }
  }

  ::v-deep .upload-header {
    .info {
      .filename {
        font-size: 12px;
        line-height: 14px;
      }

      .labels {
        .text-label {
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }

  ::v-deep .estimated-value {
    .subtitle {
      --subtitle-font-size: 14px;
      --subtitle-line-height: 14px;
      margin: 0;
    }

    .title {
      --text-label-font-size: 22px;
      --text-label-height: 22px;
    }
  }

  ::v-deep .footer {
    margin-top: 40px;
  }
}
@media (max-width: 1280px) {
  ::v-deep .footer {
    margin-top: 28px;
  }
}
@media (max-width: 1024px) {
  .footer {
    display: flex;
    flex-direction: column;

    & > * {
      margin: 5px 0 !important;
    }

    & > *:first-child {
      margin: 0 0 5px 0 !important;
    }

    & > *:last-child {
      margin: 5px 0 0 0 !important;
    }

    .button-save {
      width: 100%;
      justify-content: center;
      margin: 0;
    }
  }

  ::v-deep .upload {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .upload-header {
      display: flex;
      flex-direction: column;

      .subtitle {
        margin: 0;
      }

      * {
        width: 100%;
      }

      #file {
        margin-bottom: 5px;
      }
    }
  }
}
@media (max-width: 768px) {
}
@media (max-width: 375px) {
}
.reserved-area-titles {
  --heading-xlarge-font-size: 24px;
  --heading-xlarge-line-height: 31px;
  --heading-color: var(--theme-color-secondary);
  --heading-xlarge-weight: 700;
}
</style>
