<template>
  <div class="simulation-conditions" v-if="renderComponent">
    <div class="simulation-conditions--title" v-if="getVehicleInitialDeposit">
      <Label size="small">{{ title }}</Label>
    </div>
    <div class="simulation-conditions--range">
      <div class="simulation-conditions--range-initial-entry">
        <RangeInput
          @onChange="onChangeInitialEntry"
          @onMouseUp="onMouseUpInitialEntry"
          :label="labelRange1"
          sliderType="secondary"
          bold="normal"
          inputType="tertiary"
          :sliderMin="deposit.min"
          :sliderMax="deposit.max"
          :sliderInterval="deposit.step"
          :sliderValue="deposit.value"
          :sliderDisabled="getIncludeRetake && deposit.retake >= deposit.max"
          :key="deposit.value"
        />
      </div>
      <div class="simulation-conditions--range-number-months">
        <RangeInput
          @onChange="onChangeNumberMonths"
          @onMouseUp="onMouseUpNumberMonths"
          :label="labelRange2"
          sliderType="secondary"
          bold="normal"
          inputType="tertiary"
          :sliderMin="month.min"
          :sliderMax="month.max"
          :sliderInterval="month.step"
          :sliderValue="months"
          icon=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Label } from "../../atoms";
import { RangeInput } from "../../molecules";
import { mapGetters } from "vuex";

// Open-Drive max is 20% of price

export default {
  name: "SimulationConditions",
  components: {
    Label,
    RangeInput,
  },
  data() {
    return {
      renderComponent: true,
      intialState: true,
    };
  },
  props: {
    title: {
      type: String,
    },
    labelRange1: {
      type: String,
    },
    labelRange2: {
      type: String,
    },
    deposit: {
      type: Object,
    },
    month: {
      type: Object,
    },
  },
  methods: {
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    onChangeInitialEntry(event) {
      this.$emit("onChangeInitialEntry", event);
    },
    onChangeNumberMonths(event) {
      this.$emit("onChangeNumberMonths", event);
    },
    onMouseUpInitialEntry(event) {
      this.$emit("onMouseUpInitialEntry", event);
    },
    onMouseUpNumberMonths(event) {
      this.$emit("onMouseUpNumberMonths", event);
    },
  },
  computed: {
    ...mapGetters(["getVehicleInitialDeposit", "getIncludeRetake"]),
    months() {
      if (this.month.value >= this.month.max) return this.month.max;
      if (this.month.value <= this.month.min) return this.month.min;
      return this.month.value;
    },
  },
  mounted() {
    this.forceRerender();
  },
};
</script>

<style lang="scss" scoped>
.simulation-conditions {
  background-color: rgba(255, 255, 255, 1);
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.simulation-conditions--title {
  margin-bottom: 1.25rem;
}

.simulation-conditions--title .label {
  font-size: 21px;
  font-family: Mont;
  font-weight: 500;
}

.simulation-conditions--range-initial-entry {
  width: 100%;
  margin-bottom: 1rem;
}

.simulation-conditions--range-initial-entry
  ::v-deep
  .label--input-wrapper
  .label,
.simulation-conditions--range-number-months
  ::v-deep
  .label--input-wrapper
  .label {
  font-family: Mont;
  font-weight: 500;
}

.simulation-conditions--range-number-months {
  width: 100%;
}

::v-deep .simulation-conditions--title {
  .label {
    font-weight: 700;
    font-size: 21px;
    line-height: 27px;
    color: var(--theme-color);
  }
}

.simulation-conditions--range {
  ::v-deep {
    .label--input-wrapper {
      .subtitle {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: var(--color-1);
        text-transform: uppercase;
      }
    }
    .vue-slider-process {
      --slider-process-background-color: var(--theme-color-secondary);
    }
    .vue-slider-dot {
      border: 2px solid var(--theme-color-secondary);
    }
  }
}

@media (min-width: 768px) {
  .simulation-conditions--range {
    display: flex;
    justify-content: space-between;
  }

  .simulation-conditions--range-initial-entry {
    margin-bottom: 0px;
    width: 50%;
  }

  .simulation-conditions--range-number-months {
    width: 50%;
    margin-left: 2rem;
  }
}
</style>
