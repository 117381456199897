<template>
  <div class="div">
    <Section id="results" v-if="getRecent && getRecent.length > 0">
      <SearchGrid :vehicles="getRecent" />
    </Section>
    <Section id="pagination">
      <Pagination />
    </Section>
  </div>
</template>

<script>
import Section from "../../refactor/atoms/Section/Section.vue";
import SearchGrid from "./SearchGrid.vue";
import Pagination from "../../organisms/Pagination/Pagination.vue";
import { mapGetters } from "vuex";

export default {
  name: "SearchResults",
  components: { Section, SearchGrid, Pagination },
  computed: {
    ...mapGetters({ getRecent: "SearchModule/getRecent" }),
  },
};
</script>

<style lang="scss" scoped>
#results {
  padding-top: 0;
  padding-bottom: 0;
}

@media (max-width: 1920px) {
}

@media (max-width: 1440px) {
  #pagination,
  #results {
    --section-padding: 52px;
  }
}
@media (max-width: 1366px) {
  #pagination,
  #results {
    --section-padding: 40px;
  }
}
@media (max-width: 1280px) {
  #pagination,
  #results {
    --section-padding: 28px;
  }
}
@media (max-width: 1024px) {
}

@media (max-width: 768px) {
}
</style>
