<template>
  <div class="wrapper">
    <SearchFormMain
      @toggleOpen="showExtended = !showExtended"
      @onItemClick="handleTag"
      @onCloseTag="handleCloseTag"
      ref="mainForm"
    />
    <SearchFormExtra
      :showExtended.sync="showExtended"
      @onItemClick="handleTag"
      ref="extraForm"
    />
  </div>
</template>

<script>
import SearchFormMain from "./SearchForm/SearchFormMain.vue";
import SearchFormExtra from "./SearchForm/SearchFormExtra.vue";
import { mapGetters } from "vuex";

export default {
  name: "CpSearchForm",
  components: {
    SearchFormExtra,
    SearchFormMain,
  },
  data() {
    return {
      showExtended: false,
      replaceTags: [
        "needle",
        "kmSup",
        "kmInf",
        "yearSup",
        "yearInf",
        "monthlyDeposit",
        "price",
      ],
      replaceTagsv2: ["year", "km"],
      specialTags: [
        "km",
        "year",
        "monthlyDeposit",
        "price",
        "needle",
        "lowCost",
      ],
    };
  },
  methods: {
    handleTagGroupClick(idx, e) {
      const arr = this.getTagGroups[idx];
      const selected = arr.filter((_, i) => e[0].includes(i));
      this.$store.commit("SearchModule/setSelectedTags", selected);
    },
    parseTagId(key) {
      if (typeof key === "string") {
        return parseInt(key);
      } else {
        Object.keys(key).forEach((subKey) => {
          key[subKey] = parseInt(key[subKey]);
        });
        return key;
      }
    },
    handleTag({ dropdown, value }) {
      var tag = {
        type: dropdown === "location" ? "dealer" : dropdown,
        id: this.parseTagId(value.key),
        value: value.value,
        children: value.children || [],
        parent: value.parent || "",
        count: value.count || 0,
      };
      if (this.replaceTagsv2.includes(dropdown)) {
        value.selected
          ? this.$store.dispatch("SearchModule/filter/addOrReplaceTag", tag)
          : this.$store.commit("SearchModule/filter/removeReplaceTag", tag);
      } else {
        this.replaceTags.includes(dropdown)
          ? this.$store.dispatch("SearchModule/filter/addOrReplaceTag", tag)
          : this.$store.dispatch("SearchModule/filter/addOrRemoveTag", tag);
      }

      this.$store.commit("SearchModule/pagination/setCurrent", 1);
      this.$store.dispatch("SearchModule/fetchRecent", {
        quickSearchItem: [],
        dynamic: false,
      });
    },
    handleCloseTag(tags) {
      let tagObj = {};
      if ("value" in tags) tagObj = { 0: tags };
      else {
        tagObj = tags;
        delete tagObj["selected"];
      }
      for (const t of Object.entries(tagObj)) {
        const tag = t[1];
        if (!this.specialTags.includes(tag.type)) {
          this.$store.dispatch("SearchModule/dropdown/updateDropdown", tag);
        }

        if (
          this.getSelectedTags.find(
            (t) =>
              t.id === tag.id && t.value === tag.value && t.type === tag.type
          )
        )
          this.$store.dispatch("SearchModule/filter/addOrRemoveTag", tag);

        if (tag.children)
          tag.children.forEach((child) => {
            var newTag = {
              type: "model",
              id: child.key,
              value: child.value,
              count: child.count || 0,
            };
            this.$store.commit("SearchModule/filter/removeTag", newTag);
          });

        if (tag.type === "needle")
          this.$store.commit("SearchModule/filter/setNeedle", "");

        if (tag.type === "km") {
          this.$store.dispatch("SearchModule/dropdown/resetKms");
        }

        if (tag.type === "year") {
          this.$store.dispatch("SearchModule/dropdown/resetYears");
        }

        this.$store.commit(
          "SearchModule/dropdown/setKey",
          tag.parent?.type || tag.type
        );
      }
      this.$store.commit("SearchModule/pagination/setCurrent", 1);
      this.$store.dispatch("SearchModule/fetchRecent", {
        quickSearchItem: [],
        dynamic: false,
      });
    },
  },
  computed: {
    ...mapGetters({
      getSelectedTags: "SearchModule/filter/getTags",
      getTagGroups: "SearchModule/dropdown/getTagGroups",
    }),
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  --multiselect-height: 56px;
  width: 100%;
  position: relative;
}
</style>
