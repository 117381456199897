<template>
  <div :style="customStyles" :class="['block', customClass, classes]">
    <div class="block--content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "Block",
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      },
    },
    customClass: {
      type: String,
      default: "",
    },
    rounded: {
      type: Boolean,
      required: false,
      default: false,
    },
    padding: {
      type: Boolean,
      default: true,
    },
    bgColor: {
      type: String,
      required: false,
    },
  },
  computed: {
    classes() {
      return {
        "block--round": this.rounded,
        "block--no-padding": !this.padding,
      };
    },
    customStyles() {
      return {
        ...this.styles,
        backgroundColor: this.bgColor,
      };
    },
  },
};
</script>
<style lang="css" scoped src="./Block.css"></style>
