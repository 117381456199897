<template>
  <Section id="faqs" class="section-bg-white">
    <div class="content">
      <div class="left">
        <Heading
          :label="$t('pages.detailsPage.faqs.title')"
          tag="h2"
          class="sh-text-color-black"
        />
        <SectionHeader
          class="sh-text-color-black"
          :subtitle="$t('pages.detailsPage.faqs.subtitle')"
        />
        <div class="link-row">
          <BaseLink :label="$t('pages.detailsPage.faqs.faqs-link')" />
          <TextLabel
            :label="$t('pages.detailsPage.faqs.faqs-details')"
            class="text-label-large"
          />
        </div>

        <div class="link-row">
          <BaseLink
            @onClick="handleContact"
            :label="$t('pages.detailsPage.faqs.contact-link')"
          />
          <TextLabel
            :label="$t('pages.detailsPage.faqs.contact-details')"
            class="text-label-large"
          />
        </div>
      </div>
      <div class="right">
        <Accordion :items="faqs">
          <template v-for="(faq, index) in faqs">
            <AccordionItem
              :key="`${index}-faq`"
              :item="faq"
              @onClick="faqsFootprint(faq)"
            />
          </template>
        </Accordion>
      </div>
    </div>
  </Section>
</template>

<script>
import {
  Section,
  SectionHeader,
  BaseLink,
  TextLabel,
  Accordion,
  AccordionItem,
  Heading,
} from "@sc/ds-ui-component-library";

import { mapGetters, mapMutations } from "vuex";

export default {
  components: {
    Heading,
    Section,
    SectionHeader,
    BaseLink,
    TextLabel,
    Accordion,
    AccordionItem,
  },
  name: "FaqSection",
  data() {
    return {
      faqs: this.$t("pages.detailsPage.faqs.faqsList"),
    };
  },
  methods: {
    ...mapMutations(["setContactPanel"]),
    handleContact() {
      this.setContactPanel({
        display: true,
        vehicle: this.vehicle,
        personal: this.personal,
        type: "CONTACT",
      });
    },
    faqsFootprint(faq) {
      this.$emit("onActiveFootprint", faq);
    },
  },
  computed: {
    ...mapGetters({
      personal: "UserModule/getPersonal",
      vehicle: "VehicleModule/getCard",
    }),
  },
};
</script>

<style lang="scss" scoped>
#faqs {
  --section-header-width: 100%;
  --section-header-text-align: left;
}

.content {
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin: 0 32px;
  }

  & > *:first-child {
    margin: 0 32px 0 0;
  }

  & > *:last-child {
    margin: 0 0 0 32px;
  }
}

.left {
  flex: 1;
}

.right {
  flex: 1;
}

.link-row p {
  display: inline;
}

.link-row {
  margin-top: 64px;
}

::v-deep .baselink {
  --baselink-color: var(--theme-color-secondary);
  text-decoration: underline;
  display: inline !important;
  --theme-color-secondary: #6497ff;
}

.section-header ::v-deep .text-label {
  margin-top: 24px;
}

.section-bg-white {
  background-color: var(--color-7);
}

.sh-text-color-black {
  --heading-color: var(--color-1);
  --text-label-color: var(--color-1);
}

.text-label-large {
  --text-label-font-size: var(--text-2-font-size);
}

@media (max-width: 1024px) {
  .content {
    flex-direction: column;

    & > * {
      margin: 32px 0;
    }

    & > *:first-child {
      margin: 0 0 32px 0;
    }

    & > *:last-child {
      margin: 32px 0 0 0;
    }
  }
}

@media (max-width: 768px) {
  .left {
    width: 100%;
  }
  ::v-deep .baselink {
    --baselink-md-font-size: 14px;
    --baselink-md-line-heigh: 21px;
    --baselink-font-weight: 500;
  }
  .section-header ::v-deep h1 {
    font-size: 24px;
    line-height: 31px;
  }
  .section-header ::v-deep p {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
  }
}
</style>
