import httpClient from "./httpClient";

const SEARCH = "/search";
const SAVE_SEARCH = "/search/saveV2";
const SEARCH_V2 = "/search/v2";

function search(search, page, sort, orderBy) {
  const { needle, tags } = search;

  const newSearch = {
    needle,
    tags,
  };

  return httpClient.post(SEARCH, newSearch, {
    params: {
      page,
      sort,
      orderBy,
    },
    headers: { Authorization: "" },
  });
}

function saveSearch(search) {
  return httpClient.post(SAVE_SEARCH, {
    ...search,
  });
}

function deleteSearch(id) {
  return httpClient.delete(`${SAVE_SEARCH}?id=${id}`);
}

function getSearches() {
  return httpClient.get(SAVE_SEARCH);
}

function getSimilar() {
  return httpClient.post(SEARCH_V2 + "?showReservation=0", {
    headers: { Authorization: "" },
  });
}

function getRecent(
  payload = {},
  page = 1,
  sort = "price",
  orderBy = "asc",
  nElem = 14,
  showReservation = 1
) {
  return httpClient.post(
    `${SEARCH_V2}?numberElements=${nElem}&page=${page}&showReservation=${showReservation}`,
    payload,
    {
      params: {
        sort,
        orderBy,
      },
      headers: { Authorization: "" },
    }
  );
}

export { search, saveSearch, deleteSearch, getSearches, getSimilar, getRecent };
