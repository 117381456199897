<template>
  <div
    :class="['radio-button-label', classes, customClass]"
    :style="customStyles"
    @click="triggerChange"
  >
    <div class="radio-button-label--title">
      <RadioButton
        :disabled="disabled"
        ref="change"
        :size="radioSize"
        :color="radioColor"
        :circular="radioCircular"
        :name="radioName"
        :value="radioValue"
        :id="radioId"
        :checked="radioChecked"
        @onChange="onChange"
      ></RadioButton>
      <Label :color="titleColor" :forType="radioId">{{ title }}</Label>
    </div>
    <div v-if="subtitle" class="radio-button-label--subtitle">
      <Label :color="subtitleColor" :forType="radioId">{{ subtitle }}</Label>
    </div>
  </div>
</template>

<script>
import { RadioButton, Label } from "../../atoms";
export default {
  name: "RadioButtonLabel",
  components: {
    RadioButton,
    Label,
  },
  props: {
    title: {
      type: String,
      default: "Title",
    },
    subtitle: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    radioId: {
      type: String,
      default: "radio",
    },
    radioSize: {
      type: String,
      default: "small",
    },
    radioColor: {
      type: String,
      default: "secondary",
    },
    radioName: {
      type: String,
      default: "radio",
    },
    radioCircular: {
      type: Boolean,
      default: true,
    },
    radioValue: {
      type: String,
      default: "radio",
    },
    titleColor: {
      type: String,
      default: "primary",
    },
    titleSize: {
      type: String,
      default: "large",
    },
    subtitleColor: {
      type: String,
      default: "tertiary",
    },
    border: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: "",
    },
    radioChecked: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onChange(event) {
      this.$emit("onChange", event);
    },
    triggerChange() {
      this.$refs.change.externalChange();
    },
  },
  computed: {
    classes() {
      return {
        //border
        "radio-button-label--border": this.border,
      };
    },
    customStyles() {
      return {
        ...this.styles,
      };
    },
  },
};
</script>

<style lang="css" scoped src="./RadioButtonLabel.css"></style>
