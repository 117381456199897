import dayjs from "dayjs";
require("dayjs/locale/pt");
import dayjsBusinessDays from "dayjs-business-days";

// TODO: load in function
dayjs.locale("pt");

dayjs.extend(dayjsBusinessDays);

const atNoon = (date) => date.hour(12);

const nextBusinessDayNoon = (date) =>
  date.isBusinessDay
    ? date.isBefore(atNoon(dayjs()))
      ? atNoon(date)
      : atNoon(date.add(1, "day"))
    : atNoon(dayjs().nextBusinessDay());

// FIXME: add locale
const formatDate = (date) => date.locale("pt").format("dddd, D MMMM");

const noon = dayjs().hour(12).format("h") + "h";

const inBusinessDaysAhead = (n) =>
  nextBusinessDayNoon(dayjs()).businessDaysAdd(n);

const inBusinessDaysAheadFormatted = (n) => formatDate(inBusinessDaysAhead(n));

export {
  formatDate,
  atNoon,
  nextBusinessDayNoon,
  noon,
  inBusinessDaysAhead,
  inBusinessDaysAheadFormatted,
};
