import { getVehicleViewers } from "../../../api/product";
const state = {
  viewers: 5,
};

const getters = {
  getViewers: (state) => state.viewers,
};

const mutations = {
  setViewers(state, viewers) {
    state.viewers = viewers;
  },
};

const actions = {
  fetchViewers: async ({ commit }, vin) => {
    try {
      const res = await getVehicleViewers(vin);
      commit("setViewers", res.data?.data?.viewers);
    } catch (err) {
      console.error(err);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
