<template>
  <div>
    <div class="header">
      <Heading
        tag="h1"
        customClass="page-title"
        class="reserved-area-titles title"
        :label="$t('pages.personalPage.searches.title')"
      ></Heading>
    </div>
    <div class="content">
      <div v-if="getFormattedSearch.length > 0">
        <div v-for="(search, index) in getFormattedSearch" :key="index">
          <SavedSearchCard
            class="saved-search"
            :search="search"
            :button="$t('pages.personalPage.searches.button')"
            @closeCard="remove"
            @onClickSearch="resumeSearch"
          />
        </div>
      </div>
      <div v-else>
        <NoResults
          class="mt-10"
          :title="$t('pages.personalPage.searches.noResults.title')"
          :description="$t('pages.personalPage.searches.noResults.description')"
          :labelButton="$t('pages.personalPage.searches.noResults.button')"
          @onClick="onClickNoResultsHandler"
        ></NoResults>
      </div>
    </div>
  </div>
</template>

<script>
import { SavedSearchCard, Heading } from "@sc/ds-ui-component-library";
import { mapGetters } from "vuex";
import { NoResults } from "../../../organisms";

export default {
  components: {
    SavedSearchCard,
    NoResults,
    Heading,
  },
  methods: {
    remove(id) {
      this.$store.dispatch("SearchModule/deleteSearch", id);
    },
    resumeSearch(id) {
      this.$store.commit("SearchModule/filter/setSaveTags", true);
      const tags = this.getFormattedSearch.find(
        (search) => search.id === id
      ).tags;
      tags.forEach((tag) => {
        if (tag.type === "needle")
          this.$store.commit("SearchModule/filter/setNeedle", tag.value);
      });
      this.$store.commit("SearchModule/filter/setTags", tags);
      this.$router.push({ name: "SearchPage" });
    },
    onClickNoResultsHandler() {
      this.$router.push({ name: "SearchPage" });
    },
  },
  computed: {
    ...mapGetters({
      getFormattedSearch: "SearchModule/getFormattedSearch",
      getDropdowns: "SearchModule/dropdown/getDropdowns",
    }),
  },
  async mounted() {
    await this.$store.dispatch("SearchModule/dropdown/fetchDropdowns");
    await this.$store.dispatch("SearchModule/getSearches");
  },
};
</script>

<style lang="scss" scoped>
.saved-search {
  margin-bottom: 1.75rem;

  ::v-deep .saved-card--header-footer-left {
    flex-wrap: wrap;
  }
}

.page-title {
  font-size: 24px;
  font-weight: 500;
  font-family: Mont;
}

.header {
  border-bottom: 2px solid #e4ecf0;
  padding-bottom: 1.25rem;
}

.content {
  margin-top: 1.25rem;
}

.reserved-area-titles {
  --heading-xlarge-font-size: 24px;
  --heading-xlarge-line-height: 31px;
  --heading-color: var(--theme-color-secondary);
  --heading-xlarge-weight: 700;
  text-transform: uppercase;
}
</style>
