<template>
  <div>
    <div class="header">
      <Heading
        tag="h1"
        customClass="page-title"
        class="reserved-area-titles title"
        :label="$t('pages.personalPage.reservations.pageTitle')"
      ></Heading>
    </div>
    <div class="content">
      <div v-if="getReservations.length > 0">
        <div
          class="content--list"
          v-for="(it, idx) in getReservations"
          :key="idx"
        >
          <SavedReservation
            class="saved-reservation"
            :reservationId="it.id"
            :retake="it.retake"
            :vehicle="it.vehicle"
            :deliveryDate="it.estimatedDeliveryDate"
            @onClick="openReservation(it)"
          />
        </div>
      </div>
      <div v-else>
        <NoResults
          class="mt-10"
          :title="$t('pages.personalPage.reservations.noResults.title')"
          :description="
            $t('pages.personalPage.reservations.noResults.description')
          "
          :labelButton="
            $t('pages.personalPage.reservations.noResults.labelButton')
          "
          @onClick="onClickNoResultsHandler"
        ></NoResults>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { NoResults } from "../../../organisms";
import { Heading } from "@sc/ds-ui-component-library";
import SavedReservation from "./SavedReservation.vue";

export default {
  name: "Reservations",
  components: {
    Heading,
    SavedReservation,
    NoResults,
  },
  methods: {
    openReservation(it) {
      this.$store.commit("setCurrentReservation", it.id);
      this.$router.push({ name: "ReservationDetails", params: { id: it.id } });
    },
    onClickNoResultsHandler() {
      this.$router.push({
        name: "SearchPage",
      });
    },
  },
  mounted() {
    this.$store.dispatch("storeReservations");
  },
  computed: {
    ...mapGetters(["getReservations"]),
  },
};
</script>

<style lang="scss" scoped>
.header {
  border-bottom: 2px solid #e4ecf0;
  padding-bottom: 1.25rem;
}

.content {
  margin-top: 1.25rem;
}

.content--list {
  margin-bottom: 1rem;
}

.page-title {
  font-size: 24px;
  font-weight: 500;
  font-family: Mont;
}

.btn-container {
  display: flex;
  flex-direction: row-reverse;
  padding-top: 1rem;
}

.search-btn {
  width: 250px;
  height: 60px;
}

.reserved-area-titles {
  --heading-xlarge-font-size: 24px;
  --heading-xlarge-line-height: 31px;
  --heading-color: var(--theme-color-secondary);
  --heading-xlarge-weight: 700;
  text-transform: uppercase;
}
</style>
