import {
  getAllVehicles,
  getVehicle,
  getVehicleSpecifications,
  getVehicleImages,
  getVehicleImagesFullView,
  getTotalVehicles,
} from "@/api/product";
import { search } from "@/api/search";

const INITIAL_PAGE = 1;

const state = {
  vehicles: [],
  similar: [],
  pagination: {},
  // Im not sure
  vehicleDetails: {},
  vehicleSpecifications: {},
  vehicleImages: {},
  vehicleImagesFullView: {},
  totalVehicles: 300,
  // errors
  errors: [],
  // for loading spinner
  loading: false,

  // sort
  page: 1,

  sortList: [
    {
      text: "Preço Mais Baixo",
      value: "Preço Mais Baixo",
      sort: "price",
      orderBy: "asc",
      selected: true,
    },
    {
      text: "Preço Mais Alto",
      value: "Preço Mais Alto",
      sort: "price",
      orderBy: "desc",
      selected: false,
    },
    {
      text: "Mensalidade Mais Baixa",
      value: "Mensalidade Mais Baixa",
      sort: "monthly",
      orderBy: "asc",
      selected: false,
    },
    {
      text: "Mensalidade Mais Alta",
      value: "Mensalidade Mais Alta",
      sort: "monthly",
      orderBy: "desc",
      selected: false,
    },
    {
      text: "Segmento",
      value: "Segmento",
      sort: "segment",
      orderBy: "asc",
      selected: false,
    },
    {
      text: "Modelo",
      value: "Modelo",
      sort: "model",
      orderBy: "asc",
      selected: false,
    },
  ],
};

const getters = {
  getAllVehicles: (state) => state.vehicles,
  getSimilarVehicles: (state) => state.similar,
  getVehicleDetails: (state) => state.vehicleDetails,
  getVehicleSpecifications: (state) => state.vehicleSpecifications,
  getVehicleImages: (state) => state.vehicleImages,
  getVehicleImagesFullView: (state) => state.vehicleImagesFullView,
  getTotalVehicles: (state) => state.totalVehicles,
  getPagination: (state) => state.pagination,
  getErrors: (state) => state.errors,
  getLoadingState: (state) => state.loading,
  getSort: (state) => state.sort,
  getOrderBy: (state) => state.orderBy,
  getPage: (state) => state.pagination?.currentPage,
  getSortList: (state) => state.sortList,
  getVehicleById: (state) => (vin) => {
    const i = state.vehicles.findIndex((v) => v.vin === vin);
    if (~i) {
      return state.vehicles[i];
    }
  },
  getVehicleFavoriteByVin: (state) => (vin) => {
    const i = state.vehicles.findIndex((v) => v.vin === vin);
    if (~i) {
      return state.vehicles[i].favorite;
    }
  },
};

const actions = {
  // FIXME: This method is not being used!!
  async storeAllVehicles({ commit }, page) {
    commit("setLoading", true);
    try {
      const response = await getAllVehicles(page);
      commit("setVehicles", response.data.data);
      commit("setPagination", response.data.pagination);
      commit("setPage", response.data.pagination["currentPage"]);

      if (response.data.data.length >= 4) {
        commit("setSimilar", response.data.data);
      }
    } catch (e) {
      commit("setErrors", e);
      commit("setLoading", false);
    }
    commit("setLoading", false);
  },
  async storeTotalVehicles({ commit }) {
    try {
      const response = await getTotalVehicles();
      commit("setTotalVehicles", response.data.data.count);
    } catch (e) {
      commit("setErrors", e);
    }
  },
  //Fetch vehicle by id from the api
  async storeVehicleDetails({ commit }, id) {
    commit("setLoading", true);
    try {
      const response = await getVehicle(id);
      const v = response.data.data;
      v.carDetailCard.pricePvp = v.carDetailCard.pricePvp
        ? v.carDetailCard.pricePvp
        : 0;
      v.carDetailCard.previousPvp =
        v.carDetailCard.prices?.filter((p) => p.priceType === "TotalPrice") &&
        v.carDetailCard.prices?.filter((p) => p.priceType === "TotalPrice")
          .previousValue
          ? v.carDetailCard.prices?.filter((p) => p.priceType === "TotalPrice")
              .previousValue
          : 0;
      commit("setVehicleDetails", v);
    } catch (e) {
      commit("setErrors", e);
    }
    commit("setLoading", false);
  },
  //Fetch vehicle specifications by id from the api
  async storeVehicleSpecifications({ commit }, id) {
    commit("setLoading", true);
    try {
      const response = await getVehicleSpecifications(id);
      commit("setVehicleSpecifications", response.data.data);
      commit("setLoading", false);
    } catch (e) {
      commit("setErrors", e);
      commit("setLoading", false);
    }
  },
  //Fetch vehicle images by id from the api
  async storeVehicleImages({ commit }, id) {
    commit("setLoading", true);
    try {
      const response = await getVehicleImages(id);
      commit("setVehicleImages", response.data.data);
    } catch (e) {
      commit("setErrors", e);
      commit("setLoading", false);
    }
    commit("setLoading", false);
  },
  //Fetch vehicle images full view by id from the api
  async storeVehicleImagesFullView({ commit }, id) {
    commit("setLoading", true);
    try {
      const response = await getVehicleImagesFullView(id);
      commit("setVehicleImagesFullView", response.data.data);
      commit("setLoading", false);
    } catch (e) {
      commit("setErrors", e);
      commit("setLoading", false);
    }
  },
  async storeAllSearchResults({ commit, state }, { mySearch }) {
    commit("setLoading", true);
    try {
      const selected = state.sortList.find((i) => i.selected === true);

      const response = await search(
        mySearch,
        state.pagination?.currentPage,
        selected.sort,
        selected.orderBy
      );
      if (response.data.data) {
        let vehicles = response.data.data;
        if (vehicles.length)
          vehicles = vehicles.map((el) => {
            const newEl = el;
            newEl.pricePvp = el.pricePvp ? el.pricePvp : 0;
            newEl.previousPvp =
              el.prices?.filter((p) => p.priceType === "TotalPrice") &&
              el.prices?.filter((p) => p.priceType === "TotalPrice")
                .previousValue
                ? el.prices?.filter((p) => p.priceType === "TotalPrice")
                    .previousValue
                : 0;
            return newEl;
          });

        commit("setVehicles", vehicles);
        commit("setPagination", response.data.pagination);
        commit("setPage", response.data.pagination["currentPage"]);

        // FIXME: temp
        if (response.data.data.length > 4) {
          commit("setSimilar", response.data.data);
        }
      } else {
        commit("setPagination", {});
      }
    } catch (e) {
      commit("setErrors", e);
      commit("setLoading", false);
    }
    commit("setLoading", false);
  },
  storeSort({ commit, state }, sortObj) {
    const { sort, orderBy } = sortObj;

    commit("setLoading", true);

    state.sortList = state.sortList.map((i) => {
      if (i.sort === sort && i.orderBy === orderBy) {
        i.selected = true;
      } else {
        i.selected = false;
      }
      return i;
    });

    commit("setLoading", false);
  },
  storePage({ commit }, page) {
    commit("setPage", page);
  },
  storeRemoveCompareChecked(vin) {
    state.vehicles.map((i) => {
      if (i.vin === vin) {
        i.checked = false;
      }
    });
  },
};

// const modules = {};

// serves to define state value
const mutations = {
  setVehicles(state, vehicles) {
    state.vehicles = vehicles;
  },
  setSimilar(state, vehicles) {
    state.similar = vehicles;
  },
  setVehicleDetails(state, vehicle) {
    state.vehicleDetails = vehicle;
  },
  setVehicleSpecifications(state, specifications) {
    state.vehicleSpecifications = specifications;
  },
  setVehicleImages(state, images) {
    state.vehicleImages = images;
  },
  setVehicleImagesFullView(state, imagesFullView) {
    state.vehicleImagesFullView = imagesFullView;
  },
  setPagination(state, page) {
    state.pagination = page;
  },
  setTotalVehicles(state, total) {
    state.totalVehicles = total;
  },
  setErrors(state, errors) {
    state.errors = errors;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setPage(state, page) {
    state.pagination.currentPage = page;
  },
  resetPage(state) {
    state.pagination.currentPage = INITIAL_PAGE;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
