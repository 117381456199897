const initialState = () => ({
  withFinance: false,
});

const getters = {
  includeFinancing: (state) => state.withFinance,
};

const mutations = {};

const actions = {};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions,
};
