var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('TopBar',{on:{"openLeadContact":_vm.openContactLead,"openLead":_vm.openTalkToUsLead}}),_c('Navbar',{attrs:{"id":"navbar-page"},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('router-link',{attrs:{"to":"/"}},[_c('div',{staticClass:"logo-container"},[_c('img',{attrs:{"src":_vm.appLogo,"alt":"logo carplus"}})])])]},proxy:true},{key:"center",fn:function(){return [_c('LinkList',{class:_vm.getSelectedPage,attrs:{"list":_vm.list,"bold":"normal","display":"horizontal","size":"large"},on:{"onClick":_vm.closeMobileMenu}})]},proxy:true},{key:"right-1",fn:function(){return [_c('div',{staticClass:"actions-area flex flex-row justify-center sm:justify-start items-center"},[_c('a',{staticClass:"icon--wrapper",on:{"click":_vm.openCompare}},[_c('div',[(_vm.compareCount > 0)?_c('span',{staticClass:"notify-badge"},[_vm._v(_vm._s(_vm.compareCount))]):_vm._e(),_c('div',{staticClass:"navbar--compare-icon",style:({
                backgroundImage: `url(${_vm.compareIcon})`,
              }),on:{"click":_vm.openCompare}})])]),_c('a',{staticClass:"icon--wrapper"},[_c('div',[(_vm.favoritesCount > 0)?_c('span',{staticClass:"notify-badge"},[_vm._v(_vm._s(_vm.favoritesCount))]):_vm._e(),_c('div',{staticClass:"navbar--favorite-icon",style:({
                backgroundImage: `url(${_vm.favoriteIcon})`,
              }),on:{"click":_vm.openFavoritePanel}})])])])]},proxy:true},{key:"right-2",fn:function(){return [_c('div',{staticClass:"reserved-area"},[_c('SessionDropdown',{directives:[{name:"show",rawName:"v-show",value:(_vm.showReservedArea),expression:"showReservedArea"}],attrs:{"title":_vm.$t('navbar.reservedArea.title'),"menuList":_vm.personalList},on:{"onLogout":_vm.onLogoutHandler}}),_c('div',{class:{
            'caetano-go-widget': true,
            'caetano-go-widget-logged': _vm.showReservedArea,
          },attrs:{"data-idgo-widget":""}})],1)]},proxy:true}])}),_c('transition',{attrs:{"name":"fade"}},[_c('router-view',{key:_vm.$route.path})],1),_c('div',{staticClass:"ql-bottom"},[_c('CpBottomQuicklinks',{key:_vm.bottomQlKey,on:{"openLeadContact":_vm.openContactLead,"openLead":_vm.openTalkToUsLead,"subscribeNewsletter":_vm.callSubscribeNewsletter}})],1),_c('Footer'),_c('ComparePanel'),_c('FavoritePanel'),_c('Spinner',{attrs:{"loading":_vm.isLoading}}),_c('PanelLeadContact',{key:_vm.userName,attrs:{"financingLabels":_vm.financingLabels,"title":_vm.getPanelTitle,"button":_vm.$t('panels.generalButton'),"dealerOpts":_vm.dealers,"isOpen":_vm.getContactPanelDisplay,"personal":_vm.personal,"vehicle":_vm.getContactPanel.vehicle,"optionalButton":_vm.getContactPanel.optionalButton,"optionalButtonType":_vm.getContactPanel.optionalButtonType,"optionalButtonDisabled":_vm.getContactPanel.optionalButtonDisabled,"labels":_vm.contactLabels,"loggedIn":!_vm.isGuest,"leadType":_vm.getContactPanel.type,"loginDescription":_vm.$t('panels.generalLoginDescription'),"loginLabels":_vm.$t('panels.leadLogin'),"addWeekendDays":true},on:{"onClosePanel":_vm.closeContactLead,"submitContactLead":_vm.submitContactLead,"onClickOptionalButton":_vm.closeContactLead,"onLogin":_vm.onLogin,"onRegister":_vm.onRegister}}),(_vm.getAlert.display)?_c('Alert',{staticClass:"alert-notification",attrs:{"type":_vm.getAlert.type,"message":_vm.getAlert.message,"global":_vm.getAlert.global},on:{"closeAlert":_vm.closeAlert}}):_vm._e(),_c('PanelCarAlert',{key:_vm.carAlertKey,ref:"carAlert",attrs:{"isOpenPanel":_vm.isOpenCarAlert,"title":_vm.carAlert.title,"subtitles":_vm.carAlert.subtitles,"loginLabels":_vm.$t('panels.leadLogin'),"labels":_vm.carAlert.labels,"descriptions":_vm.carAlert.descriptions,"data":_vm.carAlert.data,"loggedIn":!_vm.isGuest},on:{"onClosePanel":_vm.closeCarAlertPanel,"onLogin":_vm.onLogin,"onRegister":_vm.onRegister,"submitCarAlert":_vm.handleSubmitCarAlert}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }