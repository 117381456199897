import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store";

import { FOOTPRINT_TYPES, registerEvent } from "../api/footprint";
import { PAGES } from "../enums/pages";
import { getUtmParams } from "../utils/helpers";

Vue.use(VueRouter);

const getUtmParamsOrDefault = (utmParams) => {
  return Object.keys(utmParams).length === 0
    ? JSON.parse(sessionStorage.getItem("utms")) || {}
    : utmParams;
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(
        /* webpackChunkName: "home" */ "../components/pages/HomePage/HomePage.vue"
      ),
  },
  {
    path: "/testAOS",
    name: "TestAOS",
    component: () => import("../components/pages/AutoOnShowTest.vue"),
  },
  {
    path: "/carros-usados",
    name: "SearchPage",
    component: () =>
      import(
        /* webpackChunkName: "searchpage" */ "../components/pages/SearchPage/SearchPage.vue"
      ),
    props: true,
  },
  {
    path: "/images/:id",
    name: "ImageUUID",
    component: () =>
      import(
        /* webpackChunkName: "image" */ "../components/pages/Images/ImageUUID.vue"
      ),
  },
  {
    path: "/carros-usados/*",
    name: "SearchPageCustomRoute",
    component: () =>
      import(
        /* webpackChunkName: "searchpage" */ "../components/pages/SearchPage/SearchPage.vue"
      ),
    props: true,
  },
  {
    path: "/comparador-carros",
    name: "Comparator",
    component: () =>
      import(
        /* webpackChunkName: "searchpage" */ "../components/pages/ComparatorPage/ComparatorPage.vue"
      ),
    props: true,
  },
  {
    path: "/veiculo/:id",
    name: "DetailPage",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "detailpage" */ "../components/pages/DetailPage/DetailPage.vue"
      ),
  },
  {
    path: "/pessoal",
    name: "Personal",
    component: () =>
      import(
        /* webpackChunkName: "personalpage" */ "../components/pages/PersonalPage/PersonalPage.vue"
      ),
    props: true,
    children: [
      {
        path: "reserva",
        name: "reservations",
        component: () =>
          import(
            /* webpackChunkName: "reservations" */ "../components/pages/PersonalPage/Reservations/Reservations.vue"
          ),
      },
      {
        path: "reserva/:id",
        name: "ReservationDetails",
        component: () =>
          import(
            /* webpackChunkName: "reservations" */ "../components/pages/PersonalPage/Reservations/ReservationDetails.vue"
          ),
      },
      {
        path: "pesquisas-guardadas",
        name: "saved-searches",
        component: () =>
          import(
            /* webpackChunkName: "savedsearches" */ "../components/pages/PersonalPage/SavedSearches/SavedSearches.vue"
          ),
        props: true,
      },
      {
        path: "retoma",
        name: "returns",
        component: () =>
          import(
            /* webpackChunkName: "logout" */ "../components/pages/PersonalPage/Returns/Returns.vue"
          ),
      },
      {
        path: "retoma/:id",
        name: "returnsDetails",
        component: () =>
          import(
            /* webpackChunkName: "logout" */ "../components/pages/PersonalPage/ReturnDetails/ReturnDetails.vue"
          ),
      },
      {
        path: "notificacao/",
        name: "notifications",
        component: () =>
          import(
            /* webpackChunkName: "logout" */ "../components/pages/PersonalPage/Notifications/Notifications.vue"
          ),
      },
    ],
  },
  {
    path: "/vender-carro",
    name: "Retake Evaluation",
    component: () =>
      import(
        /* webpackChunkName: "retake" */ "../components/pages/RetakeEvaluationPage/RetakeEvaluationPage.vue"
      ),
  },
  {
    path: "/como-funciona",
    name: "HowItWorksPage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/pages/HowItWorksPage/HowItWorksPage.vue"
      ),
  },
  {
    path: "/politica-de-cookies",
    name: "cookies-terms",
    component: () => import("../components/pages/CookiePage/CookiePage.vue"),
  },
  {
    path: "/termos-e-condicoes",
    name: "terms-conditions",
    component: () =>
      import("../components/pages/TermsConditionsPage/TermsConditionsPage.vue"),
  },
  {
    path: "/politica-de-privacidade",
    name: "policy",
    component: () =>
      import("../components/pages/PolicyPrivacyPage/PolicyPrivacyPage.vue"),
  },
  {
    path: "/canal-de-denuncias",
    name: "complaint",
    component: () =>
      import("../components/pages/ComplaintPage/ComplaintPage.vue"),
  },
  {
    path: "/intermediario-de-credito",
    name: "intermediario-de-credito",
    component: () =>
      import(
        "../components/pages/CreditIntermediaryPage/CreditIntermediaryPage.vue"
      ),
  },
  {
    path: "/Policy_terms_carculator2",
    name: "Policy_terms_carculator2",
    component: () =>
      import(
        "../components/pages/Carculator2PolicyTermsPage/CarculatorPage.vue"
      ),
  },
  {
    path: "*",
    name: "error",
    component: () => import("../components/pages/ErrorPage/ErrorPage"),
  },
];

const router = new VueRouter({
  base: "/",
  mode: "history",
  scrollBehavior: function (to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    } else if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach(async (to, from, next) => {
  const { name: fromName, fullPath: fromFullPath } = from;
  const { name: toName, path: toPath, query: toQuery } = to;

  const footprintEvent = {
    from: fromName,
    to: toName,
  };

  const loggedInToken = localStorage.getItem("token");
  const isGuest = JSON.parse(localStorage.getItem("isGuest"));
  if ((fromName === null && isGuest) || (fromName === null && !loggedInToken)) {
    await store.dispatch("fetchGuestToken");
  } else {
    store.commit("setToken", loggedInToken);
    store.commit("setIsGuest", isGuest);
  }

  if (fromName === null) {
    await store.dispatch("SearchModule/dropdown/fetchDropdowns");
  }

  if (
    (fromName === PAGES.SEARCH && toName !== PAGES.DETAILS) ||
    (fromName === PAGES.SEARCH_PAGE_CUSTOM_ROUTE && toName !== PAGES.DETAILS)
  ) {
    await store.dispatch("SearchModule/filter/cleanAllTags");
    store.commit("SearchModule/filter/setNeedle", "");
    await store.dispatch("SearchModule/dropdown/resetDropdowns");
  }

  const canonicalLinks = document.head.querySelectorAll("link");
  if (canonicalLinks.length)
    canonicalLinks.forEach((link) => {
      if (link.rel === "canonical") link.remove();
    });

  let canonicalUrl = `${window.location.protocol}//${window.location.host}${toPath}`;
  if (canonicalUrl.endsWith("/")) canonicalUrl = canonicalUrl.slice(0, -1);
  const link = document.createElement("link");
  link.rel = "canonical";
  link.href = `${canonicalUrl}`;
  document.head.appendChild(link);

  // save search tags after login or logout
  if (
    toName === PAGES.SEARCH &&
    (fromFullPath.includes("?action=login") ||
      fromFullPath.includes("?action=logout"))
  ) {
    store.commit("SearchModule/filter/setSaveTags", true);
  }

  // reset pagination when reaching search page from other page than details page
  if (toName === PAGES.SEARCH && fromName !== PAGES.DETAILS) {
    store.commit("resetPage");
  }

  if (toQuery.action && toQuery.action === "logout") {
    await store.dispatch("storeLogout");
    store.dispatch("UserModule/cleanUser");
    store.commit("setUserInfo", {});
  }

  const utmParams = getUtmParams();
  const utms = getUtmParamsOrDefault(utmParams);

  if (Object.keys(utms).length) {
    footprintEvent.utms = utms;
  }

  sessionStorage.setItem("utms", JSON.stringify(utms));

  registerEvent(FOOTPRINT_TYPES.PAGE_VIEW, footprintEvent);

  document.body.classList.remove("no-scroll");

  if (
    toPath.split("/").includes("pessoal") &&
    localStorage.getItem("isGuest") == "true"
  ) {
    return next({ name: PAGES.HOME });
  } else {
    return next();
  }
});

export default router;
