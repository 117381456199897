function formDemultiplexer(e) {
  const module = whatModuleFor(e[0]);
  const setter = whatSetterFor(e[0]);

  return `${module}/${setter}`;
}

function whatModuleFor(type) {
  switch (type) {
    case "name":
    case "phone":
    case "email":
      return "UserModule";
    default:
      return "ReserveModule";
  }
}

function whatSetterFor(type) {
  const map = {
    name: "setName",
    email: "setEmail",
    phone: "setPhone",
    nif: "setNif",
    address: "setAddress",
    city: "setCity",
    zip: "setZipCode",
    "address-extra": "setExtraAddress",
    "city-extra": "setExtraCity",
    "zip-extra": "setExtraZipCode",
  };
  return map[type];
}

export default formDemultiplexer;
