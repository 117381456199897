<template>
  <div class="financing">
    <div class="financing--options">
      <div class="financing--options-row">
        <BuyWithFinancing
          :title="$t('pages.detailsPage.financing.BuyWithFinancing.title')"
          :yesTitle="
            $t('pages.detailsPage.financing.BuyWithFinancing.yesTitle')
          "
          :noTitle="$t('pages.detailsPage.financing.BuyWithFinancing.noTitle')"
          @onChange="clickBuyWithFinancing"
        />
      </div>
      <div v-if="withFinance" class="financing--options-row">
        <SimulationConditions
          v-if="renderComponent"
          :title="$t('pages.detailsPage.financing.SimulationConditions.title')"
          :labelRange1="
            $t('pages.detailsPage.financing.SimulationConditions.labelRange1')
          "
          :labelRange2="
            $t('pages.detailsPage.financing.SimulationConditions.labelRange2')
          "
          :month="month"
          :deposit="deposit"
          @onChangeInitialEntry="clickChangeInitialEntry"
          @onChangeNumberMonths="clickChangeNumberMonths"
        />
      </div>
      <div v-if="withFinance" class="financing--options-row">
        <Button
          :customClass="'update-simulation'"
          :label="$t('pages.detailsPage.financing.button')"
          :type="'secondary'"
          :disabled="isDisabledButton"
          @onClick="calculate"
        ></Button>
      </div>
      <div class="financing--avaliation">
        <RateCard
          v-if="getReturns.length > 0 && !isGuest"
          :title="$t('pages.detailsPage.financing.rateCard.title')"
          :description="$t('pages.detailsPage.financing.rateCard.description')"
          filename="car.png"
          :buttonLabel="$t('pages.detailsPage.financing.rateCard.buttonLabel')"
          :border="false"
          :resume="getReturns[0]"
          @onToggle="onToggleHandler"
          :checkedToggle="getIncludeRetake"
        />
        <RateCard
          v-else
          :subtitle="$t('pages.detailsPage.financing.rateCard.subtitle')"
          class="mb-5 md:mb-0"
          :border="false"
          filename="car.png"
          :description="$t('pages.detailsPage.financing.rateCard.description')"
          :buttonLabel="$t('pages.detailsPage.financing.rateCard.buttonLabel')"
          @onClick="onClickRateCardRetakeHandler"
        />
        <Alert
          v-if="showInfoAlert"
          class="info"
          type="info-alert"
          :message="$t('pages.detailsPage.retakeAlert')"
        />
      </div>
    </div>
    <div class="financing--result">
      <FinancingResult
        :deposit="getVehicleInitialDeposit"
        :error="getCalculateFinanceError"
        :haveSimulation="getIncludeRetake"
        :resumePrice="deposit.retake"
        :tooltip="tooltipResult"
        :reserveStatus="getVehicle.carDetailCard.reserveType"
        @onAddResume="onToggleHandler"
        @onClickInterest="clickInterest"
        @getFinFile="getPdfFinFile"
        @interestFootprint="handleInterestFootprint"
      />
      <Popup
        v-show="openRetakeValuePopup"
        :closeButton="false"
        :closeOnClickOutside="true"
        @close="openRetakeValuePopup = false"
        customClass="alert-test"
      >
        <template slot="body">
          <div class="main-section">
            <TextLabel
              class="popup-title"
              :label="$t('pages.detailsPage.financing.retakePopup.title')"
            />
            <TextLabel
              class="popup-content"
              :label="$t('pages.detailsPage.financing.retakePopup.content')"
            />
            <Button
              class="exit-btn"
              :label="$t('pages.detailsPage.financing.retakePopup.exitBtn')"
              type="secondary"
              @onClick="openRetakeValuePopup = false"
            />
          </div>
        </template>
      </Popup>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Popup } from "../../atoms";
import { BuyWithFinancing } from "../../molecules";
import {
  SimulationConditions,
  RateCard,
  FinancingResult,
} from "../../organisms";
import { getPriceFormatted } from "../../../utils/currency";
import { CREDIT_TYPE } from "../../../enums/creditType";
import { TextLabel, Alert, Button } from "@sc/ds-ui-component-library";
import { PAGES } from "../../../enums/pages";
import { updateSimulationEvent } from "../../../utils/googleTagManager";

const DEPOSIT_OFFSET = 500;
const OPEN_DRIVE_P = 5;
const CREDIT_TO_DRIVE_P = 4;

export default {
  components: {
    BuyWithFinancing,
    SimulationConditions,
    RateCard,
    FinancingResult,
    Button,
    Alert,
    Popup,
    TextLabel,
  },
  data() {
    return {
      typeFinancing: "credit-to-drive",
      creditType: CREDIT_TYPE.CREDIT,
      initialEntry: undefined,
      numberMonths: this.getVehicleNumberMonths,
      turnOnButton: false,
      myMonthlyPrice: this.monthlyPrice,
      myTaeg: this.taeg,
      myCapitalToFinance: this.capitalToFinance,
      isInterestedOpened: false,
      isTestDrive: false,
      resumeObj: {
        model: "Nissan Qashqai",
        value: 5000,
        registration: "35-RO-13",
        expire: "6",
      },
      includeRetake: this.includeRetake,
      renderComponent: true,
      openRetakeValuePopup: false,
    };
  },
  props: {
    haveSimulation: {
      type: Boolean,
      default: false,
    },
    month: {
      type: Object,
    },
    deposit: {
      type: Object,
    },
    minRate: {
      type: Number,
    },
    rate: {
      type: Number,
    },
    pvpCetelem: {
      type: Number,
    },
    pvpTotal: {
      type: Number,
    },
    monthlyPrice: {
      type: String,
    },
    taeg: {
      type: String,
    },
    capitalToFinance: {
      type: String,
    },
    car: {
      type: Array[Object],
      default: () => {
        return [];
      },
    },
    tooltipResult: {
      type: String,
    },
  },
  methods: {
    getPriceFormatted,
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    normalizeValue(value, offset) {
      return Math.ceil(value / offset) * offset;
    },
    onToggleHandler(event) {
      this.$store.commit("setIncludeRetake", event);
      this.initialEntry = event
        ? this.normalizeValue(this.deposit.retake, this.deposit.step)
        : this.deposit.defaultValue;

      this.includeRetake = event;
      this.includeRetake === true
        ? sessionStorage.setItem("retake", JSON.stringify(this.resumeObj))
        : sessionStorage.removeItem("retake");

      if (this.showInfoAlert) this.openRetakeValuePopup = true;

      this.calculate();
    },
    clickBuyWithFinancing(event) {
      this.$store.commit("VehicleModule/toggleWithFinance", event);
      this.turnOnButton = true;
    },
    clickChooseTypeFinancing(event) {
      this.$store.commit("setIsOpenDrive", event === "open-drive");

      if (event === "credit-to-drive") {
        // TODO: fix string logic later, might break stuff
        this.typeFinancing = "credit-to-drive";
        this.creditType = CREDIT_TYPE.CREDIT;
        if (this.numberMonths >= this.getCreditTypeDetails(3).maxNbrPayments) {
          this.numberMonths = this.getCreditTypeDetails(3).maxNbrPayments;
        }
        if (this.numberMonths <= this.getCreditTypeDetails(3).minNbrPayments) {
          this.numberMonths = this.getCreditTypeDetails(3).minNbrPayments;
        }
        this.$store.commit("setCreditType", CREDIT_TYPE.CREDIT);
      } else if (event === "open-drive") {
        this.typeFinancing = "open-drive";
        this.creditType = CREDIT_TYPE.OPEN_DRIVE;
        if (this.numberMonths >= this.getCreditTypeDetails(2).maxNbrPayments) {
          this.numberMonths = this.getCreditTypeDetails(2).maxNbrPayments;
        }
        if (this.numberMonths <= this.getCreditTypeDetails(2).minNbrPayments) {
          this.numberMonths = this.getCreditTypeDetails(2).minNbrPayments;
        }

        this.$store.commit("setCreditType", CREDIT_TYPE.OPEN_DRIVE);
        this.forceRerender();
      }
      this.turnOnButton = true;

      this.calculate();
    },
    clickChangeInitialEntry(event) {
      if (!event) return;
      this.initialEntry = event;
      this.turnOnButton = true;
    },
    clickChangeNumberMonths(event) {
      const lessThanMax = Math.min(event, this.month.max);
      const result = Math.max(lessThanMax, this.month.min);
      this.numberMonths = result || lessThanMax;
      this.turnOnButton = true;
    },
    clickAddResume(event) {
      // added retake to session storage
      if (this.getReturns.length > 0) {
        sessionStorage.setItem("retake", JSON.stringify(this.getReturns[0]));
        this.includeRetake = true;
        this.$emit("onClickAddResume", event);
      } else {
        this.$router.push({
          name: PAGES.RETAKE,
        });
      }
    },
    clickInterest(event) {
      this.isInterestedOpened = true;
      this.isTestDrive = false;

      this.$emit("onClickInterest", event);
    },
    clickRateCard(event) {
      if (event === "left") {
        this.$router.push({
          name: PAGES.RETAKE,
        });
      } else if (event === "right") {
        this.$router.push("/avaliar-taxa-risco");
      }

      this.$emit("onClickRateCard", event);
    },
    getMaxDeposit() {
      return this.getIsOpenDrive
        ? this.roundToStep(this.getVehiclePrice / OPEN_DRIVE_P, DEPOSIT_OFFSET)
        : this.roundToStep(
            this.getVehiclePrice / CREDIT_TO_DRIVE_P,
            DEPOSIT_OFFSET
          );
    },
    async calculate() {
      const initialValue =
        this.initialEntry > this.deposit.max
          ? this.deposit.max
          : this.initialEntry;

      let resVal;
      if (this.getIsOpenDrive) {
        resVal = this.getVehicleResidualValueOD(
          this.creditType - 1,
          this.numberMonths || this.getVehicleNumberMonths
        );
      }

      const financing = {
        buyWithFinancing: this.withFinance,
        typeFinancing: this.typeFinancing,
        initialValue: initialValue,
        nrPayments: this.numberMonths || this.getVehicleNumberMonths,
        carType: "Novo",
        pvp: this.pvp,
        rate: this.getVehicleRate(this.creditType - 1), // index in array
        minRate: this.getVehicleMinRate(this.creditType - 1),
        minInitialValue: this.initialEntry || this.getMinDeposit,
        residualValue: resVal,
        vin: this.car.vin,
      };

      await this.$store.dispatch("storeCalculateFinance", financing);

      this.turnOnButton = false;

      this.$emit("onCalculate", financing);
      const sim = {
        down_payment: this.initialEntry,
        number_of_months: financing.nrPayments,
        retake_added: this.includeRetake,
      };
      updateSimulationEvent(this.car.carDetailCard, sim, PAGES.DETAILS);
    },
    closeInterested() {
      this.isInterestedOpened = false;
    },
    openTestDrive() {
      this.isInterestedOpened = false;
      this.isTestDrive = true;
    },
    onPrevious() {
      this.isInterestedOpened = true;
      this.isTestDrive = false;
    },
    onClickRateCardRetakeHandler() {
      this.$router.push({
        name: PAGES.RETAKE,
      });
    },
    roundToStep(val, offset) {
      return val - (val % offset);
    },
    async getPdfFinFile() {
      const depositWithRetake = this.getIncludeRetake
        ? this.getReturnValue + this.initialEntry
        : this.initialEntry;
      const initialValue =
        depositWithRetake > this.getMaxDeposit()
          ? this.getMaxDeposit()
          : depositWithRetake;

      let resVal;
      if (this.getIsOpenDrive) {
        resVal = this.getVehicleResidualValueOD(
          this.creditType - 1,
          this.numberMonths || this.getVehicleNumberMonths
        );
      }
      const financing = {
        buyWithFinancing: this.withFinance,
        typeFinancing: this.typeFinancing,
        initialValue: initialValue || this.getMinDeposit,
        nrPayments: this.numberMonths || this.getVehicleNumberMonths,
        carType: "Novo",
        pvp: this.pvp,
        rate: this.getVehicleRate(this.creditType - 1), // index in array
        minRate: this.getVehicleMinRate(this.creditType - 1),
        minInitialValue: this.initialEntry || this.getMinDeposit,
        residualValue: resVal,
        vin: this.car.vin,
      };

      await this.$store.dispatch("downloadFin", financing);
      this.downloadPdf(this.getPdfFins);
    },
    downloadPdf(pdfBase64) {
      var el = document.createElement("a");
      el.href = "data:application/pdf;base64," + pdfBase64;
      el.target = "_blank";
      el.download = "Ficha_de_Informação_Normalizada";
      el.click();
    },
    isRetakeValueHigher(retake, vehicleValue) {
      return retake.status === 1
        ? retake.actualValuation > vehicleValue
        : retake.initialValuation > vehicleValue;
    },
    handleInterestFootprint() {
      this.$emit("interestFootprint");
    },
  },
  computed: {
    ...mapGetters([
      "getReturn",
      "getCalculateFinance",
      "getCalculateFinanceError",
      "getReturns",
      "getVehicleInitialDeposit",
      "getVehicleNumberMonths",
      "getMinDeposit",
      "getVehicleRate",
      "getVehicleMinRate",
      "getVehiclePrice",
      "getReturnValue",
      "getIncludeRetake",
      "getVehicleResidualValueOD",
      "getIsOpenDrive",
      "isGuest",
      "getPdfFins",
      "getCreditTypeDetails",
    ]),
    ...mapGetters({
      withFinance: "VehicleModule/withFinance",
      getVehicle: "VehicleModule/getVehicle",
    }),
    isDisabledButton() {
      console.log("onButton", this.turnOnButton);
      return !this.turnOnButton;
    },
    pvp() {
      return !this.getIsOpenDrive && !this.withFinance
        ? this.pvpCetelem
        : this.pvpTotal;
    },
    showInfoAlert() {
      return (
        this.getReturns.length > 0 &&
        !this.isGuest &&
        this.getIncludeRetake &&
        this.isRetakeValueHigher(this.getReturns[0], this.getVehiclePrice)
      );
    },
  },
  mounted() {
    this.initialEntry = this.getVehicleInitialDeposit;
    this.numberMonths = this.getVehicleNumberMonths;
  },
};
</script>

<style lang="scss" scoped>
.financing {
  padding-left: 0px;
  padding-right: 0px;
}

.financing--options {
  width: 100%;
}

.financing--result {
  width: 100%;
}

.financing--avaliation {
  width: 100%;
}

.financing--options-row {
  margin-bottom: 1.5rem;
}

.financing--options-row .button {
  height: 64px;
  font-size: 18px;
  line-height: 72px;
}

::v-deep .vehicleInfo--container {
  margin-top: 18px;
}

.right-sidebar ::v-deep .right-sidebar--content {
  display: block;
}

.interested--price-model-details {
  border-bottom: 2px solid #e5ecf0;
  padding-bottom: 2rem;
}

.interested--title {
  border-bottom: 2px solid #e5ecf0;
  padding-top: 0.5rem;
  padding-bottom: 1.25rem;
}

.accordion ::v-deep .accordion-item {
  padding-left: 0px;
}

.interested--footer {
  display: flex;
  bottom: 0px;
  position: absolute;
  margin-bottom: 1rem;
  margin: auto;
  justify-content: space-between;
}

.interested--footer-test-drive .button {
  height: 56px;
}

.interested--footer-reserve .button {
  height: 56px;
}

::v-deep .right-sidebar--footer {
  position: relative;
  width: 100%;
  margin-top: auto;
}

::v-deep .right-sidebar--footer .button {
  height: 56px;
}

.reserve {
  margin-top: 1rem;
}

::v-deep .accordion-item--icon-container svg {
  color: rgba(15, 83, 251, 1);
}

::v-deep .right-sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
}

::v-deep .title--content-title {
  font-size: 24px;
  line-height: 24px;
}

::v-deep .title--content-subtitle {
  font-size: 20px;
  line-height: 20px;
}

::v-deep .finance--container-title {
  font-size: 24px;
  line-height: 24px;
}

::v-deep .finance--container-subtitle {
  font-size: 20px;
  line-height: 20px;
}

::v-deep .default-car-image {
  margin: 0 auto;
}

::v-deep .details-container {
  width: 100%;
}

::v-deep .button--disabled {
  background-color: #e5ecf0;
  opacity: 1;
  color: #abb1c5;
  border-color: #abb1c5;
}

.update-simulation {
  width: 100%;
  --button-justify-content: center;
}
.financing--avaliation .info {
  max-width: 495px;
}
.popup-title {
  --text-label-font-size: 18px;
  --text-label-line-height: 23px;
  --text-label-weight: 800;
  --text-label-color: #0053ff;
  padding-bottom: 8px;
  padding-top: 32px;
}
.popup-content {
  --text-label-font-size: 16px;
  --text-label-line-height: 21px;
  --text-label-weight: 600;
  --text-label-color: #333333;
  padding-bottom: 24px;
}
.exit-btn {
  border: 2px solid red;
  --button-display: inline;
}
.alert-test ::v-deep .popup {
  width: 446px;
  height: 259px;
  text-align: center;
  align-content: center;
  overflow: hidden;
}
@media (max-width: 390px) {
  .alert-test ::v-deep .popup {
    width: auto;
    height: auto;
  }
}
@media (max-width: 1023px) {
  .financing--avaliation .info {
    margin-bottom: 15px;
  }
}
@media (min-width: 1024px) {
  .financing {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .financing--options {
    width: 66.666667%;
    margin-bottom: 1.5rem /* 24px */;
  }
  .financing--result {
    width: 33.333333%;
    padding-left: 1.5rem /* 24px */;
    margin-bottom: 0px;
  }
  .financing--avaliation {
    width: 66.666667%;
  }
  .financing--options-row {
    margin-bottom: 1.5rem /* 24px */;
  }
  ::v-deep .right-sidebar--footer {
    display: flex;
    justify-content: space-between;
  }
  .reserve {
    margin-left: 1rem /* 16px */;
    margin-top: 0px;
  }
}
</style>
