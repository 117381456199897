<template>
  <Card
    :class="'missing-action-card'"
    @click.native="redirect"
    :bgColor="backgroundColor"
    padding
  >
    <div class="card-content">
      <div class="warning-label">
        <StatusTag class="status-tag" :type="type" :label="warningLabel" />
      </div>
      <div class="card-content--title">
        <Label color="primary" bold="semibold">{{ title }}</Label>
      </div>
      <div class="card-content--description">
        <Label color="tertiary">{{ description }} </Label>
      </div>
    </div>
    <div class="card--icon-container">
      <font-awesome-icon icon="chevron-right" />
    </div>
  </Card>
</template>

<script>
import { Card, Label } from "../../atoms";
import { StatusTag } from "@sc/ds-ui-component-library";
export default {
  name: "CardWithAction",
  components: {
    Card,
    Label,
    StatusTag,
  },
  props: {
    description: {
      type: String,
    },
    title: {
      type: String,
    },
    warningLabel: {
      type: String,
    },
    type: {
      type: String,
    },
    backgroundColor: {
      type: String,
      default: "white",
    },
  },
  methods: {
    redirect() {
      this.$emit("onRedirect");
    },
  },
  computed: {},
};
</script>

<style lang="css" scoped src="./CardWithAction.css"></style>
