import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";

import reserve from "./modules/reserve";
import vehicles from "./modules/vehicles.js";
import autocomplete from "./modules/autocomplete.js";
import search from "./modules/search.js";
import dropdowns from "./modules/dropdowns";
import authentication from "./modules/authentication";
import finance from "./modules/finance";
import returns from "./modules/returns";
import retake from "./modules/retake";

import alertStatus from "./modules/components/alert.js";
import contactPanelStatus from "./modules/components/contactPanel.js";

/*
  Store refactoring - New modules
*/
import spinnerModule from "./modules/spinner";
import CompareModule from "./modules/compareModule";
import FavoritesModule from "./modules/favoritesModule";
import ConfigurationModule from "./modules/configurationModule";
import SearchModule from "./modules/searchModule";
import VehicleModule from "./modules/vehicleModule";
import NotificationModule from "./modules/notificationModule";
import PanelModule from "./modules/panelModule";
import TradeinModule from "./modules/tradeinModule";
import FinancingModule from "./modules/financingModule";
import ReserveModule from "./modules/reserveModule";
import UserModule from "./modules/userModule";
import StoreModule from "./modules/storeModule";

import responsiveness from "./modules/responsiveness";

Vue.use(Vuex);

const unwantedMutations = ["someMutationThatGetsCalledTooOften"];

const vuexLocalStorage = new VuexPersist({
  key: "vuex", // The key to store the state on in the storage provider.
  storage: window.sessionStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  reducer: (state) => ({
    UserModule: state.UserModule,
    PanelModule: state.PanelModule,
    TradeinModule: state.TradeinModule,
    ReserveModule: state.ReserveModule,
    SearchModule: state.SearchModule,
    contactPanelStatus: state.contactPanelStatus,
    // getRidOfThisModule: state.getRidOfThisModule (No one likes it.)
  }),
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  filter: (mutation) => unwantedMutations.indexOf(mutation.type) === -1,
});

export default new Vuex.Store({
  state: {
    isOpenSignupPopup: false,
    isOpenSigninPopup: false,
    isOpenVerificationAccount: false,
    isOpenReservePanel: false,
    isOpenRetakePanel: false,
    isOpenNavbar: false,
  },
  getters: {
    getIsOpenPopup: (state) =>
      state.isOpenSigninPopup || state.isOpenSignupPopup,
    getIsOpenSigninPopup: (state) => state.isOpenSigninPopup,
    getIsOpenVerificationAccount: (state) => state.isOpenVerificationAccount,
  },
  mutations: {
    setOpenVerificationAccount(state, open) {
      state.isOpenVerificationAccount = open;
    },
  },
  modules: {
    vehicles,
    autocomplete,
    search,
    dropdowns,
    authentication,
    finance,
    reserve,
    returns,
    retake,
    alertStatus,
    contactPanelStatus,
    responsiveness,
    // new namespaced modules
    spinnerModule,
    CompareModule,
    FavoritesModule,
    ConfigurationModule,
    SearchModule,
    VehicleModule,
    NotificationModule,
    PanelModule,
    TradeinModule,
    FinancingModule,
    ReserveModule,
    UserModule,
    StoreModule,
  },
  plugins: [vuexLocalStorage.plugin],
});
