<template>
  <CaptionIcon
    :iconColor="'#808080'"
    :label="$t('pages.detailsPage.viewers', { viewers })"
    icon="eye"
  />
</template>

<script>
import CaptionIcon from "./molecules/CaptionIcon/CaptionIcon.vue";
import { mapGetters } from "vuex";

export default {
  name: "VehicleViewers",
  components: { CaptionIcon },
  computed: {
    ...mapGetters({
      viewers: "VehicleModule/getViews",
    }),
  },
};
</script>
