<template>
  <Section id="experience">
    <div class="row">
      <TextLabel :label="$t('experiencerow.title')" />
      <div class="icons">
        <CaptionIcon
          withoutPointer
          icon="check"
          :label="$t('experiencerow.delivery')"
        />
        <div class="divider" />
        <CaptionIcon
          withoutPointer
          icon="check"
          :label="$t('experiencerow.return')"
        />
        <div class="divider" />
        <CaptionIcon
          withoutPointer
          icon="check"
          :label="$t('experiencerow.warranty')"
        />
      </div>
    </div>
  </Section>
</template>

<script>
import { Section, TextLabel, CaptionIcon } from "@sc/ds-ui-component-library";
export default {
  name: "ExperienceRow",
  components: { Section, TextLabel, CaptionIcon },
};
</script>

<style lang="scss" scoped>
#experience {
  --section-padding: 0px;
  --section-bg-color: var(--color-6);
  --section-margin: 0px 64px;

  ::v-deep {
    --text-label-color: var(--color-1);
    --text-label-font-weight: 600;
    --text-label-font-size: 18px;
    --text-label-line-height: 18px;
  }
}

.row {
  display: flex;
  justify-content: space-around;
  padding: 28px 64px;
}

.row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.icons {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;

  .caption-icon {
    margin: 0px 67px;
  }
}

.divider {
  height: 56px;
  width: 1px;
  border: 1px solid var(--color-4);
}

@media (max-width: 1200px) {
  .row {
    padding: 28px 10px;
  }
  .divider {
    display: none;
  }
}

@media (max-width: 576px) {
  .row {
    justify-content: left;
    padding: 24px;
    --text-label-font-size: 16px;
    --text-label-weight: 600;
    --text-label-color: #333333;
    .text-label {
      --text-label-line-height: 21px;
    }
  }
  .icons {
    justify-content: left;
    .caption-icon {
      width: 100%;
      margin: 16px 0;
    }
    .caption-icon ::v-deep .text-label {
      margin-left: 16px;
      --text-label-line-height: 16px;
    }
  }
}
</style>
