import { changeReceipt } from "@/api/userMe";

const state = {
  receipt: {
    nif: "",
    address: "",
    zipCode: "",
    location: "",
  },
};

const getters = {
  getReceipt: (state) => state.receipt,
};

const mutations = {
  setReceipt(state, receipt) {
    state.receipt = receipt;
  },
};

const actions = {
  storeReceipt({ commit }, receipt) {
    commit("setReceipt", receipt);
    changeReceipt(receipt);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
