<template>
  <Section id="section-1" class="hero">
    <Heading
      class="hero-title"
      :label="$t('pages.homepage.hero.title')"
      tag="h1"
    />

    <div class="hero-btns">
      <Button
        class="buy-vehicle-btn"
        label="Comprar carro"
        @onClick="onBuyVehicle"
      />
      <Button
        type="secondary"
        class="sell-vehicle-btn"
        label="Vender carro"
        @onClick="onSellVehicle"
      />
    </div>
    <img
      :src="
        $t('resources.images.homepage.hero') ||
        require('@/assets/images/stuff/Hero_Homepage2.webp')
      "
      class="hero-image"
      width="650"
      height="500"
      :alt="$t('pages.homepage.hero.alt')"
    />
  </Section>
</template>

<script>
import { Section, Heading, Button } from "@sc/ds-ui-component-library";
import { PAGES } from "../../enums/pages";
export default {
  name: "Hero",
  components: {
    Section,
    Heading,
    Button,
  },
  props: {
    filterTags: {
      type: Array,
    },
  },
  methods: {
    onBuyVehicle() {
      this.$router.push({
        name: PAGES.SEARCH,
      });
    },
    onSellVehicle() {
      this.$router.push({
        name: PAGES.RETAKE,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.hero {
  --section-bg-color: none;
  --section-padding: 150px;
  position: relative;
  --section-overflow: unset;
}

.hero-title {
  --heading-color: var(--color-7);
  width: 920px;
  text-align: center;
  margin: auto auto 40px auto;
  z-index: 100;
  --heading-xlarge-font-size: 42px;
  --heading-xlarge-line-height: 55px;
  --heading-weight-bold: 800;
}

.hero-image {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: -1;
  object-fit: cover;
  object-position: bottom;
}

.hero-btns {
  width: 100%;
  margin-top: 92px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  & > * {
    margin: 0px 16px;
  }
}

@media (max-width: 1440px) {
}
@media (max-width: 1366px) {
}
@media (max-width: 1280px) {
}
@media (max-width: 1024px) {
  .hero {
    --section-padding: 60px 20px;
    --section-header-text-align: left;
  }
  .hero-title {
    --heading-xlarge-font-size: 32px;
    --heading-xlarge-line-height: 36px;
    --section-header-width: 95%;
    width: fit-content;
  }
  .hero-btns {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .hero-title {
    --heading-xlarge-font-size: 24px;
    --heading-xlarge-line-height: 31px;
  }
  .hero-image {
    object-position: left;
  }
  .hero-btns {
    --button-display: block;
    width: 100%;
    flex-direction: column;
    align-items: center;
    & > * {
      margin: 16px 0px;
    }

    .buy-vehicle-btn,
    .sell-vehicle-btn {
      width: 100%;
      max-width: 450px;
      --button-padding: 0px;
    }
  }
}
@media (max-width: 375px) {
}
</style>
