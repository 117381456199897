const UTM_PREFIX = "utm_";

const getUtmParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);

  const params = Object.fromEntries(urlSearchParams.entries());

  const utmParams = Object.keys(params)
    .filter((key) => key.includes(UTM_PREFIX))
    .reduce((res, key) => ((res[key] = params[key]), res), {});

  return utmParams;
};

const getUtmURL = (utms) => {
  var utmUrl = "";
  var count = 1;
  for (const [key, value] of Object.entries(utms)) {
    if (count === 1) utmUrl += "?";
    utmUrl += `${key}=${value}`;
    if (count < Object.entries(utms).length) utmUrl += "&";
    count++;
  }
  return utmUrl;
};

const dealerNameFormat = (p) => {
  const stringsToRemove = ["carplus", "clicayvendecoche", "clicaevende"];

  let filteredString = p.replace(/[()\s]/g, "").toLowerCase();

  stringsToRemove.forEach((stringToRemove) => {
    filteredString = filteredString.replace(stringToRemove, "");
  });
  filteredString =
    filteredString.charAt(0).toUpperCase() + filteredString.slice(1);
  return filteredString;
};

export { getUtmParams, getUtmURL, dealerNameFormat };
