<template>
  <div class="input--wrapper">
    <input
      :style="customStyles"
      :class="['input--text', customClass, classes]"
      @input="onInput"
      @focus="onFocus"
      @blur="onBlur"
      :disabled="disabled"
      :readonly="readonly"
      :required="isRequired"
      :maxlength="maxLength"
      :autocomplete="allowAutocomplete"
      :placeholder="placeholder"
      :name="name"
      :type="inputType"
      :pattern="pattern"
      :value="currentValue"
    />
    <div class="input--icon-wrapper" v-if="icon" @click="onIconClick">
      <font-awesome-icon
        :class="['input--icon', customIconClass]"
        :icon="icon"
        :style="{ color: iconColor }"
      ></font-awesome-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "Input",
  data() {
    return {};
  },
  props: {
    styles: {
      type: Object,
      default: () => {
        return {};
      },
    },
    customClass: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      required: false,
    },
    autocomplete: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    value: {
      type: [Number, String],
    },
    inputType: {
      type: String,
      default: "text",
      validator: (inputType) => {
        return ["text", "email", "number", "password", "tel", "date"].includes(
          inputType
        );
      },
    },
    type: {
      type: String,
      default: "primary",
      validator: (type) => {
        return ["primary", "secondary", "tertiary"].includes(type);
      },
    },
    textColor: {
      type: String,
      validator: (textColor) => {
        return ["primary", "secondary"].includes(textColor);
      },
    },
    bgColor: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      default: "",
    },
    iconColor: {
      type: String,
      required: false,
    },
    iconPosition: {
      type: String,
      default: "left",
    },
    border: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onInput(event) {
      this.currentValue = event.target.value;
      this.$emit("onChange", event.target.value);
    },
    onFocus(event) {
      this.$emit("onFocus", event);
    },
    onBlur(event) {
      this.$emit("onBlur", event);
    },
    onIconClick(event) {
      this.$emit("onIconClick", event);
    },
  },
  computed: {
    classes() {
      return {
        "input--with-icon": this.icon,
        "input--primary": this.type === "primary",
        "input--secondary": this.type === "secondary",
        "input--tertiary": this.type === "tertiary",
        "input--border-primary": this.type === "primary" && this.border,
        "input--border-secondary": this.type === "secondary" && this.border,
        "input--border-tertiary": this.type === "tertiary" && this.border,
        "input--text-primary": this.textColor === "primary",
        "input--text-secondary": this.textColor === "secondary",
        "input--rounded": this.rounded,
      };
    },
    customIconClass() {
      return {
        "input--icon-primary": this.type === "primary",
        "input--icon-secondary": this.type === "secondary",
      };
    },
    customStyles() {
      return {
        ...this.styles,
        backgroundColor: this.bgColor,
      };
    },
    allowAutocomplete() {
      if (navigator.userAgent.indexOf("Chrome") != -1 && !this.autocomplete) {
        return "one-time-code";
      }
      return this.autocomplete ? "on" : "off";
    },
    currentValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
    pattern() {
      if (this.inputType === "date") {
        return "[0-9]{2}-[0-9]{2}-[0-9]{4}";
      } else if (this.inputType === "number") {
        return "\\d*";
      } else {
        return "";
      }
    },
  },
  updated() {
    if (this.inputType === "date" && this.currentValue.length > 10) {
      this.currentValue = this.currentValue.slice(1);
    }
  },
};
</script>

<style lang="css" scoped src="./Input.css"></style>
