import httpClient from "./httpClient";

const USER_ADDRESS = "/users/me/address";

const USER_PASSWORD = "/users/me/password";

const USER_PERSONAL = "/users/me/personal";

const USER_DEALER = "/users/me/dealer";

const USER = "/users/";

const USER_RECOVER = "/password/recover";

const USER_RECOVER_UPDATE_PASSWORD = "/users/password/recover/update";

const USER_VERIFICATION_ACCOUNT = "/users/validate";

function changePersonalInfo(payload) {
  return httpClient.put(USER_PERSONAL, payload);
}

function changeUserPassword(payload) {
  return httpClient.put(USER_PASSWORD, payload);
}

function changeUserAddress(payload) {
  return httpClient.put(USER_ADDRESS, payload);
}

function changeUserDealer(payload) {
  return httpClient.put(USER_DEALER, payload);
}

function changeReceipt(payload) {
  console.log(payload);
}

function recoverPassword(email) {
  return httpClient.post(USER + email + USER_RECOVER);
}

function recoverUpdatePassword(payload) {
  return httpClient.put(USER_RECOVER_UPDATE_PASSWORD, payload);
}

function verificationAccount(payload) {
  return httpClient.put(USER_VERIFICATION_ACCOUNT, payload);
}

export {
  changePersonalInfo,
  changeUserPassword,
  changeUserAddress,
  changeUserDealer,
  changeReceipt,
  recoverPassword,
  recoverUpdatePassword,
  verificationAccount,
};
