<template>
  <Section
    id="bottomquicklinks-section"
    class="section-12-blocks section-bg-white"
  >
    <CpContactBlock
      @openLeadContact="emitOpenLeadContact"
      @openLead="emitOpenLead"
    />
    <CpFAQBlock />
    <CpNewsletterBlock @subscribeNewsletter="emitClick" />
  </Section>
</template>

<script>
import CpContactBlock from "./CpContactBlock.vue";
import CpFAQBlock from "./CpFAQBlock.vue";
import CpNewsletterBlock from "./CpNewsletterBlock.vue";

export default {
  name: "CpBottomQuicklinks",
  components: {
    CpContactBlock,
    CpFAQBlock,
    CpNewsletterBlock,
  },
  methods: {
    emitClick(data) {
      this.$emit("subscribeNewsletter", data);
    },
    emitOpenLeadContact() {
      this.$emit("openLeadContact");
    },
    emitOpenLead() {
      this.$emit("openLead");
    },
  },
};
</script>

<style lang="scss" scoped>
.section-bg-white {
  --section-bg-color: var(--color-7);
}

.section-12-block:nth-child(2) {
  margin: 0 17px;
}

.section-12-blocks {
  width: 100%;
  display: flex;
  --section-bg-color: var(--color-7);
  --title-color: var(--color-1);

  .section-12-block {
    background-color: #fbfbfb;
    width: 100%;
    padding: 52px 64px;

    &.section-12-block-3 {
      --theme-color-tertiary: #5b8df426;
      background-color: var(--theme-color-tertiary);
      padding: 40px 64px;
    }
  }
}

@media (max-width: 1920px) {
}

@media (max-width: 1440px) {
}
@media (max-width: 1366px) {
}
@media (max-width: 1280px) {
}

@media (max-width: 1024px) {
}

@media (max-width: 768px) {
}

@media (max-width: 1024px) {
  .section-12-block:nth-child(2) {
    margin: 24px 0;
  }

  .section-12-blocks {
    flex-direction: column;
    height: 100%;
    width: 100%;

    .section-12-block {
      padding: 22px 24px;

      &.section-12-block-3 {
        padding: 20px 24px;
      }
    }
  }
  ::v-deep {
    .section-12-block-1-links {
      justify-content: center;
    }
  }
}
</style>
