<template>
  <input
    :disabled="disabled"
    type="radio"
    :id="id"
    :class="['input', customClass, classes]"
    :style="customStyles"
    :name="name"
    :value="value"
    @change="onChangeHandler"
    :checked="checked"
  />
</template>

<script>
export default {
  name: "RadioButton",
  data() {
    return {
      radioChecked: this.checked,
    };
  },
  props: {
    styles: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    customClass: {
      type: String,
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "primary",
      validator: (value) => {
        return ["primary", "secondary", "tertiary"].includes(value);
      },
    },
    size: {
      type: String,
      default: "medium",
      validator: (value) => {
        return ["x-small", "small", "medium", "large"].includes(value);
      },
    },
    circular: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
    disable: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
    checked: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onChangeHandler(event) {
      this.$emit("onChange", event.target.value);
    },
    externalChange() {
      if (!this.radioChecked) {
        this.radioChecked = true;
        this.$emit("onChange", this.value);
      }
    },
  },
  computed: {
    classes() {
      return {
        "radio--size-x-small": this.size === "x-small",
        "radio--size-small": this.size === "small",
        "radio--size-medium": this.size === "medium",
        "radio--size-large": this.size === "large",
        "radio--color-primary": this.color === "primary",
        "radio--color-secondary": this.color === "secondary",
        "radio--color-tertiary": this.color === "tertiary",
        "radio--circle": this.circular,
        "radio--disable": this.disable,
      };
    },
    customStyles() {
      return {
        ...this.styles,
      };
    },
  },
  mounted() {
    this.radioChecked = this.checked;
  },
};
</script>
<style lang="css" scoped src="./RadioButton.css"></style>
