<template>
  <div id="app">
    <TopBar @openLeadContact="openContactLead" @openLead="openTalkToUsLead" />
    <Navbar id="navbar-page">
      <template v-slot:left>
        <router-link to="/">
          <div class="logo-container">
            <img :src="appLogo" alt="logo carplus" />
          </div>
        </router-link>
      </template>
      <template v-slot:center>
        <LinkList
          :class="getSelectedPage"
          @onClick="closeMobileMenu"
          :list="list"
          bold="normal"
          display="horizontal"
          size="large"
        />
      </template>
      <template v-slot:right-1>
        <div
          class="actions-area flex flex-row justify-center sm:justify-start items-center"
        >
          <a @click="openCompare" class="icon--wrapper">
            <div>
              <span class="notify-badge" v-if="compareCount > 0">{{
                compareCount
              }}</span>

              <div
                @click="openCompare"
                class="navbar--compare-icon"
                :style="{
                  backgroundImage: `url(${compareIcon})`,
                }"
              />
            </div>
          </a>

          <a class="icon--wrapper">
            <div>
              <span class="notify-badge" v-if="favoritesCount > 0">{{
                favoritesCount
              }}</span>

              <div
                @click="openFavoritePanel"
                class="navbar--favorite-icon"
                :style="{
                  backgroundImage: `url(${favoriteIcon})`,
                }"
              />
            </div>
          </a>
        </div>
      </template>
      <template v-slot:right-2>
        <div class="reserved-area">
          <SessionDropdown
            v-show="showReservedArea"
            :title="$t('navbar.reservedArea.title')"
            :menuList="personalList"
            @onLogout="onLogoutHandler"
          />
          <div
            :class="{
              'caetano-go-widget': true,
              'caetano-go-widget-logged': showReservedArea,
            }"
            data-idgo-widget
          ></div>
        </div>
      </template>
    </Navbar>
    <transition name="fade">
      <router-view :key="$route.path" />
    </transition>
    <div class="ql-bottom">
      <CpBottomQuicklinks
        @openLeadContact="openContactLead"
        @openLead="openTalkToUsLead"
        @subscribeNewsletter="callSubscribeNewsletter"
        :key="bottomQlKey"
      />
    </div>
    <Footer />

    <ComparePanel />
    <FavoritePanel />
    <Spinner :loading="isLoading" />
    <PanelLeadContact
      :financingLabels="financingLabels"
      :title="getPanelTitle"
      :button="$t('panels.generalButton')"
      :dealerOpts="dealers"
      @onClosePanel="closeContactLead"
      :isOpen="getContactPanelDisplay"
      :personal="personal"
      :key="userName"
      :vehicle="getContactPanel.vehicle"
      :optionalButton="getContactPanel.optionalButton"
      :optionalButtonType="getContactPanel.optionalButtonType"
      :optionalButtonDisabled="getContactPanel.optionalButtonDisabled"
      @submitContactLead="submitContactLead"
      @onClickOptionalButton="closeContactLead"
      :labels="contactLabels"
      :loggedIn="!isGuest"
      :leadType="getContactPanel.type"
      :loginDescription="$t('panels.generalLoginDescription')"
      @onLogin="onLogin"
      @onRegister="onRegister"
      :loginLabels="$t('panels.leadLogin')"
      :addWeekendDays="true"
    />
    <Alert
      class="alert-notification"
      v-if="getAlert.display"
      :type="getAlert.type"
      :message="getAlert.message"
      :global="getAlert.global"
      @closeAlert="closeAlert"
    />

    <PanelCarAlert
      @onClosePanel="closeCarAlertPanel"
      :isOpenPanel="isOpenCarAlert"
      :title="carAlert.title"
      :subtitles="carAlert.subtitles"
      :loginLabels="$t('panels.leadLogin')"
      :labels="carAlert.labels"
      :descriptions="carAlert.descriptions"
      :data="carAlert.data"
      :loggedIn="!isGuest"
      @onLogin="onLogin"
      @onRegister="onRegister"
      @submitCarAlert="handleSubmitCarAlert"
      ref="carAlert"
      :key="carAlertKey"
    />
  </div>
</template>

<script>
import { uuid } from "uuidv4";
import {
  Navbar,
  LinkList,
  Footer,
  Spinner,
  SessionDropdown,
} from "./components";

import {
  PanelLeadContact,
  Alert,
  PanelCarAlert,
} from "@sc/ds-ui-component-library";

import { getPriceFormatted } from "./utils/currency.js";
import { preLogin } from "./api/authentication";

import {
  reserveContact,
  reserveContactVechile,
  reservePriceDrop,
  reserveTestDrive,
  subscribeNewsletter,
  submitCarAlert,
} from "./api/bookVehicle";

import ComparePanel from "./components/sidebars/compare/ComparePanel.vue";
import FavoritePanel from "./components/sidebars/favorites/FavoritePanel.vue";

import TopBar from "./components/refactor/TopBar.vue";
import CpBottomQuicklinks from "./components/refactor/CpBottomQuicklinks.vue";

import { mapState, mapGetters, mapMutations } from "vuex";
import store from "./store";
import {
  newsLetterEvent,
  quandoDisponivelEvent,
} from "./utils/googleTagManager";

const Status = {
  NONE: 0,
  RESERVE: 1,
  TEST_DRIVE: 2,
  CONTACT: 3,
  PRICE_DROP: 4,
};

export default {
  components: {
    Alert,
    TopBar,
    Navbar,
    LinkList,
    Footer,
    ComparePanel,
    FavoritePanel,
    Spinner,
    CpBottomQuicklinks,
    SessionDropdown,
    PanelLeadContact,
    PanelCarAlert,
  },
  metaInfo() {
    return {
      title: this.$t("meta.homepage.title"),
    };
  },
  data() {
    return {
      priceDropType: "PRICE-DROP",
      showReservedArea: false,
      personalAction: "/pessoal",
      loginAction: "?action=login",
      logoutAction: "?action=logout",
      carAlertKey: 1234,
      preloginRes: {},
      bottomQlKey: 0,
      prodBaseAuth: false,
      renderComponent: true,
      isRegisterFormOpen: false,
      user: {},
      userObj: {},
      passwordSent: false,
      verificationAccountOpened: false,
      verifiedAccount: false,
      linkListClass: "none-selected",
      dataLayerObj: {
        "CONTACT-VEHICLE": "eec.contactoInformacao",
        "PRICE-DROP": "eec.baixaPreco",
        "TEST-DRIVE": "eec.testeDrive",
        CONTACT: "eec.ligar",
        "CONTACT-TALK-TO-US": "eec.formNotification",
      },
    };
  },
  async created() {
    var meta = document.createElement("meta");
    if (!process.env.VUE_APP_PROD) {
      meta.name = "robots";
      meta.content = "noindex, nofollow";
      document.head.appendChild(meta);
    }

    this.$store.dispatch("ConfigurationModule/loadConfiguration");
  },
  async mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onWindowResize);
    });

    if (!localStorage.getItem("dssessionId"))
      localStorage.setItem("dssessionId", uuid());

    if (!this.isGuest) await this.$store.dispatch("storeUserInfo");

    await this.$store.dispatch("CompareModule/loadVehiclesFromStorage");
    await this.$store.dispatch("StoreModule/fetchDealers");

    const response = await preLogin();
    this.preloginRes =
      response && response.data && response.data.data && response.data.data.url
        ? response.data.data.url
        : "";

    if (this.preloginRes) {
      // Load dynamic script
      let preloginScript = document.createElement("script");
      preloginScript.type = "text/javascript";
      preloginScript.async = true;
      preloginScript.src = this.preloginRes;
      preloginScript.id = "caetanogo_script-js";
      document.head.append(preloginScript);
    }

    setTimeout(async () => {
      if (
        document.querySelectorAll('[data-idgo-login=""]').length &&
        localStorage.getItem("isGuest") === "false" &&
        localStorage.getItem("token")
      ) {
        await this.onLogoutHandler();
        this.$nextTick(() => {
          document.location.reload();
        });
      } else if (
        document.querySelector("[data-idgo-dropdown-trigger]") &&
        localStorage.getItem("isGuest") === "true"
      ) {
        await store.dispatch("storeLogin", store.getters.getUserObj);
      } else if (
        document.querySelector("[data-idgo-dropdown-trigger]") &&
        localStorage.getItem("isGuest") === "false"
      ) {
        const oldHash = localStorage.getItem("sessionStatus");
        await store.dispatch("revalidateLogin", store.getters.getUserObj);
        const currentHash = localStorage.getItem("sessionStatus");
        if (oldHash !== currentHash) {
          // document.location.reload();
        }
      }
    }, 2000);

    const hasToOpenContactLead = this.$route.query.action === "contact";
    if (hasToOpenContactLead) {
      this.openContactLead();
    }
  },
  updated() {
    this.showReservedArea =
      localStorage.getItem("isGuest") === "false" &&
      localStorage.getItem("token");
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  watch: {
    $route(to, from) {
      const newUrl = to.fullPath;
      const fromUrl = from.fullPath;
      if (newUrl.includes(this.loginAction)) {
        const newUrl = sessionStorage.getItem("url");
        this.$router.push(newUrl);
      } else if (newUrl.includes(this.logoutAction)) {
        const newUrl = sessionStorage.getItem("url");
        this.$router.push(newUrl.split(this.personalAction)[0]);
      } else {
        sessionStorage.setItem("url", newUrl.replace(this.logoutAction, ""));
        sessionStorage.setItem(
          "previousUrl",
          fromUrl.replace(this.logoutAction, "")
        );
      }
      if (newUrl === this.list[0].url || newUrl.startsWith("/comprar-carro")) {
        this.linkListClass = "first-selected";
      } else if (newUrl === this.list[1].url) {
        this.linkListClass = "second-selected";
      } else if (newUrl === this.list[2].url) {
        this.linkListClass = "third-selected";
      } else {
        this.linkListClass = "none-selected";
      }
    },
    vehicleInUse(newVehicleInUse, oldVehicleInUse) {
      console.log(newVehicleInUse);
      console.log(oldVehicleInUse);
      if (
        newVehicleInUse &&
        oldVehicleInUse &&
        newVehicleInUse.carDetailCard &&
        oldVehicleInUse.carDetailCard &&
        newVehicleInUse.carDetailCard.vin &&
        oldVehicleInUse.carDetailCard.vin &&
        newVehicleInUse.carDetailCard.vin !== oldVehicleInUse.carDetailCard.vin
      ) {
        this.setStatus(Status.NONE);
        this.setReserveStep(1);
        this.setOnEditing(false);
        this.$store.commit("ReserveModule/resetReserve");
      }
    },
  },
  computed: {
    ...mapState([
      "isOpenSignupPopup",
      "isOpenSigninPopup",
      "isOpenVerificationAccount",
    ]),
    ...mapGetters([
      "getContactPanel",
      "getToken",
      "isGuest",
      "getIsOpenPopup",
      "getIsOpenSigninPopup",
      "getIsOpenVerificationAccount",
      "getUserObj",
      "getAlert",
    ]),
    ...mapGetters({
      isLoading: "spinnerModule/isLoading",
      compareCount: "CompareModule/count",
      favoritesCount: "FavoritesModule/count",
      dealers: "StoreModule/getDealersDropdowns",
      vehicleInUse: "VehicleModule/getVehicle",
      isOpenCarAlert: "PanelModule/isOpenCarAlert",
      getTags: "SearchModule/filter/getTags",
      getUserPhoneNumber: "getUserPhoneNumber",
      getFormattedFilters: "SearchModule/getFormattedFilters",
      getNeedle: "SearchModule/filter/getNeedle",
      personal: "UserModule/getPersonal",
      userName: "UserModule/getUserName",
      fromLogin: "SearchModule/filter/getFromLogin",
      isOpenReservePanel: "PanelModule/isOpenReserve",
    }),
    financingLabels() {
      return {
        entry: this.$t("vehicle.entry"),
        monthly: this.$t("vehicle.monthly"),
        financing: this.$t("vehicle.financing"),
        monthsLabel: "meses",
      };
    },
    favoriteIcon() {
      return (
        this.$t("resources.images.navbar.favorite") ||
        require("./assets/icons/favoriteNavbar.svg")
      );
    },
    compareIcon() {
      return (
        this.$t("resources.images.navbar.compare") ||
        require("./assets/icons/compare.svg")
      );
    },
    userIcon() {
      return (
        this.$t("resources.images.navbar.avatar") ||
        require("./assets/icons/avatar.svg")
      );
    },
    appLogo() {
      return (
        this.$t("resources.images.navbar.applogo") ||
        require("./assets/logo.svg")
      );
    },
    getContactPanelDisplay() {
      if (!this.isOpenReservePanel)
        this.getContactPanel.display
          ? document.body.classList.add("no-scroll")
          : document.body.classList.remove("no-scroll");
      return this.getContactPanel.display;
    },
    openPopup() {
      if (this.getIsOpenPopup) {
        this.forceRerender();
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
      return this.getIsOpenPopup;
    },
    carAlert() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.carAlertKey++;
      return {
        title: this.$t("panels.carAlert.title"),
        labels: {
          ...this.$t("panels.carAlert.labels"),
        },
        data: {
          user: {
            name: this.personal.name,
            email: this.personal.email,
            phone: this.personal.phone || "",
          },
          search: this.getTags,
        },
        subtitles: {
          ...this.$t("panels.carAlert.subtitles"),
        },
        descriptions: {
          ...this.$t("panels.carAlert.descriptions"),
        },
      };
    },
    getSelectedPage() {
      return this.linkListClass;
    },
    getPanelTitle() {
      switch (this.getContactPanel.type) {
        case "CONTACT":
          return this.$t("panels.leadTitles.contact.title");
        case "CONTACT-TALK-TO-US":
          return this.$t("panels.leadTitles.contact.title");
        case "CONTACT-VEHICLE":
          return this.$t("panels.leadTitles.contactVehicle.title");
        case "TEST-DRIVE":
          return this.$t("panels.leadTitles.testDrive.title");
        default:
          return this.$t("panels.generalLeadTitle");
      }
    },
    list() {
      return [...this.$t("navbar.menu")];
    },
    personalList() {
      return [
        {
          label: this.$t("navbar.reservedArea.redirects.reservations"),
          url: "/pessoal/reserva",
        },
        {
          label: this.$t("navbar.reservedArea.redirects.retakes"),
          url: "/pessoal/retoma",
        },
        {
          label: this.$t("navbar.reservedArea.redirects.searches"),
          url: "/pessoal/pesquisas-guardadas",
        },
        {
          label: this.$t("navbar.reservedArea.redirects.notifications"),
          url: "/pessoal/notificacao",
        },
      ];
    },
    contactLabels() {
      return {
        leadContact: this.$t("panels.contact.leadContact"),
        date: this.$t("panels.contact.dayLabel"),
        time: this.$t("panels.contact.hourLabel"),
        indicators: this.$t("panels.contact.indicators"),
        contactHours: this.$t("panels.contact.contactHours"),
      };
    },
  },
  methods: {
    ...mapMutations([
      "clearAlert",
      "clearContactPanel",
      "setAlert",
      "setContactPanel",
    ]),
    ...mapMutations({
      toggleCarAlert: "PanelModule/toggleCarAlert",
      setStatus: "PanelModule/setDetailPageStatus",
      setOnEditing: "PanelModule/setOnEditing",
      setReserveStep: "PanelModule/setReserveStep",
    }),
    onWindowResize() {
      this.$store.commit("setReponsivenessStatus", {
        windowWidth: window.innerWidth,
      });
    },
    async callSubscribeNewsletter(data) {
      const payload = {};
      payload.emailAddress = data && data.email ? data.email : "";
      payload.name = data && data.name ? data.name : "";
      payload.currentUrl = window.location.href || "";
      try {
        this.$store.commit("spinnerModule/setLoading", true, { root: true });
        const { ...response } = await subscribeNewsletter(payload);

        if (response && response.status === 200) {
          newsLetterEvent();
          this.setAlert({
            message: this.$t("toast.newsletter.success"),
            type: "success",
            display: true,
            global: true,
          });
        } else if (response && response.status === 409) {
          this.setAlert({
            message: this.$t("toast.newsletter.subscribed"),
            type: "error",
            display: true,
            global: true,
          });
        } else {
          this.setAlert({
            message: this.$t("toast.newsletter.error"),
            type: "error",
            display: true,
            global: true,
          });
        }
      } catch (error) {
        this.setAlert({
          message: this.$t("toast.newsletter.error"),
          type: "error",
          display: true,
          global: true,
        });
      }

      this.$store.commit("spinnerModule/setLoading", false, { root: true });
      this.bottomQlKey += 1;
    },
    closeAlert() {
      this.clearAlert();
    },
    async submitContactLead(data) {
      const payload = {};
      payload.name = data && data.name ? data.name : "";

      if (this.getContactPanel.type !== this.priceDropType) {
        payload.day =
          data && data.date ? new Date(data.timestamp * 1000) : new Date();
        payload.time = data && data.time ? data.time : "";
      }

      payload.dealerId = data && data.dealer ? data.dealer.key : "";
      payload.phoneNumber = data && data.phone ? data.phone : "";
      payload.emailAddress = data && data.email ? data.email : "";
      payload.infoCheck = data && data.personalData ? data.personalData : false;
      // REMAINING DATA
      payload.vin = this.getContactPanel.vehicle.vin || "";
      payload.currentUrl = window.location.href || "";
      payload.dropPriceNotificationDetails =
        this.vehicleInUse &&
        this.vehicleInUse.carDetailFinance &&
        this.vehicleInUse.carDetailFinance.pvp
          ? {
              active: true,
              currPvp: this.vehicleInUse.carDetailFinance.pvp,
              prevPvp: this.vehicleInUse.carDetailFinance.pvp,
              purged: false,
            }
          : {};
      payload.finance =
        this.vehicleInUse &&
        this.vehicleInUse.carDetailFinance &&
        this.vehicleInUse.carDetailFinance.pvp
          ? {
              fee: this.vehicleInUse.carDetailFinance.monthlyPayment,
              initialExpenses: 500,
              initialValue: this.vehicleInUse.carDetailFinance.depositValue,
              nbrPayments: this.vehicleInUse.carDetailFinance.months,
              productName: "Crédito",
              pvp: this.vehicleInUse.carDetailFinance.pvp,
              residualValue: this.vehicleInUse.carDetailFinance.residualValue,
              taeg: this.vehicleInUse.carDetailFinance.taeg,
              tan: this.vehicleInUse.carDetailFinance.tan,
            }
          : {};
      payload.tradeInId = "";

      try {
        this.$store.commit("spinnerModule/setLoading", true, { root: true });
        let response;

        switch (this.getContactPanel.type) {
          case "CONTACT":
            response = await reserveContact(payload);
            break;
          case "CONTACT-TALK-TO-US":
            response = await reserveContact(payload);
            break;
          case "CONTACT-VEHICLE":
            response = await reserveContactVechile(payload);
            break;
          case "PRICE-DROP":
            response = await reservePriceDrop(payload);
            break;
          case "TEST-DRIVE":
            response = await reserveTestDrive(payload);
            break;
          default:
            response = "";
        }

        if (response && response.status === 200) {
          const dataLayerContent = {
            event: this.dataLayerObj[this.getContactPanel.type],
            model: this.getContactPanel?.vehicle?.model,
            brand: this.getContactPanel?.vehicle?.brand,
          };

          if (
            !this.getContactPanel?.vehicle?.model ||
            !this.getContactPanel?.vehicle?.brand
          ) {
            delete dataLayerContent["model"];
            delete dataLayerContent["brand"];
          }

          if (this.getContactPanel.type === "CONTACT-TALK-TO-US") {
            dataLayerContent["timestamp"] = new Date().getTime();
            dataLayerContent["fields"] = {
              dealer: data.dealer.value,
            };
          }
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push(dataLayerContent);

          this.setAlert({
            message: this.$t("toast.contact.success"),
            type: "success",
            display: true,
            global: true,
          });
        } else {
          this.setAlert({
            message: this.$t("toast.contact.error"),
            type: "error",
            display: true,
            global: true,
          });
        }
      } catch (error) {
        this.setAlert({
          message: this.$t("toast.contact.error"),
          type: "error",
          display: true,
          global: true,
        });
      }

      this.$store.commit("spinnerModule/setLoading", false, { root: true });
      this.closeContactLead();
    },
    openContactLead() {
      this.setContactPanel({
        display: true,
        vehicle: {},
        personal: this.personal,
        type: "CONTACT",
      });
    },
    openTalkToUsLead() {
      this.setContactPanel({
        display: true,
        vehicle: {},
        personal: this.personal,
        type: "CONTACT-TALK-TO-US",
      });
    },
    closeContactLead() {
      this.clearContactPanel();
    },
    closeCarAlertPanel() {
      this.carAlertKey++;
      this.toggleCarAlert();
    },
    ...mapMutations({
      openComparePanel: "CompareModule/openPanel",
      openFavoritesPanel: "FavoritesModule/openPanel",
    }),
    getPriceFormatted,
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    async onLogoutHandler() {
      await this.$store.dispatch("storeLogout");
    },
    closeMobileMenu() {
      this.$store.state.isOpenNavbar = false;
      let menu = document.querySelector(".mobile-menu");
      menu.classList.remove("active");
    },
    openCompare() {
      this.closeMobileMenu();
      this.openComparePanel();
    },
    openFavoritePanel() {
      this.closeMobileMenu();
      if (!this.getToken || this.isGuest) {
        document.querySelectorAll("[data-idgo-login]")[0].click();
      } else {
        this.openFavoritesPanel();
      }
    },
    onLogin() {
      document.querySelectorAll("[data-idgo-login]")[0].click();
    },
    onRegister() {
      document.querySelectorAll("[data-idgo-registration]")[0].click();
    },
    async handleSubmitCarAlert(e) {
      const {
        name,
        email: emailAddress,
        title,
        phone: phoneNumber,
        personalData: infoCheck,
      } = e;
      var filters = this.getFormattedFilters || {};
      delete filters.needle;
      const payload = {
        name,
        day: new Date(),
        time: "",
        emailAddress,
        phoneNumber,
        infoCheck,
        dealerId: "",
        currentUrl: window.location.href || "",
        carAlert: {
          title,
          search: {
            needle: this.getNeedle,
            filters,
          },
        },
      };

      try {
        this.$store.commit("spinnerModule/setLoading", true, { root: true });
        const response = await submitCarAlert(payload);

        if (response && response.status === 200) {
          quandoDisponivelEvent();
          this.setAlert({
            message: this.$t("panels.carAlert.response.success"),
            type: "success",
            display: true,
            global: true,
          });
        } else {
          this.setAlert({
            message: this.$t("panels.carAlert.response.error"),
            type: "error",
            display: true,
            global: true,
          });
        }
      } catch (error) {
        this.setAlert({
          message: this.$t("panels.carAlert.response.error"),
          type: "error",
          display: true,
          global: true,
        });
      }

      this.$store.commit("spinnerModule/setLoading", false, { root: true });

      this.closeCarAlertPanel();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .panel {
  .panel--content .panel--content-inner::-webkit-scrollbar {
    display: block;
    width: 12px;
  }
  .panel--content .panel--content-inner::-webkit-scrollbar-thumb {
    background: lightgrey;
  }
  .panel--content .panel--content-inner::-webkit-scrollbar-thumb:active,
  .panel--content .panel--content-inner::-webkit-scrollbar-thumb:hover {
    background: darkgrey;
  }
  .panel--content .vertical-card {
    .tooltip .left {
      transform: translate(5%, 60%);
    }
  }
}

.compare-balance {
  margin-right: 1rem;
  width: 1.75rem;
  height: 1.75rem;
  margin: 0 auto;
  margin-top: 8px;
}

.none-selected {
  ::v-deep a .link--bold-normal {
    font-weight: 600;
  }
}

.first-selected {
  :nth-child(1) ::v-deep a .link--bold-normal {
    font-weight: bold;
  }
  ::v-deep a .link--bold-normal {
    font-weight: 600;
  }
}
.second-selected {
  :nth-child(2) ::v-deep a .link--bold-normal {
    font-weight: bold;
  }
  ::v-deep a .link--bold-normal {
    font-weight: 600;
  }
}
.third-selected {
  :nth-child(3) ::v-deep a .link--bold-normal {
    font-weight: bold;
  }
  ::v-deep a .link--bold-normal {
    font-weight: 600;
  }
}

.favorite-navbar {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.compare-balance:hover,
.favorite-navbar:hover {
  cursor: pointer;
}

/* RightSidebar */

.right-sidebar .right-sidebar--content {
  grid-template-columns: repeat(auto-fill, 231px);
  grid-gap: 5px;
  margin-bottom: 10px;
}

.right-sidebar--content a {
  height: 252px;
  width: 231px;
}

.right-sidebar--content a ::v-deep .search-vehicle-result-card {
  height: 270px;
  width: 231px;
}

.right-sidebar--content a ::v-deep .image--wrapper {
  height: 120px;
  background-color: white;
}

.right-sidebar--content
  a
  ::v-deep
  .card--content
  .image--wrapper
  .block--content
  .image {
  height: 120px;
  width: 100%;
}

.right-sidebar--content a ::v-deep .container--wrapper {
  margin-bottom: 15px;
}

.right-sidebar--content a ::v-deep .info--wrapper {
  height: 252px;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.right-sidebar--content a ::v-deep .title--content .label {
  font-size: 14px;
  line-height: 16px;
}

.right-sidebar--content a ::v-deep .title--content {
  width: 50%;
}

.right-sidebar--content a ::v-deep .title--content .price-model-description {
  font-size: 10px;
  line-height: 15px;
  margin-top: 15px;
}

.right-sidebar--content a ::v-deep .finance--container .label {
  font-size: 16px;
  line-height: 16px;
}

.right-sidebar--content a ::v-deep .finance--wrapper .label {
  font-size: 10px;
  line-height: 10px;
  margin-top: 10px;
}

.right-sidebar--content a ::v-deep .finance--container .finance-model-taeg {
  font-size: 10px;
  line-height: 10px;
}

.right-sidebar--content
  a
  ::v-deep
  .block--content
  .search-vehicle-result-card-info-label {
  font-size: 12px;
  line-height: 12px;
}

.right-sidebar--content a ::v-deep .checkbox--checkmark {
  width: 1.25rem;
  height: 1.25rem;
}

.right-sidebar--content a ::v-deep .checkbox--container {
  width: 1.25rem;
  height: 1.25rem;
}

.right-sidebar--content a ::v-deep .toggle--button-icon {
  width: 1.25rem;
  height: 1.25rem;
}

.right-sidebar--content a ::v-deep .compare-car--wrapper {
  right: 10px;
  top: 35px;
}

.right-sidebar--content
  a
  ::v-deep
  .checkbox--container
  .checkbox--checkmark.checkbox--secondary::after {
  top: 1px;
  left: 6px;
}

::v-deep .right-sidebar--content .toggle--button-compare-icon {
  width: 1rem;
  height: 1rem;
  top: 35px;
  right: 16px;
}

::v-deep .right-sidebar--content .toggle--button-compare {
  width: 1rem;
  height: 1rem;
  top: 35px;
  right: 16px;
}

.right-sidebar--content a {
  margin-bottom: 18px;
}

.button-compare {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 280px;
  height: 60px;
  margin-bottom: 20px;
  margin-right: 20px;
}

.notify-badge {
  position: absolute;
  /* right: -20px; */
  top: -6px;
  background: #02226f;
  text-align: center;
  border-radius: 50%;
  color: white;
  padding: 1px;
  font-size: 12px;
  margin-left: 17px;
  width: 18px;
  height: 18px;
}

.account-image {
  width: 40px;
  height: 40px;
}

.session--link {
  color: #333;
  margin-left: 0.625rem;
}

.navbar--favorite-icon,
.navbar--compare-icon {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  height: 28px;
  width: 28px;
}

.icon--wrapper {
  display: grid;
  place-items: center;
  height: 44px;
  width: 44px;
  margin-right: 20px;
}

.usericon--wrapper {
  display: grid;
  place-items: center;
  height: 40px;
  width: 40px;
}

.usericon--wrapper > div {
  position: relative;
}

.icon--wrapper > div {
  position: relative;
}

.logo-container {
  display: grid;
  place-items: center;
  width: 253px;
  height: 52px;
}

@media (max-width: 1280px) {
  .logo-container {
    width: 200px;
  }
}

@media (max-width: 768px) {
  .navbar--compare-icon,
  .navbar--favorite-icon {
    height: 24px !important;
  }
  .navbar--compare-icon {
    margin-right: -24rem;
  }
  .logo-container {
    width: 150px;
  }
}

// ::v-deep .link-list .item a .link--bold-normal {
//   font-weight: 600;
// }

.ql-bottom {
  padding: 16px;
  background-color: var(--color-7);
}

.reserved-area {
  display: flex;
  gap: 20px;
}

::v-deep .idgo-widget .idgo-wrapper .options .user > .dropdown {
  z-index: 10;
}
.alert-notification {
  position: fixed;
  z-index: 3000;
  top: 16px;
  right: 16px;
  left: 16px;
  margin: auto;
  gap: 10px;

  ::v-deep {
    .left {
      gap: 10px;
    }
    .icon {
      cursor: pointer;
    }
  }
}

@media (min-width: 1024px) {
  .alert-notification {
    position: fixed;
    z-index: 3000;
    top: 16px;
    right: 16px;
    left: auto;
    //width: 687px;
    margin: 0px;
    gap: 10px;

    ::v-deep {
      .left {
        gap: 10px;
      }
      .icon {
        cursor: pointer;
      }
    }
  }
}

.caetano-go-widget {
  z-index: 10;

  ::v-deep .idgo-widget img {
    filter: invert(100%) sepia(12%) saturate(2%) hue-rotate(136deg)
      brightness(112%) contrast(101%);
  }
  ::v-deep .user span {
    color: #ffffff !important;
  }
}

.reserved-area {
  width: fit-content;
  justify-content: center;

  .caetano-go-widget {
    width: 240px;
    justify-content: center;
  }

  .caetano-go-widget-logged {
    width: 170px;
    justify-content: center;
  }

  .session-dropdown {
    width: 170px;
    justify-content: center;
  }

  ::v-deep {
    .dropdown--title {
      padding-bottom: 10px;
      color: white;
    }
    .dropdown--title-icon {
      height: 20px;
    }
    .dropdown--title-icon svg {
      filter: invert(100%) sepia(12%) saturate(2%) hue-rotate(136deg)
        brightness(112%) contrast(101%);
    }
    .dropdown--title label {
      color: white;
    }
  }
}

.navbar {
  background-color: var(--theme-color-secondary);
  height: 88px;
}

@media (max-width: 1920px) {
  ::v-deep .panel {
    --panel-header-inner-title-font-size: 1.25rem;
    --panel-header-inner-title-line-height: 1.688rem;

    .lead-tradein-info {
      --title-font-size: 16px;
      --title-line-height: 16px;
      --subtitle-font-size: 12px;
      --subtitle-line-height: 14px;
    }
    .lead-sell .lead-sell-icon-block {
      --title-font-size: 2.188rem;
      --subtitle-font-size: 1rem;
    }
    .step-progress--title {
      --text-label-font-size: 0.8rem;
    }
  }
}

@media (max-width: 1440px) {
  ::v-deep .panel {
    --panel-responsive-width: 620px;
    --panel-content-inner-padding: 28px 36px;
    .panel--footer .panel--footer-inner {
      --button-font-size: 16px;
      --button-line-height: 16px;
    }
    .button {
      font-size: 17px;
    }
  }
  .navbar {
    height: 75px;
  }
  .topbar--container {
    padding: 24px 28px;
  }
  ::v-deep .section-12-blocks {
    .section-12-block {
      padding: 32px 32px;

      &.section-12-block-3 {
        padding: 32px 32px;
      }
      .section-12-block-1-links {
        flex-direction: row;
        --baselink-md-font-size: 14px;

        .conjuction {
          margin: 0 8px;
        }
      }

      .block-title {
        --text-label-font-size: 21px;
        --text-label-weight: 800;
      }
      &.section-12-block-2 {
        .block--content {
          .caption {
            --text-label-font-size: 28px;
          }
          .text {
            --text-label-font-size: 16px;
          }
        }
      }
    }
  }
}
@media (max-width: 1366px) {
  ::v-deep .panel {
    --panel-responsive-width: 590px;
    --panel-content-inner-padding: 26px 34px;
  }
  .navbar {
    height: 64px;
  }

  .topbar--container {
    padding: 18px 28px;
  }

  ::v-deep .section-12-blocks {
    .section-12-block {
      padding: 32px 32px;

      &.section-12-block-3 {
        padding: 32px 32px;
      }
      .section-12-block-1-links {
        flex-direction: row;
        --baselink-md-font-size: 14px;

        .conjuction {
          margin: 0 8px;
        }
      }

      .block-title {
        --text-label-font-size: 21px;
        --text-label-weight: 800;
      }
      &.section-12-block-2 {
        .block--content {
          .caption {
            --text-label-font-size: 28px;
          }
          .text {
            --text-label-font-size: 16px;
          }
        }
      }
    }
  }
}
@media (max-width: 1280px) {
  ::v-deep .panel {
    --panel-header-inner-padding: 30px 32px;
    --panel-responsive-width: 560px;
    --panel-content-inner-padding: 24px 32px;
    .panel--footer {
      --button-padding: 19px 40px;
    }
    .button {
      font-size: 16px;
    }
    .panel--content .vertical-card {
      .tooltip .left {
        transform: translate(20%, 70%);
      }
    }
  }
  .topbar--container {
    padding: 14px 28px;
  }

  ::v-deep .section-12-blocks {
    .section-12-block {
      padding: 32px 32px;

      &.section-12-block-3 {
        padding: 32px 32px;
      }
      .section-12-block-1-links {
        flex-direction: row;
        --baselink-md-font-size: 14px;

        .conjuction {
          margin: 0 8px;
        }
      }

      .block-title {
        --text-label-font-size: 21px;
        --text-label-weight: 800;
      }
      &.section-12-block-2 {
        .block--content {
          .caption {
            --text-label-font-size: 28px;
          }
          .text {
            --text-label-font-size: 16px;
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  ::v-deep .panel {
    --panel-content-inner-padding: 24px 30px;
    --panel-responsive-width: 530px;
  }
}

@media (max-width: 768px) {
  _::-webkit-full-page-media,
  ::v-deep .panel.opened {
    --panel-height: 100%;
  }

  ::v-deep .panel {
    --panel-content-inner-padding: 24px 24px;
    width: 100% !important;

    .lead-tradein-info {
      --title-3-font-size: 16px;
      --title-3-line-height: 16px;
      --subtitle-font-size: 12px;
      --subtitle-line-height: 14px;
    }
    .panel--content .vertical-card {
      .tooltip--text {
        --tooltip-width-large: 270px;
      }
      .tooltip .left {
        transform: translate(0%, 70%);
      }
    }
  }
  .topbar--container {
    padding: 16px;
  }
  @media (max-width: 530px) {
    ::v-deep .vertical-card {
      .car-image {
        max-height: unset;
      }
      .block--content {
        height: unset;
      }
      .top {
        --lead-intro-subtitle-margin-bottom: 5px;
      }
      .bottom {
        flex-direction: row;
        align-items: start;
      }
    }
  }
}

@media (min-width: 768px) {
  .favorite-navbar {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0.75rem /* 12px */;
  }
}
</style>
